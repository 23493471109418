import React, { useContext, useState } from 'react'
import { useTimeout } from '@deal/dom-hooks'

type QuickReplyContextType = {
  defaultTags: string[]
  highlightQuickReplyIcon: boolean
  showQuickReplyTooltip: boolean
  showQuickReplyUserMenuTooltip: boolean
  showQuickReplyPopover: boolean
  showManageQuickRepliesModal: boolean
  setShowManageQuickRepliesModal: (value: boolean) => void
  setHighlightQuickReplyIcon: (value: boolean) => void
  setShowQuickReplyTooltip: (value: boolean) => void
  setShowQuickReplyPopover: (value: boolean) => void
  setShowQuickReplyUserMenuTooltip: (value: boolean) => void
  setDefaultTags: (tags: string[]) => void
  resetDefaultTag: () => void
  resetContext: () => void
}

const QuickReplyContext = React.createContext<QuickReplyContextType>({
  defaultTags: [''],
  highlightQuickReplyIcon: false,
  showQuickReplyTooltip: false,
  showQuickReplyUserMenuTooltip: false,
  showQuickReplyPopover: false,
  showManageQuickRepliesModal: false,
  setShowManageQuickRepliesModal: () => {},
  setHighlightQuickReplyIcon: () => {},
  setShowQuickReplyPopover: () => {},
  setShowQuickReplyTooltip: () => {},
  setShowQuickReplyUserMenuTooltip: () => {},
  setDefaultTags: () => {},
  resetDefaultTag: () => {},
  resetContext: () => {}
})

const QuickReplyContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const initialDefaultTag = 'QUICK_REPLIES'
  const [defaultTags, setDefaultTags] = useState([initialDefaultTag])
  const [highlightQuickReplyIcon, setHighlightQuickReplyIcon] = useState(false)
  const [showQuickReplyTooltip, setShowQuickReplyTooltip] = useState(false)
  const [showQuickReplyUserMenuTooltip, setShowQuickReplyUserMenuTooltip] = useState(false)
  const [showQuickReplyPopover, setShowQuickReplyPopover] = useState(false)
  const [showManageQuickRepliesModal, setShowManageQuickRepliesModal] = useState(false)

  // Autoclose the tooltip
  useTimeout(
    () => setShowQuickReplyUserMenuTooltip(false),
    showQuickReplyUserMenuTooltip ? 5000 : null
  )

  return (
    <QuickReplyContext.Provider
      value={{
        defaultTags,
        highlightQuickReplyIcon,
        showQuickReplyTooltip,
        showQuickReplyUserMenuTooltip,
        showQuickReplyPopover,
        showManageQuickRepliesModal,
        setShowManageQuickRepliesModal,
        setShowQuickReplyPopover,
        setHighlightQuickReplyIcon,
        setShowQuickReplyTooltip,
        setShowQuickReplyUserMenuTooltip,
        setDefaultTags,
        resetDefaultTag: () => setDefaultTags([initialDefaultTag]),
        resetContext: () => {
          setDefaultTags([initialDefaultTag])
          setHighlightQuickReplyIcon(false)
          setShowQuickReplyTooltip(false)
        }
      }}
    >
      {children}
    </QuickReplyContext.Provider>
  )
}

const useQuickReplyContext = () => {
  const quickReplyContext = useContext(QuickReplyContext)

  if (!quickReplyContext) {
    throw new Error('Invoked QuickReplyContext outside of provider')
  }

  return quickReplyContext
}

export { QuickReplyContext, QuickReplyContextProvider, useQuickReplyContext }
