import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

interface Props {
  title?: string
  className?: string
}

const MenuSection: React.FC<React.PropsWithChildren<Props>> = ({ children, title, className }) => {
  return (
    <div className={styles.container}>
      {title && <div className={styles.menuSectionTitle}>{title}</div>}
      <ul className={classnames(styles.menuSection, className)}> {children}</ul>
    </div>
  )
}

export default MenuSection
