import React from 'react'
import numeral from 'numeral'
import classnames from 'classnames'
import { Tooltip } from '@deal/components'
import { BusinessUserState } from '#src/generated/types'
import { formatMonetaryAmountAsJsx } from '#src/app/services/money'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import styles from './styles.css'

const ExpertPayInformation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const businessUser = useBusinessUser()

  const pendingCommissionBasisAmount = businessUser.pendingPayoutTotal.commissionBasisAmount
  const completedCommissionBasisAmount = businessUser.completedPayoutTotal.commissionBasisAmount

  if (businessUser.state === BusinessUserState.PENDING) {
    return null
  }

  const isPendingPayoutTotalPositive = numeral(pendingCommissionBasisAmount.amount).value() > 0

  return (
    <div className={styles.container}>
      <div className={styles.completedAmount}>
        <Tooltip
          message="Revenue up to last payout"
          placement="right"
          appendTo={() => document.body}
        >
          <>{formatMonetaryAmountAsJsx(completedCommissionBasisAmount)}</>
        </Tooltip>
      </div>
      <div
        className={classnames(styles.pendingAmount, {
          [styles.green]: isPendingPayoutTotalPositive
        })}
      >
        {isPendingPayoutTotalPositive && <span className={styles.plus}>+</span>}
        <Tooltip
          message="Revenue since last payout"
          placement="right"
          appendTo={() => document.body}
        >
          <>{formatMonetaryAmountAsJsx(pendingCommissionBasisAmount)}</>
        </Tooltip>
      </div>
    </div>
  )
}

export default ExpertPayInformation
