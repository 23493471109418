import { BusinessUserTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCuratedItemAddedEventProperties {
  sellable_id: string
  curated_item_id: string
  curated_list_id?: string
  lead_id: string
  activity_id?: string
  badges?: string[]
  source: BusinessUserTracking.ExpertCuratedItemAddedSource
  list_id?: string
  position?: number
}

export class ExpertCuratedItemAddedEvent extends Event<ExpertCuratedItemAddedEventProperties> {
  static displayName = 'Expert Curated Item Added'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
