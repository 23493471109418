import React from 'react'
import { AuthenticatedBusinessUserFragment } from "../../fragments/myself.generated";

type BusinessUser = AuthenticatedBusinessUserFragment | null

// Context
// Hack to avoid having to check for businessUser when consuming context
// if context is used outside provider businessUser will be undefined
const BusinessUserContext = React.createContext<BusinessUser>(null)

// Consumer
const BusinessUserConsumer = BusinessUserContext.Consumer

interface Props {
  businessUser: AuthenticatedBusinessUserFragment
}

const BusinessUserProvider: React.FC<React.PropsWithChildren<Props>> = ({ businessUser, children }) => {
  return (
    <BusinessUserContext.Provider value={businessUser}>{children}</BusinessUserContext.Provider>
  )
}

export { BusinessUserProvider, BusinessUserConsumer, BusinessUserContext, BusinessUser }
