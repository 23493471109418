import React, { useState } from 'react'

// Context
type InboxSnapshotContextType = {
  showFollowUpNudge: boolean
  showClaimLeadFromPoolConfirmation: boolean
  showFollowUpSuccessModal: boolean
  showFollowUpSuccessHeader: boolean
  currentFollowUpLeadCount: number
  setShowFollowUpNudge: (showFollowUpNudge: boolean) => void
  setShowClaimLeadFromPoolConfirmation: (showClaimLeadFromPoolConfirmation: boolean) => void
  setShowFollowUpSuccessModal: (showFollowUpSuccessModal: boolean) => void
  setShowFollowUpSuccessHeader: (showFollowUpSuccessHeader: boolean) => void
  setCurrentFollowUpLeadCount: (currentFollowUpLeadCount: number) => void
}

const InboxSnapshotContext = React.createContext<InboxSnapshotContextType>({
  showFollowUpNudge: true,
  showClaimLeadFromPoolConfirmation: false,
  showFollowUpSuccessModal: false,
  showFollowUpSuccessHeader: false,
  currentFollowUpLeadCount: 0,
  setShowFollowUpNudge: () => {},
  setShowClaimLeadFromPoolConfirmation: () => {},
  setShowFollowUpSuccessModal: () => {},
  setShowFollowUpSuccessHeader: () => {},
  setCurrentFollowUpLeadCount: () => {}
})

// Consumer
const InboxSnapshotConsumer = InboxSnapshotContext.Consumer

// Provider
const InboxSnapshotProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [showFollowUpNudge, setShowFollowUpNudge] = useState(true)
  const [showClaimLeadFromPoolConfirmation, setShowClaimLeadFromPoolConfirmation] = useState(false)
  const [showFollowUpSuccessModal, setShowFollowUpSuccessModal] = useState(false)
  const [showFollowUpSuccessHeader, setShowFollowUpSuccessHeader] = useState(false)
  const [currentFollowUpLeadCount, setCurrentFollowUpLeadCount] = useState(0)

  const value = {
    showFollowUpNudge,
    showClaimLeadFromPoolConfirmation,
    showFollowUpSuccessModal,
    showFollowUpSuccessHeader,
    currentFollowUpLeadCount,
    setShowFollowUpNudge,
    setShowClaimLeadFromPoolConfirmation,
    setShowFollowUpSuccessModal,
    setShowFollowUpSuccessHeader,
    setCurrentFollowUpLeadCount,
  }

  return <InboxSnapshotContext.Provider value={value}>{children}</InboxSnapshotContext.Provider>
}

export {
  InboxSnapshotContext,
  InboxSnapshotConsumer,
  InboxSnapshotProvider
}