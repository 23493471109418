import { useContext } from 'react'
import { BusinessUserContext } from '#src/app/context/BusinessUser'

const useBusinessUser = () => {
  const businessUser = useContext(BusinessUserContext)

  if (!businessUser) {
    throw new Error('Can not access business user context outside of the provider')
  }

  return businessUser
}

export default useBusinessUser
