import React, { useContext, useState } from 'react'
import { Page } from '#src/app/components/QuickCurationFlyout'

type TrainingContextType = {
  exerciseTrackingId?: string
  quickCurationFlyoutPage: Page | null
  setExerciseTrackingId?: (id?: string) => void
  setQuickCurationFlyoutPage: (page: Page | null) => void
}

// Context
const TrainingContext = React.createContext<TrainingContextType>({
  quickCurationFlyoutPage: null,
  setQuickCurationFlyoutPage: () => {}
})

// Consumer
const TrainingConsumer = TrainingContext.Consumer

// Provider
const TrainingProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [exerciseTrackingId, setExerciseTrackingId] = useState<string>()
  const [quickCurationFlyoutPage, setQuickCurationFlyoutPage] = useState<Page | null>(null)

  return (
    <TrainingContext.Provider
      value={{
        exerciseTrackingId: exerciseTrackingId,
        quickCurationFlyoutPage,
        setExerciseTrackingId: id => setExerciseTrackingId(id),
        setQuickCurationFlyoutPage
      }}
    >
      <>{children}</>
    </TrainingContext.Provider>
  )
}

const useTrainingContext = () => {
  const trainingContext = useContext(TrainingContext)

  return trainingContext
}

export { TrainingConsumer, TrainingProvider, TrainingContext, useTrainingContext }
