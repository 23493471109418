import React, { useEffect, useState } from 'react'
import { useLocation } from '@deal/router'
import SurveyPathModal from '#src/app/components/SurveyPathModal'
import ExpertKnowledgeTestPathModal from '../ExpertKnowledgeTestPathModal/index'

const ExpertPathModal: React.FC<React.PropsWithChildren<unknown>> = ({}) => {
  const [surveyName, setSurveyName] = useState<string | undefined>(undefined)
  const [expertKnowledgeTestName, setExpertKnowledgeTestName] = useState<string | undefined>(
    undefined
  )
  const [sourceKey, setSourceKey] = useState<string | undefined>(undefined)
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('expert-nps-survey')) {
      setSurveyName('s-nps-experts-2x-season')
    } else if (params.has('expert-deactivation-survey')) {
      setSurveyName('s-expert-deactivation')
    } else if (params.has('expert-onboarding-exit-survey')) {
      setSurveyName('s-expert-onboarding-exit')
    } else if (params.get('survey')?.startsWith('s-')) {
      setSurveyName(params.get('survey')!)
    } else {
      setSurveyName(undefined)
    }

    if (params.get('expertKnowledgeTest')?.startsWith('ekt-')) {
      setExpertKnowledgeTestName(params.get('expertKnowledgeTest')!)
    }
    setSourceKey(params.get('sk') || undefined)
  }, [location.search])

  if (surveyName) {
    return (
      <SurveyPathModal
        onCloseRequested={() => setSurveyName(undefined)}
        isOpen={true}
        sourceKey={sourceKey || 'external-link'}
        ctaName="external-link"
        pathSchemaSlug={surveyName}
      />
    )
  } else if (expertKnowledgeTestName) {
    return (
      <ExpertKnowledgeTestPathModal
        onCloseRequested={() => setExpertKnowledgeTestName(undefined)}
        isOpen={true}
        sourceKey={sourceKey || 'external-link'}
        ctaName="external-link"
        pathSchemaSlug={expertKnowledgeTestName}
      />
    )
  } else {
    return null
  }
}

export default ExpertPathModal
