// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadEscalationFragmentDoc } from '../../fragments/leadEscalation.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadEscalationQueryVariables = Types.Exact<{
  id: Types.Scalars['LeadEscalationId']['input'];
}>;


export type LeadEscalationQuery = { __typename: 'Query', leadEscalation: { __typename: 'LeadEscalation', id: any, createdAt: any, type: Types.LeadEscalationType, level: Types.LeadEscalationLevel, ownerId?: any | null, expertsNotifiedAt?: any | null, teamNotifiedAt?: any | null, supportNotifiedAt?: any | null, closedAt?: any | null, status: Types.LeadEscalationStatus, acceptAsLeadExpertRole: Types.LeadExpertRole, owner?: { __typename: 'BusinessUser', id: any, displayName: string, shortName: string } | null, lead: { __typename: 'Lead', id: any, delegateExpert?: { __typename: 'BusinessUser', id: any } | null, expert: { __typename: 'BusinessUser', id: any }, consumer: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, vip: boolean }, conversation: { __typename: 'Conversation', id: any, externalId: string } } } };


export const LeadEscalationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LeadEscalation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadEscalationId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadEscalation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadEscalation"}}]}}]}},...LeadEscalationFragmentDoc.definitions]} as unknown as DocumentNode;
export type LeadEscalationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeadEscalationQuery, LeadEscalationQueryVariables>, 'query'> & ({ variables: LeadEscalationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LeadEscalationComponent = (props: LeadEscalationComponentProps) => (
      <ApolloReactComponents.Query<LeadEscalationQuery, LeadEscalationQueryVariables> query={LeadEscalationDocument} {...props} />
    );
    

/**
 * __useLeadEscalationQuery__
 *
 * To run a query within a React component, call `useLeadEscalationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadEscalationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadEscalationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeadEscalationQuery(baseOptions: Apollo.QueryHookOptions<LeadEscalationQuery, LeadEscalationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadEscalationQuery, LeadEscalationQueryVariables>(LeadEscalationDocument, options);
      }
export function useLeadEscalationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadEscalationQuery, LeadEscalationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadEscalationQuery, LeadEscalationQueryVariables>(LeadEscalationDocument, options);
        }
export function useLeadEscalationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LeadEscalationQuery, LeadEscalationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LeadEscalationQuery, LeadEscalationQueryVariables>(LeadEscalationDocument, options);
        }
export type LeadEscalationQueryHookResult = ReturnType<typeof useLeadEscalationQuery>;
export type LeadEscalationLazyQueryHookResult = ReturnType<typeof useLeadEscalationLazyQuery>;
export type LeadEscalationSuspenseQueryHookResult = ReturnType<typeof useLeadEscalationSuspenseQuery>;
export type LeadEscalationQueryResult = Apollo.QueryResult<LeadEscalationQuery, LeadEscalationQueryVariables>;