// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MarketHeatChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type MarketHeatChangedSubscription = { __typename: 'Subscription', marketHeatChanged: { __typename: 'MarketHeatChangedPayload', marketHeatLevel: Types.MarketHeatLevel } };


export const MarketHeatChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"MarketHeatChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"marketHeatChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"marketHeatLevel"}}]}}]}}]} as unknown as DocumentNode;
export type MarketHeatChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<MarketHeatChangedSubscription, MarketHeatChangedSubscriptionVariables>, 'subscription'>;

    export const MarketHeatChangedComponent = (props: MarketHeatChangedComponentProps) => (
      <ApolloReactComponents.Subscription<MarketHeatChangedSubscription, MarketHeatChangedSubscriptionVariables> subscription={MarketHeatChangedDocument} {...props} />
    );
    

/**
 * __useMarketHeatChangedSubscription__
 *
 * To run a query within a React component, call `useMarketHeatChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMarketHeatChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketHeatChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useMarketHeatChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<MarketHeatChangedSubscription, MarketHeatChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MarketHeatChangedSubscription, MarketHeatChangedSubscriptionVariables>(MarketHeatChangedDocument, options);
      }
export type MarketHeatChangedSubscriptionHookResult = ReturnType<typeof useMarketHeatChangedSubscription>;
export type MarketHeatChangedSubscriptionResult = Apollo.SubscriptionResult<MarketHeatChangedSubscription>;