import React, { useEffect, useState } from 'react'
import { Button, CircleLoader, FullBlockLoader, Image, SimpleModal } from '@deal/components'
import useBreakpointWrapper from '#src/app/hooks/useBreakpoint'
import { GlobalView, useGlobalViewsContext } from '#src/app/context/GlobalViews'
import ImageThumbnails from '#src/app/components/ImageThumbnails'
import ArrowRightIcon from './right.svg'
import ArrowLeftIcon from './left.svg'
import CloseIcon from './close.svg'
import styles from './styles.css'

type ImageType = {
  id: string
  url: string
}

// Utility function returns current zoomed image source, whether or not there is a previous / next image.
const getCurrentZoomedImageInfo = (args: {
  zoomImageSourceIndex?: number
  images: ImageType[]
}) => {
  const { zoomImageSourceIndex, images } = args
  if (zoomImageSourceIndex === undefined || !images || images.length === 0) {
    return { zoomedImageSource: undefined, previousImageExists: false, nextImageExists: false }
  } else {
    return {
      zoomedImageSource: images[zoomImageSourceIndex].url,
      previousImageExists: !!images[zoomImageSourceIndex - 1],
      nextImageExists: !!images[zoomImageSourceIndex + 1]
    }
  }
}

// Utility functions used for incrementing/decrementing a number that also might be undefined
const incrementMaybeNumber = (currentIndex?: number) => {
  return typeof currentIndex === 'number' ? ++currentIndex : undefined
}

const decrementMaybeNumber = (currentIndex?: number) => {
  return typeof currentIndex === 'number' ? --currentIndex : undefined
}

interface ImageModalProps {
  src: string
  loading?: boolean
  thumbnailImages?: ImageType[]
  onImageSelected?: (url: string) => void
  onImageThumbnailClicked?: (idx: number) => void
  onNext?: () => void
  onPrevious?: () => void
  onModalClose: () => void
}

const ImageModal: React.FC<React.PropsWithChildren<React.PropsWithChildren<ImageModalProps>>> = ({
  src,
  loading,
  thumbnailImages,
  onImageSelected,
  onImageThumbnailClicked,
  onModalClose,
  onNext,
  onPrevious
}) => {
  const isLargerThanSm = useBreakpointWrapper('sm')
  const { openView, closeView } = useGlobalViewsContext()
  const [isImageLoading, setIsImageLoading] = useState(false)
  function handleKeyPress(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowLeft':
        onPrevious?.()
        break
      case 'ArrowRight':
        onNext?.()
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [onNext, onPrevious])

  useEffect(() => {
    if (src) {
      setIsImageLoading(true)
    }
  }, [src])

  useEffect(() => {
    openView(GlobalView.ImageModal)
    return () => closeView(GlobalView.ImageModal)
  }, [])

  const showImageThumbnails = !!thumbnailImages && thumbnailImages.length > 1

  return (
    <SimpleModal
      isOpen
      size="large"
      onRequestClose={onModalClose}
      elevation="highest"
      className={styles.modal}
      modalContentClassName={styles.modalContent}
    >
      {(loading || isImageLoading) && (
        <div className={styles.loading}>
          <FullBlockLoader loader={CircleLoader} />
        </div>
      )}
      <Image
        src={src}
        size="90vw"
        className={styles.image}
        htmlAttributes={{
          onLoad: () => setIsImageLoading(false)
        }}
      />
      {isLargerThanSm && showImageThumbnails && (
        <div className={styles.imageThumbnailsContainer}>
          <ImageThumbnails
            images={thumbnailImages}
            selectedImageIndex={thumbnailImages.findIndex(image => image.url === src)}
            onImageClicked={idx => onImageThumbnailClicked?.(idx)}
          />
        </div>
      )}
      {onPrevious && <ArrowLeftIcon className={styles.arrowLeft} onClick={onPrevious} />}
      {onNext && <ArrowRightIcon className={styles.arrowRight} onClick={onNext} />}
      <CloseIcon className={styles.close} onClick={() => onModalClose()} />
      {onImageSelected && (
        <Button
          variant="neutral-dark"
          size="small"
          className={styles.button}
          onClick={() => onImageSelected(src)}
        >
          Use image
        </Button>
      )}
    </SimpleModal>
  )
}

export default ImageModal
export { getCurrentZoomedImageInfo, incrementMaybeNumber, decrementMaybeNumber }
