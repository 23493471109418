// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertBroadcastLeadEligibilityChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertBroadcastLeadEligibilityChangedSubscription = { __typename: 'Subscription', expertBroadcastLeadEligibilityChanged: { __typename: 'ExpertLeadEligibilityChange', id: any, eligibility: { __typename: 'ExpertLeadEligibility', eligible: boolean, detailReason?: string | null } } };


export const ExpertBroadcastLeadEligibilityChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertBroadcastLeadEligibilityChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertBroadcastLeadEligibilityChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"eligibility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eligible"}},{"kind":"Field","name":{"kind":"Name","value":"detailReason"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ExpertBroadcastLeadEligibilityChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertBroadcastLeadEligibilityChangedSubscription, ExpertBroadcastLeadEligibilityChangedSubscriptionVariables>, 'subscription'>;

    export const ExpertBroadcastLeadEligibilityChangedComponent = (props: ExpertBroadcastLeadEligibilityChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertBroadcastLeadEligibilityChangedSubscription, ExpertBroadcastLeadEligibilityChangedSubscriptionVariables> subscription={ExpertBroadcastLeadEligibilityChangedDocument} {...props} />
    );
    

/**
 * __useExpertBroadcastLeadEligibilityChangedSubscription__
 *
 * To run a query within a React component, call `useExpertBroadcastLeadEligibilityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertBroadcastLeadEligibilityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertBroadcastLeadEligibilityChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertBroadcastLeadEligibilityChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertBroadcastLeadEligibilityChangedSubscription, ExpertBroadcastLeadEligibilityChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertBroadcastLeadEligibilityChangedSubscription, ExpertBroadcastLeadEligibilityChangedSubscriptionVariables>(ExpertBroadcastLeadEligibilityChangedDocument, options);
      }
export type ExpertBroadcastLeadEligibilityChangedSubscriptionHookResult = ReturnType<typeof useExpertBroadcastLeadEligibilityChangedSubscription>;
export type ExpertBroadcastLeadEligibilityChangedSubscriptionResult = Apollo.SubscriptionResult<ExpertBroadcastLeadEligibilityChangedSubscription>;