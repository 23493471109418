import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '@deal/dom-hooks'
import { Button } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpointWrapper from '#src/app/hooks/useBreakpoint'
import clientOnly from '#src/app/hocs/clientOnly'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import IntroVideo from './video.mp4'
import PlayIcon from './play.svg'
import CloseIcon from './close.svg'
import styles from './styles.css'

const localStorageKey = 'quick-replies-v2-onboarding-popup'

interface QuickRepliesV2IntroPopupProps {}

const QuickRepliesV2IntroPopup: React.FC<React.PropsWithChildren<
  QuickRepliesV2IntroPopupProps
>> = () => {
  const isLargerThanSm = useBreakpointWrapper('sm')
  const videoRef = useRef<HTMLVideoElement>(null)
  const [showSelf, setShowSelf] = useState(true)
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [toggleOnQuickReplyUserMenuTooltip, setToggleOnQuickReplyUserMenuTooltip] = useState(true)
  const {
    setShowQuickReplyUserMenuTooltip,
    showManageQuickRepliesModal,
    setShowManageQuickRepliesModal
  } = useQuickReplyContext()
  const businessUser = useBusinessUser()

  const showPopup =
    isLargerThanSm &&
    showSelf &&
    !window.localStorage.getItem(localStorageKey) &&
    businessUser.active

  const handleClose = () => {
    if (!showManageQuickRepliesModal && showPopup) {
      if (toggleOnQuickReplyUserMenuTooltip) {
        setShowQuickReplyUserMenuTooltip(true)
      }
      window.localStorage.setItem(localStorageKey, 'completed')
      setShowSelf(false)
    }
  }

  const containerRef = useOnClickOutside<HTMLDivElement>(() => {
    handleClose()
  }, [showManageQuickRepliesModal, showPopup, toggleOnQuickReplyUserMenuTooltip])

  return showPopup ? (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.closeLine}>
        <CloseIcon onClick={() => handleClose()} />
      </div>
      <div className={styles.header}>Introducing, Quick Replies 2.0</div>
      <div className={styles.items}>
        <div>
          <div className={styles.itemHeader}>Boost response rate</div>
          <div className={styles.itemText}>
            Copy and edit HQ suggested messages which boost response rates
          </div>
        </div>
        <div>
          <div className={styles.itemHeader}>Personalize messages at scale</div>
          <div className={styles.itemText}>
            Dynamically include <strong>expert name</strong> or <strong>customer name</strong> in
            all saved messages with variables
          </div>
        </div>
        <div>
          <div className={styles.itemHeader}>Send messages in 1 click</div>
          <div className={styles.itemText}>
            Tag quick replies and set favorites so you can send messages in 1 click
          </div>
        </div>
      </div>
      <Button
        size="xsmall"
        variant="secondary"
        onClick={() => {
          setShowManageQuickRepliesModal(true)
          setToggleOnQuickReplyUserMenuTooltip(false)
        }}
      >
        Manage quick replies
      </Button>
      <div className={styles.videoContainer}>
        <video ref={videoRef} controls preload="metadata">
          <source src={IntroVideo} type="video/mp4" />
        </video>
        {!videoPlayed && (
          <div className={styles.overlay}>
            <PlayIcon
              onClick={() => {
                videoRef.current?.play()
                setVideoPlayed(true)
                setToggleOnQuickReplyUserMenuTooltip(false)
              }}
              className={styles.playIcon}
            />
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default clientOnly(QuickRepliesV2IntroPopup)
