import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertWarehouseSuggestionsPageViewedEventProperties {
  lead_id: string
  activity_id?: string
}

export class ExpertWarehouseSuggestionsPageViewedEvent extends Event<
  ExpertWarehouseSuggestionsPageViewedEventProperties
> {
  static displayName = 'Expert Warehouse Suggestions Page Viewed'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
