import React, { useContext } from 'react'
import type { TrackingClient } from '@deal/web-tracking/types'
import { AnalyticsContext } from '#src/app/containers/Analytics'

export interface WithAnalyticsProps {
  analytics?: TrackingClient
}

function withAnalytics<P>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithAnalyticsProps>>) {
  const WithAnalytics: React.FC<React.PropsWithChildren<P>> = props => {
    const analytics = useContext(AnalyticsContext)
    return <WrappedComponent analytics={analytics} {...props} />
  }

  return WithAnalytics
}

export { withAnalytics }
