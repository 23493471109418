import React, { useState } from 'react'
import classnames from 'classnames'
import { Redirect, useHistory, useRouteMatch } from '@deal/router'
import { Tooltip } from '@deal/components'
import { useExperiment } from '@deal/experiment-js'
import { useHelpCenterNewReleasesContext } from '#src/app/context/HelpCenterNewReleases'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import clientOnly from '#src/app/hocs/clientOnly'
import QuestionMarkIcon from './helpcenter.svg'
import styles from './styles.css'

const HelpCenterWidget: React.FC = () => {
  const history = useHistory()
  const { unviewedNewReleaseCardCount, unviewedJiraTicketCount } = useHelpCenterNewReleasesContext()
  const search = history.location.search
  const params = new URLSearchParams(search)
  const knowledgeCardIdFromParams = params.get('knowledgeCardId') || undefined

  const [visible, setVisible] = useState(false)

  // show the help center if there's a knowledgeCardId query param
  if (knowledgeCardIdFromParams && !visible) {
    setVisible(true)
  }

  const inboxRouteMatch = useRouteMatch<{ leadId: string }>({
    path: '/inbox/:leadId'
  })
  const curateRouteMatch = useRouteMatch<{ leadId: string }>({
    path: '/curate/:leadId'
  })
  const isLargerThanSmall = useBreakpoint('sm')

  const { result: helpCenterExperimentResult } = useExperiment({
    experiment: 'biz-app-help-center-tab',
    defaultTreatment: 'control'
  })

  const hideHelpCenter = helpCenterExperimentResult === 'on'

  /**
   * Should skip rendering if:
   *  - SSR: react spring has memory leak issues and tippy.js doesn't support SSR correctly (see clientOnly hoc)
   *  - If mobile curation route
   */
  const shouldSkipRendering =
    (!isLargerThanSmall && !!curateRouteMatch?.params.leadId) || hideHelpCenter

  /**
   * Hide the icon that opens the help center if on mobile and looking at a conversation - The help center may still be accessed by other means (e.g. suggested actions w/ knowledgeCenterId field)
   */
  const shouldHideIcon = (!isLargerThanSmall && !!inboxRouteMatch?.params.leadId) || hideHelpCenter

  if (knowledgeCardIdFromParams && helpCenterExperimentResult !== 'control') {
    return <Redirect to={`/help-center/card/${knowledgeCardIdFromParams}`} />
  }

  if (shouldSkipRendering) {
    return null
  }

  const totalNotifications = unviewedNewReleaseCardCount + unviewedJiraTicketCount

  return (
    <>
      {!visible && !shouldHideIcon && (
        <div
          className={classnames(styles.icon, { [styles.hide]: visible })}
          onClick={() => {
            history.push('/help-center')
          }}
        >
          <Tooltip
            visible={totalNotifications > 0}
            placement="right"
            message={
              <>
                {unviewedNewReleaseCardCount > 0 && (
                  <div>
                    {unviewedNewReleaseCardCount} new{' '}
                    {unviewedNewReleaseCardCount == 1 ? 'announcement' : 'announcements'} in last 7
                    days
                  </div>
                )}
                {unviewedJiraTicketCount > 0 && (
                  <div>
                    {unviewedJiraTicketCount} new support ticket{' '}
                    {unviewedJiraTicketCount == 1 ? 'update' : 'updates'}
                  </div>
                )}
              </>
            }
            interactive={false}
            zIndex={99999}
          >
            <div>
              {totalNotifications > 0 && <div className={styles.count}>{totalNotifications} </div>}
              <QuestionMarkIcon />
              <div className={styles.textContainer}>
                <div className={styles.text}>HELP CENTER</div>
              </div>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  )
}

export default clientOnly(HelpCenterWidget)
