// Polyfills
import './polyfills/urlSearchParams'

import React, { ComponentType } from 'react'
import loggerClient from '#src/app/services/loggerClient'
import { shouldClientSessionReset, setClientSessionId } from '#src/app/services/tracking'
import ReactDOM from 'react-dom/client'
import { TrackingClient } from '@deal/web-tracking/types'
import { IdentityProvider } from '#src/app/containers/Identity'
import { MyselfDocument, MyselfQuery } from '#src/app/containers/Identity/Myself.generated'
import {
  TrackOnlineActivityDocument,
  TrackOnlineActivityMutation,
  TrackOnlineActivityMutationVariables
} from '#src/app/mutations/TrackOnlineActivity.generated'
import schemaFragments from '#src/generated/schema.fragments'
import schemaNonNormalizableTypes from '#src/generated/schema.non-normalizable-types'
import schemaRelayStylePaginationFields from '#src/generated/schema.relay-style-pagination-fields'
import { ApolloProvider } from '@apollo/client'
import { PageKeyPriority, Route, Router, Switch } from '@deal/router'
import * as browserHistory from 'history'
import StalePageRefresher from '@deal/refresh-stale-page'
import PageInteractionObserver from '@deal/page-interaction-observer'
import {
  createBrowserGraphQLClient,
  ViewerContext as APIViewerContext,
  ServerSentEventClient,
  ServerSentEventClientProvider
} from '@deal/api-client-js'
import { createBrowserExperimentClient, BrowserExperimentClientConfig } from '@deal/experiment-js'
import UserExperimentClientProvider from '#src/app/containers/UserExperimentClientProvider'
import App from '#src/app/containers/App'
import config from '#src/app/config'
import { loadableReady } from '@loadable/component'
import { ErrorResponse } from '@apollo/client/link/error'
import { HelmetProvider } from 'react-helmet-async'
import Heartbeat from '#src/app/components/Heartbeat'
import { UserAgentProvider } from '#src/app/context/UserAgent'
import { ApolloReconnectProvider } from '#src/app/containers/ApolloReconnect'
import { VisibilityProvider } from '#src/app/containers/Visibility'
import { AnalyticsProvider } from '#src/app/containers/Analytics'
import { LeadSearchFolderProvider } from '#src/app/context/LeadSearchFolder'
import { WakeupTimeContextProvider } from '#src/app/context/WakeupTime'
import { HostnameProvider, Hostname } from '#src/app/containers/Hostname'
import { createHostAwareConfig, HostAwareConfigProvider } from '#src/app/containers/HostAwareConfig'
import SendTokensToNative from '#src/app/services/sendTokensToNative'
import { ExpertToolsProvider } from '#src/app/context/ExpertTools'
import { GlobalViewsProvider } from '#src/app/context/GlobalViews'
import { LeadDetailsProvider } from '#src/app/context/LeadDetails'
import { TrainingProvider } from '#src/app/context/Training'
import { LuxTrackingProvider } from '#src/app/context/LuxTracking'
import { CurationTrackingProvider } from '#src/app/context/CurationTrackingContext'
import { getForceTraceId } from '#src/app/services/tracing'
import persistedQueries from '#src/app/services/persistedQueries'
import { initBoomerang } from '#src/app/services/boomerang'
import { isImpersonating } from '#src/app/utilities/identity'
import {
  WebMessageEventEmitter,
  WebMessageEventEmitterProvider
} from '#src/app/containers/ReceiveMessageFromNative'
import { NavigationProvider } from '#src/app/context/Navigation'
import { ViewerContext as TrackingViewerContext } from '@deal/web-tracking/constants'
import { MyselfFragment } from '#src/app/fragments/myself.generated'
import { ConfettiProvider } from '#src/app/context/Confetti'
import { PageInteractedEvent } from '@deal/web-tracking'
import { QuickReplyContextProvider } from '#src/app/containers/ExpertChat/QuickReplyContext'
import { AccountsNeedAttentionProvider } from '#src/app/context/AccountsNeedAttention'
import { InboxSnapshotProvider } from '#src/app/context/InboxSnapshot'
import { UserNeedsFilterProvider } from '#src/app/context/UserNeedsFilter'
import ChangeAccounts from '#src/app/components/ChangeAccounts'

// Refresh the page/tab if it's been backgrounded for over 1 hour
StalePageRefresher.monitor()

const isBot = window.__IS_BOT__

const fullHostname = window.location.hostname.toLowerCase()
let hostname = Hostname.Deal
if (fullHostname.endsWith(config.get('gcpHostSuffix'))) {
  hostname = Hostname.Gcp
} else if (fullHostname.endsWith(config.get('curatedHostSuffix'))) {
  hostname = Hostname.Curated
}

const apiConfig = createHostAwareConfig(hostname).get('api')

// Allow overrides via query parameters
const urlQueryString = window.location.search
const urlParams = new URLSearchParams(urlQueryString)
const experimentOverrides = urlParams.get('experimentOverrides') || undefined
const forceDefaultTreatments =
  urlParams.get('forceDefaultTreatments') === 'true' || window.__IS_BOT__
const experimentBucketingKey = urlParams.get('experimentBucketingKey') || undefined

// Callback for Apollo GraphQL errors
const handleError = (error: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = error

  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      loggerClient.captureGQLError(error, operation)
    })
  }

  if (networkError) {
    loggerClient.captureNetworkError(networkError, operation)
  }
}

const handleNetworkError = (networkError: Error) => {
  // @ts-ignore `networkError` is incompletely typed
  //   See: https://github.com/apollographql/apollo-link/issues/300
  if (networkError && networkError.statusCode == 401) {
    const consumer = config.get('curated.consumer')

    // Redirect to login on 401
    const url = window.location.href
    window.location.href = `${consumer.protocol}://${consumer.host}:${
      consumer.port
    }/auth/login?then=${encodeURIComponent(url)}`
  }
}

const sendTokensToNative = new SendTokensToNative()
// Bootstrap the native client with the initial state of the tokens
window.addEventListener('load', sendTokensToNative.sendIfChanged)

// Intercept DealNativeBridge.sendToWeb calls, dispatching them through this event listener
const webMessageEventEmitter = new WebMessageEventEmitter()
webMessageEventEmitter.interceptBridgeSendToWeb()

// History to be used by react router
const history = browserHistory.createBrowserHistory()

const { apolloClient, reconnect: apolloReconnect } = createBrowserGraphQLClient({
  protocol: apiConfig.protocol,
  webSocketProtocol: apiConfig.webSocketProtocol,
  host: apiConfig.host,
  port: apiConfig.port,
  cachePossibleTypes: schemaFragments.possibleTypes,
  cacheNonNormalizableTypes: schemaNonNormalizableTypes,
  cacheRelayStylePaginationFields: schemaRelayStylePaginationFields,
  initialCacheState: window.__APOLLO_STATE__,
  experimentOverrides,
  forceTrace: () => getForceTraceId(window.location.href),
  isBot: () => window.__IS_BOT__,
  viewerContext: () => APIViewerContext.BUSINESS,
  onIdentityMismatch: () => {
    if (
      window.confirm(
        'It looks like you logged into a different account in another tab. Most features will not work as expected until you refresh the page. Click "Ok" to refresh now.'
      )
    ) {
      window.location.reload()
    }
  },
  onError: handleError,
  onNetworkError: handleNetworkError,
  links: [sendTokensToNative.sendIfChangedOnResponseLink()],
  clientAwarenessName: 'business-app-client-' + config.get('environment'),
  clientAwarenessVersion: window.__APP_VERSION__,
  persistedQueries: persistedQueries(window.location.href),
  // TenantId is implicitly derived from the BusinessUser; it is not provided by the client explicitly
  tenantId: () => null
})

delete window.__APOLLO_STATE__

const sseClient = new ServerSentEventClient({
  protocol: apiConfig.protocol,
  host: apiConfig.host,
  port: apiConfig.port,
  viewerContext: () => APIViewerContext.BUSINESS,
  isBot: () => window.__IS_BOT__,
  experimentOverrides: () => experimentOverrides || '',
  forceTrace: () => getForceTraceId(window.location.href)
})

// Rendering
apolloClient
  .query<MyselfQuery>({
    query: MyselfDocument
  })
  .then(({ data }) => {
    const identity: {
      userId?: string
      realUserId?: string
      businessUserId?: string
    } = {}

    // @ts-ignore
    const tracking = window.tracking as TrackingClient

    // Inform the backend that this user is active on the site
    const trackOnlineActivity = () => {
      // Only track non-impersonated, logged-in users
      if (!identity.businessUserId || identity.userId !== identity.realUserId) {
        return
      }

      apolloClient.mutate<TrackOnlineActivityMutation, TrackOnlineActivityMutationVariables>({
        mutation: TrackOnlineActivityDocument,
        variables: {
          input: {
            id: identity.businessUserId
          }
        }
      })

      tracking.track(new PageInteractedEvent())
    }

    const initializeTracking = (me?: MyselfFragment | null) => {
      identity.userId = me?.businessUser?.user.id
      identity.realUserId = me?.realUser.id
      identity.businessUserId = me?.businessUser?.id

      if (me && isImpersonating(me)) {
        tracking.disable()
      } else {
        tracking.enable()
      }

      if (identity.userId && identity.businessUserId) {
        tracking.identify(
          {
            userId: identity.userId,
            businessUserId: identity.businessUserId,
            tenantId: me?.businessUser?.tenant.historyId
          },
          TrackingViewerContext.BusinessUser
        )
      }

      // Track initial user activity (loading this page or switching identity)
      trackOnlineActivity()
    }

    initializeTracking(data.me)

    // Track ongoing user activity (moving mouse, scrolling, etc.)
    const interactionObserver = new PageInteractionObserver(() => {
      trackOnlineActivity()
    })
    interactionObserver.observe()

    initBoomerang()

    const onPageKeyChanged = (pageKey?: string) => {
      if (pageKey) {
        if (window.LUX) {
          window.LUX.label = `biz-app-${pageKey}`
        }
      } else {
        if (window.LUX) {
          delete window.LUX.label
        }
      }
    }

    // Experiment client
    const fallbackTreatments = window.__EXPERIMENT_TREATMENTS__
    const holdouts = window.__EXPERIMENT_HOLDOUTS__

    const experimentClientConfig: BrowserExperimentClientConfig = {
      graphqlConfig: {
        apolloClient,
        fetchUpdatesIntervalMillis: 60000
      },
      initialExperiments: [],
      experimentOverrides,
      forceDefaultTreatments,
      fallback: fallbackTreatments,
      holdouts,
      trackingClient: () => window.tracking,
      luxClient: () => window.LUX,
      heapClient: () => window.heap
    }

    delete window.__EXPERIMENT_TREATMENTS__
    delete window.__EXPERIMENT_HOLDOUTS__

    // Should we reset the user's session ID
    if (shouldClientSessionReset()) {
      setClientSessionId()
    }

    const experimentClient = createBrowserExperimentClient(experimentClientConfig)

    // Rendering method
    const renderer = window.__APP_RENDERER__ || 'client'
    delete window.__APP_RENDERER__

    // Rendering function
    const render = (AppComponent: ComponentType<React.PropsWithChildren<unknown>>) => {
      const Root = (
        <Router history={history} onPageKeyChanged={onPageKeyChanged}>
          <ServerSentEventClientProvider client={sseClient}>
            <ApolloProvider client={apolloClient}>
              <Switch>
                {/* This needs to be above and outside all active queries and subscriptions. When an expert changes accounts we want to have all Apollo unmounted  */}
                <Route
                  path="/change-account/:newExpertId"
                  pageKey="change-account"
                  exact={true}
                  priority={PageKeyPriority.TOP_LEVEL}
                  render={({
                    match: {
                      params: { newExpertId }
                    }
                  }) => newExpertId && <ChangeAccounts newExpertId={newExpertId} />}
                />
                <Route
                  render={() => (
                    <HostnameProvider hostname={hostname}>
                      <HostAwareConfigProvider>
                        <UserAgentProvider isBot={isBot}>
                          <ApolloReconnectProvider value={apolloReconnect}>
                            <WebMessageEventEmitterProvider value={webMessageEventEmitter}>
                              <Router history={history} onPageKeyChanged={onPageKeyChanged}>
                                <VisibilityProvider>
                                  <NavigationProvider>
                                    <AnalyticsProvider client={tracking}>
                                      <IdentityProvider onChanged={initializeTracking}>
                                        <Heartbeat />
                                        <UserExperimentClientProvider
                                          experimentClient={experimentClient}
                                          overrideId={experimentBucketingKey}
                                        >
                                          <ExpertToolsProvider>
                                            <LeadDetailsProvider>
                                              <LeadSearchFolderProvider>
                                                <LuxTrackingProvider>
                                                  <CurationTrackingProvider>
                                                    <HelmetProvider>
                                                      <GlobalViewsProvider>
                                                        <WakeupTimeContextProvider>
                                                          <QuickReplyContextProvider>
                                                            <ConfettiProvider>
                                                              <AccountsNeedAttentionProvider>
                                                                <UserNeedsFilterProvider>
                                                                  <InboxSnapshotProvider>
                                                                    <TrainingProvider>
                                                                      <AppComponent />
                                                                    </TrainingProvider>
                                                                  </InboxSnapshotProvider>
                                                                </UserNeedsFilterProvider>
                                                              </AccountsNeedAttentionProvider>
                                                            </ConfettiProvider>
                                                          </QuickReplyContextProvider>
                                                        </WakeupTimeContextProvider>
                                                      </GlobalViewsProvider>
                                                    </HelmetProvider>
                                                  </CurationTrackingProvider>
                                                </LuxTrackingProvider>
                                              </LeadSearchFolderProvider>
                                            </LeadDetailsProvider>
                                          </ExpertToolsProvider>
                                        </UserExperimentClientProvider>
                                      </IdentityProvider>
                                    </AnalyticsProvider>
                                  </NavigationProvider>
                                </VisibilityProvider>
                              </Router>
                            </WebMessageEventEmitterProvider>
                          </ApolloReconnectProvider>
                        </UserAgentProvider>
                      </HostAwareConfigProvider>
                    </HostnameProvider>
                  )}
                />
              </Switch>
            </ApolloProvider>
          </ServerSentEventClientProvider>
        </Router>
      )

      if (renderer === 'server') {
        loadableReady(() => {
          ReactDOM.hydrateRoot(document.getElementById('deal')!, Root)
        })
      } else {
        const root = ReactDOM.createRoot(document.getElementById('deal')!)
        root.render(Root)
      }
    }

    // Initial render
    render(App)
  })
