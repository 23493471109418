import React, { useReducer } from 'react'

export enum ActionType {
  ShowTransferLead,
  HideTransferLead,
  ShowSendFittingInvitation,
  HideSendFittingInvitation
}

export interface Action {
  type: ActionType
}

export interface ToolState {
  visible: boolean
}

export interface State {
  reassignLead: ToolState
  createGiftCard: ToolState
  transferLead: ToolState
  sendFittingInvitation: ToolState
}

const initialState: State = {
  reassignLead: {
    visible: false
  },
  createGiftCard: {
    visible: false
  },
  transferLead: {
    visible: false
  },
  sendFittingInvitation: {
    visible: false
  }
}

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.ShowTransferLead:
      return { ...state, transferLead: { visible: true } }
    case ActionType.HideTransferLead:
      return { ...state, transferLead: { visible: false } }
    case ActionType.ShowSendFittingInvitation:
      return { ...state, sendFittingInvitation: { visible: true } }
    case ActionType.HideSendFittingInvitation:
      return { ...state, sendFittingInvitation: { visible: false } }
  }
}

// Context
type ExpertToolsContextType = { state: State; dispatch: React.Dispatch<Action> }
const ExpertToolsContext = React.createContext<ExpertToolsContextType>({
  state: initialState,
  dispatch: () => {}
})

// Consumer
const ExpertToolsConsumer = ExpertToolsContext.Consumer

// Provider
const ExpertToolsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ExpertToolsContext.Provider value={{ state, dispatch }}>
      {children}
    </ExpertToolsContext.Provider>
  )
}

export { ExpertToolsConsumer, ExpertToolsProvider, ExpertToolsContext }
