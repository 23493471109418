import React, { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { MediaAssetState, MediaAssetType } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import {
  MediaAssetsPaginationForMyRecentsPanelDocument,
  useMediaAssetsPaginationForMyRecentsPanelQuery
} from './MyRecentsPanel.generated'
import GeneralRecentsPanel from '../GeneralRecentsPanel'

const RESULTS_PER_FETCH = 6

interface MyRecentsPanelProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const MyRecentsPanel: React.FC<MyRecentsPanelProps> = props => {
  const apolloClient = useApolloClient()
  const [moreResultsMightExist, setMoreResultsMightExist] = useState(true)

  const { id: businessUserId } = useBusinessUser()
  const queryVariables = {
    input: {
      businessUserId,
      state: MediaAssetState.PUBLISHED,
      type: MediaAssetType.BUSINESS_USER_UPLOAD
    },
    limit: RESULTS_PER_FETCH
  }

  const { data, loading, fetchMore } = useMediaAssetsPaginationForMyRecentsPanelQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data.mediaAssetsPagination.mediaAssets.length === 0) {
        setMoreResultsMightExist(false)
      }
    }
  })

  const mediaListings = data?.mediaAssetsPagination.mediaAssets || []

  const maybeFetchMoreResults = useCallback(
    (onMoreResultsConfirmed?: () => void) => {
      if (!moreResultsMightExist) {
        return
      }

      fetchMore({
        variables: { offset: mediaListings.length }
      }).then(res => {
        if (!res.data.mediaAssetsPagination.mediaAssets.length) {
          setMoreResultsMightExist(false)
        } else {
          apolloClient.cache.writeQuery({
            query: MediaAssetsPaginationForMyRecentsPanelDocument,
            variables: queryVariables,
            data: {
              mediaAssetsPagination: {
                mediaAssets: [...mediaListings, ...res.data.mediaAssetsPagination.mediaAssets]
              }
            }
          })
          onMoreResultsConfirmed?.()
        }
      })
    },
    [mediaListings, moreResultsMightExist, setMoreResultsMightExist, fetchMore]
  )

  const listingPagination = {
    loading,
    moreResultsMightExist,
    mediaListings,
    maybeFetchMoreResults
  }
  return <GeneralRecentsPanel listingPagination={listingPagination} {...props} />
}

export default MyRecentsPanel
