// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AcceptSurgeShiftMutationVariables = Types.Exact<{
  input: Types.AcceptSurgeShiftInput;
}>;


export type AcceptSurgeShiftMutation = { __typename: 'Mutation', acceptSurgeShift: { __typename: 'AcceptSurgeShiftPayload', hasError: boolean, errorMessage?: string | null, shift?: { __typename: 'Shift', id: any, shiftSlot?: { __typename: 'ShiftSlot', id?: any | null } | null } | null } };


export const AcceptSurgeShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptSurgeShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptSurgeShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptSurgeShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasError"}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"shift"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shiftSlot"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type AcceptSurgeShiftMutationFn = Apollo.MutationFunction<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables>;
export type AcceptSurgeShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables>, 'mutation'>;

    export const AcceptSurgeShiftComponent = (props: AcceptSurgeShiftComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables> mutation={AcceptSurgeShiftDocument} {...props} />
    );
    

/**
 * __useAcceptSurgeShiftMutation__
 *
 * To run a mutation, you first call `useAcceptSurgeShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSurgeShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSurgeShiftMutation, { data, loading, error }] = useAcceptSurgeShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptSurgeShiftMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables>(AcceptSurgeShiftDocument, options);
      }
export type AcceptSurgeShiftMutationHookResult = ReturnType<typeof useAcceptSurgeShiftMutation>;
export type AcceptSurgeShiftMutationResult = Apollo.MutationResult<AcceptSurgeShiftMutation>;
export type AcceptSurgeShiftMutationOptions = Apollo.BaseMutationOptions<AcceptSurgeShiftMutation, AcceptSurgeShiftMutationVariables>;