// Note: At this point these types are manually copied & pasted between this and the mobile native
// app. If we add a lot more message types, we can re-factor and move these types to a shared
// library.
export type OnTokenChangeMessage = {
  type: 'onTokenChange'
  sn?: string
  at?: string
  rt?: string
}

export type InitializeAudioRecordingRequest = {
  type: 'initializeAudioRecordingRequest'
  messageId: string
}

export type InitializeAudioRecordingResponse = {
  type: 'initializeAudioRecordingResponse'
  messageId: string
  success: boolean
}

export type StartAudioRecordingRequest = {
  type: 'startAudioRecordingRequest'
  messageId: string
}

export type StartAudioRecordingResponse = {
  type: 'startAudioRecordingResponse'
  messageId: string
  success: boolean
}

export type StopAudioRecordingRequest = {
  type: 'stopAudioRecordingRequest'
  messageId: string
}

export type StopAudioRecordingResponse = {
  type: 'stopAudioRecordingResponse'
  messageId: string
  success: boolean
  file?: {
    id: string
    url: string
    mimeType: string
    originalFileName?: string | null
  }
}

export type CloseAudioRecordingMessage = {
  type: 'closeAudioRecordingMessage'
}

export type NavigateToMessage = {
  type: 'navigateTo'
  // Add additional pages as union types
  page: {
    name: 'leadConversation'
    leadId: string
  }
}

export type AnalyticsMessage = {
  type: 'analyticsMessage'
  payload:
    | {
        type: 'identify'
        userId: string
        traits?: any
      }
    | {
        type: 'track'
        event: string
        properties?: any
      }
    | {
        type: 'screen'
        name: string
        properties?: any
      }
    | {
        type: 'reset'
      }
}

export type OnNotificationOpenMessage = {
  type: 'onNotificationOpen'
  notificationId: string
  action: string
  data?: any
  results?: any
}

export type WebMessage =
  | NavigateToMessage
  | InitializeAudioRecordingResponse
  | StartAudioRecordingResponse
  | StopAudioRecordingResponse
  | OnNotificationOpenMessage
export type NativeMessage =
  | OnTokenChangeMessage
  | InitializeAudioRecordingRequest
  | StartAudioRecordingRequest
  | StopAudioRecordingRequest
  | CloseAudioRecordingMessage
  | AnalyticsMessage

export type DealNativeBridge = {
  sendToNative(message: NativeMessage): void
  sendToWeb(message: WebMessage): void
  _sendToWebInitBuffer: WebMessage[]
}

/**
 * Invoke `callback` if the a bridge between this web app and a hosting native app has been supplied.
 *
 * Note that this code base does not set the `dealNativeBridge` property; the object is injected
 * into the window object by our React Native apps. The injection is done after the document is
 * creted, but before any other content is loaded. See
 * https://developer.apple.com/documentation/webkit/wkuserscriptinjectiontime/wkuserscriptinjectiontimeatdocumentstart?language=objc
 * for documentation on when this is injected.
 *
 * @param callback callback that's invoked only if the bridge has been injected.
 */
export function ifBridgeDefined<T>(callback: (bridge: DealNativeBridge) => T): T | undefined {
  const bridge: DealNativeBridge = (window as any).dealNativeBridge
  if (bridge) {
    return callback(bridge)
  }
  return undefined
}
