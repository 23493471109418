import React from 'react'
import startCase from 'lodash/startCase'
import { TicketState, TicketType } from '#src/generated/types'

export interface TicketTypeLabelProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  type: TicketType | (string & {})
  state?: TicketState | (string & {}) | null
  as?: 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4'
}

export const TicketTypeLabel: React.FC<TicketTypeLabelProps> = ({
  type: _type,
  state = null,
  as: Element = 'div',
  ...rest
}) => {
  const type = _type === TicketType.MANUAL_FULFILLMENT_REQUEST ? 'Order Processing Question' : _type
  return (
    <Element {...rest}>
      {state && <>[{state}]&nbsp;</>}
      {startCase(type.replace('_', ' ').toLowerCase())}
    </Element>
  )
}
