import React, { useEffect, useState } from 'react'
import { useHistory, usePageKey } from '@deal/router'
import { useCreatePath } from '@deal/path-flow'
import { Modal } from '@deal/components'
import { AttributeInput } from '#src/generated/types'
import StandalonePath, { ExpertKnowledgeTestResult } from '#src/app/components/StandalonePath'
import ExpertKnowledgeTestResultDisplay from '../ExpertKnowledgeTestInlinePath/ExpertKnowledgeTestResultDisplay'
import styles from './styles.css'

interface Props {
  onCloseRequested: () => void
  onExpertKnowledgeTestFinished?: () => void
  isOpen: boolean
  pathSchemaSlug: string
  sourceKey: string
  ctaName: string
  initialAttributes?: AttributeInput[]
}

const ExpertKnowledgeTestPathModal: React.FC<React.PropsWithChildren<Props>> = ({
  onCloseRequested,
  onExpertKnowledgeTestFinished,
  isOpen,
  pathSchemaSlug,
  sourceKey,
  ctaName,
  initialAttributes
}) => {
  const pageKey = usePageKey()
  const history = useHistory()
  const [flowId, setFlowId] = useState<string | undefined>()
  const [directedActiveNodeIdOrSlug, setDirectedActiveNodeIdOrSlug] = useState<string | undefined>()
  const [result, setResult] = useState<ExpertKnowledgeTestResult>()

  const [createPath] = useCreatePath({
    onPathCreated: ({ pathGraphFlow, nodeSlugOrId }) => {
      setFlowId(pathGraphFlow?.id)
      setDirectedActiveNodeIdOrSlug(nodeSlugOrId)
    }
  })

  useEffect(() => {
    isOpen &&
      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        expertKnowledgeTest: {
          pathSelector: {
            pathSchemaSlug
          },
          initialAttributes
        },
        trackingCodes: {
          sourceKey,
          pageKey: pageKey || '',
          ctaName
        }
      })
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      modalContentClassName={styles.modalContent}
      mobilePosition="bottom"
      contentLabel="Expert Knowledge Test"
    >
      {flowId && isOpen && directedActiveNodeIdOrSlug && (
        <>
          {result ? (
            <ExpertKnowledgeTestResultDisplay
              result={result}
              onBackRequested={() => {
                onCloseRequested()
                setResult(undefined)
                // Strip any query params and remove from history in-case that is how path was launched
                history.replace(history.location.pathname)
              }}
              onCloseRequested={onCloseRequested}
            />
          ) : (
            <StandalonePath
              pathPlacement="modal"
              onActiveNodeUpdated={pathNavigationDetails => {
                if (pathNavigationDetails.nodeSlugOrId !== directedActiveNodeIdOrSlug) {
                  setDirectedActiveNodeIdOrSlug(pathNavigationDetails.nodeSlugOrId)
                }
              }}
              onExpertKnowledgeTestFinished={result => {
                onExpertKnowledgeTestFinished?.()
                setResult(result)
              }}
              directedActiveNodeIdOrSlug={directedActiveNodeIdOrSlug}
              flowId={flowId}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default ExpertKnowledgeTestPathModal
