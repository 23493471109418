import React, { useContext } from 'react'
import { Image , Logo } from '@deal/components'
import { isChromeBrowser } from '#src/app/services/supportedBrowsers'
import { UserAgentContext } from '#src/app/context/UserAgent'
import ChromeUrl from './images/chrome.png'
import styles from './styles.css'

interface UnsupportedBrowserTakeoverProps {}

interface BrowserDownloadIconProps {
  link: string
  text: string
}

const BrowserDownloadIcon: React.FC<React.PropsWithChildren<BrowserDownloadIconProps>> = ({ link, text }) => {
  return (
    <div className={styles.iconContainer}>
      <Image src={ChromeUrl} className={styles.icon} height={72} />
      Chrome
      <a className={styles.browserLink} href={link}>
        {text}
      </a>
    </div>
  )
}

const UnsupportedBrowserTakeover: React.FC<React.PropsWithChildren<UnsupportedBrowserTakeoverProps>> = () => {
  const ua = useContext(UserAgentContext)

  const isChrome = isChromeBrowser(ua)

  return (
    <div className={styles.modal}>
      <div className={styles.topBar} />

      <div className={styles.modalBody}>
        <div className={styles.logoBar}>
          <Logo mark="combo" />
        </div>
        <div className={styles.header}>
          {isChrome
            ? 'Please upgrade your version of Google Chrome'
            : 'Please install and use the Google Chrome browser'}
        </div>
        <div className={styles.textContainer}>
          {isChrome ? (
            <>
              <strong>We’re sorry, but your version of Google Chrome isn't supported.</strong> To
              login to the Curated business app, please <strong>upgrade</strong> your version of
              Google Chrome
            </>
          ) : (
            <>
              <strong>We’re sorry, but your browser isn’t supported. </strong>
              To login to the Curated business app, please install the latest version of Google
              Chrome
            </>
          )}
        </div>

        {/* Send everyone to the google chrome home page and let them handle sending to appropriate app store vs upgrading */}
        <BrowserDownloadIcon
          link={'https://www.google.com/chrome/'}
          text={isChrome ? 'Upgrade for free' : 'Install for free'}
        />
      </div>
    </div>
  )
}

export default UnsupportedBrowserTakeover
