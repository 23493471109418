// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadForSnoozeMutationsFragmentDoc } from '../fragments/leadConversation.generated';
import { LeadSnoozeForIsReminderSetFragmentDoc } from '../utilities/snooze/isRemidnerSet.generated';
import { LeadSnoozeForIsFollowUpSetFragmentDoc } from '../utilities/snooze/isFollowUpSet.generated';
import { SnoozeForLeadForLeadListItemFragmentDoc, LeadForLeadListItemFragmentDoc } from '../routes/inbox/LeadListItem/LeadListItem.generated';
import { LeadForSnoozeFormFragmentDoc } from '../routes/inbox/SnoozePopoverWithInboxLead/SnoozeForm/SnoozeForm.generated';
import { LeadForReminderFragmentDoc } from '../routes/inbox/SnoozePopoverWithInboxLead/SnoozeForm/Reminder/Reminder.generated';
import { LeadForFollowUpFragmentDoc, SnoozeStepForFollowUpFragmentDoc } from '../routes/inbox/SnoozePopoverWithInboxLead/SnoozeForm/FollowUp/FollowUp.generated';
import { SnoozeStepForSnoozeStepFragmentDoc } from '../routes/inbox/SnoozePopoverWithInboxLead/SnoozeForm/SnoozeStep/SnoozeStep.generated';
import { MediaFileFragmentDoc } from '../fragments/mediaFile.generated';
import { SnoozeStepForIsInvalidSnoozeStepFragmentDoc } from '../utilities/snooze/isInvalidSnoozeStep.generated';
import { LeadForConversationSummaryButtonFragmentDoc } from '../components/ConversationSummaryButton/ConversationSummaryButton.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type WakeupLeadsSnoozeMutationVariables = Types.Exact<{
  input: Types.WakeupLeadsSnoozeInput;
}>;


export type WakeupLeadsSnoozeMutation = { __typename: 'Mutation', wakeupLeadsSnooze: { __typename: 'WakeupLeadsSnoozePayload', leads: Array<{ __typename: 'Lead', id: any, conversationSummaryEligible: boolean, snooze?: { __typename: 'LeadSnooze', note?: string | null, wakeupTime: any, cancelMessageOnConsumerReply: boolean, message?: string | null, active: boolean, activeSnoozeIndex?: number | null, isAiGenerated: boolean, id?: any | null, leadActionAfterAllSnoozeSteps?: Types.LeadActionAfterAllSnoozeSteps | null, activeSnoozeStep?: { __typename: 'SnoozeStep', title?: string | null, wakeupTime: any } | null, snoozeSteps?: Array<{ __typename: 'SnoozeStep', active: boolean, message?: string | null, messageSourceId?: any | null, isMessageSourceSuggestedMessage: boolean, wakeupTime: any, title?: string | null, messageSentAt?: any | null, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> | null } | null, consumer: { __typename: 'User', id: any, experimentUserId: any } }> } };


export const WakeupLeadsSnoozeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"WakeupLeadsSnooze"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"WakeupLeadsSnoozeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wakeupLeadsSnooze"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForSnoozeMutations"}}]}}]}}]}},...LeadForSnoozeMutationsFragmentDoc.definitions,...LeadSnoozeForIsReminderSetFragmentDoc.definitions,...LeadSnoozeForIsFollowUpSetFragmentDoc.definitions,...SnoozeForLeadForLeadListItemFragmentDoc.definitions,...LeadForSnoozeFormFragmentDoc.definitions,...LeadForReminderFragmentDoc.definitions,...LeadForFollowUpFragmentDoc.definitions,...SnoozeStepForFollowUpFragmentDoc.definitions,...SnoozeStepForSnoozeStepFragmentDoc.definitions,...MediaFileFragmentDoc.definitions,...SnoozeStepForIsInvalidSnoozeStepFragmentDoc.definitions,...LeadForConversationSummaryButtonFragmentDoc.definitions]} as unknown as DocumentNode;
export type WakeupLeadsSnoozeMutationFn = Apollo.MutationFunction<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables>;
export type WakeupLeadsSnoozeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables>, 'mutation'>;

    export const WakeupLeadsSnoozeComponent = (props: WakeupLeadsSnoozeComponentProps) => (
      <ApolloReactComponents.Mutation<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables> mutation={WakeupLeadsSnoozeDocument} {...props} />
    );
    

/**
 * __useWakeupLeadsSnoozeMutation__
 *
 * To run a mutation, you first call `useWakeupLeadsSnoozeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWakeupLeadsSnoozeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wakeupLeadsSnoozeMutation, { data, loading, error }] = useWakeupLeadsSnoozeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWakeupLeadsSnoozeMutation(baseOptions?: Apollo.MutationHookOptions<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables>(WakeupLeadsSnoozeDocument, options);
      }
export type WakeupLeadsSnoozeMutationHookResult = ReturnType<typeof useWakeupLeadsSnoozeMutation>;
export type WakeupLeadsSnoozeMutationResult = Apollo.MutationResult<WakeupLeadsSnoozeMutation>;
export type WakeupLeadsSnoozeMutationOptions = Apollo.BaseMutationOptions<WakeupLeadsSnoozeMutation, WakeupLeadsSnoozeMutationVariables>;