import React, { useContext, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client/react/hooks'
import { useHistory, useLocation } from '@deal/router'
import { LeadExpertRole } from '#src/generated/types'
import leadSurgeStartedToast from '#src/app/toasts/leadSurgeStarted'
import { OnNotificationOpenMessage } from '#src/app/services/dealNativeBridge'
import { NavigationContext } from '#src/app/context/Navigation'
import {
  Disposable,
  WebMessageEventEmitterContext
} from '#src/app/containers/ReceiveMessageFromNative'
import { IdentityContext } from '#src/app/containers/Identity'
import {
  LeadEscalationDocument,
  LeadEscalationQuery,
  LeadEscalationQueryVariables
} from '../../toasts/leadEscalationStarted/LeadEscalation.generated'
import { useAssumeBusinessUserIdentityMutation } from '../../mutations/AssumeBusinessUserIdentity.generated'

const NotificationOpenEvent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { setIsNavigationOpen } = useContext(NavigationContext)
  const { myself } = useContext(IdentityContext)
  const [assumeBusinessUserIdentity] = useAssumeBusinessUserIdentityMutation()
  const eventEmitter = useContext(WebMessageEventEmitterContext)
  const history = useHistory()
  const location = useLocation()
  const apolloClient = useApolloClient()
  const disposable = useRef<Disposable | undefined>()
  useEffect(() => {
    disposable.current && disposable.current.dispose()
    disposable.current = undefined
    if (eventEmitter) {
      disposable.current = eventEmitter.on<OnNotificationOpenMessage>('onNotificationOpen', msg => {
        /**
         * If we open the app via a push notification set this global variable
         * so we can track events related to a push notification "session"
         */
        location.state = { isPushNotificationSession: true }

        if (msg.data) {
          const data = msg.data
          let changeIdentity = Promise.resolve(myself)

          if (
            data['expert-id'] &&
            myself &&
            myself.businessUser &&
            data['expert-id'] !== myself.businessUser.id
          ) {
            changeIdentity = assumeBusinessUserIdentity({
              variables: {
                input: {
                  businessUserId: data['expert-id']
                }
              }
            }).then(({ data }) => data?.assumeBusinessUserIdentity.myself || null)
          }

          changeIdentity.then(newMyself => {
            if (data['type'] === 'leadConversation' && data['lead-id']) {
              const url = `/inbox/${data['lead-id']}`
              if (myself === newMyself) {
                history.push(url)
              } else {
                document.location.href = url
              }
              return
            }

            if (
              data['type'] === 'leadEscalation' &&
              data['lead-escalation-id'] &&
              data['lead-id']
            ) {
              apolloClient
                .query<LeadEscalationQuery, LeadEscalationQueryVariables>({
                  query: LeadEscalationDocument,
                  variables: { id: data['lead-escalation-id'] }
                })
                .then(({ data }) => {
                  /**
                   * If the escalation if for a PRIMARY expert (expert reassign) we should send them to nav claim button
                   * Otherwise, we should pop the toast so experts can still claim delegate escalations.
                   * The button in nav is only for claiming PRIMARY expert lead escalations.
                   */
                  if (data.leadEscalation.acceptAsLeadExpertRole === LeadExpertRole.PRIMARY) {
                    history.push('/inbox')
                    setIsNavigationOpen(true)
                  } else {
                    const url = `/inbox/${data.leadEscalation.lead.id}/escalation/${data.leadEscalation.id}`
                    if (myself === newMyself) {
                      history.push(url)
                    } else {
                      document.location.href = url
                    }
                  }
                })
              return
            }

            // backward compatibility
            if (data['lead-id']) {
              const url = `/inbox/${data['lead-id']}`
              if (myself === newMyself) {
                history.push(url)
              } else {
                document.location.href = url
              }
              return
            }

            if (data['notification-type'] === 'LEAD_ESCALATION') {
              apolloClient
                .query<LeadEscalationQuery, LeadEscalationQueryVariables>({
                  query: LeadEscalationDocument,
                  variables: { id: data['notification-entity-id'] }
                })
                .then(({ data }) => {
                  /**
                   * If the escalation if for a PRIMARY expert (expert reassign) we should send them to nav claim button
                   * Otherwise, we should pop the toast so experts can still claim delegate escalations.
                   * The button in nav is only for claiming PRIMARY expert lead escalations.
                   */
                  if (data.leadEscalation.acceptAsLeadExpertRole === LeadExpertRole.PRIMARY) {
                    history.push('/inbox')
                    setIsNavigationOpen(true)
                  } else {
                    const url = `/inbox/${data.leadEscalation.lead.id}/escalation/${data.leadEscalation.id}`
                    if (myself === newMyself) {
                      history.push(url)
                    } else {
                      document.location.href = url
                    }
                  }
                })
              return
            } else if (data['notification-type'] === 'LEAD_SURGE') {
              leadSurgeStartedToast({
                expertId: data['expert-id'],
                leadSurgeId: data['notification-entity-id']
              })
            }

            console.warn('Unknown notification opened', data)
          })
        } else {
          console.error('Invalid notification opened', msg)
        }
      })
    }
    return () => {
      disposable.current && disposable.current.dispose()
      disposable.current = undefined
    }
  }, [])

  return <>{children}</>
}

export default NotificationOpenEvent
