import React, { useContext } from 'react'
import Hammer from 'react-hammerjs'
import classnames from 'classnames'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { NavigationContext } from '#src/app/context/Navigation'
import { GlobalView, GlobalViewsContext } from '#src/app/context/GlobalViews'
import { IdentityContext } from '#src/app/containers/Identity'
import UserMenu from './UserMenu'
import ShiftDifficultyModal from './ShiftDifficultyModal'
import NavigationContent from './NavigationContent'
import ExpertApplicantNavigation from './ExpertApplicantNavigation'
import EndOfShiftModal from './EndOfShiftModal'
import CloseIcon from './close.svg'
import AccountSelector from './AccountSelector'
import styles from './styles.css'

// HACK: The typings for react-hammerjs are not up-to-date.
//  See: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/28508
const UntypedHammer: React.FC<React.PropsWithChildren<any>> = props => <Hammer {...props} />

const Navigation: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const isLargerThanMd = useBreakpoint('md')
  /** Context */
  const { myself } = useContext(IdentityContext)
  const businessUser = useBusinessUser()
  const { isViewActive, closeAllViews, closeView, openView } = useContext(GlobalViewsContext)
  const { setIsNavigationOpen, isNavigationOpen, isUserMenuOpen, setIsUserMenuOpen } = useContext(
    NavigationContext
  )

  if (!myself) return null

  return (
    <>
      <EndOfShiftModal
        isOpen={isViewActive(GlobalView.EndOfShiftModal)}
        onRequestClose={() => closeAllViews()}
      />
      <ShiftDifficultyModal
        isOpen={isViewActive(GlobalView.ShiftDifficultyModal)}
        onRequestClose={() => {
          closeView(GlobalView.ShiftDifficultyModal)
          openView(GlobalView.EndOfShiftModal)
        }}
      />

      <div className={styles.layout}>
        {isLargerThanMd && <AccountSelector />}
        <UntypedHammer onSwipe={() => setIsNavigationOpen(false)} direction="DIRECTION_LEFT">
          <nav
            className={classnames(styles.nav, {
              [styles.navOpened]: isNavigationOpen
            })}
            data-testid="navigation"
          >
            {businessUser.isExpertApplicant ? (
              <ExpertApplicantNavigation />
            ) : (
              <NavigationContent onClosed={() => setIsNavigationOpen(false)} />
            )}
          </nav>
        </UntypedHammer>

        {!isLargerThanMd && (
          <UntypedHammer onSwipe={() => setIsUserMenuOpen(false)} direction="DIRECTION_RIGHT">
            <nav
              className={classnames(styles.userMenu, {
                [styles.userMenuOpened]: isUserMenuOpen
              })}
            >
              <UserMenu />
            </nav>
          </UntypedHammer>
        )}

        <div
          className={classnames(styles.overlay, {
            [styles.overlayVisible]: isNavigationOpen || isUserMenuOpen
          })}
        >
          <div
            className={classnames(styles.closeButton, {
              [styles.navigationCloseButton]: isNavigationOpen,
              [styles.userMenuCloseButton]: isUserMenuOpen
            })}
            onClick={() => {
              setIsNavigationOpen(false)
              setIsUserMenuOpen(false)
            }}
            data-testid="navigation-close-icon"
          >
            <CloseIcon className={styles.closeIcon} />
          </div>
        </div>

        <main className={styles.main}>{children}</main>
      </div>
    </>
  )
}

export default Navigation
