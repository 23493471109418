import React from 'react'
import InfoPopover from '../InfoPopover'
import styles from './styles.css'

interface TagsInfoPopoverProps {}

const TagsInfoPopover: React.FC<React.PropsWithChildren<TagsInfoPopoverProps>> = () => {
  return (
    <InfoPopover
      displayText="How to use tags"
      popoverContent={
        <div className={styles.container}>
          <div className={styles.header}>Easily find and send messages</div>
          <div className={styles.paragraphs}>
            <div>
              Tag quick replies to certain lead activity events and we will show you matching quick
              replies as suggested messages when that even happens.
            </div>
            <div>
              Favorite your best messages and they will move to the top of your suggested messages
              list.
            </div>
          </div>
        </div>
      }
    />
  )
}

export default TagsInfoPopover
