import React from 'react'
import { Button } from '@deal/bluxome'
import TrashIcon from './trash.svg'
import SaveIcon from './save.svg'
import PencilIcon from './pencil.svg'
import styles from './styles.css'

interface ExpertSavedActionsProps {
  onViewLeads: () => void
  onEdit: () => void
  onDelete: () => void
  onSave: () => void
  isDisabled: boolean
  isBeingEdited: boolean
  isSystem?: boolean
}

const ExpertSavedActions: React.FC<ExpertSavedActionsProps> = ({
  onViewLeads,
  onEdit,
  onDelete,
  onSave,
  isDisabled,
  isBeingEdited,
  isSystem = false
}) => {
  return (
    <div className={styles.actions}>
      <Button size="small" color="neutral-light" onPress={onViewLeads}>
        View leads
      </Button>
      {!isSystem && (
        <>
          {isBeingEdited ? (
            <Button
              aria-label="save"
              size="small"
              color="neutral-light"
              icon={SaveIcon}
              onPress={onSave}
              isDisabled={isDisabled}
            />
          ) : (
            <Button
              aria-label="edit"
              size="small"
              color="neutral-light"
              icon={PencilIcon}
              onPress={onEdit}
              isDisabled={isDisabled}
            />
          )}
          <Button
            aria-label="delete"
            size="small"
            color="neutral-light"
            icon={TrashIcon}
            isDisabled={isDisabled}
            onPress={onDelete}
          />
        </>
      )}
    </div>
  )
}

export default ExpertSavedActions
