import React from 'react'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import { SegmentedControl } from '@deal/components'
import { useSetAcceptingNewRequestsMutation } from '#src/app/mutations/SetAcceptingNewRequests.generated'
import styles from './styles.css'

interface Props {
  accepting: boolean
  businessUserId: string
}

enum PauseLeadsSwitchValues {
  ACCEPTING = 'accepting',
  NOT_ACCEPTING = 'not accepting'
}

const PauseLeadsSwitch: React.FC<React.PropsWithChildren<Props>> = ({ accepting, businessUserId }) => {
  const value = accepting ? PauseLeadsSwitchValues.ACCEPTING : PauseLeadsSwitchValues.NOT_ACCEPTING

  const [setAcceptingNewRequests, { loading }] = useSetAcceptingNewRequestsMutation({
    onCompleted: data => {
      if (data.setAcceptingNewRequests.error) {
        toast.error(data.setAcceptingNewRequests.error)
      }
    }
  })

  return (
    <div className={styles.switch}>
      <SegmentedControl
        size="small"
        className={styles.segmentedControl}
        backgroundClassName={classnames(styles.pausedSegmentBackground, {
          [styles.onSegmentBackground]: value === PauseLeadsSwitchValues.ACCEPTING
        })}
        segmentClassName={styles.segmentSegment}
        name="Pause leads switch"
        disabled={loading}
        value={value}
        segments={[
          { label: 'Leads on', value: PauseLeadsSwitchValues.ACCEPTING },
          { label: 'Pause', value: PauseLeadsSwitchValues.NOT_ACCEPTING }
        ]}
        onSegmentSelected={segment =>
          setAcceptingNewRequests({
            variables: {
              id: businessUserId,
              accept: segment === PauseLeadsSwitchValues.ACCEPTING
            }
          })
        }
      />
    </div>
  )
}

export default PauseLeadsSwitch
