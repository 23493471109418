import React, { useContext } from 'react'
import { BackgroundImage, Button, Modal } from '@deal/components'
import { FOLDER_SLUGS } from '#src/app/utilities/displayNames'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { IntelligentFollowUpCompleteModalMoreLeadsButtonClickedEvent } from '#src/app/events/IntelligentFollowUpCompleteModalMoreLeadsButtonClicked'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { InboxSnapshotContext } from '#src/app/context/InboxSnapshot'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import BackgroundImageSrc from './follow-up-complete-background.png'
import styles from './styles.css'

interface FollowUpCompleteModalProps {}

export const FOLLOW_UP_COMPLETE_MODAL_ACKNOWLEDGED_KEY =
  'intelligent-follow-complete-modal-acknowledged'
export const FOLLOW_UP_COMPLETE_MODAL_ACKNOWLEDGED = 'acknowledged'

const FollowUpCompleteModal: React.FC<React.PropsWithChildren<FollowUpCompleteModalProps>> = () => {
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const analytics = useAnalyticsContext()
  const { id: expertId } = useBusinessUser()
  const { showFollowUpSuccessModal, setShowFollowUpSuccessModal } = useContext(InboxSnapshotContext)

  const onCloseModal = () => {
    leadSearchFolderContext.setFolder(FOLDER_SLUGS.LABEL_ACTIVE, null)
    showFollowUpSuccessModal && setShowFollowUpSuccessModal(false)
    window &&
      window.localStorage.setItem(
        FOLLOW_UP_COMPLETE_MODAL_ACKNOWLEDGED_KEY,
        FOLLOW_UP_COMPLETE_MODAL_ACKNOWLEDGED
      )
    window && window.location.reload()
  }

  const getMoreLeads = () => {
    analytics?.track(
      new IntelligentFollowUpCompleteModalMoreLeadsButtonClickedEvent({
        expert_id: expertId
      })
    )

    onCloseModal()
  }

  return (
    <Modal
      isOpen
      onRequestClose={onCloseModal}
      mobilePosition="floating"
      width="xsmall"
      modalContentClassName={styles.modal}
      contentLabel="Follow up complete"
    >
      <>
        <BackgroundImage src={BackgroundImageSrc} sizes="100vw" />
        <div className={styles.content}>
          <div className={styles.title}>Nicely done!</div>
          <div className={styles.description}>
            The leads in this folder are identified as high intent. Potential repeat customers,
            leads with items in their carts, and leads that interacted a lot who have not purchased
            yet. Send 5-10 messages per day to these leads to stay in touch and grow your book of
            business.
          </div>
          <div className={styles.buttonContainer}>
            <Button
              size="xsmall"
              variant="secondary"
              onClick={getMoreLeads}
              className={styles.button}
            >
              Get 5 more leads
            </Button>
          </div>
        </div>
      </>
    </Modal>
  )
}

export default FollowUpCompleteModal
