import React from 'react'
import classnames from 'classnames'
import { Image } from '@deal/components'
import styles from './styles.css'

interface ImageThumbnailsProps {
  images: { id: string; url: string }[]
  maxImagesToShow?: number
  selectedImageIndex: number
  onImageClicked: (idx: number) => void
  onOverlayClicked?: (idx: number) => void
}

const ImageThumbnails: React.FC<React.PropsWithChildren<ImageThumbnailsProps>> = ({
  images,
  maxImagesToShow,
  selectedImageIndex,
  onImageClicked,
  onOverlayClicked
}) => {
  const shouldShowAdditionalImagesOverlay = !!maxImagesToShow && images.length > maxImagesToShow
  const imagesToShow = shouldShowAdditionalImagesOverlay ? images.slice(0, maxImagesToShow) : images

  return (
    <>
      {imagesToShow.map((image, idx) => (
        <div
          key={image.id}
          className={classnames(styles.thumbnail, {
            [styles.thumbnailSelected]: selectedImageIndex === idx
          })}
          onClick={() => onImageClicked(idx)}
        >
          <div>
            <Image src={image.url} size="50px" />
            {shouldShowAdditionalImagesOverlay && idx === maxImagesToShow - 1 && (
              <div
                className={styles.overlay}
                onClick={() => {
                  onOverlayClicked?.(idx)
                }}
              >
                +{images.length - maxImagesToShow}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default ImageThumbnails
