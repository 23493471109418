import React from 'react'
import MagnifierIcon from './magnifier.svg'
import styles from './styles.css'

interface MagnifierProps {
  onClick: () => void
}

const Magnifier: React.FC<React.PropsWithChildren<React.PropsWithChildren<MagnifierProps>>> = ({ onClick }) => {
  return (
    <div
      className={styles.container}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <MagnifierIcon onClick={onClick} />
    </div>
  )
}

export default Magnifier
