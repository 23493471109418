// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../fragments/mediaFile.generated';
export type LeadSuggestedMessageForSuggestedMessageDisplayFragment = { __typename: 'LeadSuggestedMessage', id: string, message: string, suggestedMessageRevision: { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, tags: Array<string>, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null } };

export type SuggestedMessageRevisionForSuggestedMessageDisplayFragment = { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, tags: Array<string>, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export const SuggestedMessageRevisionForSuggestedMessageDisplayFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageDisplay"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"mediaFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"starred"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}}]}}]} as unknown as DocumentNode;
export const LeadSuggestedMessageForSuggestedMessageDisplayFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadSuggestedMessageForSuggestedMessageDisplay"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LeadSuggestedMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageDisplay"}}]}}]}}]} as unknown as DocumentNode;