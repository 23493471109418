import createConfigClient, { Config as ConfigBase } from './createConfigClient'

let config: ConfigBase

if (process.env.TARGET === 'web') {
  config = createConfigClient(window.__APP_CONFIG__)
} else {
  const nodeConfig = require('config')
  config = createConfigClient(nodeConfig.util.toObject(nodeConfig))
}

export interface Config extends ConfigBase {}
export default config
