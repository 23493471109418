import React from 'react'
import { ExpertPresenceStatus } from '#src/generated/types'
import Toast from '#src/app/components/Toast'
import formattedToast from '../formattedToast'
import StopWatch from './stopwatch.svg'
import styles from './styles.css'

interface Props {
  closeToast?: () => void
  currentStatus: ExpertPresenceStatus
  previousStatus: ExpertPresenceStatus
}

const ShiftEndedToast: React.FC<React.PropsWithChildren<Props>> = ({ closeToast, previousStatus }) => {
  return (
    <Toast onCloseToastRequested={() => closeToast && closeToast()}>
      <div className={styles.side}>
        <StopWatch />
      </div>
      <div className={styles.textContainer}>
        {previousStatus === ExpertPresenceStatus.ON_SHIFT ? (
          <div className={styles.title}>Your Customer Acquisition Time has ended</div>
        ) : previousStatus === ExpertPresenceStatus.ON_STANDBY ? (
          <div className={styles.title}>Your Flex Mode time has ended</div>
        ) : (
          <div className={styles.title}>Your On Call CAT has ended</div>
        )}
        {previousStatus === ExpertPresenceStatus.ON_SHIFT ? (
          <div className={styles.subTitle}>
            New leads are now paused. Existing leads may continue to message you. If you have
            another CAT slot next, please reset your status and unpause leads now.
          </div>
        ) : previousStatus === ExpertPresenceStatus.ON_STANDBY ? (
          <div className={styles.subTitle}>
            New leads are now paused. Existing leads may continue to message you. If you want to
            continue to be on flex mode, please restart your flex mode and unpause leads now.
          </div>
        ) : (
          <div className={styles.subTitle}>
            You will no longer receive new delegated leads. If you have another CAT slot coming up,
            you'll need to reset your status.
          </div>
        )}
      </div>
    </Toast>
  )
}

const shiftEndedToast = (props: Props) => formattedToast(<ShiftEndedToast {...props} />)

export default shiftEndedToast
