// https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructor
import dingAudio from './ding.mp3'

const isNewNotificationSupported = () => {
  //@ts-ignore
  if (!window || !window.Notification || !Notification.requestPermission) return false
  try {
    new Notification('')
  } catch (e) {
    if (e instanceof Error && e.name == 'TypeError') {
      return false
    }
  }
  return true
}

const desktopNotification = (title: string, text: string, sound?: boolean) => {
  // @ts-ignore
  if (window.Notification && Notification.permission == 'granted') {
    new Notification(title, { body: text })
    if (sound) {
      playNotificationSound()
    }
  } else if (isNewNotificationSupported()) {
    if (Notification.permission !== 'denied') {
      /**
       * Safari does not support the promise version of requestPermission()
       * https://stackoverflow.com/questions/38114266/web-notifications-not-appearing-in-safari
       */
      Notification.requestPermission(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(title, { body: text })
        }
      })
    }
  }
}

interface NotificationOptions {
  title: string
  body: string
  sound?: boolean
}

const showNotification = ({ title, body, sound = false }: NotificationOptions) => {
  if (window.Notification) {
    Notification.requestPermission(result => {
      if (result === 'granted') {
        navigator.serviceWorker.ready.then(registration => {
          /**
           * Notifications not supported on all devices / browsers
           * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification
           */
          if (registration.showNotification) {
            if (sound) {
              playNotificationSound()
            }
            registration.showNotification(title, {
              body
            })
          }
        })
      }
    })
  }
}

const playNotificationSound = () => {
  const ringtone = new Audio(dingAudio)
  ringtone.loop = false
  ringtone.play()
}

export { desktopNotification, playNotificationSound, showNotification }
