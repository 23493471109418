import React from 'react'
import useLocalStorageState from 'use-local-storage-state'
import classnames from 'classnames'
import MyRecentsPanel from './MyRecentsPanel'
import EveryoneRecentsPanel from './EveryoneRecentsPanel'
import styles from './styles.css'

const PANELS = [
  {
    title: 'My recents',
    Display: MyRecentsPanel
  },
  {
    title: 'Everyone',
    Display: EveryoneRecentsPanel
  }
]

interface RecentsPanelMenuProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const RecentsPanelMenu: React.FC<React.PropsWithChildren<RecentsPanelMenuProps>> = props => {
  const [activeDisplayIndex, setActiveDisplayIndex] = useLocalStorageState(
    'media-upload-recents-active-display',
    {
      defaultValue: 1
    }
  )
  const panelTitles = PANELS.map(panel => panel.title)
  const ActiveResults = PANELS[activeDisplayIndex].Display

  return (
    <>
      <div className={styles.panelTitles}>
        {panelTitles.map((title, idx) => {
          return (
            <div
              key={title}
              className={classnames(styles.panelTitle, {
                [styles.activePanelTitle]: idx === activeDisplayIndex
              })}
              onClick={() => setActiveDisplayIndex(idx)}
            >
              {title}
            </div>
          )
        })}
      </div>
      <ActiveResults {...props} />
    </>
  )
}

const RecentsPanelModule = {
  title: 'Recents',
  Display: RecentsPanelMenu
}

export default RecentsPanelModule
