import React from 'react'
import { Logo } from '@deal/components'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import ReactivateBusinessUserAccountDisplay from '../ReactivateBusinessUserAccountDisplay'
import styles from './styles.css'

interface Props {
  selectedBusinessUserId?: string
  onSubmitted?: () => void
}

const ReactivateBusinessUserAccountSplash: React.FC<React.PropsWithChildren<Props>> = props => {
  const isLargerThanMd = useBreakpoint('md')
  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.header}>
            <Logo
              mark="combo"
              height={isLargerThanMd ? 40 : 24}
              width={isLargerThanMd ? 202 : 124}
            />

            <div className={styles.experts}>EXPERTS</div>
          </div>
          <ReactivateBusinessUserAccountDisplay {...props} />
          <div className={styles.footer}>
            Need help? Send an email to:{' '}
            <a href="mailto:expert-applications@operations.curated.com" className={styles.link}>
              expert-applications@operations.curated.com
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReactivateBusinessUserAccountSplash
