import { useAnalyticsContext } from '#src/app/containers/Analytics'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { isOnLeadShift } from '#src/app/services/expertStatus'
import { HotMarketIndicatorViewedEvent } from '#src/app/events/HotMarketIndicatorViewedEvent'
import { useOnScreenCallback } from '@deal/dom-hooks'
import { useExperiment } from '@deal/experiment-js'
import React, { useRef } from 'react'
import styles from './styles.css'
import { MarketHeatLevel } from '#src/generated/types'
import { useMarketHeatChangedSubscription } from './MarketHeatIndicator.generated'
import StartStandbyButton from './StartStandbyButton'
import StartCATShiftButton from './StartCATShiftButton'

const MarketHeatIndicator: React.FC = () => {
  const { result: resultAvailabilityNudges } = useExperiment({
    experiment: 'biz-app-expert-availability-nudges',
    defaultTreatment: 'control'
  })
  if (resultAvailabilityNudges === 'control') {
    return null
  }

  const businessUser = useBusinessUser()
  const indicatorRef = useRef<HTMLDivElement>(null)
  const analytics = useAnalyticsContext()

  const { data } = useMarketHeatChangedSubscription({
    variables: { expertId: businessUser.id }
  })
  const heatLevel = data?.marketHeatChanged.marketHeatLevel

  const marketIsHot =
    heatLevel == MarketHeatLevel.HOT &&
    !isOnLeadShift(businessUser) &&
    businessUser.canAcceptLeadsInStandby

  useOnScreenCallback({
    ref: indicatorRef,
    callback: () => {
      if (heatLevel) {
        analytics?.track(
          new HotMarketIndicatorViewedEvent({
            level: heatLevel
          })
        )
      }
    }
  })

  if (marketIsHot) {
    return (
      <>
        <div ref={indicatorRef} className={styles.container}>
          🔥 Hot Market!
          {businessUser.hasCurrentExpertShift ? <StartCATShiftButton /> : <StartStandbyButton />}
        </div>
      </>
    )
  }
  return null
}

export default MarketHeatIndicator
