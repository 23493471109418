// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type NewAiReEngagementMessageSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type NewAiReEngagementMessageSubscription = { __typename: 'Subscription', leadReEngagementScheduled: { __typename: 'Lead', id: any } };


export const NewAiReEngagementMessageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"NewAiReEngagementMessage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadReEngagementScheduled"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type NewAiReEngagementMessageComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<NewAiReEngagementMessageSubscription, NewAiReEngagementMessageSubscriptionVariables>, 'subscription'>;

    export const NewAiReEngagementMessageComponent = (props: NewAiReEngagementMessageComponentProps) => (
      <ApolloReactComponents.Subscription<NewAiReEngagementMessageSubscription, NewAiReEngagementMessageSubscriptionVariables> subscription={NewAiReEngagementMessageDocument} {...props} />
    );
    

/**
 * __useNewAiReEngagementMessageSubscription__
 *
 * To run a query within a React component, call `useNewAiReEngagementMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewAiReEngagementMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewAiReEngagementMessageSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useNewAiReEngagementMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewAiReEngagementMessageSubscription, NewAiReEngagementMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewAiReEngagementMessageSubscription, NewAiReEngagementMessageSubscriptionVariables>(NewAiReEngagementMessageDocument, options);
      }
export type NewAiReEngagementMessageSubscriptionHookResult = ReturnType<typeof useNewAiReEngagementMessageSubscription>;
export type NewAiReEngagementMessageSubscriptionResult = Apollo.SubscriptionResult<NewAiReEngagementMessageSubscription>;