import React, { useEffect, useState } from 'react'
import { useHistory, usePageKey } from '@deal/router'
import { useCreatePath } from '@deal/path-flow'
import { Modal } from '@deal/components'
import { AttributeInput } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import SurveyComplete from '#src/app/components/SurveyComplete'
import StandalonePath from '#src/app/components/StandalonePath'
import styles from './styles.css'

interface ExpertRatingProps {
  onCloseRequested: () => void
  isOpen: boolean
  onSurveyResponseFinished?: () => void
  pathSchemaSlug: string
  sourceKey: string
  ctaName: string
  initialAttributes?: AttributeInput[]
}

const SurveyPathModal: React.FC<React.PropsWithChildren<ExpertRatingProps>> = ({
  onCloseRequested,
  isOpen,
  pathSchemaSlug,
  sourceKey,
  ctaName,
  onSurveyResponseFinished,
  initialAttributes
}) => {
  const pageKey = usePageKey()
  const businessUser = useBusinessUser()
  const history = useHistory()
  const [flowId, setFlowId] = useState<string | undefined>()
  const [directedActiveNodeIdOrSlug, setDirectedActiveNodeIdOrSlug] = useState<string | undefined>()
  const [showSurveyCompletePage, setShowSurveyCompletePage] = useState(false)

  const [createPath] = useCreatePath({
    onPathCreated: ({ pathGraphFlow, nodeSlugOrId }) => {
      setFlowId(pathGraphFlow?.id)
      setDirectedActiveNodeIdOrSlug(nodeSlugOrId)
    }
  })

  useEffect(() => {
    isOpen &&
      createPath({
        timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
        surveyResponse: {
          pathSelector: {
            pathSchemaSlug
          },
          initialAttributes,
          surveyRequestRecipientId: businessUser.id
        },
        trackingCodes: {
          sourceKey,
          pageKey: pageKey || '',
          ctaName
        }
      })
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      modalContentClassName={styles.modalContent}
      mobilePosition="bottom"
      contentLabel="Survey"
    >
      {flowId && isOpen && directedActiveNodeIdOrSlug && (
        <>
          {showSurveyCompletePage ? (
            <SurveyComplete
              onRedirectClicked={() => {
                onCloseRequested()
                // Strip any query params and remove from history in-case that is how path was launched
                history.replace(history.location.pathname)
              }}
            />
          ) : (
            <StandalonePath
              pathPlacement="modal"
              onActiveNodeUpdated={pathNavigationDetails => {
                if (pathNavigationDetails.nodeSlugOrId !== directedActiveNodeIdOrSlug) {
                  setDirectedActiveNodeIdOrSlug(pathNavigationDetails.nodeSlugOrId)
                }
              }}
              onSurveyResponseFinished={() => {
                onSurveyResponseFinished && onSurveyResponseFinished()
                setShowSurveyCompletePage(true)
              }}
              directedActiveNodeIdOrSlug={directedActiveNodeIdOrSlug}
              flowId={flowId}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default SurveyPathModal
