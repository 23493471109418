import React from 'react'
import {
  Button,
  CircleLoader,
  FancyContent,
  FancyShowcase,
  FullBlockLoader,
  Modal,
  ModalContent,
  ModalTitle
} from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useSavedSearchesQuery } from './SavedSearches.generated'
import Clock from './clock.svg'
import MessageBubble from './chat-round.svg'
import ShoppingBag from './bag.svg'
import Archive from './archive.svg'
import styles from './styles.css'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

const EndOfShiftModal: React.FC<React.PropsWithChildren<Props>> = ({ onRequestClose, isOpen }) => {
  const businessUser = useBusinessUser()

  const { data, loading } = useSavedSearchesQuery({
    variables: { groupName: 'expert-end-of-shift', entityName: 'lead' },
    skip: !isOpen
  })

  const savedSearches = data?.savedSearches

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      mobilePosition="bottom"
      width="medium"
      contentLabel="End of shift reminders"
      contentSpacing={false}
    >
      {savedSearches ? (
        <div className={styles.modal}>
          <FancyShowcase className={styles.fancyShowcase}>
            <div className={styles.content}>
              <div className={styles.value}>{savedSearches[0].count}</div>
              <div className={styles.label}>{savedSearches[0].title} in the past 7 days</div>
              <div className={styles.message}>Nice work {businessUser.shortName}!</div>
            </div>
          </FancyShowcase>
          <FancyContent>
            <ModalTitle>Before you go, make sure to</ModalTitle>
            <ModalContent className={styles.modalContent}>
              <div className={styles.reminder}>
                <MessageBubble className={styles.icon} /> Respond to each quality conversation
              </div>
              <div className={styles.reminder}>
                <ShoppingBag className={styles.icon} /> Send each a recommendation
              </div>
              <div className={styles.reminder}>
                <Clock className={styles.icon} /> Snooze ones you will follow-up on later
              </div>
              <div className={styles.reminder}>
                <Archive className={styles.icon} /> Archive ones that need no further action
              </div>

              <Button className={styles.button} size="large" onClick={onRequestClose}>
                OK
              </Button>
            </ModalContent>
          </FancyContent>
        </div>
      ) : loading ? (
        <FullBlockLoader loader={CircleLoader} />
      ) : (
        <div>error...</div>
      )}
    </Modal>
  )
}

export default EndOfShiftModal
