import React from 'react'
import { useExperiment } from '@deal/experiment-js'
import { Permission, useHasPermission } from '#src/app/utilities/permission'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import useExpertSavedSearchesExperiment from '#src/app/hooks/useExpertSavedSearchesExperiment'
import NavigationHeader from '../NavigationHeader'
import NavigationFooter from '../NavigationFooter'
import ExpertSavedSearches from '../ExpertSavedSearches'
import ExpertTags from '../ExpertTags'
import ExpertLeadFolderGroups from '../ExpertLeadFolderGroups'
import AccountSelector from '../AccountSelector'
import styles from './styles.css'

interface NavigationContentProps {
  onClosed: () => void
}

const NavigationContent: React.FC<NavigationContentProps> = ({ onClosed }) => {
  const isLargerThanMd = useBreakpoint('md')

  const { result: expertTags } = useExperiment({
    experiment: 'biz-app-expert-tags',
    defaultTreatment: 'control'
  })
  const showExpertTags = expertTags === 'on'

  const showExpertSavedSearches = useExpertSavedSearchesExperiment()

  const [canViewLeads] = useHasPermission(Permission.LEAD_READ)

  return (
    <>
      {!isLargerThanMd && <AccountSelector />}
      {isLargerThanMd && <NavigationHeader />}
      {showExpertTags && <ExpertTags />}
      {canViewLeads && <ExpertLeadFolderGroups onClosed={onClosed} />}
      {showExpertSavedSearches && <ExpertSavedSearches />}
      <NavigationFooter containerClassName={styles.footer} />
    </>
  )
}

export default NavigationContent
