import React from 'react'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { useQuickReplyVariableInfoPopoverQuery } from './QuickReplyVariableInfoModal.generated'
import InfoPopover from '../InfoPopover'
import styles from './styles.css'

interface QuickReplyVariableInfoModalProps {}

const QuickReplyVariableInfoPopover: React.FC<React.PropsWithChildren<
  QuickReplyVariableInfoModalProps
>> = () => {
  const { data, loading } = useQuickReplyVariableInfoPopoverQuery()

  const variables = data?.quickReplyTemplateVariable?.variables?.filter(
    variable => !variable.deprecated
  )

  return (
    <InfoPopover
      displayText="How to personalize messages with variables"
      popoverContent={
        loading ? (
          <FullBlockLoader loader={CircleLoader} className={styles.loader} />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>Use variables to personalize messages</div>
            <div className={styles.paragraphs}>
              {
                'Variables can be included in any quick reply. Quickly add a variable by typing an opening bracket { and selecting which one you want to use.'
              }
            </div>
            <div className={styles.variablesContainer}>
              <div className={styles.variablesHeader}>Available variables:</div>
              <div className={styles.variables}>
                {variables?.map(variable => (
                  <div key={variable.name}>
                    <div className={styles.variable}>{variable.name}</div>
                    <div className={styles.variableDescription}>{variable.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    />
  )
}

export default QuickReplyVariableInfoPopover
