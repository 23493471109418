// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForEditNeedsPopoverFragment = { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', category: { __typename: 'Category', id: any } } };

export type UserNeedForEditNeedsPopoverFragment = { __typename: 'UserNeed', id: any, category: { __typename: 'Category', id: any } };

export type CategoryTypeahaedForEditNeedsPopoverQueryVariables = Types.Exact<{
  query: Types.CategoryTypeaheadQuery;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CategoryTypeahaedForEditNeedsPopoverQuery = { __typename: 'Query', categoryTypeahead: { __typename: 'CategoryTypeaheadPayload', hits: Array<{ __typename: 'CategoryTypeaheadHit', id: any, pluralDisplayName: string }> } };

export type CategoryForEditNeedsPopoverQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['CategoryId']['input'];
}>;


export type CategoryForEditNeedsPopoverQuery = { __typename: 'Query', category: { __typename: 'Category', id: any, pluralDisplayName: string, children: Array<{ __typename: 'Category', id: any, pluralDisplayName: string }> } };

export const BusinessUserForEditNeedsPopoverFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForEditNeedsPopover"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export const UserNeedForEditNeedsPopoverFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserNeedForEditNeedsPopover"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserNeed"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const CategoryTypeahaedForEditNeedsPopoverDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CategoryTypeahaedForEditNeedsPopover"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryTypeaheadQuery"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"first"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"categoryTypeahead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"Argument","name":{"kind":"Name","value":"first"},"value":{"kind":"Variable","name":{"kind":"Name","value":"first"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pluralDisplayName"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CategoryTypeahaedForEditNeedsPopoverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>, 'query'> & ({ variables: CategoryTypeahaedForEditNeedsPopoverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryTypeahaedForEditNeedsPopoverComponent = (props: CategoryTypeahaedForEditNeedsPopoverComponentProps) => (
      <ApolloReactComponents.Query<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables> query={CategoryTypeahaedForEditNeedsPopoverDocument} {...props} />
    );
    

/**
 * __useCategoryTypeahaedForEditNeedsPopoverQuery__
 *
 * To run a query within a React component, call `useCategoryTypeahaedForEditNeedsPopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTypeahaedForEditNeedsPopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTypeahaedForEditNeedsPopoverQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCategoryTypeahaedForEditNeedsPopoverQuery(baseOptions: Apollo.QueryHookOptions<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>(CategoryTypeahaedForEditNeedsPopoverDocument, options);
      }
export function useCategoryTypeahaedForEditNeedsPopoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>(CategoryTypeahaedForEditNeedsPopoverDocument, options);
        }
export function useCategoryTypeahaedForEditNeedsPopoverSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>(CategoryTypeahaedForEditNeedsPopoverDocument, options);
        }
export type CategoryTypeahaedForEditNeedsPopoverQueryHookResult = ReturnType<typeof useCategoryTypeahaedForEditNeedsPopoverQuery>;
export type CategoryTypeahaedForEditNeedsPopoverLazyQueryHookResult = ReturnType<typeof useCategoryTypeahaedForEditNeedsPopoverLazyQuery>;
export type CategoryTypeahaedForEditNeedsPopoverSuspenseQueryHookResult = ReturnType<typeof useCategoryTypeahaedForEditNeedsPopoverSuspenseQuery>;
export type CategoryTypeahaedForEditNeedsPopoverQueryResult = Apollo.QueryResult<CategoryTypeahaedForEditNeedsPopoverQuery, CategoryTypeahaedForEditNeedsPopoverQueryVariables>;
export const CategoryForEditNeedsPopoverDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CategoryForEditNeedsPopover"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CategoryId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"categoryId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"categoryId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"children"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"recurse"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"pluralDisplayName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"pluralDisplayName"}}]}}]}}]} as unknown as DocumentNode;
export type CategoryForEditNeedsPopoverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>, 'query'> & ({ variables: CategoryForEditNeedsPopoverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CategoryForEditNeedsPopoverComponent = (props: CategoryForEditNeedsPopoverComponentProps) => (
      <ApolloReactComponents.Query<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables> query={CategoryForEditNeedsPopoverDocument} {...props} />
    );
    

/**
 * __useCategoryForEditNeedsPopoverQuery__
 *
 * To run a query within a React component, call `useCategoryForEditNeedsPopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryForEditNeedsPopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryForEditNeedsPopoverQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCategoryForEditNeedsPopoverQuery(baseOptions: Apollo.QueryHookOptions<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>(CategoryForEditNeedsPopoverDocument, options);
      }
export function useCategoryForEditNeedsPopoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>(CategoryForEditNeedsPopoverDocument, options);
        }
export function useCategoryForEditNeedsPopoverSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>(CategoryForEditNeedsPopoverDocument, options);
        }
export type CategoryForEditNeedsPopoverQueryHookResult = ReturnType<typeof useCategoryForEditNeedsPopoverQuery>;
export type CategoryForEditNeedsPopoverLazyQueryHookResult = ReturnType<typeof useCategoryForEditNeedsPopoverLazyQuery>;
export type CategoryForEditNeedsPopoverSuspenseQueryHookResult = ReturnType<typeof useCategoryForEditNeedsPopoverSuspenseQuery>;
export type CategoryForEditNeedsPopoverQueryResult = Apollo.QueryResult<CategoryForEditNeedsPopoverQuery, CategoryForEditNeedsPopoverQueryVariables>;