import { useExperiment } from '@deal/experiment-js'

const useExpertSavedSearchesExperiment = () => {
  const { result } = useExperiment({
    experiment: 'biz-app-expert-saved-searches',
    defaultTreatment: 'control'
  })

  return result === 'on'
}

export default useExpertSavedSearchesExperiment
