import React, { useState } from 'react'
import { Popover } from '@deal/components'
import InfoIcon from './info.svg'
import styles from './styles.css'

interface InfoPopoverProps {
  displayText: string
  popoverContent: React.ReactNode
}

const InfoPopover: React.FC<React.PropsWithChildren<InfoPopoverProps>> = ({ displayText, popoverContent }) => {
  const [showPopover, setShowPopover] = useState(false)
  return (
    <Popover
      contentLabel={displayText}
      placement="bottom"
      elevation="high"
      visible={showPopover}
      onRequestClose={() => setShowPopover(false)}
      content={popoverContent}
    >
      <div
        className={showPopover ? styles.active : styles.container}
        onClick={() => setShowPopover(true)}
      >
        <InfoIcon /> {displayText}
      </div>
    </Popover>
  )
}

export default InfoPopover
