import React from 'react'
import isNumber from 'lodash/isNumber'
import classnames from 'classnames'
import { useHistory } from '@deal/router'
import { Tooltip } from '@deal/components'
import styles from './styles.css'
interface Props {
  icon?: React.ReactNode
  displayName: string
  displayNameMessage?: string | null
  count?: number | null
  countMessage?: string | null
  subText?: string
  badgeCount?: boolean
  badgeVariant?: 'primary' | 'secondary'
  isActive?: boolean
  orange?: boolean
  to?: string
  onClick?: () => void
}

const NavFolderLink: React.FC<React.PropsWithChildren<Props>> = ({
  displayName,
  displayNameMessage,
  subText,
  count,
  countMessage,
  badgeCount = false,
  icon,
  to,
  isActive,
  badgeVariant = 'primary',
  orange = false,
  onClick
}) => {
  const history = useHistory()
  const { location } = history

  // If isActive isn't provided, default to matching the path
  const active =
    typeof isActive === 'boolean' ? isActive : to ? location.pathname.includes(to) : false

  const showBadge = !active && badgeCount
  const badge = (
    <div
      className={classnames(styles.count, {
        [styles.primary]: showBadge && badgeVariant === 'primary',
        [styles.secondary]: showBadge && badgeVariant === 'secondary'
      })}
    >
      {count}
    </div>
  )
  const folderId = 'folder-' + displayName.replace(/\s/g, '-').toLowerCase()

  return (
    <div
      onClick={() => {
        if (to) {
          history.push(to)
        }
        onClick?.()
      }}
      className={classnames(styles.navLink, {
        [styles.orange]: orange,
        [styles.navLinkActive]: active
      })}
      id={folderId}
    >
      <div className={styles.left}>
        <div className={styles.display}>
          {icon && <div className={styles.icon}>{icon}</div>}
          {displayNameMessage ? (
            <Tooltip message={displayNameMessage} placement="right" hideTooltipOnMobile>
              <div className={styles.displayName}>{displayName}</div>
            </Tooltip>
          ) : (
            <div className={styles.displayName}>{displayName}</div>
          )}
        </div>
        {subText && <div className={styles.subText}>{subText}</div>}
      </div>

      {isNumber(count) && (
        <>
          {countMessage ? (
            <Tooltip message={countMessage} placement="right" theme="dark" hideTooltipOnMobile>
              {badge}
            </Tooltip>
          ) : (
            badge
          )}
        </>
      )}
    </div>
  )
}

export default NavFolderLink
