import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertMediaSearchPanelViewedEventProperties {
  expert_id: string
  panel_viewed: string
}

export class ExpertMediaSearchPanelViewedEvent extends Event<
  ExpertMediaSearchPanelViewedEventProperties
> {
  static displayName = 'Expert Viewed Media Search Panel'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
