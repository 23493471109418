import { Permission } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { BusinessUserFragment } from '#src/app/fragments/businessUser.generated'

export function hasPermission(
  businessUser: BusinessUserFragment | null | undefined,
  permission: Permission
) {
  return !!businessUser?.permissions.includes(permission)
}

export function useHasPermission(...permissions: Permission[]): boolean[] {
  const businessUser = useBusinessUser()

  return permissions.map(permission => hasPermission(businessUser, permission))
}

export { Permission }
