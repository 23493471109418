import React, { useContext, useEffect, useRef } from 'react'
import cookies from 'js-cookie'
import { UnregisterCallback } from 'history'
export * from './withIdentity'
import { BroadcastChannel } from 'broadcast-channel'
import { ApolloQueryResult } from '@apollo/client'
import { ViewerContext } from '@deal/web-tracking/constants'
import { useHistory } from '@deal/router'
import { usePrevious } from '@deal/dom-hooks'
import { CircleLoader, FullPageLoader } from '@deal/components'
import { BusinessUserState, ExpertApplicationState } from '#src/generated/types'
import { AppcuesIdentifyOptions } from '#src/app/window'
import { isImpersonating } from '#src/app/utilities/identity'
import loggerClient from '#src/app/services/loggerClient'
import config from '#src/app/config'
import { AuthenticatedBusinessUserFragment, MyselfFragment } from '../../fragments/myself.generated'
import { MyselfQuery, SessionFragment, useMyselfQuery } from './Myself.generated'
import { AnalyticsContext } from '../Analytics'

function getSessionId(sessionId: string): string {
  if (process.env.TARGET === 'web') {
    const sessionCookie = cookies.get('deal_sn')
    if (sessionCookie) {
      return atob(sessionCookie.split('.')[1])
    }
  }
  return sessionId
}

function getBusinessUserExperimentAttributes(myself: MyselfFragment | null) {
  const businessUserAttributes: { [key: string]: string } = {}
  myself?.businessUser?.experimentAttributes.forEach(entry => {
    businessUserAttributes[entry.name] = entry.value
  })
  return businessUserAttributes
}

export function getAppcuesIdentityOptions(businessUser: AuthenticatedBusinessUserFragment) {
  const currentTimeString = new Date()

  const isOnboardingCompleted =
    businessUser.active &&
    businessUser.expertApplication?.state === ExpertApplicationState.ONBOARDED

  const options: AppcuesIdentifyOptions = {
    departmentSlug: businessUser.department.slug,
    categorySlug: businessUser.expertAttributes.category.slug,
    isInternal: businessUser.internal,
    isActive: businessUser.active,
    isStandbyAllowed: businessUser.isStandbyAllowed,
    isExpertApplicant: businessUser.isExpertApplicant,
    isOnboardingCompleted: isOnboardingCompleted,
    isEligibleForExpertSelection: businessUser.isEligibleForExpertSelection,
    isGigEditor: businessUser.isGigEditor,
    displayName: businessUser.displayName,
    expertPresenceStatus: businessUser.expertPresence.status,
    // For time based targeting
    currentDayOfWeek: currentTimeString.getDay(), // 0=Sunday, 6=Saturday
    currentDayOfMonth: currentTimeString.getDate(), // 1-31,
    currentHour: currentTimeString.getHours(), // 0-23
    currentMonth: currentTimeString.getMonth() // 0=January, 11=December
  }

  if (businessUser.performanceStatistics) {
    const {
      effectiveConversionRate,
      effectiveRevenuePerLead,
      sixtyDayStats: {
        shiftCount: sixtyDayShiftCount,
        activeLeads: sixtyDayActiveLeads,
        qualityConversations: sixtyDayQualityConversations,
        qualityConversationsWithCuration: sixtyDayQualityConversationWithCuration,
        effectiveRevenuePerLead: sixtyDayEffectiveRevenuePerLead
      },
      threeSixtyFiveDayStats: {
        shiftCount: threeSixtyFiveDayShiftCount,
        activeLeads: threeSixtyFiveDayActiveLeads,
        qualityConversations: threeSixtyFiveDayQualityConversations,
        qualityConversationsWithCuration: threeSixtyFiveDayQualityConversationWithCuration,
        effectiveRevenuePerLead: threeSixtyFiveDayEffectiveRevenuePerLead
      }
    } = businessUser.performanceStatistics

    options.effectiveConversionRate = effectiveConversionRate
    options.effectiveRevenuePerLead = effectiveRevenuePerLead.amount

    options.sixtyDayShiftCount = sixtyDayShiftCount
    options.sixtyDayActiveLeads = sixtyDayActiveLeads
    options.sixtyDayQualityConversations = sixtyDayQualityConversations
    options.sixtyDayQualityConversationWithCuration = sixtyDayQualityConversationWithCuration
    options.sixtyDayEffectiveRevenuePerLead = sixtyDayEffectiveRevenuePerLead.amount

    options.threeSixtyFiveDayShiftCount = threeSixtyFiveDayShiftCount
    options.threeSixtyFiveDayActiveLeads = threeSixtyFiveDayActiveLeads
    options.threeSixtyFiveDayQualityConversations = threeSixtyFiveDayQualityConversations
    options.threeSixtyFiveDayQualityConversationWithCuration = threeSixtyFiveDayQualityConversationWithCuration
    options.threeSixtyFiveDayEffectiveRevenuePerLead =
      threeSixtyFiveDayEffectiveRevenuePerLead.amount
  }

  if (businessUser.performance) {
    options.rank = businessUser.performance.rating
  }

  if (businessUser.email) {
    options.email = businessUser.email
  }

  return options
}

// Context
type IdentityContextType = {
  myself: MyselfFragment | null
  session: { id: string | null }
  businessUserExperimentAttributes: { [key: string]: string }
  refetch?: () => Promise<ApolloQueryResult<MyselfQuery>>
  isImpersonating: boolean
}

const IdentityContext = React.createContext<IdentityContextType>({
  myself: null,
  session: { id: null },
  businessUserExperimentAttributes: {},
  isImpersonating: false
})

// Consumer
const IdentityConsumer = IdentityContext.Consumer
type IdentityChangedEventHandler = (me: MyselfFragment | null) => void

interface Props {
  me: MyselfFragment | null
  session: SessionFragment
  refetch?: () => Promise<ApolloQueryResult<MyselfQuery>>
  onChanged?: IdentityChangedEventHandler
}

const IdentityProviderWithReset: React.FC<React.PropsWithChildren<Props>> = ({
  onChanged,
  me,
  session,
  refetch,
  children
}) => {
  const business = config.get('business')
  const analytics = useContext(AnalyticsContext)
  const history = useHistory()

  const userId = me && me.user && me.user.id
  const businessUserId = me && me.businessUser && me.businessUser.id
  const previousBusinessUserId = usePrevious(businessUserId)
  const unlisten = useRef<UnregisterCallback>()

  const channel = React.useRef<BroadcastChannel>()

  const onChannelMessage = (message: any) => {
    const newBizUserId = message.bizUserId

    if (businessUserId !== newBizUserId) {
      window.location.href = `${business.protocol}://${business.host}:${business.port}`
    }
  }

  useEffect(() => {
    analytics?.identify(
      {
        userId,
        businessUserId
      },
      ViewerContext.BusinessUser
    )

    const businessUser = me?.businessUser

    /**
     * The call to `identify` is how Appcues is initialized for a user and how Appcues calculates our MAU number for billing.
     * We should only activate Appcues for experts who are active or currently onboarding.
     * - If an expert is active, they will always be fully onboarded and eligible for Appcues.
     * - If an expert's status is PENDING, we need to check their application status. They could be deferred or rejected in which case they don't need Appcues.
     */
    const isActiveOrOnboardingExpert =
      businessUser?.active ||
      (businessUser?.state === BusinessUserState.PENDING &&
        businessUser?.expertApplication?.state === ExpertApplicationState.APPROVED)

    if (isActiveOrOnboardingExpert) {
      const options = getAppcuesIdentityOptions(businessUser)
      if (window.Appcues) {
        window.Appcues?.identify(businessUserId, options)
      } else {
        window.AppcuesReady(() => {
          window.Appcues?.identify(businessUserId, options)
        })
      }
    }

    // If a separate tab has signaled there was an auth change, reload the page if this one is out-of-sync
    if (channel.current) {
      channel.current.onmessage = onChannelMessage
    }

    if (previousBusinessUserId) {
      channel.current?.postMessage({ bizUserId: businessUserId })
    }

    if (onChanged) {
      onChanged(me)
    }
  }, [businessUserId])

  useEffect(() => {
    // Identify the updated userId in the logger client
    loggerClient.identifyUser({
      id: userId
    })

    channel.current = new BroadcastChannel('deal-biz-auth')

    // Fire change identity event on-mount in case we are impersonating a new expert and have already assumed new identity
    channel.current?.postMessage({ bizUserId: businessUserId })

    if (channel.current) {
      channel.current.onmessage = onChannelMessage
    }

    unlisten.current = history.listen((_location, action) => {
      // Track a PageViewed event
      const ref = new URLSearchParams(location.search).get('ref') || undefined

      analytics?.page({
        navigation_type: `client_${action.toLowerCase() as Lowercase<typeof action>}`,
        ref
      })
    })

    return () => {
      channel.current?.close()
      unlisten.current && unlisten.current()
    }
  }, [])

  /**
   * changingIdentity will only be updated after the render. However, we want to render the loader
   * immediately when the business user identity changes, thus we need the second condition
   */

  return (
    <IdentityContext.Provider
      value={{
        myself: me,
        session: { id: getSessionId(session.id) },
        businessUserExperimentAttributes: getBusinessUserExperimentAttributes(me),
        refetch,
        isImpersonating: isImpersonating(me)
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}

interface ProviderProps {
  onChanged?: IdentityChangedEventHandler
}

// Provider
// This provider is a small wrapper around the context provider, in order to handle the loading state
const IdentityProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  onChanged,
  children
}) => {
  const { data, loading, refetch: refetchMyself } = useMyselfQuery()

  const refetch = () => {
    return refetchMyself().then(response => {
      return response
    })
  }

  if (loading) {
    return <FullPageLoader loader={CircleLoader} />
  } else if (data) {
    return (
      <IdentityProviderWithReset
        me={data.me || null}
        session={data.session}
        refetch={refetch}
        onChanged={onChanged}
      >
        {children}
      </IdentityProviderWithReset>
    )
  } else {
    return <div>Error...</div>
  }
}

const useIdentityContext = () => {
  const identityContext = useContext(IdentityContext)

  if (!identityContext) {
    throw new Error('Invoked `IdentityContext` outside of provider')
  }

  return identityContext
}

export { IdentityProvider, IdentityConsumer, IdentityContext, useIdentityContext }
