import { MarketHeatLevel } from '#src/generated/types'
import { Event, ViewerContext } from '@deal/web-tracking'

export interface HotMarketIndicatorViewedEventProperties {
  level: MarketHeatLevel
}

export class HotMarketIndicatorViewedEvent extends Event<HotMarketIndicatorViewedEventProperties> {
  static displayName = 'Hot Market Indicator Viewed'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
