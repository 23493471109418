// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertLeadFolderGroupFragment = { __typename: 'ExpertLeadFolderGroup', type: Types.ExpertLeadFolderGroupType, displayName: string, folders: Array<{ __typename: 'ExpertLeadFolder', id: any, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string, description?: string | null, hideIfEmpty: boolean, subFolders: Array<{ __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string }> }> };

export type ExpertLeadFolderFragment = { __typename: 'ExpertLeadFolder', id: any, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string, description?: string | null, hideIfEmpty: boolean, subFolders: Array<{ __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string }> };

export type ExpertLeadSubFolderFragment = { __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string };

export type ExpertLeadFolderGroupsQueryVariables = Types.Exact<{
  expertId?: Types.InputMaybe<Types.Scalars['BusinessUserId']['input']>;
  expertTeamId?: Types.InputMaybe<Types.Scalars['BusinessUserTeamId']['input']>;
}>;


export type ExpertLeadFolderGroupsQuery = { __typename: 'Query', expertLeadFolderGroups: Array<{ __typename: 'ExpertLeadFolderGroup', type: Types.ExpertLeadFolderGroupType, displayName: string, folders: Array<{ __typename: 'ExpertLeadFolder', id: any, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string, description?: string | null, hideIfEmpty: boolean, subFolders: Array<{ __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string }> }> }> };

export type ExpertLeadFolderChangedSubscriptionVariables = Types.Exact<{
  expertId?: Types.InputMaybe<Types.Scalars['BusinessUserId']['input']>;
  expertTeamId?: Types.InputMaybe<Types.Scalars['BusinessUserTeamId']['input']>;
}>;


export type ExpertLeadFolderChangedSubscription = { __typename: 'Subscription', expertLeadFolderChanged: { __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string } };

export type ExpertLeadFolderChangedOnCurrentShiftSubscriptionVariables = Types.Exact<{
  expertId?: Types.InputMaybe<Types.Scalars['BusinessUserId']['input']>;
  expertTeamId?: Types.InputMaybe<Types.Scalars['BusinessUserTeamId']['input']>;
}>;


export type ExpertLeadFolderChangedOnCurrentShiftSubscription = { __typename: 'Subscription', expertLeadFolderChangedOnCurrentShift: { __typename: 'ExpertLeadFolder', id: any, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string } };

export const ExpertLeadSubFolderFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertLeadSubFolder"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertLeadFolder"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalCountOnCurrentShift"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]} as unknown as DocumentNode;
export const ExpertLeadFolderFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertLeadFolder"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertLeadFolder"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalCountOnCurrentShift"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"hideIfEmpty"}},{"kind":"Field","name":{"kind":"Name","value":"subFolders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadSubFolder"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertLeadFolderGroupFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertLeadFolderGroup"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertLeadFolderGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"folders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadFolder"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertLeadFolderGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertLeadFolderGroups"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadFolderGroups"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}},{"kind":"Argument","name":{"kind":"Name","value":"expertTeamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadFolderGroup"}}]}}]}},...ExpertLeadFolderGroupFragmentDoc.definitions,...ExpertLeadFolderFragmentDoc.definitions,...ExpertLeadSubFolderFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertLeadFolderGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>, 'query'>;

    export const ExpertLeadFolderGroupsComponent = (props: ExpertLeadFolderGroupsComponentProps) => (
      <ApolloReactComponents.Query<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables> query={ExpertLeadFolderGroupsDocument} {...props} />
    );
    

/**
 * __useExpertLeadFolderGroupsQuery__
 *
 * To run a query within a React component, call `useExpertLeadFolderGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadFolderGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadFolderGroupsQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      expertTeamId: // value for 'expertTeamId'
 *   },
 * });
 */
export function useExpertLeadFolderGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>(ExpertLeadFolderGroupsDocument, options);
      }
export function useExpertLeadFolderGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>(ExpertLeadFolderGroupsDocument, options);
        }
export function useExpertLeadFolderGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>(ExpertLeadFolderGroupsDocument, options);
        }
export type ExpertLeadFolderGroupsQueryHookResult = ReturnType<typeof useExpertLeadFolderGroupsQuery>;
export type ExpertLeadFolderGroupsLazyQueryHookResult = ReturnType<typeof useExpertLeadFolderGroupsLazyQuery>;
export type ExpertLeadFolderGroupsSuspenseQueryHookResult = ReturnType<typeof useExpertLeadFolderGroupsSuspenseQuery>;
export type ExpertLeadFolderGroupsQueryResult = Apollo.QueryResult<ExpertLeadFolderGroupsQuery, ExpertLeadFolderGroupsQueryVariables>;
export const ExpertLeadFolderChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertLeadFolderChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadFolderChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}},{"kind":"Argument","name":{"kind":"Name","value":"expertTeamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadSubFolder"}}]}}]}},...ExpertLeadSubFolderFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertLeadFolderChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertLeadFolderChangedSubscription, ExpertLeadFolderChangedSubscriptionVariables>, 'subscription'>;

    export const ExpertLeadFolderChangedComponent = (props: ExpertLeadFolderChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertLeadFolderChangedSubscription, ExpertLeadFolderChangedSubscriptionVariables> subscription={ExpertLeadFolderChangedDocument} {...props} />
    );
    

/**
 * __useExpertLeadFolderChangedSubscription__
 *
 * To run a query within a React component, call `useExpertLeadFolderChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadFolderChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadFolderChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      expertTeamId: // value for 'expertTeamId'
 *   },
 * });
 */
export function useExpertLeadFolderChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ExpertLeadFolderChangedSubscription, ExpertLeadFolderChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertLeadFolderChangedSubscription, ExpertLeadFolderChangedSubscriptionVariables>(ExpertLeadFolderChangedDocument, options);
      }
export type ExpertLeadFolderChangedSubscriptionHookResult = ReturnType<typeof useExpertLeadFolderChangedSubscription>;
export type ExpertLeadFolderChangedSubscriptionResult = Apollo.SubscriptionResult<ExpertLeadFolderChangedSubscription>;
export const ExpertLeadFolderChangedOnCurrentShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertLeadFolderChangedOnCurrentShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadFolderChangedOnCurrentShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}},{"kind":"Argument","name":{"kind":"Name","value":"expertTeamId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertTeamId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"totalCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalCountOnCurrentShift"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]} as unknown as DocumentNode;
export type ExpertLeadFolderChangedOnCurrentShiftComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertLeadFolderChangedOnCurrentShiftSubscription, ExpertLeadFolderChangedOnCurrentShiftSubscriptionVariables>, 'subscription'>;

    export const ExpertLeadFolderChangedOnCurrentShiftComponent = (props: ExpertLeadFolderChangedOnCurrentShiftComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertLeadFolderChangedOnCurrentShiftSubscription, ExpertLeadFolderChangedOnCurrentShiftSubscriptionVariables> subscription={ExpertLeadFolderChangedOnCurrentShiftDocument} {...props} />
    );
    

/**
 * __useExpertLeadFolderChangedOnCurrentShiftSubscription__
 *
 * To run a query within a React component, call `useExpertLeadFolderChangedOnCurrentShiftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadFolderChangedOnCurrentShiftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadFolderChangedOnCurrentShiftSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      expertTeamId: // value for 'expertTeamId'
 *   },
 * });
 */
export function useExpertLeadFolderChangedOnCurrentShiftSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ExpertLeadFolderChangedOnCurrentShiftSubscription, ExpertLeadFolderChangedOnCurrentShiftSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertLeadFolderChangedOnCurrentShiftSubscription, ExpertLeadFolderChangedOnCurrentShiftSubscriptionVariables>(ExpertLeadFolderChangedOnCurrentShiftDocument, options);
      }
export type ExpertLeadFolderChangedOnCurrentShiftSubscriptionHookResult = ReturnType<typeof useExpertLeadFolderChangedOnCurrentShiftSubscription>;
export type ExpertLeadFolderChangedOnCurrentShiftSubscriptionResult = Apollo.SubscriptionResult<ExpertLeadFolderChangedOnCurrentShiftSubscription>;