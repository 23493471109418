// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
export type CallParticipantForNewExpertPhoneCallToast_BusinessUser_Fragment = { __typename: 'BusinessUser', businessUserId: any, businessUserFirstName: string };

export type CallParticipantForNewExpertPhoneCallToast_Operator_Fragment = { __typename: 'Operator' };

export type CallParticipantForNewExpertPhoneCallToast_User_Fragment = { __typename: 'User', userId: any, userFirstName?: string | null };

export type CallParticipantForNewExpertPhoneCallToastFragment = CallParticipantForNewExpertPhoneCallToast_BusinessUser_Fragment | CallParticipantForNewExpertPhoneCallToast_Operator_Fragment | CallParticipantForNewExpertPhoneCallToast_User_Fragment;

export type CallActivityForNewExpertPhoneCallToastFragment = { __typename: 'CallActivity', id: any, status: Types.CallStatus, type: Types.CallType, callee?: { __typename: 'BusinessUser', businessUserId: any, businessUserFirstName: string } | { __typename: 'Operator' } | { __typename: 'User', userId: any, userFirstName?: string | null } | null, caller?: { __typename: 'BusinessUser', businessUserId: any, businessUserFirstName: string } | { __typename: 'Operator' } | { __typename: 'User', userId: any, userFirstName?: string | null } | null, lead?: { __typename: 'Lead', id: any } | null };

export const CallParticipantForNewExpertPhoneCallToastFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CallParticipantForNewExpertPhoneCallToast"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CallParticipant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"businessUserId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"businessUserFirstName"},"name":{"kind":"Name","value":"firstName"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"userId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"userFirstName"},"name":{"kind":"Name","value":"firstName"}}]}}]}}]} as unknown as DocumentNode;
export const CallActivityForNewExpertPhoneCallToastFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CallActivityForNewExpertPhoneCallToast"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CallActivity"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"callee"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallParticipantForNewExpertPhoneCallToast"}}]}},{"kind":"Field","name":{"kind":"Name","value":"caller"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallParticipantForNewExpertPhoneCallToast"}}]}},{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;