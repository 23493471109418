import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertAttemptedToClaimLeadEscalationEventProperties {
  push_notification: boolean
  on_shift: boolean
  claimed_lead: boolean
}

export class ExpertAttemptedToClaimLeadEscalationEvent extends Event<
  ExpertAttemptedToClaimLeadEscalationEventProperties
> {
  static displayName = 'Expert Attempted To Claim Lead Esclation'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
