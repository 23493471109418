import React, { useEffect, useState } from 'react'
import { Checkbox } from '@deal/components'
import useFuseSearch from '#src/app/hooks/useFuseSearch'
import PopoverWithTextFieldAndButton from '#src/app/components/PopoverWithTextFieldAndButton'
import TagDisplay from '../TagDisplay'
import PlusIcon from './plus.svg'
import styles from './styles.css'

const reduceSelectedSuggestedMessageTags = (
  tags: { [key: string]: SuggestedMessageTag },
  tag?: SuggestedMessageTag
) => {
  const updatedTags = { ...tags }
  if (tag?.name) {
    updatedTags[tag.name] = tag
  }
  return updatedTags
}

type SuggestedMessageTag = {
  displayName: string
  name: string
}

interface SuggestedMessageTagAdderProps {
  suggestedMessageTags: SuggestedMessageTag[]
  selectedSuggestedMessageTags: (SuggestedMessageTag | undefined)[]
  onTagsUpdated: (tags: SuggestedMessageTag[]) => void
}

const SuggestedMessageTagAdder: React.FC<SuggestedMessageTagAdderProps> = ({
  suggestedMessageTags,
  selectedSuggestedMessageTags,
  onTagsUpdated
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedTags, setCheckedTags] = useState(() => {
    return selectedSuggestedMessageTags.reduce(reduceSelectedSuggestedMessageTags, {})
  })
  const [popoverVisible, setPopoverVisible] = useState(false)

  useEffect(() => {
    setCheckedTags(selectedSuggestedMessageTags.reduce(reduceSelectedSuggestedMessageTags, {}))
  }, [selectedSuggestedMessageTags])

  const tagsToShow = useFuseSearch({
    items: suggestedMessageTags || [],
    keys: ['displayName'],
    searchTerm,
    skip: searchTerm === ''
  })

  return (
    <PopoverWithTextFieldAndButton
      contentClassName={styles.popoverContent}
      textfieldProps={{
        label: 'Tag Search',
        labelHidden: true,
        placeholder: 'Find a tag...',
        value: searchTerm,
        onChange: e => setSearchTerm(e.target.value)
      }}
      buttonProps={{
        onClick: () => {
          onTagsUpdated(Object.values(checkedTags))
          setPopoverVisible(false)
        }
      }}
      popoverProps={{
        contentLabel: 'Tag',
        placement: 'bottom',
        elevation: 'high',
        interactive: true,
        visible: popoverVisible,
        onRequestClose: () => setPopoverVisible(false),
        content: (
          <div className={styles.listContainer}>
            {tagsToShow.map(tag => (
              <Checkbox
                key={tag.displayName}
                checked={!!checkedTags[tag.name]}
                onChange={e => {
                  if (e.target.checked) {
                    setCheckedTags(prevCheckedTags => {
                      const updatedCheckedTags = { ...prevCheckedTags }
                      updatedCheckedTags[tag.name] = tag
                      return updatedCheckedTags
                    })
                  } else {
                    setCheckedTags(prevCheckedTags => {
                      const updatedCheckedTags = { ...prevCheckedTags }
                      delete updatedCheckedTags[tag.name]
                      return updatedCheckedTags
                    })
                  }
                }}
              >
                {tag.displayName}
              </Checkbox>
            ))}
            {tagsToShow.length === 0 && <div>No results</div>}
          </div>
        )
      }}
    >
      <TagDisplay content={<PlusIcon />} onSelect={() => setPopoverVisible(!popoverVisible)} />
    </PopoverWithTextFieldAndButton>
  )
}

export default SuggestedMessageTagAdder
