import React, { useContext, useEffect } from 'react'
import { useHistory } from '@deal/router'
import { NotificationData } from '#src/app/typings/serviceWorker'
import leadSurgeStartedToast from '#src/app/toasts/leadSurgeStarted'
import { IdentityContext } from '#src/app/containers/Identity'
import leadEscalationStartedToast from '../../toasts/leadEscalationStarted/index'

const RegisterServiceWorker: React.FC<React.PropsWithChildren<{}>> = () => {
  const history = useHistory()
  const { myself } = useContext(IdentityContext)

  const initializeServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register(`/service-worker.js`)
          .then(registration => {
            console.log('SW registered: ', registration)
          })
          .catch(registrationError => {
            console.log(registrationError)
          })
      })

      //  Handler for messages coming from the service workerOS
      navigator.serviceWorker.addEventListener('message', event => {
        const data: NotificationData = event.data

        if (!data) {
          return
        }

        if (data.action === 'lead' && data.leadId) {
          history.push(`/inbox/${data.leadId}`)
        } else if (data.action === 'lead-escalation' && data.leadEscalationId && data.expertId) {
          if (myself?.businessUser?.id === data.expertId) {
            leadEscalationStartedToast({ leadEscalationId: data.leadEscalationId })
          }
        } else if (data.action === 'lead-surge' && data.leadSurgeId && data.expertId) {
          if (myself?.businessUser?.id === data.expertId) {
            leadSurgeStartedToast({ expertId: data.expertId, leadSurgeId: data.leadSurgeId })
          }
        }
      })
      navigator.serviceWorker.ready.then(registration => {
        if (isRunningStandalone()) {
          const options = {
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(getApplicationServerKey())
          }

          registration.pushManager.subscribe(options).then(
            pushSubscription => {
              console.log('pushSubscription:', pushSubscription.toJSON())
              updatePushSubscription(pushSubscription)
            },
            error => {
              // During development it often helps to log errors to the
              // console. In a production environment it might make sense to
              // also report information about errors back to the
              // application server.
              console.error(error)
            }
          )
        }
      })
    }
  }

  useEffect(() => {
    initializeServiceWorker()
  }, [])

  return null
}

async function updatePushSubscription(subscription: PushSubscription) {
  const url = `https://${resolveApiHostName()}/webpushsubscription`
  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-Deal-Viewer-Context': 'BUSINESS' },
    credentials: 'include',
    body: JSON.stringify({
      subscription: JSON.stringify(subscription.toJSON())
    })
  })
}

function resolveApiHostName(): string {
  switch (getEnvironment()) {
    case 'prod':
      return 'api.curated.com'
    case 'staging':
      return 'api.curated-staging.com'
    case 'dev':
      return 'api.curated-dev.com'
    default:
      throw new Error('Unknown environment')
  }
}

const urlB64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function getEnvironment() {
  const hostname = self.location.hostname
  if (hostname.includes('curated.com')) {
    return 'prod'
  }
  if (hostname.includes('curated-staging.com')) {
    return 'staging'
  }
  if (hostname.includes('curated-dev')) {
    return 'dev'
  }
}

function getApplicationServerKey() {
  switch (getEnvironment()) {
    case 'prod':
      return 'BChCIwNRyyExZyS29GMxvkcOKwgmDZ58mgUDS-7qreSjSR15ZOcsf8jRA4wfZXnAhbrz4DjpebGo1mQhxC2KOhw'
    case 'staging':
      return 'BNemJ2YzMTmL-5c9CVqCYpnzg4yagMrM_OsLJXNa7oOIP0HtUnjbdBaWHrghJOBBLVlndiXMj3RGcA1ZDJUFEMU'
    case 'dev':
      return 'BBZjLnje0ZbWMUiN2TX3o5JjJbmPCh08ceJCEQ279_qeT0AfOeNjRpKRAIMaHAc1_-tB3vHv2dfrnJ4DK6khxhI'
    default:
      throw new Error('Unknown environment')
  }
}

function isRunningStandalone() {
  return window.matchMedia('(display-mode: standalone)').matches
}
export default RegisterServiceWorker
