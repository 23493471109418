// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { ExpertPresenceFragmentDoc } from '../fragments/expertPresence.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SetAcceptingNewRequestsMutationVariables = Types.Exact<{
  id: Types.Scalars['BusinessUserId']['input'];
  accept: Types.Scalars['Boolean']['input'];
}>;


export type SetAcceptingNewRequestsMutation = { __typename: 'Mutation', setAcceptingNewRequests: { __typename: 'ExpertPresenceStatusUpdateResponse', error?: string | null, businessUser?: { __typename: 'BusinessUser', id: any, canAcceptBroadcastLeads: boolean, expertPresence: { __typename: 'ExpertPresenceAway', startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceExtendedAway', startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOffShift', status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnCall', startedAt: any, endsAt: any, shiftId: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnShift', acceptingNewLeads: boolean, startedAt: any, endsAt: any, shiftId: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnStandby', acceptingNewLeads: boolean, startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } } | null } };


export const SetAcceptingNewRequestsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetAcceptingNewRequests"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"accept"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setAcceptingNewRequests"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"accept"},"value":{"kind":"Variable","name":{"kind":"Name","value":"accept"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"canAcceptBroadcastLeads"}},{"kind":"Field","name":{"kind":"Name","value":"expertPresence"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertPresence"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}},...ExpertPresenceFragmentDoc.definitions]} as unknown as DocumentNode;
export type SetAcceptingNewRequestsMutationFn = Apollo.MutationFunction<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables>;
export type SetAcceptingNewRequestsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables>, 'mutation'>;

    export const SetAcceptingNewRequestsComponent = (props: SetAcceptingNewRequestsComponentProps) => (
      <ApolloReactComponents.Mutation<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables> mutation={SetAcceptingNewRequestsDocument} {...props} />
    );
    

/**
 * __useSetAcceptingNewRequestsMutation__
 *
 * To run a mutation, you first call `useSetAcceptingNewRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAcceptingNewRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAcceptingNewRequestsMutation, { data, loading, error }] = useSetAcceptingNewRequestsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useSetAcceptingNewRequestsMutation(baseOptions?: Apollo.MutationHookOptions<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables>(SetAcceptingNewRequestsDocument, options);
      }
export type SetAcceptingNewRequestsMutationHookResult = ReturnType<typeof useSetAcceptingNewRequestsMutation>;
export type SetAcceptingNewRequestsMutationResult = Apollo.MutationResult<SetAcceptingNewRequestsMutation>;
export type SetAcceptingNewRequestsMutationOptions = Apollo.BaseMutationOptions<SetAcceptingNewRequestsMutation, SetAcceptingNewRequestsMutationVariables>;