import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCurationFlowEnteredEventProperties {
  lead_id: string
  activity_id?: string
}

export class ExpertCurationFlowEnteredEvent extends Event<
  ExpertCurationFlowEnteredEventProperties
> {
  static displayName = 'Expert Curation Flow Entered'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
