import { BusinessUserTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCurationProductsSearchedEventProperties {
  lead_id: string
  activity_id?: string
  categoryIds?: string[]
  source: BusinessUserTracking.ExpertCurationProductsSearchedSource
  keywords?: string
  filters?: string[]
  facets?: { [key: string]: any }[]
  sort_by?: string
  sort_order?: string
  list_id?: string
}

export class ExpertCurationProductsSearchedEvent extends Event<
  ExpertCurationProductsSearchedEventProperties
> {
  static displayName = 'Expert Curation Products Searched'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
