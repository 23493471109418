import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { Modal } from '@deal/components'
import { useLocation } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { ExpertApplicationState, Permission } from '#src/generated/types'
import { useHasPermission } from '#src/app/utilities/permission'
import { isImpersonating, isSchedulingEnabled } from '#src/app/utilities/identity'
import { FOLDER_SLUGS } from '#src/app/utilities/displayNames'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useHelpCenterNewReleasesContext } from '#src/app/context/HelpCenterNewReleases'
import { NavigationContext } from '#src/app/context/Navigation'
import { useLeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { IdentityContext } from '#src/app/containers/Identity'
import ReactivateBusinessAccount from '#src/app/components/Navigation/ReactivateBusinessUserAccountDisplay'
import StopImpersonationLink from '#src/app/components/StopImpersonationLink'
import { useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription } from './NavigationFooter.generated'
import NavFolderLink from '../../NavFolderLink'
import TrophyIcon from './trophy.svg'
import SendIcon from './send.svg'
import SchedulingIcon from './scheduling.svg'
import ReportsIcon from './reports.svg'
import OnboardingIcon from './onboarding.svg'
import NotificationIcon from './notification.svg'
import MyTeamIcon from './my-team.svg'
import LoudspeakerIcon from './loudspeaker.svg'
import HiringIcon from './hiring.svg'
import HelpCenterIcon from './help-center.svg'
import BoltIcon from './bolt.svg'
import PdpReviewIcon from './pdp-review.svg'
import styles from './styles.css'

interface NavigationFooterProps {
  containerClassName?: string
}

const NavigationFooter: React.FC<React.PropsWithChildren<NavigationFooterProps>> = ({
  containerClassName
}) => {
  const businessUser = useBusinessUser()
  const { myself } = useContext(IdentityContext)
  const { unviewedJiraTicketCount } = useHelpCenterNewReleasesContext()
  const expertApplication = businessUser.expertApplication
  const { setIsNavigationOpen } = useContext(NavigationContext)
  const location = useLocation()
  const { setFolder, folder } = useLeadSearchFolderContext()
  const [showReactivation, setShowReactivation] = useState(false)

  const [canUseEmails] = useHasPermission(Permission.TEMPLATE_MANAGE)
  const [canRequestReferrals, canRefer, canGradeFinalEval, canViewLeaderboard] = useHasPermission(
    Permission.EXPERT_REFERRAL_REQUEST_CREATE,
    Permission.EXPERT_APPLICATION_REFERRAL_CREATE,
    Permission.EXPERT_APPLICATION_GRADE_FINAL_EVAL,
    Permission.EXPERT_PERFORMANCE_STATS_READ
  )

  const isOnboarding =
    expertApplication &&
    expertApplication.state !== ExpertApplicationState.ONBOARDED &&
    expertApplication.state !== ExpertApplicationState.REJECTED

  const { result: expertEmailsTreatment } = useExperiment({
    experiment: 'expert-emails',
    defaultTreatment: 'control'
  })

  const { result: helpCenterExperimentResult } = useExperiment({
    experiment: 'biz-app-help-center-tab',
    defaultTreatment: 'control'
  })

  const showHelpCenterTab = helpCenterExperimentResult === 'on'
  const isGigUser = !!businessUser.gigUser && (businessUser.isGigAuthor || businessUser.isGigEditor)

  const {
    data: expertChangeRequestedLeadCampaignCountData
  } = useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription({
    variables: { expertId: businessUser.id }
  })

  const expertLeadCampaignActionRequiredCount =
    expertChangeRequestedLeadCampaignCountData?.expertLeadCampaignActionRequiredCountChanged || 0

  return (
    <footer className={classnames(styles.navFooter, containerClassName)}>
      <ul className={styles.footerMenu}>
        {expertEmailsTreatment === 'on' && canUseEmails && (
          <li>
            <NavFolderLink
              displayName="Emails"
              icon={<SendIcon />}
              count={
                expertLeadCampaignActionRequiredCount > 0
                  ? expertLeadCampaignActionRequiredCount
                  : undefined
              }
              badgeCount={true}
              onClick={() => setIsNavigationOpen(false)}
              to="/emails/my-emails"
              isActive={location.pathname.includes('/emails/')}
            />
          </li>
        )}
        {isOnboarding && (
          <li>
            <NavFolderLink
              displayName="Orientation"
              icon={<OnboardingIcon />}
              to="/onboarding"
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}
        {canViewLeaderboard && (
          <li>
            <NavFolderLink
              displayName="Performance"
              icon={<TrophyIcon />}
              to="/performance"
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}
        {isSchedulingEnabled(myself) && (
          <li>
            <NavFolderLink
              displayName="Scheduling"
              icon={<SchedulingIcon />}
              to="/scheduling"
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}
        <li>
          <NavFolderLink
            displayName="Reports"
            icon={<ReportsIcon />}
            to="/reports"
            onClick={() => setIsNavigationOpen(false)}
          />
        </li>
        {canRequestReferrals && (
          <li>
            <NavFolderLink
              displayName="BYOL"
              icon={<LoudspeakerIcon />}
              to="/influencer-program"
              onClick={() => setIsNavigationOpen(false)}
              displayNameMessage='"Bring Your Own Lead" -- allows you to get bonuses for generating new leads on Curated'
            />
          </li>
        )}
        {(canRefer || canGradeFinalEval) && (
          <li>
            <NavFolderLink
              displayName="Expert Referrals"
              icon={<HiringIcon />}
              to={'/acquisition'}
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}
        <li>
          <NavFolderLink
            displayName="Top Expert Convos"
            icon={<MyTeamIcon />}
            to="/inbox"
            onClick={() => {
              setFolder(FOLDER_SLUGS.DEPARTMENT_TOP_EXPERTS, null)
              setIsNavigationOpen(false)
            }}
            isActive={
              location.pathname.includes('/inbox') && folder === FOLDER_SLUGS.DEPARTMENT_TOP_EXPERTS
            }
          />
        </li>
        {false && isGigUser && (
          <li>
            <NavFolderLink
              displayName="Content"
              icon={<BoltIcon />}
              onClick={() => setIsNavigationOpen(false)}
              to="/content/my-products"
              isActive={location.pathname.includes('/content')}
            />
          </li>
        )}
        <li>
          <NavFolderLink
            displayName="Notifications"
            icon={<NotificationIcon className={styles.notificationIcon} />}
            to="/notifications/settings"
            onClick={() => setIsNavigationOpen(false)}
          />
        </li>
        {showHelpCenterTab && (
          <li>
            <NavFolderLink
              displayName="Help Center"
              icon={<HelpCenterIcon />}
              to="/help-center"
              orange
              count={unviewedJiraTicketCount > 0 ? unviewedJiraTicketCount : undefined}
              badgeVariant="secondary"
              badgeCount
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}
        {businessUser.isPdpOwner && (
          <li>
            <NavFolderLink
              displayName="Product Page Reviews"
              icon={<PdpReviewIcon className={styles.pdpIcon} />}
              to="/pdp-review"
              onClick={() => setIsNavigationOpen(false)}
            />
          </li>
        )}

        {isImpersonating(myself) && (
          <>
            <li>
              <StopImpersonationLink />
            </li>
            {/* give ops the ability to send reactivation request on behalf of the expert. the
            reactivation page is automatically show to experts upon login, but for impersonating
            session, we don't block biz-app with reactivation page so that ops can see lead
            conversations */}
            {businessUser.deactivated && (
              <li>
                <a className={styles.reactivateLink} onClick={() => setShowReactivation(true)}>
                  Request reactivation
                </a>
              </li>
            )}
          </>
        )}
        {showReactivation && (
          <Modal
            isOpen={true}
            onRequestClose={() => setShowReactivation(false)}
            title="Reactivate account"
            width="large"
          >
            <ReactivateBusinessAccount />
          </Modal>
        )}
      </ul>
    </footer>
  )
}

export default NavigationFooter
