import React from 'react'
import ImageIcon from './image.svg'
import styles from './styles.css'

interface ResultsPlaceholderProps {
  placeholderText: string
}

const ResultsPlaceholder: React.FC<React.PropsWithChildren<React.PropsWithChildren<ResultsPlaceholderProps>>> = ({
  placeholderText
}) => {
  return (
    <>
      <div className={styles.imageContainerNoResults}>
        <div className={styles.noResult}>
          <ImageIcon />
          {placeholderText}
        </div>
      </div>
      <div className={styles.imageContainerNoResults} />
      <div className={styles.imageContainerNoResults} />
      <div className={styles.imageContainerNoResults} />
      <div className={styles.imageContainerNoResults} />
      <div className={styles.imageContainerNoResults} />
    </>
  )
}

export default ResultsPlaceholder
