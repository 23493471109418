import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { default as ReactConfetti } from 'react-confetti'
import { useViewportSize } from '@deal/dom-hooks'
import { useConfettiContext } from '#src/app/context/Confetti'

interface Props {}

const Confetti: React.FC<React.PropsWithChildren<Props>> = () => {
  const [mounted, setMounted] = useState(false)
  const { isActive } = useConfettiContext()

  useEffect(() => {
    setMounted(true)
  }, [])

  const { width, height } = useViewportSize()

  return mounted
    ? ReactDOM.createPortal(
        <ReactConfetti
          numberOfPieces={isActive ? 600 : 0}
          width={width}
          height={height}
          initialVelocityY={30}
          gravity={0.1}
        />,
        document.body
      )
    : null
}

export default Confetti
