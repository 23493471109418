import React from 'react'
import { Tooltip } from '@deal/components'
import { Control } from '@deal/chat-firebase'
import { MESSAGE_VARIABLE_TYPES } from '../../../routes/inbox/SnoozePopoverWithInboxLead/SnoozeForm/utils'
import VariableIcon from './variable.svg'

interface VariableControlProps {
  onVariableSelected: (variable: string) => void
  hasError?: boolean
}

const VariableControl: React.FC<VariableControlProps> = ({ onVariableSelected, hasError }) => {
  return (
    <Tooltip
      interactive={false}
      visible={hasError ? true : false}
      message="Add lead first name variable to customize your message before saving"
    >
      <Control
        title="Add variable"
        onClick={() => onVariableSelected(MESSAGE_VARIABLE_TYPES.CUSTOMER_FIRST_NAME.name)}
      >
        <VariableIcon />
      </Control>
    </Tooltip>
  )
}

export default VariableControl
