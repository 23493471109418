import React, { useState } from 'react'

// Context
type AccountsNeedAttentionContextType = {
  totalLeadsCount: () => number
  leadCountByExpertId: (expertId: string) => number
  updateLeadCountByExpertId: (expertId: string, count: number) => void
}

const AccountsNeedAttentionContext = React.createContext<AccountsNeedAttentionContextType>({
  totalLeadsCount: () => 0,
  leadCountByExpertId: () => 0,
  updateLeadCountByExpertId: () => {}
})

// Consumer
const AccountsNeedAttentionConsumer = AccountsNeedAttentionContext.Consumer

// Provider
const AccountsNeedAttentionProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [leadCountMap, setLeadCountMap] = useState<Map<string, number>>(new Map())

  const value = {
    totalLeadsCount: () =>
      Array.from(leadCountMap.values()).reduce((total, current) => total + current, 0),
    leadCountByExpertId: (expertId: string) => leadCountMap.get(expertId) || 0,
    updateLeadCountByExpertId: (expertId: string, count: number) => {
      if (count != leadCountMap.get(expertId)) {
        const updated = new Map(leadCountMap)
        updated.set(expertId, count)
        setLeadCountMap(updated)
      }
    }
  }

  return (
    <AccountsNeedAttentionContext.Provider value={value}>
      {children}
    </AccountsNeedAttentionContext.Provider>
  )
}

export {
  AccountsNeedAttentionContext,
  AccountsNeedAttentionConsumer,
  AccountsNeedAttentionProvider
}
