import React from 'react'

// Context
type ApolloReconnect = (() => void) | null
const ApolloReconnectContext = React.createContext<ApolloReconnect>(null)

// Consumer
const ApolloReconnectConsumer = ApolloReconnectContext.Consumer

// Provider
// This provider is a small wrapper around the context provider, in order to handle the loading state
const ApolloReconnectProvider = ApolloReconnectContext.Provider

export { ApolloReconnectProvider, ApolloReconnectConsumer, ApolloReconnectContext }
