import { ExpertProfileState } from '#src/generated/types'
import {
  AssignedBusinessUserFragment,
  AuthenticatedBusinessUserFragment,
  MyselfFragment
} from '../../fragments/myself.generated'
import { MyselfForIsImpersonatingFragment } from './MyselfForIsImpersonating.generated'

export function isImpersonating(myself: MyselfForIsImpersonatingFragment | null) {
  return myself?.realUser &&
    myself?.businessUser?.user &&
    myself?.businessUser?.user.id !== myself?.realUser.id
    ? true
    : false
}

export function isApplicant(businessUser: AuthenticatedBusinessUserFragment) {
  return !businessUser.isExpert && businessUser.isExpertApplicant
}

export function isExpert(businessUser: AuthenticatedBusinessUserFragment) {
  return businessUser.isExpert
}

export function hasSubmittedProfile(businessUser: AuthenticatedBusinessUserFragment) {
  return businessUser.expertProfile?.state === ExpertProfileState.SUBMITTED
}

export function isSchedulingEnabled(myself: MyselfFragment | null) {
  return (
    myself &&
    myself.businessUser &&
    myself.businessUser.schedulingUser &&
    myself.businessUser.schedulingUser.active &&
    myself.businessUser.schedulingUser.profiles.filter(profile => profile.type.active).length > 0
  )
}

/**
 * Returns how many accounts an expert has in each category
 * With the introduction of tenants, an expert can have multiple accounts in the same category
 * @param businessUsers
 * @returns map of category id to number of accounts
 */
export function getExpertCategoryCounts(businessUsers: AssignedBusinessUserFragment[]) {
  return businessUsers.reduce<Record<string, number>>(
    (
      acc,
      {
        expertAttributes: {
          category: { id }
        }
      }
    ) => {
      return acc[id] ? ++acc[id] : (acc[id] = 1), acc
    },
    {}
  )
}

/**
 * Get the display name for an account based on whether the expert has multiple accounts in the same category
 * Only show tenant info if there are conflicting accounts because most experts will only have one account per category
 * @param businessUser
 * @param categoryCount
 * @returns account display name
 */
export function getAccountDisplayName(
  businessUser: AssignedBusinessUserFragment,
  categoryCount: Record<string, number>
) {
  const {
    expertAttributes: { category },
    tenantDisplayName
  } = businessUser

  const hasMultipleAccountsInSameCategory = categoryCount[category.id] > 1

  return hasMultipleAccountsInSameCategory
    ? `${category.singularDisplayName} ${tenantDisplayName}`
    : category.singularDisplayName
}
