import React, { useContext } from 'react'
import { Hostname, HostnameContext } from '#src/app/containers/Hostname'
import config, { Config } from '#src/app/config'

export type HostAwareConfigContextType = Config & {
  _hostAwareConfig: true
}

const NoProviderFoundConfig: HostAwareConfigContextType = {
  get: () => {
    throw new Error('No config provider found!')
  },
  toObject: () => {
    throw new Error('No config provider found!')
  },
  has: () => {
    throw new Error('No config provider found!')
  },
  _hostAwareConfig: true
}

const HostConfigPrefix = /^consumer|^business|^api|^ops/

function prefixPath(hostname: Hostname, path: string) {
  if (hostname === Hostname.Curated) {
    return HostConfigPrefix.test(path) ? `curated.${path}` : path
  } else if (hostname === Hostname.Gcp) {
    return HostConfigPrefix.test(path) ? `gcp.${path}` : path
  } else {
    return path
  }
}

export function createHostAwareConfig(hostname: Hostname): HostAwareConfigContextType {
  return {
    get: (path: string) => {
      return config.get(prefixPath(hostname, path))
    },
    toObject: () => {
      return config.toObject()
    },
    has: (path: string) => {
      return config.has(prefixPath(hostname, path))
    },
    _hostAwareConfig: true
  }
}

const HostAwareConfigContext = React.createContext<HostAwareConfigContextType>(
  NoProviderFoundConfig
)
const HostAwareConfigConsumer = HostAwareConfigContext.Consumer

const HostAwareConfigProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const hostname = useContext(HostnameContext)
  return (
    <HostAwareConfigContext.Provider value={createHostAwareConfig(hostname)}>
      {children}
    </HostAwareConfigContext.Provider>
  )
}

export interface WithHostAwareConfigProps {
  config: HostAwareConfigContextType
}

export function withHostAwareConfig<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithHostAwareConfigProps>>
) {
  const WithHostAwareConfig: React.FC<React.PropsWithChildren<P>> = props => {
    const config = useContext(HostAwareConfigContext)
    return <WrappedComponent config={config} {...props} />
  }

  return WithHostAwareConfig
}

const useHostAwareConfigContext = () => {
  const context = useContext(HostAwareConfigContext)

  if (!HostAwareConfigContext) {
    throw new Error('Invoked HostAwareConfigContext outside of provider')
  }

  return context
}

export {
  HostAwareConfigProvider,
  HostAwareConfigConsumer,
  HostAwareConfigContext,
  useHostAwareConfigContext
}
