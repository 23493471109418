// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForNotificatonSettingsFragment = { __typename: 'BusinessUser', id: any, timeZone: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', newMessageNotificationMode: Types.ExpertNotificationMode, broadcastLeadNotificationMode: Types.ExpertNotificationMode, pauseLeadsForCall: boolean }, notificationSettings: Array<{ __typename: 'NotificationSetting', emailEnabled: boolean, smsEnabled: boolean, pushNotificationsEnabled: boolean, type: Types.NotificationType }> };

export type UpdateBusinessUserForNotificationSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateBusinessUserInput;
}>;


export type UpdateBusinessUserForNotificationSettingsMutation = { __typename: 'Mutation', updateBusinessUser: { __typename: 'UpdateBusinessUserPayload', businessUser?: { __typename: 'BusinessUser', id: any, timeZone: string, expertAttributes: { __typename: 'BusinessUserExpertAttributes', newMessageNotificationMode: Types.ExpertNotificationMode, broadcastLeadNotificationMode: Types.ExpertNotificationMode, pauseLeadsForCall: boolean }, notificationSettings: Array<{ __typename: 'NotificationSetting', emailEnabled: boolean, smsEnabled: boolean, pushNotificationsEnabled: boolean, type: Types.NotificationType }> } | null } };

export const BusinessUserForNotificatonSettingsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForNotificatonSettings"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newMessageNotificationMode"}},{"kind":"Field","name":{"kind":"Name","value":"broadcastLeadNotificationMode"}},{"kind":"Field","name":{"kind":"Name","value":"pauseLeadsForCall"}}]}},{"kind":"Field","name":{"kind":"Name","value":"notificationSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"smsEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"pushNotificationsEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}},{"kind":"Field","name":{"kind":"Name","value":"timeZone"}}]}}]} as unknown as DocumentNode;
export const UpdateBusinessUserForNotificationSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBusinessUserForNotificationSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBusinessUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBusinessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForNotificatonSettings"}}]}}]}}]}},...BusinessUserForNotificatonSettingsFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateBusinessUserForNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables>;
export type UpdateBusinessUserForNotificationSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables>, 'mutation'>;

    export const UpdateBusinessUserForNotificationSettingsComponent = (props: UpdateBusinessUserForNotificationSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables> mutation={UpdateBusinessUserForNotificationSettingsDocument} {...props} />
    );
    

/**
 * __useUpdateBusinessUserForNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserForNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserForNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserForNotificationSettingsMutation, { data, loading, error }] = useUpdateBusinessUserForNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserForNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables>(UpdateBusinessUserForNotificationSettingsDocument, options);
      }
export type UpdateBusinessUserForNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateBusinessUserForNotificationSettingsMutation>;
export type UpdateBusinessUserForNotificationSettingsMutationResult = Apollo.MutationResult<UpdateBusinessUserForNotificationSettingsMutation>;
export type UpdateBusinessUserForNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserForNotificationSettingsMutation, UpdateBusinessUserForNotificationSettingsMutationVariables>;