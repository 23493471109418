// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type NewMessageConversationQueryVariables = Types.Exact<{
  id: Types.Scalars['ConversationId']['input'];
}>;


export type NewMessageConversationQuery = { __typename: 'Query', conversation: { __typename: 'Conversation', id: any, lead?: { __typename: 'Lead', id: any, consumer: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null } } | null } };


export const NewMessageConversationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NewMessageConversation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ConversationId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conversation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"consumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type NewMessageConversationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewMessageConversationQuery, NewMessageConversationQueryVariables>, 'query'> & ({ variables: NewMessageConversationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewMessageConversationComponent = (props: NewMessageConversationComponentProps) => (
      <ApolloReactComponents.Query<NewMessageConversationQuery, NewMessageConversationQueryVariables> query={NewMessageConversationDocument} {...props} />
    );
    

/**
 * __useNewMessageConversationQuery__
 *
 * To run a query within a React component, call `useNewMessageConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMessageConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessageConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewMessageConversationQuery(baseOptions: Apollo.QueryHookOptions<NewMessageConversationQuery, NewMessageConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewMessageConversationQuery, NewMessageConversationQueryVariables>(NewMessageConversationDocument, options);
      }
export function useNewMessageConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewMessageConversationQuery, NewMessageConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewMessageConversationQuery, NewMessageConversationQueryVariables>(NewMessageConversationDocument, options);
        }
export function useNewMessageConversationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NewMessageConversationQuery, NewMessageConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NewMessageConversationQuery, NewMessageConversationQueryVariables>(NewMessageConversationDocument, options);
        }
export type NewMessageConversationQueryHookResult = ReturnType<typeof useNewMessageConversationQuery>;
export type NewMessageConversationLazyQueryHookResult = ReturnType<typeof useNewMessageConversationLazyQuery>;
export type NewMessageConversationSuspenseQueryHookResult = ReturnType<typeof useNewMessageConversationSuspenseQuery>;
export type NewMessageConversationQueryResult = Apollo.QueryResult<NewMessageConversationQuery, NewMessageConversationQueryVariables>;