import React, { useContext, useState } from 'react'
import { FOLDER_SLUGS } from '#src/app/utilities/displayNames'

type LeadSearchFolderContextType = {
  folder: string | null
  subFolder: string | null
  savedSearch: string | null
  isMultiSelectEnabled: boolean
  isBulkReSnoozeEnabled: boolean
  isBulkReSnoozeEdited: boolean
  isBulkReSnoozeFlyoutVisible: boolean
  multiSelectLeadList: string[]
  currentSavedTagId: string | null
  isCustomFacetMenuOpen: boolean
  setFolder: (folder: string | null, subFolder: string | null) => void
  setSavedSearch: (savedSearch: string | null) => void
  setIsMultiSelectEnabled: (isMultiSelectEnabled: boolean) => void
  setIsBulkReSnoozeEnabled: (isBulkReSnoozeEnabled: boolean) => void
  setIsBulkReSnoozeEdited: (isBulkReSnoozeEdited: boolean) => void
  setIsBulkReSnoozeFlyoutVisible: (isBulkReSnoozeFlyoutVisible: boolean) => void
  setMultiSelectLeadList: (multiSelectLeadList: string[]) => void
  setCurrentSavedTagId: (currentSavedTagId: string | null) => void
  setIsCustomFacetMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const LeadSearchFolderContext = React.createContext<LeadSearchFolderContextType>({
  folder: FOLDER_SLUGS.LABEL_ACTIVE,
  subFolder: null,
  savedSearch: null,
  isMultiSelectEnabled: false,
  isBulkReSnoozeEnabled: false,
  isBulkReSnoozeEdited: false,
  isBulkReSnoozeFlyoutVisible: false,
  multiSelectLeadList: [],
  currentSavedTagId: null,
  isCustomFacetMenuOpen: false,
  setFolder: () => {},
  setSavedSearch: () => {},
  setIsMultiSelectEnabled: () => {},
  setIsBulkReSnoozeEnabled: () => {},
  setIsBulkReSnoozeEdited: () => {},
  setIsBulkReSnoozeFlyoutVisible: () => {},
  setMultiSelectLeadList: () => {},
  setCurrentSavedTagId: () => {},
  setIsCustomFacetMenuOpen: () => {}
})

interface LeadSearchFolderProviderProps {}

const LeadSearchFolderProvider: React.FC<React.PropsWithChildren<
  LeadSearchFolderProviderProps
>> = ({ children }) => {
  const [folder, setFolder] = useState<string | null>(FOLDER_SLUGS.LABEL_ACTIVE)
  const [subFolder, setSubFolder] = useState<string | null>(null)
  const [savedSearch, setSavedSearch] = useState<string | null>(null)
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState<boolean>(false)
  const [isBulkReSnoozeEnabled, setIsBulkReSnoozeEnabled] = useState<boolean>(false)
  const [isBulkReSnoozeEdited, setIsBulkReSnoozeEdited] = useState<boolean>(false)
  const [isBulkReSnoozeFlyoutVisible, setIsBulkReSnoozeFlyoutVisible] = useState<boolean>(false)
  const [multiSelectLeadList, setMultiSelectLeadList] = useState<string[]>([])
  const [currentSavedTagId, setCurrentSavedTagId] = useState<string | null>(null)
  const [isCustomFacetMenuOpen, setIsCustomFacetMenuOpen] = useState(false)

  return (
    <LeadSearchFolderContext.Provider
      value={{
        folder,
        subFolder,
        savedSearch,
        isMultiSelectEnabled,
        isBulkReSnoozeEnabled,
        isBulkReSnoozeEdited,
        isBulkReSnoozeFlyoutVisible,
        multiSelectLeadList,
        currentSavedTagId,
        isCustomFacetMenuOpen,
        setFolder: (folder, subFolder) => {
          setFolder(folder)
          setSubFolder(subFolder)

          // Reset Multi Select only when expert is navigating between folders
          // Maintain multi select state in default and custom FacetSearch
          if (folder || subFolder) {
            setIsMultiSelectEnabled(false)
            setMultiSelectLeadList([])
            setSavedSearch(null)
          }
        },
        setSavedSearch: savedSearch => {
          setFolder(null)
          setSubFolder(null)
          setSavedSearch(savedSearch)
          setCurrentSavedTagId(null)
        },
        setIsMultiSelectEnabled: isMultiSelectEnabled => {
          setIsMultiSelectEnabled(isMultiSelectEnabled)
        },
        setIsBulkReSnoozeEnabled: isBulkReSnoozeEnabled => {
          setIsBulkReSnoozeEnabled(isBulkReSnoozeEnabled)
        },
        setIsBulkReSnoozeEdited: isBulkReSnoozeEdited => {
          setIsBulkReSnoozeEdited(isBulkReSnoozeEdited)
        },
        setIsBulkReSnoozeFlyoutVisible: isBulkReSnoozeFlyoutVisible => {
          setIsBulkReSnoozeFlyoutVisible(isBulkReSnoozeFlyoutVisible)
        },
        setMultiSelectLeadList: multiSelectLeadList => {
          setMultiSelectLeadList(multiSelectLeadList)
        },
        setCurrentSavedTagId: currentSavedTagId => {
          setFolder(null)
          setSubFolder(null)
          setCurrentSavedTagId(currentSavedTagId)
          setSavedSearch(null)
        },
        setIsCustomFacetMenuOpen
      }}
    >
      {children}
    </LeadSearchFolderContext.Provider>
  )
}

const useLeadSearchFolderContext = () => {
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)

  if (!leadSearchFolderContext) {
    throw new Error('Invoked `LeadSearchFolderContext` outside of provider')
  }
  return leadSearchFolderContext
}

export { LeadSearchFolderContext, LeadSearchFolderProvider, useLeadSearchFolderContext }
