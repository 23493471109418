import { BusinessUserTracking, Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCuratedItemUpdatedEventProperties {
  curated_item_id: string
  lead_id: string
  activity_id?: string
  source: BusinessUserTracking.ExpertCuratedItemUpdatedSource
}

export class ExpertCuratedItemUpdatedEvent extends Event<ExpertCuratedItemUpdatedEventProperties> {
  static displayName = 'Expert Curated Item Updated'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
