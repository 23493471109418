import { FOLDER_SLUGS, INBOX_SNAPSHOT_TYPES } from '#src/app/utilities/displayNames'
import { ExpertInboxSnapshotViewQuery } from '#src/app/components/InboxSnapshot/InboxSnapshot.generated'

export const getFollowUpCount = (data: ExpertInboxSnapshotViewQuery | undefined) : number => {
  if (!data) return 0
  return data.expertInboxSnapshotView?.find(
    (snapshot: any) => snapshot.detail?.folderSlug === FOLDER_SLUGS.LABEL_INTELLIGENT_FOLLOW_UP
  )?.count || 0
}

export const getBroadcastCount = (data: ExpertInboxSnapshotViewQuery | undefined): number => {
  if (!data) return 0
  return data.expertInboxSnapshotView?.find(
    (snapshot: any) => snapshot.detail?.inboxSnapshotType === INBOX_SNAPSHOT_TYPES.BROADCAST_POOL
  )?.count || 0
}

export const getNeedsActionCount = (data: ExpertInboxSnapshotViewQuery | undefined): number => {
  if (!data) return 0
  return data.expertInboxSnapshotView?.find(
    (snapshot: any) => snapshot.detail?.folderSlug === FOLDER_SLUGS.LABEL_NEEDS_ACTION
  )?.count || 0
}

