import { useEffect, useRef, useState } from 'react'
// @ts-ignore
import Fuse, { FuseResult } from 'fuse.js'

interface FuseSearchParameters<P> {
  items: P[]
  keys: string[]
  searchTerm: string
  skip?: boolean
}

const useFuseSearch = <P>({ items, keys, searchTerm, skip }: FuseSearchParameters<P>) => {
  // Currently this search is only used in components connected with the quick replies experiment - after the experiment is ramped this search hook can be removed and replaces with a string.includes
  const fuse = useRef<Fuse<P>>()
  const [suggestions, setSuggestions] = useState<P[]>([])

  useEffect(() => {
    fuse.current = new Fuse(items, {
      shouldSort: true,
      keys,
      threshold: 0,
      ignoreLocation: true
    })
  }, [items])

  useEffect(() => {
    const suggestions: FuseResult[] = fuse.current ? fuse.current.search(searchTerm) : []
    setSuggestions(suggestions.map(suggestion => suggestion.item))
  }, [searchTerm])

  return suggestions.length > 0 && !skip ? suggestions : items
}

export default useFuseSearch
