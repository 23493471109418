// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindAndAcceptOpenLeadEscalationsMutationVariables = Types.Exact<{
  input: Types.FindAndAcceptOpenLeadEscalationsInput;
}>;


export type FindAndAcceptOpenLeadEscalationsMutation = { __typename: 'Mutation', findAndAcceptOpenLeadEscalations: { __typename: 'FindAndAcceptOpenLeadEscalationsResult', hasError: boolean, errorMessage?: string | null, escalations: Array<{ __typename: 'LeadEscalation', id: any, lead: { __typename: 'Lead', id: any, neverReplied: boolean } }> } };


export const FindAndAcceptOpenLeadEscalationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FindAndAcceptOpenLeadEscalations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"FindAndAcceptOpenLeadEscalationsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findAndAcceptOpenLeadEscalations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"hasError"}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}},{"kind":"Field","name":{"kind":"Name","value":"escalations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"neverReplied"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type FindAndAcceptOpenLeadEscalationsMutationFn = Apollo.MutationFunction<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables>;
export type FindAndAcceptOpenLeadEscalationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables>, 'mutation'>;

    export const FindAndAcceptOpenLeadEscalationsComponent = (props: FindAndAcceptOpenLeadEscalationsComponentProps) => (
      <ApolloReactComponents.Mutation<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables> mutation={FindAndAcceptOpenLeadEscalationsDocument} {...props} />
    );
    

/**
 * __useFindAndAcceptOpenLeadEscalationsMutation__
 *
 * To run a mutation, you first call `useFindAndAcceptOpenLeadEscalationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindAndAcceptOpenLeadEscalationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findAndAcceptOpenLeadEscalationsMutation, { data, loading, error }] = useFindAndAcceptOpenLeadEscalationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAndAcceptOpenLeadEscalationsMutation(baseOptions?: Apollo.MutationHookOptions<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables>(FindAndAcceptOpenLeadEscalationsDocument, options);
      }
export type FindAndAcceptOpenLeadEscalationsMutationHookResult = ReturnType<typeof useFindAndAcceptOpenLeadEscalationsMutation>;
export type FindAndAcceptOpenLeadEscalationsMutationResult = Apollo.MutationResult<FindAndAcceptOpenLeadEscalationsMutation>;
export type FindAndAcceptOpenLeadEscalationsMutationOptions = Apollo.BaseMutationOptions<FindAndAcceptOpenLeadEscalationsMutation, FindAndAcceptOpenLeadEscalationsMutationVariables>;