// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { OperatorTicketUserFragmentDoc } from './operatorTicketUser.generated';
export type TicketRequesterName_BusinessUser_Fragment = { __typename: 'BusinessUser', businessUserId: any, businessUserDisplayName: string };

export type TicketRequesterName_Operator_Fragment = { __typename: 'Operator', operatorId: any, operatorFirstName: string, operatorLastName: string };

export type TicketRequesterName_SystemUser_Fragment = { __typename: 'SystemUser' };

export type TicketRequesterName_User_Fragment = { __typename: 'User', userId: any, userFirstName?: string | null, userLastName?: string | null };

export type TicketRequesterNameFragment = TicketRequesterName_BusinessUser_Fragment | TicketRequesterName_Operator_Fragment | TicketRequesterName_SystemUser_Fragment | TicketRequesterName_User_Fragment;

export const TicketRequesterNameFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TicketRequesterName"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TicketRequester"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Operator"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OperatorTicketUser"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"businessUserId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"businessUserDisplayName"},"name":{"kind":"Name","value":"displayName"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"userId"},"name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"userFirstName"},"name":{"kind":"Name","value":"firstName"}},{"kind":"Field","alias":{"kind":"Name","value":"userLastName"},"name":{"kind":"Name","value":"lastName"}}]}}]}}]} as unknown as DocumentNode;