// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../fragments/monetaryAmount.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PayPerLeadSummaryDisplayQueryVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type PayPerLeadSummaryDisplayQuery = { __typename: 'Query', payPerLeadTodaySummary: { __typename: 'PayPerLeadDailySummary', newLeadCount: number, totalExpertSpend: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string }, totalCuratedSpend: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string } }, me?: { __typename: 'Myself', id: any, businessUser?: { __typename: 'BusinessUser', id: any, discountCashAccount: { __typename: 'LedgerAccount', id: any, usableBalance: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string } } } | null } | null };


export const PayPerLeadSummaryDisplayDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PayPerLeadSummaryDisplay"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"payPerLeadTodaySummary"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"newLeadCount"}},{"kind":"Field","name":{"kind":"Name","value":"totalExpertSpend"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"totalCuratedSpend"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"discountCashAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"usableBalance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]}}]}}]}},...MonetaryAmountFragmentDoc.definitions]} as unknown as DocumentNode;
export type PayPerLeadSummaryDisplayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>, 'query'> & ({ variables: PayPerLeadSummaryDisplayQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PayPerLeadSummaryDisplayComponent = (props: PayPerLeadSummaryDisplayComponentProps) => (
      <ApolloReactComponents.Query<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables> query={PayPerLeadSummaryDisplayDocument} {...props} />
    );
    

/**
 * __usePayPerLeadSummaryDisplayQuery__
 *
 * To run a query within a React component, call `usePayPerLeadSummaryDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayPerLeadSummaryDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayPerLeadSummaryDisplayQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function usePayPerLeadSummaryDisplayQuery(baseOptions: Apollo.QueryHookOptions<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>(PayPerLeadSummaryDisplayDocument, options);
      }
export function usePayPerLeadSummaryDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>(PayPerLeadSummaryDisplayDocument, options);
        }
export function usePayPerLeadSummaryDisplaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>(PayPerLeadSummaryDisplayDocument, options);
        }
export type PayPerLeadSummaryDisplayQueryHookResult = ReturnType<typeof usePayPerLeadSummaryDisplayQuery>;
export type PayPerLeadSummaryDisplayLazyQueryHookResult = ReturnType<typeof usePayPerLeadSummaryDisplayLazyQuery>;
export type PayPerLeadSummaryDisplaySuspenseQueryHookResult = ReturnType<typeof usePayPerLeadSummaryDisplaySuspenseQuery>;
export type PayPerLeadSummaryDisplayQueryResult = Apollo.QueryResult<PayPerLeadSummaryDisplayQuery, PayPerLeadSummaryDisplayQueryVariables>;