import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import classnames from 'classnames'
import { Link, Redirect } from '@deal/router'
import { useInterval } from '@deal/dom-hooks'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { AcceptEscalationResultStatus } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import Toast from '#src/app/components/Toast'
import { LeadEscalationFragment } from '../../fragments/leadEscalation.generated'
import { BusinessUserFragment } from '../../fragments/businessUser.generated'
import { useLeadEscalationChangedSubscription } from './LeadEscalationChanged.generated'
import { useLeadEscalationQuery } from './LeadEscalation.generated'
import { useAcceptLeadEscalationMutation } from './AcceptLeadEscalation.generated'
import formattedToast from '../formattedToast'
import QuickChat from '../components/QuickChat'
import VIPIcon from './vip.svg'
import CheckIcon from './c-check.svg'
import styles from './styles.css'

const isAcceptedByOtherExpert = (
  businessUser: BusinessUserFragment,
  leadEscalation?: LeadEscalationFragment
) => {
  const leadOwnerId = leadEscalation && leadEscalation.owner && leadEscalation.owner.id
  return businessUser.id && leadOwnerId && businessUser.id !== leadOwnerId
}

interface Props extends BaseProps {
  closeToast?: () => void
}

interface BaseProps {
  leadEscalationId: string
  redirect?: boolean
}

const formatTime = (secondsLeft: number) => {
  secondsLeft = secondsLeft < 0 ? 0 : secondsLeft
  const minutes = Math.floor(secondsLeft / 60)
  const seconds = secondsLeft - minutes * 60
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}

const LeadEscalationStartedToast: React.FC<React.PropsWithChildren<Props>> = ({
  closeToast,
  leadEscalationId,
  redirect
}) => {
  const { data, loading } = useLeadEscalationQuery({ variables: { id: leadEscalationId } })

  if (data && data.leadEscalation) {
    return (
      <EscalationNotification
        leadEscalation={data.leadEscalation}
        redirect={redirect}
        closeToast={closeToast}
      />
    )
  } else if (loading) {
    return <FullBlockLoader loader={CircleLoader} />
  } else {
    return null
  }
}

interface EscalationNotificationProps {
  leadEscalation: LeadEscalationFragment
  closeToast?: () => void
  redirect?: boolean
}
const EscalationNotification: React.FC<React.PropsWithChildren<EscalationNotificationProps>> = ({
  leadEscalation,
  closeToast,
  redirect
}) => {
  const businessUser = useBusinessUser()
  const [secondsToReply, setSecondsToReply] = useState(90)
  const [showChat, setShowChat] = useState(false)
  const acceptedByOtherExpert = isAcceptedByOtherExpert(businessUser, leadEscalation)
  const { lead, acceptAsLeadExpertRole, owner } = leadEscalation
  const titleElement = (
    <div className={styles.title}>
      {acceptAsLeadExpertRole === 'DELEGATE'
        ? 'Lead escalated to on-call/team: '
        : 'New lead up for grabs: '}
      {lead.consumer.firstName} {lead.consumer.vip && <VIPIcon />}
    </div>
  )

  // Setting interval delay to null will clear interval
  useInterval(
    () => {
      setSecondsToReply(secondsToReply - 1)
      if (secondsToReply === 0) {
        setTimeout(() => {
          closeToast && closeToast()
        }, 60000)
      }
    },
    secondsToReply === -1 ? null : 1000
  )

  const [acceptLeadEscalation] = useAcceptLeadEscalationMutation({
    variables: { input: { id: leadEscalation.id, close: true } },
    onCompleted: ({ acceptLeadEscalation: { status } }) => {
      switch (status) {
        case AcceptEscalationResultStatus.ACCEPTED:
          setShowChat(true)
          break
        case AcceptEscalationResultStatus.ALREADY_CLAIMED:
          closeToast && closeToast()
          toast.error('Lead was already claimed by a team member')
          break
        case AcceptEscalationResultStatus.NOT_ALLOWED:
          closeToast && closeToast()
          toast.error('You are not allowed to claim broadcast leads')
          break
        case AcceptEscalationResultStatus.ESCALATION_CLOSED:
          closeToast && closeToast()
          toast.error('User no longer needs help from a new expert')
          break
      }
    },
    onError: () => toast.error('Error: could not accept lead')
  })

  /**
   * Once toast timer hits 0:00 dismiss after 3 seconds
   * If we don't do this, experts end up with a list of old toasts that occurred while away from browser
   */
  useEffect(() => {
    if (acceptedByOtherExpert && closeToast) {
      setTimeout(() => {
        closeToast()
      }, 3000)
    }
  }, [acceptedByOtherExpert])

  // Subscribe to lead escalation changes
  useLeadEscalationChangedSubscription({
    variables: { id: leadEscalation.id }
  })

  if (showChat && redirect) {
    closeToast && closeToast()
    return <Redirect to={`/inbox/${lead.id}`} />
  }
  return (
    <Toast
      className={classnames({
        [styles.mobileFullScreen]: showChat
      })}
      onCloseToastRequested={() => closeToast && closeToast()}
    >
      <div className={styles.wrapper}>
        {acceptedByOtherExpert ? (
          <div className={styles.claimedInfo}>
            <div className={styles.acceptedBlock}>
              <CheckIcon />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>Lead claimed by {owner && owner.displayName}</div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.unclaimedInfo}>
              <div className={styles.time}>{formatTime(secondsToReply)}</div>
              <div className={styles.textContainer}>
                <div className={styles.progressBar} />
                {showChat ? (
                  <Link to={`/inbox/${lead.id}`} onClick={closeToast} className={styles.link}>
                    {titleElement}
                  </Link>
                ) : (
                  titleElement
                )}
              </div>
            </div>

            {showChat ? (
              <QuickChat leadId={lead.id} conversationId={lead.conversation.externalId} />
            ) : (
              <div className={styles.actions}>
                <button className={styles.accept} onClick={() => acceptLeadEscalation()}>
                  Accept
                </button>
                <button className={styles.deny} onClick={closeToast}>
                  Deny
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </Toast>
  )
}

const leadEscalationStartedToast = (props: BaseProps) => {
  return formattedToast(<LeadEscalationStartedToast {...props} />)
}

export default leadEscalationStartedToast
