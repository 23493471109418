import React, { useState } from 'react'
import { Checkbox, Modal, Select } from '@deal/components'
import { DurationInput, DurationUnit } from '#src/generated/types'
import { isOnShift } from '#src/app/services/expertStatus'
import { BusinessUserFragment } from '#src/app/fragments/businessUser.generated'
import { BusinessUserForStartStandbyModalFragment } from './StartStandbyModal.generated'
import styles from './styles.css'

export function showStartStandbyButton(
  businessUser: BusinessUserFragment,
  nudgesTreatment: string
) {
  if (nudgesTreatment === 'control') {
    // We haven't transitioned to the new flex mode yet. Only allow them to start standby if they
    // are eligible with the old rank-based rules.
    return businessUser.isStandbyAllowed && !isOnShift(businessUser)
  }
  return !isOnShift(businessUser)
}

const StartStandbyModal: React.FC<{
  businessUser: BusinessUserForStartStandbyModalFragment
  onRequestClose: () => void
  onStartStandby: (duration: DurationInput, acceptingNewLeads: boolean) => void
}> = ({ businessUser, onRequestClose, onStartStandby }) => {
  const [durationInMinutes, setDurationInMinutes] = useState(60)
  const [acceptNewLeads, setAcceptNewLeads] = useState(false)

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      title="Start flex mode"
      actionVariant="neutral-dark"
      primaryAction={{
        label: 'Start flex mode',
        onClick: () =>
          onStartStandby(
            {
              amount: durationInMinutes,
              unit: DurationUnit.MINUTES
            },
            acceptNewLeads
          )
      }}
      tertiaryAction={{
        label: 'Cancel',
        onClick: onRequestClose
      }}
      width="xsmall"
    >
      <div onClick={e => e.stopPropagation()}>
        <Select
          label="Please select your desired flex mode duration"
          value={durationInMinutes}
          onChange={event => setDurationInMinutes(parseInt(event.currentTarget.value))}
          options={[
            { label: '30 minutes', value: 30 },
            { label: '1 hour', value: 60 },
            { label: '1.5 hours', value: 90 },
            { label: '2 hours', value: 120 },
            { label: '2.5 hours', value: 150 },
            { label: '3 hours', value: 180 }
          ]}
        />
        {businessUser.canAcceptLeadsInStandby && (
          <div className={styles.checkbox}>
            <Checkbox
              checked={acceptNewLeads}
              onChange={e => {
                setAcceptNewLeads(e.target.checked)
              }}
            >
              Accept new leads?
            </Checkbox>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default StartStandbyModal
