// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../fragments/mediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SuggestedMessageRevisionForSuggestedMessageEditorFieldsFragment = { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export type SuggestedMessageEditorFieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SuggestedMessageEditorFieldsQuery = { __typename: 'Query', quickReplyTemplateVariable?: { __typename: 'QuickReplyTemplateVariablePayload', variables?: Array<{ __typename: 'QuickReplyTemplateVariable', name: string, description: string, deprecated: boolean }> | null } | null };

export const SuggestedMessageRevisionForSuggestedMessageEditorFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageEditorFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"starred"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"mediaFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]} as unknown as DocumentNode;
export const SuggestedMessageEditorFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageEditorFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quickReplyTemplateVariable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SuggestedMessageEditorFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>, 'query'>;

    export const SuggestedMessageEditorFieldsComponent = (props: SuggestedMessageEditorFieldsComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables> query={SuggestedMessageEditorFieldsDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageEditorFieldsQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageEditorFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageEditorFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageEditorFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedMessageEditorFieldsQuery(baseOptions?: Apollo.QueryHookOptions<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>(SuggestedMessageEditorFieldsDocument, options);
      }
export function useSuggestedMessageEditorFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>(SuggestedMessageEditorFieldsDocument, options);
        }
export function useSuggestedMessageEditorFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>(SuggestedMessageEditorFieldsDocument, options);
        }
export type SuggestedMessageEditorFieldsQueryHookResult = ReturnType<typeof useSuggestedMessageEditorFieldsQuery>;
export type SuggestedMessageEditorFieldsLazyQueryHookResult = ReturnType<typeof useSuggestedMessageEditorFieldsLazyQuery>;
export type SuggestedMessageEditorFieldsSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageEditorFieldsSuspenseQuery>;
export type SuggestedMessageEditorFieldsQueryResult = Apollo.QueryResult<SuggestedMessageEditorFieldsQuery, SuggestedMessageEditorFieldsQueryVariables>;