import React from 'react'
import { toast } from 'react-toastify'
import Tenor from 'react-tenor'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import config from '#src/app/config'
import { useMediaSearchControlContext } from '../context'
import styles from './styles.css'

export interface GifUploadProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const GifUpload: React.FC<GifUploadProps> = ({ attachMediaUrl }) => {
  const { setIsPopoverVisible } = useMediaSearchControlContext()
  const { id: sharedByUserId } = useBusinessUser()
  return (
    <div className={styles.container}>
      <Tenor
        token={config.get('tenor.token')}
        initialSearch="hello"
        onSelect={result => {
          const firstMedia = result.media[0]
          if (firstMedia.gif.size > 10485760) {
            toast.error('File size is too large')
          } else {
            // @ts-ignore the content_description field appears to be present on each result, but not included in the types
            attachMediaUrl(firstMedia.gif.url, result?.content_description, sharedByUserId)
            setIsPopoverVisible(false)
          }
        }}
      />
    </div>
  )
}

const GifPanelModule = {
  title: 'GIFs',
  Display: GifUpload
}

export default GifPanelModule
