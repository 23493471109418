import React from 'react'
import { ToastContainer as ToastifyToastContainer } from 'react-toastify'
import ReactDOM from 'react-dom'
import clientOnly from '#src/app/hocs/clientOnly'
import styles from './styles.css'

interface ToastContainerProps {}

const ToastContainer: React.FC<React.PropsWithChildren<ToastContainerProps>> = () => {
  return ReactDOM.createPortal(
    <ToastifyToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={true}
      pauseOnHover={false}
      className={styles.toast}
    />,
    document.body
  )
}

export default clientOnly(ToastContainer)
