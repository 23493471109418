import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCreateCustomItemFormViewedEventProperties {
  lead_id: string
  activity_id?: string
}

export class ExpertCreateCustomItemFormViewedEvent extends Event<
  ExpertCreateCustomItemFormViewedEventProperties
> {
  static displayName = 'Expert Create Custom Item Form Viewed'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
