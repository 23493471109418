// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { AwayAutoReplyFragmentDoc } from '../ManageAwayAutoReply/ManageAwayAutoReply.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForExtendedAwayBannerQueryVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type BusinessUserForExtendedAwayBannerQuery = { __typename: 'Query', businessUser: { __typename: 'BusinessUser', id: any, activeOrPendingAwayAutoReply?: { __typename: 'AwayAutoReply', id: any, status: Types.AwayAutoReplyStatus, effectiveFrom: any, effectiveUntil: any, message: string, isExtendedAway?: boolean | null } | null } };


export const BusinessUserForExtendedAwayBannerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BusinessUserForExtendedAwayBanner"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"activeOrPendingAwayAutoReply"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AwayAutoReply"}}]}}]}}]}},...AwayAutoReplyFragmentDoc.definitions]} as unknown as DocumentNode;
export type BusinessUserForExtendedAwayBannerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>, 'query'> & ({ variables: BusinessUserForExtendedAwayBannerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessUserForExtendedAwayBannerComponent = (props: BusinessUserForExtendedAwayBannerComponentProps) => (
      <ApolloReactComponents.Query<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables> query={BusinessUserForExtendedAwayBannerDocument} {...props} />
    );
    

/**
 * __useBusinessUserForExtendedAwayBannerQuery__
 *
 * To run a query within a React component, call `useBusinessUserForExtendedAwayBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUserForExtendedAwayBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUserForExtendedAwayBannerQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useBusinessUserForExtendedAwayBannerQuery(baseOptions: Apollo.QueryHookOptions<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>(BusinessUserForExtendedAwayBannerDocument, options);
      }
export function useBusinessUserForExtendedAwayBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>(BusinessUserForExtendedAwayBannerDocument, options);
        }
export function useBusinessUserForExtendedAwayBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>(BusinessUserForExtendedAwayBannerDocument, options);
        }
export type BusinessUserForExtendedAwayBannerQueryHookResult = ReturnType<typeof useBusinessUserForExtendedAwayBannerQuery>;
export type BusinessUserForExtendedAwayBannerLazyQueryHookResult = ReturnType<typeof useBusinessUserForExtendedAwayBannerLazyQuery>;
export type BusinessUserForExtendedAwayBannerSuspenseQueryHookResult = ReturnType<typeof useBusinessUserForExtendedAwayBannerSuspenseQuery>;
export type BusinessUserForExtendedAwayBannerQueryResult = Apollo.QueryResult<BusinessUserForExtendedAwayBannerQuery, BusinessUserForExtendedAwayBannerQueryVariables>;