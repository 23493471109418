// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertAccountChangeRequestForReactivateBusinessUserAccountFragment = { __typename: 'ExpertAccountChangeRequest', id: any, status: Types.ExpertAccountChangeRequestStatus, type: Types.ExpertAccountChangeRequestType, expert: { __typename: 'BusinessUser', id: any } };

export type BusinessUsersForReactivationQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['BusinessUserId']['input']> | Types.Scalars['BusinessUserId']['input'];
}>;


export type BusinessUsersForReactivationQuery = { __typename: 'Query', businessUsers: Array<{ __typename: 'BusinessUser', id: any, isEligibleToReactivate: boolean } | null> };

export const ExpertAccountChangeRequestForReactivateBusinessUserAccountFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertAccountChangeRequestForReactivateBusinessUserAccount"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertAccountChangeRequest"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export const BusinessUsersForReactivationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BusinessUsersForReactivation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"ids"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isEligibleToReactivate"}}]}}]}}]} as unknown as DocumentNode;
export type BusinessUsersForReactivationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>, 'query'> & ({ variables: BusinessUsersForReactivationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BusinessUsersForReactivationComponent = (props: BusinessUsersForReactivationComponentProps) => (
      <ApolloReactComponents.Query<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables> query={BusinessUsersForReactivationDocument} {...props} />
    );
    

/**
 * __useBusinessUsersForReactivationQuery__
 *
 * To run a query within a React component, call `useBusinessUsersForReactivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUsersForReactivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUsersForReactivationQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBusinessUsersForReactivationQuery(baseOptions: Apollo.QueryHookOptions<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>(BusinessUsersForReactivationDocument, options);
      }
export function useBusinessUsersForReactivationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>(BusinessUsersForReactivationDocument, options);
        }
export function useBusinessUsersForReactivationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>(BusinessUsersForReactivationDocument, options);
        }
export type BusinessUsersForReactivationQueryHookResult = ReturnType<typeof useBusinessUsersForReactivationQuery>;
export type BusinessUsersForReactivationLazyQueryHookResult = ReturnType<typeof useBusinessUsersForReactivationLazyQuery>;
export type BusinessUsersForReactivationSuspenseQueryHookResult = ReturnType<typeof useBusinessUsersForReactivationSuspenseQuery>;
export type BusinessUsersForReactivationQueryResult = Apollo.QueryResult<BusinessUsersForReactivationQuery, BusinessUsersForReactivationQueryVariables>;