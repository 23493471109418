import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '@deal/components'
import styles from './styles.css'

interface AccountCircleProps {
  displayName: string
  tooltipMessage: string
  badging?: string
  active?: boolean
  onClick?: () => void
  disabled?: boolean
}

const AccountCircle: React.FC<React.PropsWithChildren<AccountCircleProps>> = ({
  tooltipMessage,
  displayName,
  badging,
  active,
  disabled = false,
  onClick
}) => {
  const displayInitials = displayName
    // Remove anything in parentheses
    .replace(/\s*\(.*?\)\s*/g, '')
    .split(' ')
    .map(word => word[0].toUpperCase())
    // Maximum of 3 initials fit in the circle
    .slice(0, 3)
    .join('')

  return (
    <Tooltip placement="right" message={tooltipMessage} theme="dark" hideTooltipOnMobile>
      <button
        onClick={onClick}
        className={classnames(styles.container, {
          [styles.active]: active,
          [styles.disabled]: disabled
        })}
      >
        {badging && <div className={styles.badging}>{badging}</div>}
        {displayInitials}
      </button>
    </Tooltip>
  )
}

export default AccountCircle
