import React from 'react'
import numeral from 'numeral'
import { MonetaryAmountFragment } from '../fragments/monetaryAmount.generated'

/**
 * Format a MonetaryAmount in JSX, in some common formats
 */
export interface MonetaryAmountFormattingOptions {
  round?: 'floor' | 'ceil' | boolean
  sup?: boolean
  supClassName?: string
}

export function formatMonetaryAmountAsJsx(
  amount: MonetaryAmountFragment,
  options: MonetaryAmountFormattingOptions = { sup: false, round: false }
) {
  const amountNumeral = numeral(amount.amount)
  let roundingFn

  switch (options.round) {
    case 'ceil':
      roundingFn = Math.ceil
      break
    case 'floor':
      roundingFn = Math.ceil
      break
    case true:
      roundingFn = Math.round
      break
  }

  if (options.sup) {
    return (
      <>
        {amountNumeral.format('$0,0', roundingFn)}
        <sup className={options.supClassName}>{amountNumeral.format('.00', roundingFn)}</sup>
      </>
    )
  } else {
    // Assumes that when rounding without <sup> tags, we don't want to display cents/decimals
    return amountNumeral.format(roundingFn ? '$0,0' : '$0,0.00', roundingFn)
  }
}

export function isPriceGreaterThan(price1: string | null, price2: string | null) {
  return numeral(price1).value() > numeral(price2).value()
}

export function formatMonetaryAmount(amount: MonetaryAmountFragment | string | number) {
  if (typeof amount === 'string' || typeof amount === 'number') {
    return numeral(amount).format('$0,0.00')
  }
  return numeral(amount.amount).format('$0,0.00')
}
