import React from 'react'
import { AttributeType } from '#src/generated/types'
import { toastError } from '#src/app/utilities/toast'
import { FileAttachment, GifAttachment } from '#src/app/typings/media'
import {
  SuggestedMessageEditorFields,
  SuggestedMessageEditorFieldsType
} from '../SuggestedMessageEditorFields'
import {
  SuggestedMessageRevisionForSuggestedMessageEditorFragment,
  useArchiveSuggestedMessageByExpertMutation,
  useUpdateSuggestedMessageByExpertMutation
} from './SuggestedMessageEditor.generated'

type SuggestedMessageTag = {
  displayName: string
  name: string
  deprecated?: boolean
}

type SuggestedMessageTagMap = {
  [key: string]: SuggestedMessageTag
}

interface SuggestedMessageEditorProps {
  suggestedMessage: SuggestedMessageRevisionForSuggestedMessageEditorFragment
  suggestedMessageTagMap: SuggestedMessageTagMap
  focusTitleOnMount?: boolean
  onSaveStarted?: () => void
  onSaveEnded?: () => void
  onDuplicate: (initialData: SuggestedMessageEditorFieldsType) => void
  onDelete?: (suggestesMessageId: string) => void
}

const SuggestedMessageEditor: React.FC<SuggestedMessageEditorProps> = ({
  suggestedMessage,
  suggestedMessageTagMap,
  focusTitleOnMount,
  onSaveStarted,
  onSaveEnded,
  onDuplicate,
  onDelete
}) => {
  const [updateSuggestedMessage] = useUpdateSuggestedMessageByExpertMutation({
    onCompleted: ({ updateSuggestedMessageByExpert: { errorMessage } }) => {
      if (errorMessage) {
        toastError(errorMessage.errorMessage)
      } else {
        onSaveEnded?.()
      }
    }
  })
  const [archiveSuggestedMessage, { loading }] = useArchiveSuggestedMessageByExpertMutation({
    onCompleted: data => {
      onDelete?.(data.archiveSuggestedMessageRevision.revision.id)
    }
  })

  function handleUpdateSuggestedMessage(
    suggestedMessageId: string,
    suggestedMessageTitle: string,
    suggestedMessageText: string,
    tags: string[],
    starred: boolean,
    attachments: (FileAttachment | GifAttachment)[]
  ) {
    onSaveStarted?.()
    const mediaUploadPromises = attachments.map(attachment => attachment.toMediaId(attachment.data))

    Promise.all(mediaUploadPromises).then(mediaIds => {
      updateSuggestedMessage({
        variables: {
          input: {
            id: suggestedMessageId,
            title: suggestedMessageTitle,
            message: suggestedMessageText,
            starred,
            mediaIds,
            attributes: [
              {
                name: 'leadSuggestedMessageTag',
                type: AttributeType.ENUMERATION,
                enumerationValues: tags
              }
            ]
          }
        }
      })
    })
  }

  return (
    <SuggestedMessageEditorFields
      suggestedMessage={suggestedMessage}
      suggestedMessageTagMap={suggestedMessageTagMap}
      focusTitleOnMount={focusTitleOnMount}
      loading={loading}
      onUpdate={(...fields) => {
        handleUpdateSuggestedMessage(suggestedMessage.id, ...fields)
      }}
      onDuplicate={onDuplicate}
      onDelete={() =>
        archiveSuggestedMessage({
          variables: {
            input: {
              id: suggestedMessage.id,
              reason: 'Archived by expert'
            }
          }
        })
      }
    />
  )
}

export default SuggestedMessageEditor
