import React, { useContext, useState } from 'react'
import { BusinessUserTracking } from '@deal/web-tracking'
import { ExpertWarehouseSuggestionsPageViewedEvent } from '#src/app/events/ExpertWarehouseSuggestionsPageViewedEvent'
import { ExpertCurationSubmittedEvent } from '#src/app/events/ExpertCurationSubmittedEvent'
import { ExpertCurationReviewPageViewedEvent } from '#src/app/events/ExpertCurationReviewPageViewedEvent'
import {
  ExpertCurationProductsSearchedEvent,
} from '#src/app/events/ExpertCurationProductsSearchedEvent'
import type {
  ExpertCurationProductsSearchedEventProperties
} from '#src/app/events/ExpertCurationProductsSearchedEvent'
import { ExpertCurationProductSearchPageViewedEvent } from '#src/app/events/ExpertCurationProductSearchPageViewedEvent'
import { ExpertCurationFlowExitedEvent } from '#src/app/events/ExpertCurationFlowExitedEvent'
import { ExpertCurationFlowEnteredEvent } from '#src/app/events/ExpertCurationFlowEnteredEvent'
import { ExpertCuratedItemUpdatedEvent } from '#src/app/events/ExpertCuratedItemUpdatedEvent'
import { ExpertCuratedItemRemovedEvent } from '#src/app/events/ExpertCuratedItemRemovedEvent'
import { ExpertCuratedItemAddedEvent } from '#src/app/events/ExpertCuratedItemAddedEvent'
import { ExpertCreateCustomItemFormViewedEvent } from '#src/app/events/ExpertCreateCustomItemFormViewedEvent'
import { useAnalyticsContext } from '#src/app/containers/Analytics'

export { ExpertCurationProductsSearchedEventProperties }

type CurationTrackingContextType = {
  activityId?: string
  listId?: string
  trackExpertCurationFlowEntered?: (leadId: string) => void
  trackExpertCurationFlowExited?: (leadId: string) => void
  trackExpertCurationProductSearchPageViewed?: (leadId: string) => void
  trackExpertCurationProductsSearched?: ({
    lead_id,
    categoryIds,
    source,
    keywords,
    filters,
    facets,
    sort_by,
    sort_order,
    list_id
  }: ExpertCurationProductsSearchedEventProperties) => void
  trackExpertWarehouseSuggestionsPageViewed?: (leadId: string) => void
  trackExpertCurationReviewPageViewed?: (leadId: string) => void
  trackExpertCreateCustomItemFormViewed?: (leadId: string) => void
  trackExpertCuratedItemAdded?: ({
    sellable_id,
    curated_item_id,
    lead_id,
    badges,
    source,
    list_id,
    position
  }: {
    sellable_id: string
    curated_item_id: string
    lead_id: string
    badges: string[]
    source: BusinessUserTracking.ExpertCuratedItemAddedSource
    list_id?: string,
    position?: number
  }) => void
  trackExpertCuratedItemRemoved?: ({
    sellable_id,
    curated_item_id,
    lead_id
  }: {
    sellable_id: string
    curated_item_id: string
    lead_id: string
  }) => void
  trackExpertCuratedItemUpdated?: ({
    sellable_id,
    curated_item_id,
    lead_id,
    source
  }: {
    sellable_id: string
    curated_item_id: string
    lead_id: string
    source: BusinessUserTracking.ExpertCuratedItemUpdatedSource
  }) => void
  trackExpertCurationSubmitted?: ({
    lead_id,
    activation_id
  }: {
    lead_id: string
    activation_id?: string
  }) => void
  setActivityId?: (id?: string) => void
  setListId?: (id?: string) => void
}

const CurationTrackingContext = React.createContext<CurationTrackingContextType>({})

const CurationTrackingProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const analytics = useAnalyticsContext()
  const [activityId, setActivityId] = useState<string>()
  // listId is returned by the SellableSearch API as trackingId - it is used to match the model metadata that generated the results w/ click attribution.
  const [listId, setListId] = useState<string>()

  return (
    <CurationTrackingContext.Provider
      value={{
        activityId,
        listId,
        trackExpertCurationFlowEntered: leadId => {
          analytics?.track(
            new ExpertCurationFlowEnteredEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
        },
        trackExpertCurationFlowExited: leadId => {
          analytics?.track(
            new ExpertCurationFlowExitedEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
          setActivityId(undefined)
          setListId(undefined)
        },
        trackExpertCurationProductSearchPageViewed: leadId => {
          analytics?.track(
            new ExpertCurationProductSearchPageViewedEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
        },
        trackExpertCurationProductsSearched: args => {
          analytics?.track(
            new ExpertCurationProductsSearchedEvent({
              activity_id: activityId,
              ...args
            })
          )
        },
        trackExpertWarehouseSuggestionsPageViewed: leadId => {
          analytics?.track(
            new ExpertWarehouseSuggestionsPageViewedEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
        },
        trackExpertCurationReviewPageViewed: leadId => {
          analytics?.track(
            new ExpertCurationReviewPageViewedEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
        },
        trackExpertCreateCustomItemFormViewed: leadId => {
          analytics?.track(
            new ExpertCreateCustomItemFormViewedEvent({
              activity_id: activityId,
              lead_id: leadId
            })
          )
        },
        trackExpertCuratedItemAdded: args => {
          analytics?.track(
            new ExpertCuratedItemAddedEvent({
              activity_id: activityId,
              list_id: listId,
              ...args
            })
          )
        },
        trackExpertCuratedItemRemoved: args => {
          analytics?.track(
            new ExpertCuratedItemRemovedEvent({
              activity_id: activityId,
              ...args
            })
          )
        },
        trackExpertCuratedItemUpdated: args => {
          analytics?.track(
            new ExpertCuratedItemUpdatedEvent({
              activity_id: activityId,
              ...args
            })
          )
        },
        trackExpertCurationSubmitted: args => {
          analytics?.track(
            new ExpertCurationSubmittedEvent({
              activity_id: activityId,
              ...args
            })
          )
        },
        setActivityId: id => setActivityId(id),
        setListId: id => setListId(id)
      }}
    >
      {children}
    </CurationTrackingContext.Provider>
  )
}

const useCurationTrackingContext = () => {
  const curationTrackingContext = useContext(CurationTrackingContext)

  if (!curationTrackingContext) {
    throw new Error('Invoked curationTrackingContext outside of provider')
  }

  return curationTrackingContext
}

export { CurationTrackingContext, CurationTrackingProvider, useCurationTrackingContext }
