import React, { useRef } from 'react'
import { unreadActivityToast } from '#src/app/toasts/unreadTicketActivityToast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useUnreadTicketActivityNotificationSubscription } from './UnreadTicketActivityNotification.generated'

export const UnreadTicketActivityNotification: React.FC = () => {
  const { id: expertId } = useBusinessUser()
  const toastedTicketIds = useRef(new Set<string>())

  useUnreadTicketActivityNotificationSubscription({
    variables: { expertId },
    onData: ({ data: { data } }) => {
      const ticket = data?.unreadTicketActivityCreated
      if (!ticket) {
        return
      }
      if (ticket.isCurrentExpertSubscribed && !toastedTicketIds.current.has(ticket.id)) {
        toastedTicketIds.current.add(ticket.id)
        unreadActivityToast({
          ticketId: ticket.id,
          type: ticket.type,
          state: ticket.state,
          onClose: () => toastedTicketIds.current.delete(ticket.id),
          closeOnClick: true,
          autoClose: 5_000
        })
      }
    }
  })

  return null
}
