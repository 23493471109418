import React from 'react'
import classnames from 'classnames'
import { BusinessUserState, ExpertPresenceStatus } from '#src/generated/types'
import { isAcceptingNewLeads, isOnLeadShift } from '#src/app/services/expertStatus'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import ExpertNewLeadEligibility from '../ExpertNewLeadEligibility'
import PauseLeadsSwitch from '../../PauseLeadsSwitch'
import styles from './styles.css'

const Status: React.FC = () => {
  const businessUser = useBusinessUser()
  const status = businessUser.expertPresence.status
  const leadShift = isOnLeadShift(businessUser)
  const onCall = status === ExpertPresenceStatus.ON_CALL
  const away = status === ExpertPresenceStatus.AWAY || status === ExpertPresenceStatus.EXTENDED_AWAY
  const offShift = status === ExpertPresenceStatus.OFF_SHIFT

  const acceptingNewLeads = isAcceptingNewLeads(businessUser)
  if (businessUser.state === BusinessUserState.PENDING) {
    return null
  }
  return (
    <div className={styles.container}>
      {!leadShift && (
        <div
          className={classnames({
            [styles.statusOn]: onCall,
            [styles.statusOff]: away || offShift
          })}
        >
          {businessUser.state === BusinessUserState.DEACTIVATED
            ? 'Deactivated'
            : onCall
            ? 'On call'
            : offShift
            ? 'Off CAT'
            : 'Away'}
        </div>
      )}
      {leadShift && (
        <div>
          <PauseLeadsSwitch accepting={acceptingNewLeads} businessUserId={businessUser.id} />
          <ExpertNewLeadEligibility />
        </div>
      )}
    </div>
  )
}

export default Status
