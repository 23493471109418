import cookies from 'js-cookie'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client'
import { ifBridgeDefined } from './dealNativeBridge'

export default class SendTokensToNative {
  private previousSn: string | undefined
  private previousAt: string | undefined
  private previousRt: string | undefined

  constructor() {
    this.sendIfChanged = this.sendIfChanged.bind(this)
    this.sendIfChangedOnResponseLink = this.sendIfChangedOnResponseLink.bind(this)
  }

  public sendIfChanged() {
    ifBridgeDefined(bridge => {
      const sn = cookies.get('deal_sn')
      const at = cookies.get('deal_at')
      const rt = cookies.get('deal_rt')
      if (this.previousSn !== sn || this.previousAt !== at || this.previousRt !== rt) {
        this.previousSn = sn
        this.previousAt = at
        this.previousRt = rt
        bridge.sendToNative({ type: 'onTokenChange', sn, at, rt })
      }
    })
  }

  private sendIfChangedOnSuccess(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      return forward!(operation).map(executionResult => {
        this.sendIfChanged()
        return executionResult
      })
    })
  }

  private sendIfChangedOnError(): ApolloLink {
    return onError(() => {
      this.sendIfChanged()
    })
  }

  public sendIfChangedOnResponseLink(): ApolloLink {
    return ApolloLink.from([this.sendIfChangedOnSuccess(), this.sendIfChangedOnError()])
  }
}
