import React, { useContext, useState } from 'react'
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import classnames from 'classnames'
import { Modal } from '@deal/components'
import { ExpertRatedShiftDifficultyEvent } from '#src/app/events/ExpertRatedShiftDifficultyEvent'
import { AnalyticsContext } from '#src/app/containers/Analytics'
import styles from './styles.css'

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

const ShiftDifficultyModal: React.FC<React.PropsWithChildren<Props>> = ({ onRequestClose, isOpen }) => {
  const analytics = useContext(AnalyticsContext)
  const [difficulty, setDifficulty] = useState(5)
  return (
    <Modal
      isOpen={isOpen}
      mobilePosition="bottom"
      title="How busy were you during this CAT slot?"
      actionVariant="neutral-light"
      primaryAction={{
        label: 'Submit',
        onClick: () => {
          analytics?.track(new ExpertRatedShiftDifficultyEvent({ difficulty }))
          onRequestClose()
        }
      }}
    >
      <div className={styles.content}>
        <div className={styles.modalContent}>
          <DifficultySlider
            onDifficultyChanged={values => setDifficulty(values)}
            difficulty={difficulty}
          />
          <div className={styles.speed}>
            <span>TOO SLOW</span>
            <span>TOO FAST</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ShiftDifficultyModal

const Handle: React.FC<React.PropsWithChildren<any>> = ({ handle: { id, percent }, getHandleProps }) => (
  <div
    style={{
      left: `${percent}%`
    }}
    className={styles.handle}
    {...getHandleProps(id)}
  />
)

const Track: React.FC<React.PropsWithChildren<any>> = ({ source, target, getTrackProps }) => (
  <div
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`
    }}
    className={styles.track}
    {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
  />
)

const Tick: React.FC<React.PropsWithChildren<any>> = ({ tick, count, value }) => (
  <div>
    <div
      className={classnames(styles.tick, { [styles.active]: value === tick.value })}
      style={{
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`
      }}
    >
      {tick.value}
    </div>
  </div>
)

interface DifficultySliderProps {
  onDifficultyChanged: (value: number) => void
  difficulty: number
}
const DifficultySlider: React.FC<React.PropsWithChildren<DifficultySliderProps>> = ({ onDifficultyChanged, difficulty }) => {
  return (
    <Slider
      className={styles.slider}
      domain={[0, 10]}
      values={[difficulty]}
      step={1}
      onChange={(values: ReadonlyArray<number>) => onDifficultyChanged(values[0])}
    >
      <Rail>
        {(
          { getRailProps } // adding the rail props sets up events on the rail
        ) => <div className={styles.rail} {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map(handle => (
              <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks count={10}>
        {({ ticks }) => (
          <div>
            {ticks.map(tick => (
              <Tick key={tick.id} value={difficulty} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  )
}
