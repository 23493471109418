import React from 'react'
import classnames from 'classnames'
import { Button, ButtonProps, TextField, TextFieldProps } from '@deal/components'
import SearchIcon from './search.svg'
import styles from './styles.css'

interface SearchTextFieldProps {
  textFieldProps: TextFieldProps
  buttonProps: ButtonProps
}

const SearchTextField: React.FC<React.PropsWithChildren<React.PropsWithChildren<SearchTextFieldProps>>> = ({
  textFieldProps,
  buttonProps
}) => {
  return (
    <div className={styles.searchRow}>
      <TextField {...textFieldProps} />
      <Button {...buttonProps}>
        <SearchIcon
          className={classnames(styles.icon, {
            [styles.iconDisabled]: buttonProps.disabled
          })}
        />
      </Button>
    </div>
  )
}

export default SearchTextField
