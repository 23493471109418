// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
export type AttributeValueDateFragment = { __typename: 'AttributeValueDate', date: any };

export type AttributeValueDateRangeFragment = { __typename: 'AttributeValueDateRange', maxDate?: any | null, minDate?: any | null };

export type AttributeValueDateTimeFragment = { __typename: 'AttributeValueDateTime', dateTime: any };

export type AttributeValueDateTimeRangeFragment = { __typename: 'AttributeValueDateTimeRange', maxDateTime?: any | null, minDateTime?: any | null };

export type AttributeValueDecimalFragment = { __typename: 'AttributeValueDecimal', decimal: string };

export type AttributeValueDecimalRangeFragment = { __typename: 'AttributeValueDecimalRange', maxDecimal?: string | null, minDecimal?: string | null };

export type AttributeValueEnumerationFragment = { __typename: 'AttributeValueEnumeration', enumerationValue: string, enumerationValueDescription: string };

export type AttributeValueIdFragment = { __typename: 'AttributeValueId', id?: any | null };

export type AttributeValueIntegerFragment = { __typename: 'AttributeValueInteger', integer: any };

export type AttributeValueIntegerRangeFragment = { __typename: 'AttributeValueIntegerRange', maxInteger?: any | null, minInteger?: any | null };

export type AttributeValueTextFragment = { __typename: 'AttributeValueText', text: string };

export type AttributeValueTimeFragment = { __typename: 'AttributeValueTime', time: any };

export type AttributeValueTimeRangeFragment = { __typename: 'AttributeValueTimeRange', maxTime?: any | null, minTime?: any | null };

export type AttributeValueZoneIdFragment = { __typename: 'AttributeValueZoneId', zoneId?: string | null };

export type AttributeValues_AttributeValueDate_Fragment = { __typename: 'AttributeValueDate', date: any };

export type AttributeValues_AttributeValueDateRange_Fragment = { __typename: 'AttributeValueDateRange', maxDate?: any | null, minDate?: any | null };

export type AttributeValues_AttributeValueDateTime_Fragment = { __typename: 'AttributeValueDateTime', dateTime: any };

export type AttributeValues_AttributeValueDateTimeRange_Fragment = { __typename: 'AttributeValueDateTimeRange', maxDateTime?: any | null, minDateTime?: any | null };

export type AttributeValues_AttributeValueDecimal_Fragment = { __typename: 'AttributeValueDecimal', decimal: string };

export type AttributeValues_AttributeValueDecimalRange_Fragment = { __typename: 'AttributeValueDecimalRange', maxDecimal?: string | null, minDecimal?: string | null };

export type AttributeValues_AttributeValueEnumeration_Fragment = { __typename: 'AttributeValueEnumeration', enumerationValue: string, enumerationValueDescription: string };

export type AttributeValues_AttributeValueId_Fragment = { __typename: 'AttributeValueId', id?: any | null };

export type AttributeValues_AttributeValueInteger_Fragment = { __typename: 'AttributeValueInteger', integer: any };

export type AttributeValues_AttributeValueIntegerRange_Fragment = { __typename: 'AttributeValueIntegerRange', maxInteger?: any | null, minInteger?: any | null };

export type AttributeValues_AttributeValueText_Fragment = { __typename: 'AttributeValueText', text: string };

export type AttributeValues_AttributeValueTime_Fragment = { __typename: 'AttributeValueTime', time: any };

export type AttributeValues_AttributeValueTimeRange_Fragment = { __typename: 'AttributeValueTimeRange', maxTime?: any | null, minTime?: any | null };

export type AttributeValues_AttributeValueZoneId_Fragment = { __typename: 'AttributeValueZoneId', zoneId?: string | null };

export type AttributeValuesFragment = AttributeValues_AttributeValueDate_Fragment | AttributeValues_AttributeValueDateRange_Fragment | AttributeValues_AttributeValueDateTime_Fragment | AttributeValues_AttributeValueDateTimeRange_Fragment | AttributeValues_AttributeValueDecimal_Fragment | AttributeValues_AttributeValueDecimalRange_Fragment | AttributeValues_AttributeValueEnumeration_Fragment | AttributeValues_AttributeValueId_Fragment | AttributeValues_AttributeValueInteger_Fragment | AttributeValues_AttributeValueIntegerRange_Fragment | AttributeValues_AttributeValueText_Fragment | AttributeValues_AttributeValueTime_Fragment | AttributeValues_AttributeValueTimeRange_Fragment | AttributeValues_AttributeValueZoneId_Fragment;

export const AttributeValueDecimalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDecimal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDecimal"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"decimal"}}]}}]} as unknown as DocumentNode;
export const AttributeValueDecimalRangeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDecimalRange"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDecimalRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxDecimal"}},{"kind":"Field","name":{"kind":"Name","value":"minDecimal"}}]}}]} as unknown as DocumentNode;
export const AttributeValueEnumerationFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueEnumeration"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueEnumeration"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enumerationValue"}},{"kind":"Field","name":{"kind":"Name","value":"enumerationValueDescription"}}]}}]} as unknown as DocumentNode;
export const AttributeValueIdFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueId"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueId"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]} as unknown as DocumentNode;
export const AttributeValueIntegerFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueInteger"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueInteger"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"integer"}}]}}]} as unknown as DocumentNode;
export const AttributeValueIntegerRangeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueIntegerRange"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueIntegerRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxInteger"}},{"kind":"Field","name":{"kind":"Name","value":"minInteger"}}]}}]} as unknown as DocumentNode;
export const AttributeValueTextFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueText"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueText"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"text"}}]}}]} as unknown as DocumentNode;
export const AttributeValueDateFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}}]}}]} as unknown as DocumentNode;
export const AttributeValueDateRangeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDateRange"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDateRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxDate"}},{"kind":"Field","name":{"kind":"Name","value":"minDate"}}]}}]} as unknown as DocumentNode;
export const AttributeValueDateTimeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDateTime"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDateTime"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dateTime"}}]}}]} as unknown as DocumentNode;
export const AttributeValueDateTimeRangeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueDateTimeRange"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueDateTimeRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxDateTime"}},{"kind":"Field","name":{"kind":"Name","value":"minDateTime"}}]}}]} as unknown as DocumentNode;
export const AttributeValueTimeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueTime"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueTime"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"time"}}]}}]} as unknown as DocumentNode;
export const AttributeValueTimeRangeFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueTimeRange"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueTimeRange"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maxTime"}},{"kind":"Field","name":{"kind":"Name","value":"minTime"}}]}}]} as unknown as DocumentNode;
export const AttributeValueZoneIdFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValueZoneId"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValueZoneId"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"zoneId"}}]}}]} as unknown as DocumentNode;
export const AttributeValuesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AttributeValues"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDecimal"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDecimalRange"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueEnumeration"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueId"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueInteger"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueIntegerRange"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueText"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDate"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDateRange"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDateTime"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueDateTimeRange"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueTime"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueTimeRange"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValueZoneId"}}]}}]} as unknown as DocumentNode;