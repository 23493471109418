import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from '@deal/router'
import config from '#src/app/config'

// Add labels here for pages we want to track metrics for
// These labels are used for page loads that wouldn't necessarily trigger a pathname change
export type LuxTrackingLabel =
  | 'quick-curation-flyout-search'
  | 'quick-curation-flyout-review'
  | 'inbox-conversation'
  | 'curate-products-search'
  | 'curate-products-edit'

type luxTrackingContextType = {
  luxInit: (label: LuxTrackingLabel) => void
}

const routeSepcificSamplingRate: { [key: string]: number } = {
  'inbox-conversation': 0.33
}

const LuxTrackingContext = React.createContext<luxTrackingContextType>({
  luxInit: () => {}
})

const LuxTrackingProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation()
  const awaitingLuxSendEvent = useRef<boolean>(false)

  function luxSend() {
    if (window.LUX && awaitingLuxSendEvent.current) {
      window.LUX.send()
      awaitingLuxSendEvent.current = false
    }
  }

  function handlePageChangeIfAwaitingLuxSendEvent() {
    if (window.LUX && awaitingLuxSendEvent.current) {
      console.log('Previous LUX.init call interrupted')
      window.LUX.addData('early-nav-termination', 'true')
      luxSend()
    }
  }

  /**
   * Check if window.LUX exists then call luxSend to clear any initiated luxInit calls (all invocations of LUX.init must have a matching LUX.send).
   * If a label is passed in use it to set the LUX.label (this is done automatically for any route that uses Route - see boomerang.ts which is used to initiate boomerang) - a label should be passed in for page updates that aren't associated with a path change (e.g. quick curation flyout)
   * Set awaitingLuxSendEvent to true so we know LUX.init has bee invoked
   */
  const luxInit = (label: LuxTrackingLabel) => {
    if (window.LUX && window.BOOMR) {
      handlePageChangeIfAwaitingLuxSendEvent()

      window.LUX.addData('early-nav-termination', 'false')
      if (!routeSepcificSamplingRate[label] || Math.random() < routeSepcificSamplingRate[label]) {
        window.LUX.label = `biz-app-${label}`
        window.LUX.init()
      }

      window.BOOMR.plugins.SPA.route_change(() => {
        luxSend()
      })

      awaitingLuxSendEvent.current = true
    }
  }

  useEffect(() => {
    handlePageChangeIfAwaitingLuxSendEvent()
  }, [location.pathname])

  useEffect(() => {
    if (window.LUX) {
      window.LUX.samplerate = config.get('lux.sample_rate') || 0
    }
  }, [])

  return (
    <LuxTrackingContext.Provider
      value={{
        luxInit
      }}
    >
      {children}
    </LuxTrackingContext.Provider>
  )
}

const useLuxTrackingContext = () => {
  const luxTrackingContext = useContext(LuxTrackingContext)

  if (!luxTrackingContext) {
    throw new Error('Invoked LuxTrackingContext outside of provider')
  }

  return luxTrackingContext
}

export { LuxTrackingContext, LuxTrackingProvider, useLuxTrackingContext }
