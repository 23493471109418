import React from 'react'
import { ToastOptions } from 'react-toastify'
import { Link } from '@deal/router'
import { Text } from '@deal/bluxome'
import {
  TicketTypeLabel,
  TicketTypeLabelProps
} from '#src/app/routes/tickets/components/TicketTypeLabel'
import Toast from '#src/app/components/Toast'
import formattedToast from '../formattedToast'
import TicketIcon from './ticket.svg'
import styles from './styles.css'

interface UnreadTicketActivityToastProps extends TicketTypeLabelProps {
  ticketId: string
  closeToast?: () => void
}

const UnreadTicketActivityToast: React.FC<UnreadTicketActivityToastProps> = ({
  closeToast,
  ticketId,
  ...ticketLabelProps
}) => {
  return (
    <Toast onCloseToastRequested={() => closeToast?.()}>
      <Link to={`/tickets/${ticketId}`} className={styles.toast}>
        <div className={styles.side}>
          <TicketIcon />
        </div>
        <div className={styles.textContainer}>
          <Text style="base-medium" color="neutrals-black">
            New CS Ticket Activity
          </Text>
          <TicketTypeLabel {...ticketLabelProps} className="body-small text-black" />
        </div>
      </Link>
    </Toast>
  )
}

export const unreadActivityToast = ({
  ticketId,
  type,
  state,
  ...options
}: UnreadTicketActivityToastProps & Omit<ToastOptions, 'type'>) => {
  return formattedToast(
    <UnreadTicketActivityToast ticketId={ticketId} type={type} state={state} />,
    options
  )
}
