/**
 * Global style imports
 *
 * These must be imported first. Webpack will maintain source order, and we want to be able to override
 *   styles from 3rd party libraries with application styles.
 */
import '@deal/standard-css/curated.min.css'
import '@deal/dom-hooks/bodyScrollLock.min.css'
import '@deal/components/es/index.css'
import '@deal/chat-firebase/es/index.css'
import '@deal/path-flow/es/index.css'
import '@deal/bluxome/es/index.css'
import 'react-table/react-table.css'
import 'draft-js/dist/Draft.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './toastify.css'
import './styles.css'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { PathFlowProvider } from '@deal/path-flow'
import { DeviceType } from '@deal/dom-hooks'
import { ComponentsProvider } from '@deal/components'
import Router from '#src/app/routes'
import { useUserAgentContext } from '#src/app/context/UserAgent'
import RegisterServiceWorker from '#src/app/containers/RegisterServiceWorker'
import config from '#src/app/config'
import Favicon32 from './favicons/favicon-32x32.png'
import Favicon16 from './favicons/favicon-16x16.png'
import AppleTouchIcon from './favicons/apple-touch-icon.png'

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userAgent = useUserAgentContext()
  const deviceType = userAgent.device.type as DeviceType
  const env: string = config.get('environment')
  const envTag = env === 'staging' ? '[STAGING] ' : env.startsWith('dev') ? '[DEV] ' : ''
  const title = `${envTag}Curated Business`
  return (
    <ComponentsProvider value={{ deviceType }}>
      <PathFlowProvider value={{ deviceType }}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://business.curated.com" />
          <meta
            property="og:description"
            content="Get expert recommendations, competitive prices, and handpicked deals for products, experiences, travel, and more!"
          />
          <meta
            name="description"
            content="Get expert recommendations, competitive prices, and handpicked deals for products, experiences, travel, and more!"
          />
          <meta name="keywords" content="deal deals" />
          <meta name="msapplication-TileColor" content="#00c469" />
          <meta name="theme-color" content="#00c469" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
          <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
        </Helmet>

        <RegisterServiceWorker />
        <Router />
      </PathFlowProvider>
    </ComponentsProvider>
  )
}

// Hot Module Replacement API
export default App
