import React from 'react'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useExpertLeadIntentChangedSubscription } from "./expertLeadIntentChanged.generated";

const LeadIntentChangedSubscription: React.FC<React.PropsWithChildren<{}>> = () => {
  const businessUser = useBusinessUser()
  useExpertLeadIntentChangedSubscription({ variables: { expertId: businessUser.id } })
  return null
}

export default LeadIntentChangedSubscription
