import React, { useContext, useState } from 'react'
import addDays from 'date-fns/addDays'
import { FileAttachment, GifAttachment } from '#src/app/typings/media'
import { LeadActionAfterAllSnoozeSteps } from '#src/generated/types'

type FollowUpSettings = {
  wakeupTimes: Date[]
  suggestedMessageRevisionIds: string[]
  leadActionAfterAllSnoozeSteps: LeadActionAfterAllSnoozeSteps
  attachments: (FileAttachment | GifAttachment)[][]
}

type WakeupTimeContext = {
  savedFollowUpSettings: FollowUpSettings
  setSavedFollowUpSettings: React.Dispatch<React.SetStateAction<FollowUpSettings>>
  savedReminderWakeupTime: Date
  setSavedReminderWakeupTime: React.Dispatch<React.SetStateAction<Date>>
} | null

const WakeupTimeContext = React.createContext<WakeupTimeContext>(null)

const WakeupTimeContextConsumer = WakeupTimeContext.Consumer

const WakeupTimeContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [savedFollowUpSettings, setSavedFollowUpSettings] = useState<FollowUpSettings>({
    wakeupTimes: [
      new Date(addDays(new Date(), 1).setHours(12, 0, 0)),
      new Date(addDays(new Date(), 3).setHours(12, 0, 0)),
      new Date(addDays(new Date(), 8).setHours(12, 0, 0))
    ],
    suggestedMessageRevisionIds: [],
    leadActionAfterAllSnoozeSteps: LeadActionAfterAllSnoozeSteps.ARCHIVE,
    attachments: [[], [], []]
  })
  const [savedReminderWakeupTime, setSavedReminderWakeupTime] = useState(
    new Date(addDays(new Date(), 1).setHours(12, 0, 0))
  )

  return (
    <WakeupTimeContext.Provider
      value={{
        savedFollowUpSettings,
        setSavedFollowUpSettings,
        savedReminderWakeupTime,
        setSavedReminderWakeupTime
      }}
    >
      {children}
    </WakeupTimeContext.Provider>
  )
}

const useWakeupTimeContext = () => {
  const wakeupTimeContext = useContext(WakeupTimeContext)

  if (!wakeupTimeContext) {
    throw new Error('Invoked `WakeupTimeContext` outside of provider')
  }

  return wakeupTimeContext
}

export {
  WakeupTimeContext,
  WakeupTimeContextConsumer,
  WakeupTimeContextProvider,
  useWakeupTimeContext
}
