import React, { useState } from 'react'
import Toast from '#src/app/components/Toast'
import { ConsumerReviewAddedActivityForConsumerReviewModalFragment } from '#src/app/components/ConsumerReviewModal/ConsumerReviewAddedActivityForConsumerReviewModal.generated'
import ConsumerReviewModal from '#src/app/components/ConsumerReviewModal'
import formattedToast from '../formattedToast'
import StarIcon from './star.svg'
import styles from './styles.css'

interface Props {
  closeToast?: () => void
  review: ConsumerReviewAddedActivityForConsumerReviewModalFragment
}

const ConsumerReviewAddedToast: React.FC<React.PropsWithChildren<Props>> = ({ closeToast, review }) => {
  const [showConsumerReviewModal, setShowConsumerReviewModal] = useState(false)

  const { consumer } = review.lead
  const consumerName = `${consumer.firstName}${
    consumer.lastName ? ` ${consumer.lastName[0].toUpperCase()}.` : ''
  }`

  return (
    <>
      {showConsumerReviewModal && (
        <ConsumerReviewModal
          review={review}
          onRequestClose={() => {
            closeToast && closeToast()
            setShowConsumerReviewModal(false)
          }}
        />
      )}
      <Toast
        onClick={() => setShowConsumerReviewModal(true)}
        className={styles.toast}
        onCloseToastRequested={() => closeToast && closeToast()}
      >
        <div className={styles.side}>
          <StarIcon />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title}>New review</div>
          <div className={styles.subTitle}>
            {consumerName} just left you a {review.rating} star review
          </div>
        </div>
      </Toast>
    </>
  )
}

const consumerReviewAddedToast = (
  props: ConsumerReviewAddedActivityForConsumerReviewModalFragment
) => formattedToast(<ConsumerReviewAddedToast review={props} />)

export default consumerReviewAddedToast
