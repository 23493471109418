import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCuratedItemRemovedEventProperties {
  curated_item_id: string
  lead_id: string
  activity_id?: string
}

export class ExpertCuratedItemRemovedEvent extends Event<ExpertCuratedItemRemovedEventProperties> {
  static displayName = 'Expert Curated Item Removed'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
