// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForTermsOfUseAgreementModalFragment = { __typename: 'BusinessUser', id: any, hasAgreedToCuratedTermsAndConditions: boolean };

export type AcceptCuratedTermsOfUseMutationVariables = Types.Exact<{
  input: Types.AcceptCuratedTermsOfUseInput;
}>;


export type AcceptCuratedTermsOfUseMutation = { __typename: 'Mutation', acceptCuratedTermsOfUse: { __typename: 'AcceptCuratedTermsOfUsePayload', businessUser: { __typename: 'BusinessUser', id: any, hasAgreedToCuratedTermsAndConditions: boolean } } };

export const BusinessUserForTermsOfUseAgreementModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForTermsOfUseAgreementModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"hasAgreedToCuratedTermsAndConditions"}}]}}]} as unknown as DocumentNode;
export const AcceptCuratedTermsOfUseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptCuratedTermsOfUse"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptCuratedTermsOfUseInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptCuratedTermsOfUse"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForTermsOfUseAgreementModal"}}]}}]}}]}},...BusinessUserForTermsOfUseAgreementModalFragmentDoc.definitions]} as unknown as DocumentNode;
export type AcceptCuratedTermsOfUseMutationFn = Apollo.MutationFunction<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables>;
export type AcceptCuratedTermsOfUseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables>, 'mutation'>;

    export const AcceptCuratedTermsOfUseComponent = (props: AcceptCuratedTermsOfUseComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables> mutation={AcceptCuratedTermsOfUseDocument} {...props} />
    );
    

/**
 * __useAcceptCuratedTermsOfUseMutation__
 *
 * To run a mutation, you first call `useAcceptCuratedTermsOfUseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCuratedTermsOfUseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCuratedTermsOfUseMutation, { data, loading, error }] = useAcceptCuratedTermsOfUseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCuratedTermsOfUseMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables>(AcceptCuratedTermsOfUseDocument, options);
      }
export type AcceptCuratedTermsOfUseMutationHookResult = ReturnType<typeof useAcceptCuratedTermsOfUseMutation>;
export type AcceptCuratedTermsOfUseMutationResult = Apollo.MutationResult<AcceptCuratedTermsOfUseMutation>;
export type AcceptCuratedTermsOfUseMutationOptions = Apollo.BaseMutationOptions<AcceptCuratedTermsOfUseMutation, AcceptCuratedTermsOfUseMutationVariables>;