import React, { useContext, useState } from 'react'
import { Popover, PopoverProps } from '@deal/components'
import { Control } from '@deal/chat-firebase'
import { ExpertChatEmojiAddedEvent } from '#src/app/events/ExpertChatEmojiAddedEvent'
import { AnalyticsContext } from '#src/app/containers/Analytics'
import EmojiPicker from '../EmojiPicker'
import SmileIcon from './smile.svg'

interface EmojiPickerControlProps {
  appendText: (text: string) => void
  appendTo?: PopoverProps['appendTo']
}

const EmojiPickerControl: React.FC<EmojiPickerControlProps> = ({ appendText, appendTo }) => {
  const analytics = useContext(AnalyticsContext)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Popover
      visible={isPopoverOpen}
      contentLabel="Emoji picker"
      elevation="high"
      interactive={true}
      content={
        <EmojiPicker
          onEmojiClick={(_, emojiObject) => {
            appendText(emojiObject.emoji)
            analytics?.track(new ExpertChatEmojiAddedEvent())
          }}
          pickerStyle={{
            width: '100%'
          }}
          native
          disableSkinTonePicker
        />
      }
      onRequestClose={() => setIsPopoverOpen(false)}
      appendTo={appendTo || undefined}
    >
      <Control title="Add Emoji" onClick={() => setIsPopoverOpen(isPopoverOpen => !isPopoverOpen)}>
        <SmileIcon />
      </Control>
    </Popover>
  )
}

export default EmojiPickerControl
