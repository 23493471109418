export const FOLDER_SLUGS = {
  LABEL_ACTIVE: 'label-active',
  LABEL_STARRED: 'label-starred',
  LABEL_SNOOZED: 'label-snoozed',
  LABEL_NEEDS_RESPONSE: 'label-needsResponse',
  LABEL_ARCHIVED: 'label-archived',
  LABEL_INTELLIGENT_FOLLOW_UP: 'intelligent-followups',
  LABEL_NEVER_REPLIED: 'label-neverReplied',
  LABEL_DO_NOT_CALL: 'label-doNotCall',
  LABEL_NEEDS_OFFER: 'label-needsOffer',
  LABEL_HAS_OFFER: 'label-hasOffer',
  LABEL_SOLD: 'label-sold',
  LABEL_HAS_OPEN_TICKET: 'label-hasOpenTicket',
  LABEL_NEEDS_ACTION: 'label-needsAction',
  SAVED_SEARCH_LEADS_WITH_OFFERS: 'saved-search-leads-with-offers',
  SAVED_SEARCH_NEW_LEADS: 'saved-search-new-leads',
  SAVED_SEARCH_STARRED_LEADS: 'saved-search-starred-leads',
  SAVED_SEARCH_RECENT_CUSTOMERS: 'saved-search-recent-customers',
  SAVED_SEARCH_QUALITY_CONVERSATION: 'saved-search-quality-conversation',
  SAVED_SEARCH_NO_REQUEST_LEADS: 'saved-search-no-request-leads',
  SAVED_SEARCH_PHONE_CALL_LEADS: 'saved-search-phone-call-leads',
  SAVED_SEARCH_ALL_PAID_CUSTOMERS: 'saved-search-all-paid-customers',
  DEPARTMENT_TOP_EXPERTS: 'department-top-experts'
}

// The broadcast lead pool is not a folder but we use this unique ID
// to represent it on the FE
export const BROADCAST_SLUG = 'label-lead-pool'

export const SNAPSHOT_CARD_NAMES = {
  [FOLDER_SLUGS.LABEL_NEEDS_ACTION]: 'Needs Action',
  [BROADCAST_SLUG]: 'Broadcast',
  [FOLDER_SLUGS.LABEL_INTELLIGENT_FOLLOW_UP]: 'Follow Up',
  [FOLDER_SLUGS.LABEL_SNOOZED]: 'Snoozed'
}

export const INBOX_SNAPSHOT_TYPES = {
  BROADCAST_POOL: 'BROADCAST_POOL',
  FOLDER: 'FOLDER'
}

export const folderMap: { [key: string]: string } = {
  [FOLDER_SLUGS.LABEL_ACTIVE]: 'Active',
  [FOLDER_SLUGS.LABEL_STARRED]: 'Starred',
  [FOLDER_SLUGS.LABEL_SNOOZED]: 'Snoozed',
  [FOLDER_SLUGS.LABEL_NEEDS_RESPONSE]: 'Needs response',
  [FOLDER_SLUGS.LABEL_ARCHIVED]: 'Archived',
  [FOLDER_SLUGS.LABEL_INTELLIGENT_FOLLOW_UP]: 'Intelligent Follow Up',
  [FOLDER_SLUGS.LABEL_NEVER_REPLIED]: 'Never replied',
  [FOLDER_SLUGS.LABEL_DO_NOT_CALL]: 'Do not call',
  [FOLDER_SLUGS.LABEL_NEEDS_OFFER]: 'Needs offer',
  [FOLDER_SLUGS.LABEL_HAS_OFFER]: 'Has offer',
  [FOLDER_SLUGS.LABEL_SOLD]: 'Sold',
  [FOLDER_SLUGS.LABEL_HAS_OPEN_TICKET]: 'Escalations',
  [FOLDER_SLUGS.LABEL_NEEDS_ACTION]: 'Need action',
  [FOLDER_SLUGS.SAVED_SEARCH_LEADS_WITH_OFFERS]: 'All with offer',
  [FOLDER_SLUGS.SAVED_SEARCH_NEW_LEADS]: 'New leads',
  [FOLDER_SLUGS.SAVED_SEARCH_STARRED_LEADS]: 'Starred & active',
  [FOLDER_SLUGS.SAVED_SEARCH_RECENT_CUSTOMERS]: 'Recent customers',
  [FOLDER_SLUGS.SAVED_SEARCH_QUALITY_CONVERSATION]: 'Quality conversations',
  [FOLDER_SLUGS.SAVED_SEARCH_NO_REQUEST_LEADS]: 'No request',
  [FOLDER_SLUGS.SAVED_SEARCH_PHONE_CALL_LEADS]: 'Phone calls',
  [FOLDER_SLUGS.SAVED_SEARCH_ALL_PAID_CUSTOMERS]: 'Past customers'
}

export const timezoneMap: { [key: string]: string } = {
  'America/New_York': 'US Eastern Time',
  'America/Chicago': 'US Central Time',
  'America/Denver': 'US Mountain Time',
  'America/Phoenix': 'US Mountain Time (Arizona)',
  'America/Los_Angeles': 'US Pacific Time',
  'Pacific/Honolulu': 'US Hawaii Time',
  'America/Anchorage': 'US Alaska Time'
}
