import React from 'react'
import { Popover } from '@deal/components'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import QuickReplyPopoverContent, {
  QuickReplyPopoverContentProps
} from './components/QuickReplyPopoverContent'
import styles from './styles.css'

interface QuickReplyPopoverProps
  extends Omit<
    QuickReplyPopoverContentProps,
    | 'onRequestManageQuickRepliesOpen'
    | 'onRequestManageQuickRepliesClose'
    | 'isManageQuickRepliesModalOpen'
  > {
  visible: boolean
  onRequestClose: () => void
}
const QuickReplyPopover: React.FC<React.PropsWithChildren<QuickReplyPopoverProps>> = ({
  children,
  visible,
  onRequestClose,
  ...passthroughProps
}) => {
  const { showManageQuickRepliesModal } = useQuickReplyContext()

  return (
    <Popover
      contentLabel="Quick reply"
      visible={visible}
      onRequestClose={() => {
        // If the manage modal is open, we don't want to close the popover
        !showManageQuickRepliesModal && onRequestClose()
      }}
      interactive={true}
      placement="top"
      elevation="low"
      size="large"
      appendTo="parent"
      contentClassName={styles.content}
      wrapperClassName={styles.wrapper}
      content={
        /* We only render the popover content when showPopover is true to prevent unnecessary queries / mutations */
        visible ? <QuickReplyPopoverContent {...passthroughProps} /> : null
      }
    >
      {children}
    </Popover>
  )
}

export default QuickReplyPopover
