import React, { useContext } from 'react'
import { formatDate } from '@deal/date-utils'
import { Avatar } from '@deal/components'
import {
  ExpertAccountChangeRequestStatus,
  ExpertAccountChangeRequestType
} from '#src/generated/types'
import { isImpersonating } from '#src/app/utilities/identity'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { IdentityContext } from '#src/app/containers/Identity'
import { useExpertAccountChangeRequestsForReactivateBusinessUserAccountDisplayQuery } from './ExpertAccountChangeRequests.generated'
import ReactivateBusinessAccount from '../ReactivateBusinessUserAccount'
import styles from './styles.css'

interface Props {
  selectedBusinessUserId?: string
  onSubmitted?: () => void
}

const ReactivateBusinessAccountDisplay: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmitted,
  ...props
}) => {
  const isLargerThanSm = useBreakpoint('sm')
  const { myself } = useContext(IdentityContext)
  const businessUser = myself?.businessUser
  const displayName = businessUser?.displayName
  const deactivatedBusinessUsers =
    businessUser?.user.assignedBusinessUsers.filter(businessUser => businessUser.deactivated) || []
  const impersonating = isImpersonating(myself)
  const deactivatedExpertApplicant = !businessUser?.roles.includes('expert')
  const lastDeactivatedBusinessUser = impersonating
    ? businessUser
    : deactivatedBusinessUsers.length > 0
    ? deactivatedBusinessUsers.reduce((lastDeactivatedBusinessUser, currentBusinessUser) => {
        // Only consider businessUsers that have a lastDeactivatedAt - if none of them have that field return the currentBusinessUser
        if (
          lastDeactivatedBusinessUser.lastDeactivatedAt &&
          currentBusinessUser.lastDeactivatedAt
        ) {
          return currentBusinessUser.lastDeactivatedAt >
            lastDeactivatedBusinessUser.lastDeactivatedAt
            ? currentBusinessUser
            : lastDeactivatedBusinessUser
        } else if (lastDeactivatedBusinessUser.lastDeactivatedAt) {
          return lastDeactivatedBusinessUser
        } else {
          return currentBusinessUser
        }
      })
    : null

  const {
    data,
    refetch
  } = useExpertAccountChangeRequestsForReactivateBusinessUserAccountDisplayQuery({
    variables: {
      input: {
        expertIds: deactivatedBusinessUsers.map(businessUser => businessUser.id),
        status: ExpertAccountChangeRequestStatus.SUBMITTED,
        type: ExpertAccountChangeRequestType.REACTIVATE_ACCOUNT
      }
    },
    skip: !myself || deactivatedBusinessUsers.length == 0 || deactivatedExpertApplicant,
    fetchPolicy: 'cache-and-network'
  })

  if (!lastDeactivatedBusinessUser) return null

  const requests = data?.expertAccountChangeRequests.requests
  const requestExists = requests && requests.length > 0

  return (
    <div className={styles.container}>
      <Avatar
        name={displayName || ''}
        src={
          impersonating
            ? businessUser?.expertProfile?.profilePhoto?.url
            : lastDeactivatedBusinessUser?.expertProfile?.profilePhoto?.url
        }
        size={isLargerThanSm ? 146 : 96}
        className={styles.avatar}
      />
      <div className={styles.name}>{displayName}</div>
      {(lastDeactivatedBusinessUser.lastDeactivatedAt || impersonating) && (
        <>
          <div className={styles.deactivationDate}>
            {impersonating
              ? 'Deactivated'
              : `Deactivated on ${formatDate(lastDeactivatedBusinessUser.lastDeactivatedAt, {
                  format: 'long'
                })}`}
          </div>
          {deactivatedExpertApplicant ? (
            <div className={styles.body}>
              <div>
                Thank you for your interest in Curated. At this time, we have decided to not move
                forward with your candidacy.
              </div>
              <div>
                If you would like to re-apply for this category or any other category, please visit{' '}
                <a href="https://www.curated.com/apply">https://www.curated.com/apply</a>.
              </div>
              <div>
                Questions?{' '}
                <a href="mailto:expert-success@curated.com">expert-success@curated.com</a>
              </div>
            </div>
          ) : null}
        </>
      )}
      {requestExists && (
        <div className={styles.requests}>
          {requests!.map(request => (
            <div className={styles.request} key={request.id}>
              Reactivation request for {request.expert.department.displayName} sent
            </div>
          ))}
          <div className={styles.request}>The Expert Team will reach out soon</div>
        </div>
      )}
      <div className={styles.button}>
        <ReactivateBusinessAccount
          {...props}
          onSubmitted={() => {
            onSubmitted?.()
            refetch()
          }}
          existingRequests={requests}
        />
      </div>
    </div>
  )
}

export default ReactivateBusinessAccountDisplay
