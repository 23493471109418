import React, { useState } from 'react'
import { Image } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import config from '#src/app/config'
import SearchTextField from '../SearchTextField'
import ResultsPlaceholder from '../ResultsPlaceholder'
import ResultsContainer, { decrementMaybeNumber, incrementMaybeNumber } from '../ResultsContainer'
import Magnifier from '../Magnifier'
import { useMediaSearchControlContext } from '../context'
import WarningIcon from './warning.svg'
import styles from './styles.css'

const acceptedMimeTypes = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp']

type GoogleSearchResultPageInfo = {
  startIndex: number
}

type GoogleSearchResultItem = {
  link: string
  title: string
  displayLink: string
  mime: string
  image: {
    thumbnailLink: string
  }
}

type GoogleSearchResult = {
  queries: {
    nextPage?: GoogleSearchResultPageInfo[]
    previousPage?: GoogleSearchResultPageInfo[]
  }
  items: GoogleSearchResultItem[]
}

interface GoogleSearchPanelProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const GoogleSearchPanel: React.FC<React.PropsWithChildren<GoogleSearchPanelProps>> = ({
  attachMediaUrl
}) => {
  const { setIsPopoverVisible } = useMediaSearchControlContext()
  const [zoomImageSourceIndex, setZoomImageSourceIndex] = useState<number>()
  const [keywords, setSearchTerm] = useState('')
  const [searchedKeywords, setSearchedKeywords] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [searchResult, setSearchResult] = useState<GoogleSearchResult>()
  const { id: sharedByUserId } = useBusinessUser()

  const searchGoogleImages = (startIndex: number) => {
    // For general info on the custom search api see: https://developers.google.com/custom-search/v1/introduction
    // Specify which fields we want in the response: https://developers.google.com/custom-search/v1/performance#partial
    const fields =
      'fields=queries(nextPage/startIndex, previousPage/startIndex), items(title, link, displayLink, mime, image/thumbnailLink)'
    return fetch(
      `https://www.googleapis.com/customsearch/v1?searchType=image&key=${config.get(
        'google.api_key'
      )}&cx=${config.get(
        'google.programmable_search_engine_id'
      )}&q=${keywords}&${fields}&start=${startIndex}`
    )
      .then(res => {
        if (res.ok) {
          setError('')
          return res.json()
        } else {
          setError('Something went wrong, please try again.')
        }
      })
      .then((responseData: GoogleSearchResult) => {
        // filter out any items that are not an accepted mimeType
        const filteredItems = responseData.items.filter(item =>
          acceptedMimeTypes.includes(item.mime)
        )
        responseData.items = filteredItems

        if (!startIndex) {
          setSearchResult(responseData)
        } else {
          setSearchResult(prevResults => {
            if (!prevResults) {
              return responseData
            }

            return { ...responseData, items: [...prevResults.items, ...responseData.items] }
          })
        }
      })
      .finally(() => setLoading(false))
  }

  const handleSearch = (startIndex: number) => {
    setLoading(true)
    setSearchedKeywords(keywords)
    return searchGoogleImages(startIndex)
  }

  const handleImageSelected = (url: string) => {
    attachMediaUrl(url, searchedKeywords, sharedByUserId)
  }

  const searchResultItems = searchResult?.items || []
  const nextPageInfo = searchResult?.queries.nextPage?.[0]
  const zoomedImageUrl =
    zoomImageSourceIndex === undefined || !searchResultItems
      ? undefined
      : searchResultItems[zoomImageSourceIndex].link
  const zoomedImageIsTheLastQueriedImage = searchResultItems.length - 1 === zoomImageSourceIndex

  return (
    <div>
      <div className={styles.searchRow}>
        <SearchTextField
          textFieldProps={{
            label: 'Google search',
            labelHidden: true,
            onChange: e => setSearchTerm(e.target.value),
            inputProps: {
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  handleSearch(0)
                }
              }
            }
          }}
          buttonProps={{
            onClick: () => {
              handleSearch(0)
            },
            variant: 'secondary',
            disabled: keywords === searchedKeywords
          }}
        />
      </div>
      <ResultsContainer
        zoomImageSource={zoomedImageUrl}
        loading={loading}
        onZoomedImageSelected={() => {
          handleImageSelected(zoomedImageUrl!)
          setZoomImageSourceIndex(undefined)
        }}
        onNextZoomedImageRequested={
          zoomedImageIsTheLastQueriedImage && !nextPageInfo
            ? undefined
            : () => {
                if (zoomedImageIsTheLastQueriedImage && nextPageInfo) {
                  handleSearch(nextPageInfo.startIndex).then(() => {
                    setZoomImageSourceIndex(incrementMaybeNumber)
                  })
                } else {
                  setZoomImageSourceIndex(incrementMaybeNumber)
                }
              }
        }
        onPreviousZoomedImageRequested={
          zoomImageSourceIndex !== 0
            ? () => setZoomImageSourceIndex(decrementMaybeNumber)
            : undefined
        }
        onModalClose={() => {
          setZoomImageSourceIndex(undefined)
        }}
        onScrollToEndOfResults={
          nextPageInfo
            ? () => {
                handleSearch(nextPageInfo.startIndex)
              }
            : undefined
        }
      >
        {searchResult ? (
          <>
            {searchResultItems.length > 0 ? (
              <>
                {searchResultItems.map((image, idx) => {
                  return (
                    <div
                      className={styles.result}
                      onClick={() => {
                        handleImageSelected(image.link)
                        setIsPopoverVisible(false)
                      }}
                    >
                      <div key={`${image.link}-${idx}`} className={styles.imageContainer}>
                        <Image src={image.image.thumbnailLink} size="240px" />
                        <div className={styles.magnifierContainer}>
                          <Magnifier
                            onClick={() => {
                              setZoomImageSourceIndex(idx)
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.imageTitle}>{image.title}</div>
                      <div className={styles.displayLink}>{image.displayLink}</div>
                    </div>
                  )
                })}
              </>
            ) : (
              'No results found for that search term.'
            )}
          </>
        ) : error ? (
          <div className={styles.errorContainer}>
            <div>
              <WarningIcon />
            </div>
            <div>{error}</div>
          </div>
        ) : !loading ? (
          <ResultsPlaceholder placeholderText="Search Google Images" />
        ) : null}
      </ResultsContainer>
    </div>
  )
}

const GooglePanelModule = {
  title: 'Google',
  Display: GoogleSearchPanel
}

export default GooglePanelModule
