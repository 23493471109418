import React from 'react'
import { Image } from '@deal/components'
import { toastSuccess } from '#src/app/utilities/toast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import DefaultImage from '#src/app/assets/images/default-image.jpg'
import { useExpertVideoRecordingsPaginationForVideoPanelQuery } from './VideoPanel.generated'
import ResultsContainer from '../ResultsContainer'
import styles from './styles.css'

interface Props {
  leadId?: string
}

const VideoPanel: React.FC<Props> = ({ leadId }) => {
  const businessUser = useBusinessUser()

  const { data, loading } = useExpertVideoRecordingsPaginationForVideoPanelQuery({
    variables: { query: { expertId: businessUser.id, leadId }, offset: 0, limit: 10 },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <div className={styles.container}>
      <ResultsContainer loading={loading}>
        {data?.expertVideoRecordingsPagination.recordings.map(({ id, shareUrl, thumbnailUrl }) => {
          return (
            <div
              key={id}
              onClick={() => {
                navigator.clipboard.writeText(shareUrl)
                toastSuccess('Link copied')
              }}
              className={styles.imageContainer}
            >
              <Image src={thumbnailUrl || DefaultImage} size="240px" />
            </div>
          )
        })}
      </ResultsContainer>
    </div>
  )
}

const VideoPanelModule = {
  title: 'Videos',
  Display: VideoPanel
}

export default VideoPanelModule
