import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { useStateWithCallback } from '@deal/dom-hooks'
import { Button, ResizingTextArea, Tooltip } from '@deal/components'
import { SuggestedMessageRevisionType } from '#src/generated/types'
import { suggestedMessageTagNameAllowList } from '#src/app/utilities/suggestedActionUtils'
import { toastError } from '#src/app/utilities/toast'
import { FileAttachment, GifAttachment } from '#src/app/typings/media'
import { MediaFileFragment } from '#src/app/fragments/mediaFile.generated'
import {
  getMustacheTemplateTextErrors,
  getGenericFriendlyMustacheTemplateTextErrorMessage
} from '#src/app/services/mustacheTemplate'
import { gernateAttachmentsFromMediaFiles } from '#src/app/services/mediaAttachment'
import {
  AttachmentHandler,
  MediaAttachmentControls
} from '#src/app/components/MediaAttachmentControls'
import { useSuggestedMessageEditorFieldsQuery } from './SuggestedMessageEditorFields.generated'
import TagDisplay from '../TagDisplay'
import SuggestedMessageTagAdder from '../SuggestedMessageTagAdder'
import QuickReplyVariablePopover from '../QuickReplyVariablePopover'
import StarIcon from './star.svg'
import SaveIcon from './save.svg'
import EditIcon from './edit.svg'
import DuplicateIcon from './duplicate.svg'
import DeleteIcon from './delete.svg'
import CuratedIcon from './curated.svg'
import styles from './styles.css'

type SuggestedMessageTag = {
  displayName: string
  name: string
  deprecated?: boolean
}

type SuggestedMessageTagMap = {
  [key: string]: SuggestedMessageTag
}

type SuggestedMessageEditorFieldsType = {
  title: string
  message: string
  tags: string[]
  starred?: boolean | null
  type: SuggestedMessageRevisionType
  mediaFiles?: MediaFileFragment[] | null
}

interface SuggestedMessageEditorFieldsProps {
  suggestedMessage?: SuggestedMessageEditorFieldsType
  suggestedMessageTagMap: SuggestedMessageTagMap
  focusTitleOnMount?: boolean
  loading?: boolean
  onUpdate: (
    suggestedMessageTitle: string,
    suggestedMessageText: string,
    tags: string[],
    starred: boolean,
    attachments: (FileAttachment | GifAttachment)[]
  ) => void
  onDuplicate?: (initialData: SuggestedMessageEditorFieldsType) => void
  onDelete: () => void
}

const SuggestedMessageEditorFields: React.FC<SuggestedMessageEditorFieldsProps> = ({
  suggestedMessage = {
    title: '',
    message: '',
    tags: [],
    starred: false,
    type: SuggestedMessageRevisionType.EXPERT,
    mediaFiles: []
  },
  suggestedMessageTagMap,
  focusTitleOnMount,
  loading,
  onUpdate,
  onDuplicate,
  onDelete
}) => {
  const titleFieldRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [isEditing, setIsEditing] = useState(focusTitleOnMount)
  const [suggestedMessageTitle, setSuggestedMessageTitle] = useState(suggestedMessage.title)
  const [suggestedMessageText, setSuggestedMessageText] = useStateWithCallback(
    suggestedMessage.message
  )
  const [selectedSuggestedMessageTags, setSelectedSuggestedMessageTags] = useState<
    SuggestedMessageTag[]
  >(suggestedMessage.tags.map(tag => suggestedMessageTagMap[tag]))
  const [isStarred, setIsStarred] = useState(suggestedMessage.starred)
  const [attachments, setAttachments] = useState<(FileAttachment | GifAttachment)[]>(
    gernateAttachmentsFromMediaFiles(suggestedMessage.mediaFiles || [])
  )
  const [indexOfStartOfCurrentMessageSegment, setIndexOfStartOfCurrentMessageSegment] = useState(0)
  const [indexOfEndOfCurrentMessageSegment, setIndexOfEndOfCurrentMessageSegment] = useState(0)

  const { data } = useSuggestedMessageEditorFieldsQuery()
  const variables = data?.quickReplyTemplateVariable?.variables || []
  const errors = getMustacheTemplateTextErrors(suggestedMessageText, variables)
  const errorsExist = errors.length > 0

  useEffect(() => {
    if (titleFieldRef.current && focusTitleOnMount) titleFieldRef.current.focus()
  }, [titleFieldRef.current])

  useEffect(() => {
    setSuggestedMessageTitle(suggestedMessage.title)
    setSuggestedMessageText(suggestedMessage.message)
    setSelectedSuggestedMessageTags(suggestedMessage.tags.map(tag => suggestedMessageTagMap[tag]))
    setIsStarred(suggestedMessage.starred)
  }, [
    suggestedMessage.title,
    suggestedMessage.message,
    suggestedMessage.starred,
    suggestedMessage.tags
  ])

  const isHQCreatedSuggestedMessage = suggestedMessage.type === SuggestedMessageRevisionType.SYSTEM

  return (
    <div className={classnames(styles.container, { [styles.loading]: loading })}>
      <input
        ref={titleFieldRef}
        placeholder="Add title"
        className={styles.name}
        value={suggestedMessageTitle}
        disabled={isHQCreatedSuggestedMessage || !isEditing}
        onChange={e => setSuggestedMessageTitle?.(e.target.value)}
      />
      <QuickReplyVariablePopover
        messageSegment={suggestedMessageText.substring(
          indexOfStartOfCurrentMessageSegment,
          indexOfEndOfCurrentMessageSegment
        )}
        onClick={variableName => {
          // Turn the suggested message text into an array then splice the variable into the message text.
          const currentSuggestedMessageTextArray = suggestedMessageText.split('')
          const numberOfCharactersToRemove =
            indexOfEndOfCurrentMessageSegment - indexOfStartOfCurrentMessageSegment

          currentSuggestedMessageTextArray.splice(
            indexOfStartOfCurrentMessageSegment,
            numberOfCharactersToRemove,
            variableName
          )
          const newMessage = currentSuggestedMessageTextArray.join('')

          // Set the message in state, then use a callback to set the new cursor position.
          setSuggestedMessageText(newMessage, () => {
            if (textAreaRef.current) {
              textAreaRef.current.focus()
              textAreaRef.current.selectionStart =
                indexOfStartOfCurrentMessageSegment + variableName.length
              textAreaRef.current.selectionEnd =
                indexOfStartOfCurrentMessageSegment + variableName.length
            }
          })
          // Set new index to match the cursor position
          setIndexOfEndOfCurrentMessageSegment(
            indexOfStartOfCurrentMessageSegment + variableName.length
          )
        }}
      >
        <div className={styles.textContainer}>
          <ResizingTextArea
            ref={textAreaRef}
            placeholder="Add quick reply text"
            className={styles.text}
            value={suggestedMessageText}
            textAreaProps={{
              disabled: isHQCreatedSuggestedMessage || !isEditing
            }}
            onChange={e => {
              // Get cursor position
              const selectionIndex = e.target.selectionStart

              // Get position of first space before the current cursor position
              // This is done by reversing the array then finding the position of the first space after the current selection
              // Then using that index to find the corresponding index in the non reversed string
              const indexFromBackOfSpaceBeforeSelection = e.target.value
                .split('')
                .reverse()
                .indexOf(' ', e.target.value.length - selectionIndex)

              const firstSpaceBeforeSelectionIndex =
                indexFromBackOfSpaceBeforeSelection === -1
                  ? 0
                  : e.target.value.length -
                    e.target.value
                      .split('')
                      .reverse()
                      .indexOf(' ', e.target.value.length - selectionIndex)

              // Update the state to reflect the new selections
              setIndexOfStartOfCurrentMessageSegment(firstSpaceBeforeSelectionIndex)
              setIndexOfEndOfCurrentMessageSegment(selectionIndex)
              setSuggestedMessageText(e.target.value)
            }}
          />
          {attachments.length > 0 && (
            <AttachmentHandler
              attachments={attachments}
              onDetach={
                !isHQCreatedSuggestedMessage && isEditing
                  ? index => {
                      setAttachments(prevAttachments => {
                        return prevAttachments.filter((_, idx) => idx !== index)
                      })
                    }
                  : undefined
              }
            />
          )}
          {!isHQCreatedSuggestedMessage && isEditing && (
            <MediaAttachmentControls
              onAppendText={text => {
                if (!textAreaRef.current) {
                  return
                }

                const cursorStartPosition = textAreaRef.current.selectionStart
                const cursorEndPosition = textAreaRef.current.selectionEnd

                const updatedMessageText =
                  suggestedMessageText.slice(0, cursorStartPosition) +
                  text +
                  suggestedMessageText.slice(cursorEndPosition)

                setSuggestedMessageText(updatedMessageText, () => {
                  if (textAreaRef.current) {
                    textAreaRef.current.focus()
                    textAreaRef.current.selectionEnd = cursorStartPosition + 1
                  }
                })
              }}
              onAttachmentAdded={attachment => {
                setAttachments(prevAttachments => {
                  if (prevAttachments.length >= 3) {
                    toastError('Only 3 attachments are allowed per message', { autoClose: 3000 })
                    return prevAttachments
                  }

                  return [...prevAttachments, attachment]
                })
              }}
              hasVariableError={false}
            />
          )}
        </div>
      </QuickReplyVariablePopover>
      {errorsExist &&
        errors.map(error => (
          <div className={styles.errorText}>
            {getGenericFriendlyMustacheTemplateTextErrorMessage(error)}
          </div>
        ))}
      <div className={styles.bottomLine}>
        <div className={styles.tags}>
          {selectedSuggestedMessageTags.map(selectedTag => {
            return (
              <TagDisplay
                key={selectedTag.name}
                content={selectedTag.displayName}
                deprecated={selectedTag.deprecated}
                onRemove={
                  // We do not want to allow removal if the suggested message is HQ created or if there is only one tag on the suggested message
                  isHQCreatedSuggestedMessage ||
                  selectedSuggestedMessageTags.length === 1 ||
                  !isEditing
                    ? undefined
                    : () => {
                        const updatedTags = [...selectedSuggestedMessageTags].filter(
                          prevTag => prevTag !== selectedTag
                        )
                        setSelectedSuggestedMessageTags(updatedTags)
                      }
                }
              />
            )
          })}
          {!isHQCreatedSuggestedMessage && isEditing && (
            <Tooltip
              theme="dark"
              message="Add any labels you'd like your quick reply to show up under."
              placement="top"
              hideTooltipOnMobile
            >
              <SuggestedMessageTagAdder
                onTagsUpdated={tags => {
                  setSelectedSuggestedMessageTags(tags)
                }}
                suggestedMessageTags={Object.values(suggestedMessageTagMap).filter(
                  entry => !entry.deprecated && suggestedMessageTagNameAllowList.has(entry.name)
                )}
                selectedSuggestedMessageTags={selectedSuggestedMessageTags}
              />
            </Tooltip>
          )}
        </div>
        <div className={styles.actions}>
          {!!isHQCreatedSuggestedMessage && (
            <Tooltip
              theme="dark"
              message="HQ suggested quick reply. These don't allow edits, but you can duplicate to create your own version."
              placement="top"
            >
              <CuratedIcon />
            </Tooltip>
          )}
          {!isHQCreatedSuggestedMessage && (
            <Tooltip
              theme="dark"
              message="Favorite to move to the top when you're messaging leads."
              placement="top"
              hideTooltipOnMobile
            >
              <Button
                className={styles.starButton}
                variant={isStarred ? 'neutral-dark' : 'neutral-light-ghost'}
                size="xsmall"
                onClick={() => {
                  setIsStarred(prev => {
                    // If the suggested message is not being edited immediately save the change (otherwise save after the "Save" button is clicked)
                    if (!isEditing) {
                      onUpdate(
                        suggestedMessageTitle,
                        suggestedMessageText,
                        selectedSuggestedMessageTags.map(tag => tag.name),
                        !prev,
                        attachments
                      )
                    }
                    return !prev
                  })
                }}
              >
                <StarIcon
                  className={classnames(styles.starIcon, { [styles.invertIcon]: isStarred })}
                />
                Favorite
              </Button>
            </Tooltip>
          )}
          {!isHQCreatedSuggestedMessage && (
            <Button
              variant="neutral-light-ghost"
              size="xsmall"
              disabled={errorsExist && isEditing}
              onClick={() => {
                isEditing &&
                  onUpdate(
                    suggestedMessageTitle,
                    suggestedMessageText,
                    selectedSuggestedMessageTags.map(tag => tag.name),
                    !!isStarred,
                    attachments
                  )
                setIsEditing(prev => !prev)
              }}
            >
              {isEditing ? (
                <>
                  <SaveIcon /> Save
                </>
              ) : (
                <>
                  <EditIcon /> Edit
                </>
              )}
            </Button>
          )}
          {onDuplicate && (
            <Tooltip
              theme="dark"
              message="Duplicate to create your new version."
              placement="top"
              hideTooltipOnMobile
            >
              <Button
                variant="neutral-light-ghost"
                size="xsmall"
                onClick={() => {
                  onDuplicate({
                    title: suggestedMessageTitle,
                    message: suggestedMessageText,
                    tags: selectedSuggestedMessageTags.map(tag => tag.name),
                    starred: !!isStarred,
                    mediaFiles: suggestedMessage.mediaFiles || [],
                    type: suggestedMessage.type
                  })
                }}
              >
                <DuplicateIcon className={styles.icon} /> Duplicate
              </Button>
            </Tooltip>
          )}
          {!isHQCreatedSuggestedMessage && (
            <Button
              variant="neutral-light-ghost"
              size="xsmall"
              onClick={() => {
                onDelete()
              }}
            >
              <DeleteIcon className={styles.icon} /> Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export { SuggestedMessageEditorFields, SuggestedMessageEditorFieldsType }
