import { MediaUrlPreview } from '@deal/chat-firebase'
import { MediaFileFragment } from '#src/app/fragments/mediaFile.generated'

export const gernateAttachmentsFromMediaFiles = (mediaFiles?: MediaFileFragment[]) => {
  return (
    mediaFiles?.map(mediaFile => ({
      data: { url: mediaFile.url },
      previewer: MediaUrlPreview,
      toMediaId: () => Promise.resolve(mediaFile.id)
    })) || []
  )
}
