// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { CallActivityForNewExpertPhoneCallToastFragmentDoc, CallParticipantForNewExpertPhoneCallToastFragmentDoc } from '../../toasts/newExpertPhoneCallToast/CallActivityForNewExpertPhoneCallToast.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertCallActivityChangedForExpertPhoneCallsSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertCallActivityChangedForExpertPhoneCallsSubscription = { __typename: 'Subscription', expertCallActivityChanged: { __typename: 'CallActivity', id: any, status: Types.CallStatus, type: Types.CallType, callee?: { __typename: 'BusinessUser', businessUserId: any, businessUserFirstName: string } | { __typename: 'Operator' } | { __typename: 'User', userId: any, userFirstName?: string | null } | null, caller?: { __typename: 'BusinessUser', businessUserId: any, businessUserFirstName: string } | { __typename: 'Operator' } | { __typename: 'User', userId: any, userFirstName?: string | null } | null, lead?: { __typename: 'Lead', id: any } | null } };


export const ExpertCallActivityChangedForExpertPhoneCallsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertCallActivityChangedForExpertPhoneCalls"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertCallActivityChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CallActivityForNewExpertPhoneCallToast"}}]}}]}},...CallActivityForNewExpertPhoneCallToastFragmentDoc.definitions,...CallParticipantForNewExpertPhoneCallToastFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertCallActivityChangedForExpertPhoneCallsComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertCallActivityChangedForExpertPhoneCallsSubscription, ExpertCallActivityChangedForExpertPhoneCallsSubscriptionVariables>, 'subscription'>;

    export const ExpertCallActivityChangedForExpertPhoneCallsComponent = (props: ExpertCallActivityChangedForExpertPhoneCallsComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertCallActivityChangedForExpertPhoneCallsSubscription, ExpertCallActivityChangedForExpertPhoneCallsSubscriptionVariables> subscription={ExpertCallActivityChangedForExpertPhoneCallsDocument} {...props} />
    );
    

/**
 * __useExpertCallActivityChangedForExpertPhoneCallsSubscription__
 *
 * To run a query within a React component, call `useExpertCallActivityChangedForExpertPhoneCallsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertCallActivityChangedForExpertPhoneCallsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertCallActivityChangedForExpertPhoneCallsSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertCallActivityChangedForExpertPhoneCallsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertCallActivityChangedForExpertPhoneCallsSubscription, ExpertCallActivityChangedForExpertPhoneCallsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertCallActivityChangedForExpertPhoneCallsSubscription, ExpertCallActivityChangedForExpertPhoneCallsSubscriptionVariables>(ExpertCallActivityChangedForExpertPhoneCallsDocument, options);
      }
export type ExpertCallActivityChangedForExpertPhoneCallsSubscriptionHookResult = ReturnType<typeof useExpertCallActivityChangedForExpertPhoneCallsSubscription>;
export type ExpertCallActivityChangedForExpertPhoneCallsSubscriptionResult = Apollo.SubscriptionResult<ExpertCallActivityChangedForExpertPhoneCallsSubscription>;