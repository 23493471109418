import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'
import CloseIcon from './close.svg'
import styles from './styles.css'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onCloseToastRequested?: () => void
}

const Toast: React.FC<React.PropsWithChildren<Props>> = ({ children, className, onCloseToastRequested, ...props }) => {
  return (
    <div {...props} className={classnames(className, styles.container)}>
      {onCloseToastRequested && (
        <button className={styles.closeButton} onClick={onCloseToastRequested}>
          <CloseIcon fill={'#575757'} width={15} height={15} />
        </button>
      )}
      {children}
    </div>
  )
}

export default Toast
