import React, { useContext, useState } from 'react'
import { useHistory } from '@deal/router'
import { useExperiment } from '@deal/experiment-js'
import { Permission, useHasPermission } from '#src/app/utilities/permission'
import { isApplicant } from '#src/app/utilities/identity'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { IdentityContext } from '#src/app/containers/Identity'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import Status from '../Status'
import SelectExpertStatus from '../SelectExpertStatus'
import MenuSection from '../MenuSection'
import MenuRow from '../MenuRow'
import ExpertSelectionSettingModal from '../ExpertSelectionSettingModal/index'
import ExpertPayInformation from '../ExpertPayInformation'
import AccountSelector from '../AccountSelector'
import styles from './styles.css'

interface UserMenuProps {}

const UserMenu: React.FC<React.PropsWithChildren<UserMenuProps>> = () => {
  const isLargerThanMd = useBreakpoint('md')
  const history = useHistory()
  const businessUser = useBusinessUser()
  const { myself } = useContext(IdentityContext)
  const [showExpertSelectionModal, setShowExpertSelectionModal] = useState(false)
  const { setShowManageQuickRepliesModal } = useQuickReplyContext()

  /**
   * Using LEAD_READ permission as a proxy for if this expert should be able to interact with any shift/status related features.
   * Expert presence mutations are all gated by a generic BUSINESS_USER_UPDATE permission which is too broad.
   */
  const [canEngageWithLeads, canManageQuickReplies] = useHasPermission(
    Permission.LEAD_READ,
    Permission.SUGGESTED_ACTION_MANAGE
  )

  const isActiveExpert = businessUser.active
  const isExpertApplicant = isApplicant(businessUser)

  const { result: expertSelectionResult } = useExperiment({
    defaultTreatment: 'control',
    experiment: 'expert-selection-setting'
  })

  const hasMultipleAccounts =
    myself && myself.businessUser && myself.businessUser.user.assignedBusinessUsers.length > 1

  return (
    <>
      <div className={styles.menu}>
        {!isLargerThanMd && <AccountSelector />}
        {!isLargerThanMd && (
          <section className={styles.container}>
            <div>
              <div className={styles.name}>{businessUser.displayName} </div>
              {hasMultipleAccounts && (
                <div className={styles.status}>
                  <div>{businessUser.department.displayName}</div>
                </div>
              )}
              {canEngageWithLeads && (
                <>
                  <ExpertPayInformation />
                  <Status />
                </>
              )}
            </div>
          </section>
        )}
        {!isExpertApplicant && (
          <>
            {isActiveExpert && canEngageWithLeads && <SelectExpertStatus />}
            {showExpertSelectionModal && (
              <ExpertSelectionSettingModal
                onRequestClose={() => setShowExpertSelectionModal(false)}
              />
            )}
          </>
        )}
        {expertSelectionResult === 'enabled' &&
          businessUser.isEligibleForExpertSelection &&
          canEngageWithLeads && (
            <MenuSection>
              <MenuRow
                title="Expert Selection Status"
                onClick={() => {
                  setShowExpertSelectionModal(true)
                }}
                checked={
                  businessUser.expertAttributes.expertSelectionSetting.isExpertSelectionEnabled
                }
              />
            </MenuSection>
          )}
        {canManageQuickReplies && (
          <MenuSection>
            <MenuRow
              title="Edit quick replies"
              onClick={() => {
                setShowManageQuickRepliesModal(true)
              }}
              checked={false}
            />
          </MenuSection>
        )}

        <MenuSection>
          <MenuRow
            title="Edit profile"
            onClick={() => history.push('/profile/edit')}
            checked={false}
          />
        </MenuSection>
        <MenuSection className={styles.signout}>
          <MenuRow onClick={() => history.push('/auth/logout')} title="Sign out" checked={false} />
        </MenuSection>
      </div>
    </>
  )
}

export default UserMenu
