import React from 'react'
import { Tooltip } from '@deal/components'
import CheckColumn from '../CheckColumn'
import LockIcon from './lock.svg'
import styles from './styles.css'

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  title: string
  subTitle?: string
  checked: boolean
  badging?: string
  lockMessage?: string
}

const MenuRow: React.FC<React.PropsWithChildren<Props>> = ({
  checked,
  title,
  subTitle,
  badging,
  lockMessage,
  onClick,
  ...rest
}) => {
  return (
    <Tooltip
      appendTo={() => document.body}
      message={lockMessage}
      theme="dark"
      visible={!!lockMessage ? undefined : false}
    >
      <li onClick={!!lockMessage ? undefined : onClick} {...rest} className={styles.menuRow}>
        <div className={styles.menuRowTitle}>
          <CheckColumn checked={checked} />
          <div>
            <div className={styles.titleLine}>
              {title}
              {!!lockMessage && <LockIcon />}
            </div>
            {subTitle && <div className={styles.menuRowSubtitle}>{subTitle}</div>}
          </div>
        </div>
        {badging && <div className={styles.badging}>{badging}</div>}
        {!!lockMessage && <div className={styles.lockedOverlay} />}
      </li>
    </Tooltip>
  )
}

export default MenuRow
