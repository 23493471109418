import React from 'react'
import { Popover } from '@deal/components'
import useBreakpointWrapper from '#src/app/hooks/useBreakpoint'
import { useQuickReplyVariablePopoverQuery } from './QuickReplyVariablePopover.generated'
import styles from './styles.css'

interface QuickReplyVariablePopoverProps {
  messageSegment: string
  onClick: (variableName: string) => void
}

const QuickReplyVariablePopover: React.FC<React.PropsWithChildren<
  QuickReplyVariablePopoverProps
>> = ({ messageSegment, onClick, children }) => {
  const isLargerThanSmall = useBreakpointWrapper('sm')
  const { data } = useQuickReplyVariablePopoverQuery({
    skip: !messageSegment || !isLargerThanSmall
  })

  const variables =
    data?.quickReplyTemplateVariable?.variables?.filter(variable => !variable.deprecated) || []
  const matchingVariables = variables.filter(variable => {
    const variableName = variable.name
    return variableName.startsWith(messageSegment) && messageSegment !== variableName
  })

  return (
    <Popover
      contentLabel="Quick reply variable"
      placement="bottom-start"
      visible={!!matchingVariables.length}
      wrapperClassName={styles.wrapper}
      elevation="highest"
      content={
        <div className={styles.container}>
          <div className={styles.header}>Insert variable</div>
          {matchingVariables?.map(variable => {
            return (
              <div
                key={variable.name}
                className={styles.variableContainer}
                onClick={() => onClick(variable.name)}
              >
                <div className={styles.variableDescription}>{variable.description}</div>
                <div className={styles.variable}>{variable.name}</div>
              </div>
            )
          })}
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default QuickReplyVariablePopover
