// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MonetaryAmountFragmentDoc } from '../../../../../fragments/monetaryAmount.generated';
export type BusinessUserForLeadGateProgressModalFragment = { __typename: 'BusinessUser', id: any, expertStats?: { __typename: 'ExpertStats', effectiveMPQL: { __typename: 'ExpertMPQLStats', marginPerQualifiedLead: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string } } } | null };

export type ExpertLeadGateForLeadGateProgressModalFragment = { __typename: 'ExpertLeadGate', state: Types.ExpertLeadGateState, leadGateRule?: { __typename: 'ExpertLeadGateRuleAndProgress', leads?: number | null, expertLeadGateSyntheticScoreRule?: { __typename: 'ExpertLeadGateSyntheticScoreRuleAndProgress', passingScore: number, pointsForQualityConversation?: number | null, pointsForCurationVideo?: number | null, pointsForAddToCart?: number | null, pointsForPurchase?: number | null, qualityConversationCount?: number | null, curationVideoCount?: number | null, addToCartCount?: number | null, purchaseCount?: number | null, isPassing: boolean, score?: number | null, leadCount: number } | null } | null };

export const BusinessUserForLeadGateProgressModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForLeadGateProgressModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"effectiveMPQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"marginPerQualifiedLead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export const ExpertLeadGateForLeadGateProgressModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertLeadGateForLeadGateProgressModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertLeadGate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadGateRule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"}},{"kind":"Field","name":{"kind":"Name","value":"expertLeadGateSyntheticScoreRule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"passingScore"}},{"kind":"Field","name":{"kind":"Name","value":"pointsForQualityConversation"}},{"kind":"Field","name":{"kind":"Name","value":"pointsForCurationVideo"}},{"kind":"Field","name":{"kind":"Name","value":"pointsForAddToCart"}},{"kind":"Field","name":{"kind":"Name","value":"pointsForPurchase"}},{"kind":"Field","name":{"kind":"Name","value":"qualityConversationCount"}},{"kind":"Field","name":{"kind":"Name","value":"curationVideoCount"}},{"kind":"Field","name":{"kind":"Name","value":"addToCartCount"}},{"kind":"Field","name":{"kind":"Name","value":"purchaseCount"}},{"kind":"Field","name":{"kind":"Name","value":"isPassing"}},{"kind":"Field","name":{"kind":"Name","value":"score"}},{"kind":"Field","name":{"kind":"Name","value":"leadCount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]} as unknown as DocumentNode;