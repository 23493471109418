import React from 'react'
import { Tooltip } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useExpertNewLeadEligibilityChangedSubscription } from '../ExpertNewLeadEligibilityChanged.generated'
import styles from './styles.css'

const ExpertNewLeadEligibility = () => {
  const businessUser = useBusinessUser()

  const { data } = useExpertNewLeadEligibilityChangedSubscription({
    variables: {
      expertId: businessUser.id
    }
  })

  return data ? (
    <>
      {(businessUser.expertPresence.__typename === 'ExpertPresenceOnShift' ||
        businessUser.expertPresence.__typename === 'ExpertPresenceOnStandby') &&
        businessUser.expertPresence.acceptingNewLeads && (
          <Tooltip
            placement="auto"
            theme="dark"
            message={
              data.expertNewLeadEligibilityChanged.eligibility.detailReason ||
              (data.expertNewLeadEligibilityChanged.eligibility.eligible
                ? 'You are receiving new leads.'
                : 'You are auto paused so no new leads will be assigned to you.')
            }
          >
            <div className={styles.status}>
              <div
                className={
                  data.expertNewLeadEligibilityChanged.eligibility.eligible
                    ? styles.onShift
                    : styles.offShift
                }
              />
              <div className={styles.statusText}>
                {data.expertNewLeadEligibilityChanged.eligibility.eligible
                  ? 'Receiving new leads'
                  : 'Auto paused'}
              </div>
            </div>
          </Tooltip>
        )}
    </>
  ) : null
}

export default ExpertNewLeadEligibility
