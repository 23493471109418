import React, { useState } from 'react'
import { Text } from '@deal/bluxome'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useLeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import NavSectionWithCollapsibleContent from '#src/app/components/NavSectionWithCollapsibleContent'
import NavFolderLink from '#src/app/components/NavFolderLink'
import { useFindExpertTagsQuery } from './ExpertTags.generated'
import ManageTagsModal from '../ManageTagsModal'
import styles from './styles.css'

const ExpertTags: React.FC = () => {
  const businessUser = useBusinessUser()
  const { currentSavedTagId } = useLeadSearchFolderContext()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isModalOpen, setisModalOpen] = useState(false)

  const { data } = useFindExpertTagsQuery({
    variables: {
      id: businessUser.id
    }
  })

  if (data?.findExpertTags) {
    const hasSavedTags = data?.findExpertTags.length
    return (
      <>
        <NavSectionWithCollapsibleContent
          sectionTitle="Tags"
          contentVisible={!!currentSavedTagId}
          optionsVisible={isExpanded}
          onOptionsIconClick={() => setIsExpanded(prev => !prev)}
          onOptionsClose={() => setIsExpanded(false)}
          optionsContent={
            <div
              onClick={() => {
                setIsExpanded(false)
                setisModalOpen(true)
              }}
              className={styles.popover}
            >
              <Text>Manage Tags</Text>
            </div>
          }
        >
          {hasSavedTags ? (
            data.findExpertTags.map(tag => (
              <NavFolderLink
                key={tag?.id}
                displayName={tag?.name || 'Unknown Tag'}
                onClick={() => {
                  // TODO
                }}
                isActive={tag?.id === currentSavedTagId}
              />
            ))
          ) : (
            <span className={styles.noTagsText}>No Saved Tags</span>
          )}
        </NavSectionWithCollapsibleContent>
        {isModalOpen && (
          <ManageTagsModal savedTags={data.findExpertTags} onClose={() => setisModalOpen(false)} />
        )}
      </>
    )
  } else {
    return null
  }
}

export default ExpertTags
