import React, { useEffect } from 'react'
import numeral from 'numeral'
import { Link } from '@deal/router'
import { PaymentActivityType } from '#src/generated/types'
import { useConfettiContext } from '#src/app/context/Confetti'
import Toast from '#src/app/components/Toast'
import { LeadPaymentSucceededActivityFragment } from '../../components/LeadActivitySubscription/LeadActivityOccurred.generated'
import formattedToast from '../formattedToast'
import CartIcon from './cart.svg'
import styles from './styles.css'

interface Props {
  activity: LeadPaymentSucceededActivityFragment
  closeToast?: () => void
}

const LeadPaymentSucceededToast: React.FC<React.PropsWithChildren<Props>> = ({ closeToast, activity }) => {
  const { setIsActive } = useConfettiContext()

  useEffect(() => {
    setIsActive(true)
    return () => {
      setIsActive(false)
    }
  }, [])

  return (
    <>
      <Toast onCloseToastRequested={() => closeToast && closeToast()}>
        <div className={styles.side}>
          <CartIcon />
        </div>
        <div className={styles.textContainer}>
          <Link to={`/inbox/${activity.lead.id}`} onClick={closeToast} className={styles.title}>
            {toastHeadline(activity)}
          </Link>

          <div className={styles.subTitle}>{toastBody(activity)}</div>
        </div>
      </Toast>
    </>
  )
}

const toastHeadline = function(activity: LeadPaymentSucceededActivityFragment): string {
  switch (activity.paymentActivityType) {
    case PaymentActivityType.DEPOSIT:
      return `${activity.lead.consumer.firstName} just made paid deposit!`
    case PaymentActivityType.FULL_PAYMENT:
      return `${activity.lead.consumer.firstName} just made a purchase!`
    case PaymentActivityType.REMAINING_BALANCE:
      return `${activity.lead.consumer.firstName} just paid remaining balance!`
    default:
      return `${activity.lead.consumer.firstName} just made a payment!`
  }
}

const toastBody = function(activity: LeadPaymentSucceededActivityFragment): string {
  const { tip, total, amountPaid, transactionAmount } = activity

  switch (activity.paymentActivityType) {
    case PaymentActivityType.DEPOSIT:
      if (tip) {
        return `${numeral(tip.amount).format('$0,0.00')} tip and ${numeral(
          transactionAmount.amount
        ).format('$0,0.00')} deposit on a ${numeral(total.amount).format('$0,0.00')} purchase`
      } else {
        return `${numeral(transactionAmount.amount).format('$0,0.00')} deposit on a ${numeral(
          total.amount
        ).format('$0,0.00')} purchase`
      }
    case PaymentActivityType.FULL_PAYMENT:
      if (tip) {
        return `${numeral(tip.amount).format('$0,0.00')} tip on a ${numeral(total.amount).format(
          '$0,0.00'
        )} purchase`
      } else {
        return ` ${numeral(total.amount).format('$0,0.00')} purchase`
      }
    case PaymentActivityType.REMAINING_BALANCE:
      return `${numeral(amountPaid.amount).format('$0,0.00')} paid on a ${numeral(
        total.amount
      ).format('$0,0.00')} purchase`
    default:
      return `${activity.lead.consumer.firstName} just made a payment!`
  }
}

const leadPaymentSucceededToast = (props: LeadPaymentSucceededActivityFragment) =>
  formattedToast(<LeadPaymentSucceededToast activity={props} />)

export default leadPaymentSucceededToast
