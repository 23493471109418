import React, { useEffect } from 'react'
import numeral from 'numeral'
import { Link } from '@deal/router'
import { useConfettiContext } from '#src/app/context/Confetti'
import Toast from '#src/app/components/Toast'
import { LeadConnectPartnerOrderActivityFragment } from '../../components/LeadActivitySubscription/LeadActivityOccurred.generated'
import formattedToast from '../formattedToast'
import CartIcon from './cart.svg'
import styles from './styles.css'

interface Props {
  activity: LeadConnectPartnerOrderActivityFragment
  closeToast?: () => void
}

const LeadConnectPartnerOrderToast: React.FC<React.PropsWithChildren<Props>> = ({
  closeToast,
  activity
}) => {
  const { setIsActive } = useConfettiContext()

  useEffect(() => {
    setIsActive(true)
    return () => {
      setIsActive(false)
    }
  }, [])

  return (
    <>
      <Toast onCloseToastRequested={() => closeToast && closeToast()}>
        <div className={styles.side}>
          <CartIcon />
        </div>
        <div className={styles.textContainer}>
          <Link to={`/inbox/${activity.lead.id}`} onClick={closeToast} className={styles.title}>
            {toastHeadline(activity)}
          </Link>

          <div className={styles.subTitle}>{toastBody(activity)}</div>
        </div>
      </Toast>
    </>
  )
}

const toastHeadline = function(activity: LeadConnectPartnerOrderActivityFragment): string {
  return `${activity.lead.consumer.firstName} just made a purchase!`
}

const toastBody = function(activity: LeadConnectPartnerOrderActivityFragment): string {
  const { total } = activity
  return ` ${numeral(total.amount).format('$0,0.00')} purchase`
}

const leadConnectPartnerOrderToast = (props: LeadConnectPartnerOrderActivityFragment) =>
  formattedToast(<LeadConnectPartnerOrderToast activity={props} />)

export default leadConnectPartnerOrderToast
