import React, { useContext, useState } from 'react'
import { useHistory } from '@deal/router'
import { Modal } from '@deal/components'
import { ExpertApplicationState } from '#src/generated/types'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { toastError } from '#src/app/utilities/toast'
import { getAccountDisplayName, getExpertCategoryCounts } from '#src/app/utilities/identity'
import { AccountsNeedAttentionContext } from '#src/app/context/AccountsNeedAttention'
import { IdentityContext } from '#src/app/containers/Identity'
import ReactivateBusinessAccount from '../ReactivateBusinessUserAccountDisplay'
import AccountCircle from './AccountCircle'
import styles from './styles.css'

const AccountSelector: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { myself } = useContext(IdentityContext)
  const [accountToReactivate, setAccountToReactivate] = useState<undefined | string>(undefined)
  const isLargerThanSmall = useBreakpoint('sm')
  const history = useHistory()
  const { leadCountByExpertId } = useContext(AccountsNeedAttentionContext)

  if (!myself || !myself.businessUser) {
    return null
  }
  const bizUsers = myself.businessUser.user.assignedBusinessUsers
  const activeBizUsers = bizUsers.filter(bizUser => !bizUser.deactivated)

  if (bizUsers.length < 2 || activeBizUsers.length === 1) {
    return null
  }

  const currentBizUser = myself.businessUser!

  const categoryCount = getExpertCategoryCounts(bizUsers)

  return (
    <div className={styles.container}>
      {bizUsers
        .filter(businessUser => !businessUser.deactivated)
        .map(businessUser => {
          const { expertApplication, id, deactivated } = businessUser

          const isPending = expertApplication?.state === ExpertApplicationState.PENDING

          const displayName = getAccountDisplayName(businessUser, categoryCount)

          if (id === currentBizUser.id) {
            return (
              <AccountCircle
                key={id}
                displayName={displayName}
                tooltipMessage={displayName}
                active
              />
            )
          }
          return (
            <AccountCircle
              key={id}
              tooltipMessage={isPending ? 'Application in review, inbox unavailable' : displayName}
              displayName={displayName}
              disabled={isPending}
              onClick={() => {
                // on desktop & disabled, do nothing.
                // On mobile & disabled, show a toast error since we don't show tooltip on mobile
                if (isPending && isLargerThanSmall) {
                  return
                } else if (isPending && !isLargerThanSmall) {
                  toastError('Application in review, inbox unavailable')
                } else if (deactivated) {
                  setAccountToReactivate(id)
                } else {
                  history.push({ pathname: `/change-account/${id}` })
                }
              }}
              badging={
                leadCountByExpertId(id) === 0 ? undefined : leadCountByExpertId(id).toString()
              }
            />
          )
        })}
      {accountToReactivate && (
        <Modal
          isOpen={true}
          onRequestClose={() => setAccountToReactivate(undefined)}
          modalContentClassName={styles.modal}
          mobilePosition="bottom"
          contentLabel="Reactivate account"
        >
          <ReactivateBusinessAccount
            selectedBusinessUserId={accountToReactivate}
            onSubmitted={() => setAccountToReactivate(undefined)}
          />
        </Modal>
      )}
    </div>
  )
}

export default AccountSelector
