import React from 'react'
import Toast from '#src/app/components/Toast'
import formattedToast from '../formattedToast'
import styles from './styles.css'

interface Props {
  closeToast?: () => void
}

const NextShiftStartedToast: React.FC<React.PropsWithChildren<Props>> = ({ closeToast }) => {
  return (
    <Toast onCloseToastRequested={() => closeToast && closeToast()}>
      <div className={styles.textContainer}>
        <div className={styles.title}>Your Customer Acquisition Time has started</div>
        <div className={styles.subTitle}>
          We automatically started your next CAT. If yours leads were paused previously, they are
          now unpaused.
        </div>
      </div>
    </Toast>
  )
}

const nextShiftStartedToast = (props: Props) => formattedToast(<NextShiftStartedToast {...props} />)

export default nextShiftStartedToast
