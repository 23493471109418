import React, { useState } from 'react'
import { Button } from '@deal/components'
import { formatDate } from '@deal/date-utils'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import ManageAwayAutoReply from '../ManageAwayAutoReply'
import { useBusinessUserForExtendedAwayBannerQuery } from './ExtendedAwayBanner.generated'
import { ExpertPresenceStatus, AwayAutoReplyStatus } from '#src/generated/types'
import CalendarIcon from './calendar.svg'
import styles from './styles.css'

interface ExtendedAwayBannerProps {}

const ExtendedAwayBanner: React.FC<ExtendedAwayBannerProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const businessUser = useBusinessUser()

  const isBusinessUserExtendedAway =
    businessUser.expertPresence.status === ExpertPresenceStatus.EXTENDED_AWAY

  const { data: currentData, previousData } = useBusinessUserForExtendedAwayBannerQuery({
    variables: {
      expertId: businessUser.id
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !isBusinessUserExtendedAway
  })
  const data = currentData || previousData

  const awayAutoReply = data?.businessUser.activeOrPendingAwayAutoReply

  if (!isBusinessUserExtendedAway || awayAutoReply?.status !== AwayAutoReplyStatus.ACTIVE) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        <CalendarIcon />
        Away until {formatDate(awayAutoReply.effectiveUntil, { includeTime: true })}
        <Button
          className={styles.button}
          onClick={() => setIsModalOpen(true)}
          variant="secondary-link"
        >
          Edit
        </Button>
      </div>
      {isModalOpen && (
        <ManageAwayAutoReply
          expertId={businessUser.id}
          onRequestClose={() => setIsModalOpen(false)}
          isForExtendedAway
        />
      )}
    </>
  )
}

export default ExtendedAwayBanner
