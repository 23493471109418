import qs from 'qs'
import { History } from 'history'
import {
  FacetSelection,
  LeadDateRangeFilterInput,
  SellableSearchFilter,
  SellableSort,
  SortOrder
} from '#src/generated/types'

type paramId = 'qcf' | 'inbox'

export interface QueryStringState {
  selections: FacetSelectionMap
  keyword?: string
  searchType?: string
  dateRangeFilters: DateRangeFilterMap
}

/**
 * Inserts params into searchParams under an id.
 * Params should be split by an id representing the UI they are used for to prevent clashes.
 */
export const insertSearchParamsById = ({
  id,
  params,
  search
}: {
  id: paramId
  params: any
  search: string
}) => {
  const allParams = qs.parse(search.replace(/^\?/, ''))
  allParams[id] = params
  return qs.stringify(allParams)
}

/**
 * Gets params from searchParams by id.
 * Params should be split by an id representing the UI they are used for to prevent clashes.
 */
export const getSearchParamsById = ({ search, id }: { search: string; id: paramId }) => {
  const allParams = qs.parse(search.replace(/^\?/, ''), { arrayLimit: 1000 })
  const relevantParams = allParams[id]
  return relevantParams || {}
}

type FacetSelectionMap = { [name: string]: FacetSelection }
type DateRangeFilterMap = { [name: string]: LeadDateRangeFilterInput }

type InboxQuery = {
  selections: FacetSelectionMap
  keyword?: string
  searchType?: string
  dateRangeFilters: DateRangeFilterMap
}

export enum SearchType {
  ExpertServices = 'ExpertServices',
  Inventory = 'Inventory',
  SavedCurations = 'SavedCurations',
  SuggestedCurations = 'SuggestedCurations',
  RecentCurations = 'RecentCurations'
}

export interface CurationQuery {
  searchType?: SearchType
  keywords?: string
  sortBy?: SellableSort
  selectedCategoryIds?: string[]
  selectedCategoryNeedTitle?: string
  filters?: SellableSearchFilter[]
  selections?: FacetSelectionMap
  shippingRegions?: string[]
  sortOrder?: SortOrder
  startCursor?: string
  endCursor?: string
  categoryId?: string
}

export const getQuickCurationFlyoutSearchParams = (search: string): CurationQuery => {
  return (getSearchParamsById({ search, id: 'qcf' }) as unknown) as CurationQuery
}

export const insertQuickCurationFlyoutSearchParams = (obj: { search: string; params: any }) => {
  return insertSearchParamsById({ ...obj, id: 'qcf' })
}

export const getInboxSearchParams = (search: string): InboxQuery => {
  return (getSearchParamsById({ search, id: 'inbox' }) as unknown) as InboxQuery
}

export const insertInboxSearchParams = (obj: { search: string; params: any }) => {
  return insertSearchParamsById({ ...obj, id: 'inbox' })
}

export const deserializeInboxSearchParams = (search: string): QueryStringState => {
  const params = getInboxSearchParams(search)

  return {
    selections: params.selections || {},
    keyword: params.keyword || undefined,
    dateRangeFilters: params.dateRangeFilters || {},
    searchType: params.searchType || undefined
  }
}

export const serializeInboxSearchParams = (query: QueryStringState, history: History) => {
  return insertInboxSearchParams({
    search: history.location.search,
    params: query
  })
}
