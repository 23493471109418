import React from 'react'
import { HeartbeatComponent } from "./heartbeatSubscription.generated";

const Heartbeat: React.FC<React.PropsWithChildren<{}>> = () => (
  <HeartbeatComponent>
    {_props => {
      return null
    }}
  </HeartbeatComponent>
)

export default Heartbeat
