import React from 'react'
import { toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { useReleaseBusinessUserIdentityMutation } from './ReleaseBusinessUserIdentity.generated'
import styles from './styles.css'

const StopImpersonationLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const [releaseBusinessUserIdentity] = useReleaseBusinessUserIdentityMutation({
    onCompleted: data => {
      if (!data.releaseBusinessUserIdentity.myself) {
        toast.error('Server error')
      } else {
        history.replace('/')
      }
    },
    onError: () => toast.error('Server error')
  })
  return (
    <a className={styles.impersonationLink} onClick={() => releaseBusinessUserIdentity()}>
      Stop impersonation
    </a>
  )
}

export default StopImpersonationLink
