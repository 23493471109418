import React, { useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { Element as ScrollElement, scroller } from 'react-scroll'
import classnames from 'classnames'
import { useApolloClient } from '@apollo/client'
import { usePrevious } from '@deal/dom-hooks'
import {
  BubbleLoader,
  Button,
  CircleLoader,
  Modal,
  TextField,
  TouchScrollable
} from '@deal/components'
import {
  AttributeType,
  SuggestedActionRevisionState,
  SuggestedActionUseCase,
  SuggestedMessageRevisionType
} from '#src/generated/types'
import { toastError } from '#src/app/utilities/toast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpointWrapper from '#src/app/hooks/useBreakpoint'
import { useTrainingContext } from '#src/app/context/Training'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import { suggestedMessageTagNameAllowList } from '#src/app/utilities/suggestedActionUtils'
import SearchIcon from '#src/app/assets/glyphs/search.svg'
import {
  SuggestedMessageRevisionForManageQuickRepliesModalFragment,
  SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument,
  useCreateNewSuggestedMessageByExpertMutation,
  useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery,
  useSuggestedMessageTagsForManageQuickRepliesModalQuery
} from './ManageQuickRepliesModal.generated'
import {
  SuggestedMessageEditorFields,
  SuggestedMessageEditorFieldsType
} from './SuggestedMessageEditorFields'
import TagsInfoPopover from './TagsInfoPopover'
import TagDisplay from './TagDisplay'
import SuggestedMessageEditor from './SuggestedMessageEditor'
import SavedIcon from './saved.svg'
import QuickReplyVariableInfoPopover from './QuickReplyVariableInfoPopover'
import styles from './styles.css'

const RESULTS_PER_FETCH = 10

export interface ManageQuickRepliesModalProps {
  defaultSuggestedMessageTags?: string[]
}

const ManageQuickReplyModalContainer: React.FC = () => {
  const {
    showManageQuickRepliesModal,
    setShowManageQuickRepliesModal,
    defaultTags
  } = useQuickReplyContext()

  return (
    <Modal
      isOpen={showManageQuickRepliesModal}
      onRequestClose={() => setShowManageQuickRepliesModal(false)}
      mobilePosition="bottom"
      width="xxlarge"
      contentLabel="Manage quick replies"
      elevation="high"
      contentSpacing={false}
    >
      <ManageQuickRepliesModal defaultSuggestedMessageTags={defaultTags} />
    </Modal>
  )
}

const ManageQuickRepliesModal: React.FC<ManageQuickRepliesModalProps> = ({
  defaultSuggestedMessageTags
}) => {
  const isLargerThanSm = useBreakpointWrapper('sm')
  const apolloClient = useApolloClient()
  const cache = apolloClient.cache
  const timeoutRef = useRef<NodeJS.Timeout>()
  const containerRef = useRef<HTMLDivElement>(null)
  const [suggestedMessageIndexToFocusOnMount, setSuggestedMessageIndexToFocustOnMount] = useState<
    number
  >()
  const { id: expertId, expertAttributes } = useBusinessUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermForQuery, setSearchTermForQuery] = useState('')
  const [currentOffset, setCurrentOffset] = useState(0)
  const [paginating, setPaginating] = useState(false)
  const [endOfResultsReached, setEndOfResultsReached] = useState(false)
  const [saving, setSaving] = useState<boolean>()
  const [justSaved, setJustSaved] = useState(false)
  const [suggestedMessageForCreationData, setSuggestedMessageForCreationData] = useState<{
    suggestedMessage: SuggestedMessageEditorFieldsType
    index: number
  }>()

  const [selectedSuggestedMessageTagsSet, setSelectedSuggestedMessageTagSet] = useState<
    Set<string>
  >(
    new Set(
      defaultSuggestedMessageTags && defaultSuggestedMessageTags.length > 0
        ? defaultSuggestedMessageTags
        : undefined
    )
  )

  const selectedSuggestedMessageTagArray = [...selectedSuggestedMessageTagsSet]

  const [createSuggestedMessage] = useCreateNewSuggestedMessageByExpertMutation({
    onError: () => toastError('Error while creating the quick reply. Try again.'),
    onCompleted: ({ createNewSuggestedMessageByExpert: { errorMessage } }) => {
      if (errorMessage) {
        toastError(errorMessage.errorMessage)
      }
    }
  })

  const trainingContext = useTrainingContext()

  const {
    data: suggestedMessageTagsData
  } = useSuggestedMessageTagsForManageQuickRepliesModalQuery()

  const allSuggestedMessageTags = suggestedMessageTagsData?.suggestedMessageTags.enumEntries

  const publishedSuggestedMessageTags = allSuggestedMessageTags?.filter(
    enumEntry => !enumEntry.deprecated && suggestedMessageTagNameAllowList.has(enumEntry.name)
  )

  const queryVariables = {
    filter: {
      useCase: SuggestedActionUseCase.LEAD,
      state: SuggestedActionRevisionState.PUBLISHED,
      // If tags are selected use them
      // If no tags are selected and the suggestedMessageTags prop is present use it select all the tags
      tags: selectedSuggestedMessageTagArray.length
        ? selectedSuggestedMessageTagArray
        : publishedSuggestedMessageTags
        ? publishedSuggestedMessageTags.map(suggestedMessageTag => suggestedMessageTag.name)
        : [],
      businessUserId: expertId,
      titleKeyword: searchTermForQuery,
      categoryIds: [expertAttributes.category.id]
    },
    limit: RESULTS_PER_FETCH
  }

  const previousSearchTermForQuery = usePrevious(searchTermForQuery)
  const fetchFromNetwork = searchTermForQuery !== previousSearchTermForQuery

  const {
    data: newSuggestedMessageData,
    previousData: previousSuggestedMessageData,
    loading: suggestedMessagesLoading,
    fetchMore
  } = useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: fetchFromNetwork ? 'cache-and-network' : 'cache-first'
  })

  useEffect(() => {
    setSuggestedMessageIndexToFocustOnMount(undefined)
    setSearchTerm('')
    setSearchTermForQuery('')
    setEndOfResultsReached(false)
    setCurrentOffset(0)
  }, [selectedSuggestedMessageTagsSet])

  useEffect(() => {
    setSuggestedMessageIndexToFocustOnMount(undefined)
    setEndOfResultsReached(false)
    setCurrentOffset(0)
  }, [searchTerm])

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    if (justSaved) {
      timeoutRef.current = setTimeout(() => {
        setJustSaved(false)
      }, 3000)
    }
  }, [justSaved])

  useEffect(() => {
    if (
      containerRef.current &&
      defaultSuggestedMessageTags &&
      defaultSuggestedMessageTags.length > 0
    ) {
      scroller.scrollTo(defaultSuggestedMessageTags[0], {
        smooth: true,
        containerId: 'tagsContainer',
        horizontal: !isLargerThanSm
      })
    }
  }, [containerRef.current])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  const suggestedMessageData = newSuggestedMessageData || previousSuggestedMessageData

  const suggestedMessages =
    suggestedMessageData?.suggestedMessageRevisionsIncludeSystemTypePagination.revisions || []

  const loading = suggestedMessagesLoading

  function writeUpdatesToCache(
    updatedRevisions: SuggestedMessageRevisionForManageQuickRepliesModalFragment[]
  ) {
    cache.writeQuery({
      query: SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument,
      variables: queryVariables,
      data: {
        suggestedMessageRevisionsIncludeSystemTypePagination: {
          revisions: updatedRevisions
        }
      }
    })
  }

  const suggestedMessageTagMap =
    allSuggestedMessageTags?.reduce((suggestedMessageTagMap, suggestedMessageTag) => {
      const updatedSuggestedMessageTagMap = {
        ...suggestedMessageTagMap,
        [suggestedMessageTag.name]: suggestedMessageTag
      }
      return updatedSuggestedMessageTagMap
    }, {}) || {}

  return (
    <div className={styles.outerContainer}>
      <div ref={containerRef} className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            Manage quick replies
            {loading && <CircleLoader className={styles.loader} />}
            {(saving || justSaved) && (
              <div className={styles.savePill}>
                {saving ? (
                  <>
                    Saving <BubbleLoader color="#767" className={styles.bubbleLoader} />
                  </>
                ) : (
                  <>
                    Saved <SavedIcon />
                  </>
                )}
              </div>
            )}
          </div>
          {isLargerThanSm && (
            <div className={styles.popoverContainer}>
              <TagsInfoPopover />
              <QuickReplyVariableInfoPopover />
            </div>
          )}
        </div>
        <div className={styles.splitContainer}>
          <div className={styles.leftContainer}>
            <TextField
              label="Search"
              labelHidden
              value={searchTerm}
              helpText={
                searchTerm !== searchTermForQuery
                  ? 'Hit enter to update search'
                  : searchTermForQuery
                  ? `Active search: "${searchTermForQuery}"`
                  : 'No active search'
              }
              inputProps={{
                onKeyPress: event => {
                  if (event.key === 'Enter') {
                    setSearchTermForQuery(searchTerm)
                  }
                }
              }}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              placeholder="Search..."
              icon={SearchIcon}
            />
            <TouchScrollable>
              <div className={styles.suggestedMessageTags} id="tagsContainer">
                {publishedSuggestedMessageTags?.map(tag => {
                  return (
                    <ScrollElement name={tag.name} key={tag.name}>
                      <TagDisplay
                        content={tag.displayName}
                        isActive={selectedSuggestedMessageTagsSet.has(tag.name)}
                        onSelect={() => {
                          setSelectedSuggestedMessageTagSet(prevSet => {
                            const updatedSet = new Set(prevSet)

                            if (prevSet.has(tag.name)) {
                              updatedSet.delete(tag.name)
                            } else {
                              updatedSet.add(tag.name)
                            }

                            return updatedSet
                          })
                        }}
                      />
                    </ScrollElement>
                  )
                })}
              </div>
            </TouchScrollable>
            <Button
              variant="secondary"
              onClick={() => {
                if (suggestedMessageForCreationData) {
                  toastError(
                    "You're already creating a new quick reply. Finish it before starting a new one."
                  )
                } else {
                  setSuggestedMessageForCreationData({
                    suggestedMessage: {
                      title: '',
                      message: '',
                      tags:
                        selectedSuggestedMessageTagArray.length === 0
                          ? ['QUICK_REPLIES']
                          : selectedSuggestedMessageTagArray,
                      starred: false,
                      type: SuggestedMessageRevisionType.EXPERT,
                      mediaFiles: []
                    },
                    index: 0
                  })
                }
              }}
            >
              New quick reply
            </Button>
          </div>
          <TouchScrollable>
            <div
              className={classnames(styles.quickRepliesContainer, { [styles.loading]: loading })}
            >
              {suggestedMessages.map((suggestedMessage, idx) => (
                <React.Fragment key={suggestedMessage.id}>
                  {suggestedMessageForCreationData?.index === idx && (
                    <SuggestedMessageEditorFields
                      suggestedMessage={suggestedMessageForCreationData.suggestedMessage}
                      suggestedMessageTagMap={suggestedMessageTagMap}
                      focusTitleOnMount
                      onUpdate={(title, message, tags, starred, mediaIds) => {
                        if (!title || !message) {
                          toastError('Title and message cannot be empty')
                          return
                        }
                        if (!tags.length) {
                          toastError('At least one tag must be selected')
                          return
                        }

                        setSaving(true)
                        setJustSaved(false)

                        createSuggestedMessage({
                          variables: {
                            input: {
                              title,
                              message,
                              attributes: [
                                {
                                  name: 'leadSuggestedMessageTag',
                                  type: AttributeType.ENUMERATION,
                                  enumerationValues: tags
                                }
                              ],
                              starred,
                              mediaIds: mediaIds || undefined,
                              exerciseTrackingId: trainingContext.exerciseTrackingId
                            }
                          }
                        }).then(({ data }) => {
                          if (!data || !suggestedMessageData) return

                          const updatedRevisions = [...suggestedMessages]

                          // Add the newly created revision at the index where it was being edited
                          updatedRevisions.splice(
                            idx,
                            0,
                            data.createNewSuggestedMessageByExpert.revision
                          )

                          // Write the new array of revisions to the cache for the current query
                          writeUpdatesToCache(updatedRevisions)
                          setSuggestedMessageForCreationData(undefined)
                          setSaving(false)
                          setJustSaved(true)
                        })
                      }}
                      onDelete={() => {
                        setSuggestedMessageForCreationData(undefined)
                      }}
                    />
                  )}
                  <SuggestedMessageEditor
                    focusTitleOnMount={idx === suggestedMessageIndexToFocusOnMount}
                    suggestedMessage={suggestedMessage}
                    // Provide map of suggestedMessageTagName => suggestedMessageTag for quick look up inside of the display
                    suggestedMessageTagMap={suggestedMessageTagMap}
                    onSaveStarted={() => {
                      setSaving(true)
                      setJustSaved(false)
                    }}
                    onSaveEnded={() => {
                      setSaving(false)
                      setJustSaved(true)
                    }}
                    onDelete={() => {
                      const updatedRevisions = [...suggestedMessages]

                      // On delete remove the deleted revision from the current list of revisions
                      updatedRevisions.splice(idx, 1)

                      // Write the new array of revisions to the cache for the current query
                      writeUpdatesToCache(updatedRevisions)
                      setSuggestedMessageIndexToFocustOnMount(undefined)
                    }}
                    onDuplicate={suggestedMessage => {
                      if (suggestedMessageForCreationData) {
                        toastError(
                          "You're already creating a new quick reply. Finish it before starting a new one."
                        )
                      } else {
                        setSuggestedMessageForCreationData({
                          suggestedMessage,
                          index: idx
                        })
                      }
                    }}
                  />
                </React.Fragment>
              ))}
              {!loading && !paginating && !endOfResultsReached && (
                <Waypoint
                  bottomOffset={'-20px'}
                  onEnter={() => {
                    setPaginating(true)
                    fetchMore({
                      variables: {
                        offset: currentOffset + RESULTS_PER_FETCH,
                        limit: RESULTS_PER_FETCH
                      }
                    }).then(res => {
                      // Filter out any revisions that may already be in the UI
                      // This is mainly to avoid fetching revisions that were created since the modal was opened since they are returned last by the API
                      const filteredNewRevisions = res.data.suggestedMessageRevisionsIncludeSystemTypePagination.revisions.filter(
                        newRevision =>
                          !suggestedMessages.some(revision => revision.id === newRevision.id)
                      )

                      // If no new revisions are fetched we have probably reached the end of the list (unless {RESULTS_PER_FETCH}+ new revisions have been created since the modal was opened)
                      if (filteredNewRevisions.length === 0) {
                        setPaginating(false)
                        setEndOfResultsReached(true)
                        return
                      }

                      // Combine the revisions in the cache w/ the new reivisons returned by the fetchMore
                      const updatedRevisions = [...suggestedMessages, ...filteredNewRevisions]

                      // Write the combined revisions to the cache for the current query
                      writeUpdatesToCache(updatedRevisions)
                      setPaginating(false)
                      setCurrentOffset(prevOffset => prevOffset + RESULTS_PER_FETCH)
                    })
                  }}
                />
              )}
            </div>
          </TouchScrollable>
        </div>
      </div>
    </div>
  )
}

export default ManageQuickReplyModalContainer
