import React, { useContext } from 'react'
import { BackgroundImage, Popover } from '@deal/components'
import FollowUpLeadsActionFooter from '#src/app/routes/inbox/FollowUpLeadsActionFooter'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import {
  IntelligentFollowUpNudgePopoverViewLeadsButtonClickedEvent
} from '#src/app/events/IntelligentFollowUpNudgePopoverViewLeadsButtonClicked'
import { NavigationContext } from '#src/app/context/Navigation'
import { InboxSnapshotContext } from '#src/app/context/InboxSnapshot'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import BackgroundImageSrc from './nudge-background.png'
import Close from './close.svg'
import styles from './styles.css'

interface FollowUpNudgePopoverProps {
  isVisible: boolean
  followUpCount: number
  handleViewLeads: () => void
}

export const FOLLOW_UP_NUDGE_LOCAL_KEY = 'intelligent-follow-up-nudge'
export const FOLLOW_UP_NUDGE_LOCAL_KEY_COMPLETE = 'completed'

const FollowUpNudgePopover: React.FC<React.PropsWithChildren<FollowUpNudgePopoverProps>> = ({
  isVisible,
  followUpCount,
  handleViewLeads,
  children,
}) => {
  const {
    setShowFollowUpNudge
  } = useContext(InboxSnapshotContext)
  const analytics = useAnalyticsContext()
  const { id: expertId } = useBusinessUser()
  const { setIsNavigationOpen } = useContext(NavigationContext)
  const isLargerThanSmall = useBreakpoint('sm')

  const onRequestClose = () => {
    setShowFollowUpNudge(false)
  }

  const onClickViewLeads = () => {
    analytics?.track(
      new IntelligentFollowUpNudgePopoverViewLeadsButtonClickedEvent({
        expert_id: expertId
      })
    )

    onRequestClose()
    setIsNavigationOpen(false)
    window.localStorage.setItem(FOLLOW_UP_NUDGE_LOCAL_KEY, FOLLOW_UP_NUDGE_LOCAL_KEY_COMPLETE)
    handleViewLeads && handleViewLeads()
  }

  const title = followUpCount === 1
    ? 'Check out this lead'
    : `Check out these ${followUpCount} leads`

  const description = `Message ${followUpCount === 1 ? 'this lead' : 'these leads'} to maintain a healthy relationship!`

  return (
    <Popover
      contentLabel=" Follow up leads"
      placement="right"
      variant="light"
      visible={isVisible}
      interactive={true}
      onRequestClose={onRequestClose}
      content={
        <div className={styles.container}>
          {isVisible && (
            <>
              <BackgroundImage
                src={BackgroundImageSrc}
                sizes="100vw"
                containerClassName={styles.backgroundImage}
              >
                {isLargerThanSmall && <Close className={styles.close} onClick={onRequestClose} />}
              </BackgroundImage>
              <div className={styles.content}>
                <div className={styles.title}>
                  {title}
                </div>
                <div className={styles.description}>
                  {description}
                </div>
                <FollowUpLeadsActionFooter handleViewLeads={onClickViewLeads} />
              </div>
            </>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default FollowUpNudgePopover
