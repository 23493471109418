import React from 'react'
import classnames from 'classnames'
import { Image } from '@deal/components'
import { SuggestedMessageRevisionType } from '#src/generated/types'
import {
  LeadSuggestedMessageForSuggestedMessageDisplayFragment,
  SuggestedMessageRevisionForSuggestedMessageDisplayFragment
} from './SuggestedMessageDisplay.generated'
import StarIcon from './star.svg'
import CuratedIcon from './curated.svg'
import styles from './styles.css'

interface SuggestedMessageDisplayProps {
  suggestedMessage:
    | LeadSuggestedMessageForSuggestedMessageDisplayFragment
    | SuggestedMessageRevisionForSuggestedMessageDisplayFragment
  onClick: () => void
}

const SuggestedMessageDisplay: React.FC<React.PropsWithChildren<SuggestedMessageDisplayProps>> = ({
  suggestedMessage,
  onClick
}) => {
  let message
  let starred
  let type
  let title
  let mediaFiles

  switch (suggestedMessage.__typename) {
    case 'LeadSuggestedMessage':
      message = suggestedMessage.message
      starred = suggestedMessage.suggestedMessageRevision.starred
      type = suggestedMessage.suggestedMessageRevision.type
      title = suggestedMessage.suggestedMessageRevision.title
      mediaFiles = suggestedMessage.suggestedMessageRevision.mediaFiles || []

      break
    case 'SuggestedMessageRevision':
      message = suggestedMessage.message
      starred = suggestedMessage.starred
      type = suggestedMessage.type
      title = suggestedMessage.title
      mediaFiles = suggestedMessage.mediaFiles || []
      break

    default:
      break
  }

  const isHQCreatedMessage = type === SuggestedMessageRevisionType.SYSTEM
  return (
    <div
      className={classnames(styles.messageContainer, {
        [styles.messageContainerNoMessage]: !message
      })}
      onClick={onClick}
    >
      <div className={styles.suggestedMessageTitle}>
        {title || '(No title)'} {starred && <StarIcon />} {isHQCreatedMessage && <CuratedIcon />}
      </div>
      <div className={styles.suggestedMessageText}>{message || '(No message)'}</div>
      {!!mediaFiles && mediaFiles.length > 0 && (
        <div className={styles.gallery}>
          {mediaFiles.map(mediaFile => {
            return (
              <div key={mediaFile.id}>
                <Image src={mediaFile.url} size="80px" />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SuggestedMessageDisplay
