import { ToastContent, ToastId, ToastOptions , toast } from 'react-toastify'
import styles from './styles.css'

export default function formattedToast(content: ToastContent, options?: ToastOptions | undefined) {
  return toast(content, {
    className: styles.toast,
    bodyClassName: styles.bodyClass,
    position: 'top-center',
    closeButton: false,
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    ...options
  })
}

export function updateFormattedToast(
  toastId: ToastId,
  content: ToastContent,
  options?: ToastOptions | undefined
) {
  return toast.update(toastId, {
    render: content,
    className: styles.toast,
    bodyClassName: styles.bodyClass,
    position: 'top-center',
    closeButton: false,
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    ...options
  })
}
