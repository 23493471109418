import React from 'react'
import classnames from 'classnames'
import styles from './styles.css'

export interface Props {
  className?: string
  isActive?: boolean
  cursorType?: 'pointer' | 'text'
  onFocus?: () => void
  onBlur?: () => void
  onClick?: () => void
}

const FieldInteractionWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  isActive,
  cursorType = 'pointer',
  onFocus,
  onBlur,
  onClick,
  children
}) => {
  return (
    <div
      className={classnames(
        styles.container,
        {
          [styles.pointer]: cursorType === 'pointer',
          [styles.text]: cursorType === 'text',
          [styles.containerActive]: isActive
        },
        className
      )}
      onClick={onClick}
      onFocus={() => onFocus?.()}
      onBlur={() => onBlur?.()}
    >
      {children}
    </div>
  )
}

export default FieldInteractionWrapper
