// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertLeadIntentChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertLeadIntentChangedSubscription = { __typename: 'Subscription', expertLeadIntentChanged: { __typename: 'LeadIntentChange', lead: { __typename: 'Lead', id: any, intentLabels: Array<Types.LeadIntentLabel> } } };


export const ExpertLeadIntentChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertLeadIntentChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadIntentChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"intentLabels"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ExpertLeadIntentChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertLeadIntentChangedSubscription, ExpertLeadIntentChangedSubscriptionVariables>, 'subscription'>;

    export const ExpertLeadIntentChangedComponent = (props: ExpertLeadIntentChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertLeadIntentChangedSubscription, ExpertLeadIntentChangedSubscriptionVariables> subscription={ExpertLeadIntentChangedDocument} {...props} />
    );
    

/**
 * __useExpertLeadIntentChangedSubscription__
 *
 * To run a query within a React component, call `useExpertLeadIntentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadIntentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadIntentChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertLeadIntentChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertLeadIntentChangedSubscription, ExpertLeadIntentChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertLeadIntentChangedSubscription, ExpertLeadIntentChangedSubscriptionVariables>(ExpertLeadIntentChangedDocument, options);
      }
export type ExpertLeadIntentChangedSubscriptionHookResult = ReturnType<typeof useExpertLeadIntentChangedSubscription>;
export type ExpertLeadIntentChangedSubscriptionResult = Apollo.SubscriptionResult<ExpertLeadIntentChangedSubscription>;