import React, { useEffect } from 'react'
import { Button } from '@deal/components'
import { PayComponentQualityConversationRuleFragment } from '#src/app/routes/reports/ExpertPayroll/fragments/payComponentRule.generated'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useStartExpertShiftMutation } from '../../components/Navigation/startExpertShift.generated'
import { useAcceptSurgeShiftMutation } from './AcceptSurgeShift.generated'
import formattedToast from '../formattedToast'
import TrendUp from './trend-up.svg'
import styles from './styles.css'

interface Props {
  leadSurgeId: any
  expertId: any
  closeToast?: () => void
}

interface AcceptProps {
  expertId: any
  closeToast?: () => void
}

interface AcceptWithErrorProps {
  error?: string | null
  closeToast?: () => void
}

const LeadSurgeStartedNotification: React.FC<React.PropsWithChildren<Props>> = ({ leadSurgeId, expertId, closeToast }) => {
  const businessUser = useBusinessUser()

  const qcPayStructure = businessUser.payStructure?.effectiveRevision?.components?.find(
    component => component.__typename == 'PayComponentQualityConversationRule'
  ) as PayComponentQualityConversationRuleFragment
  const [acceptSurgeShift, { data: surgeShiftData }] = useAcceptSurgeShiftMutation({
    variables: {
      input: {
        leadSurgeId,
        expertId
      }
    }
  })

  // Close toast after 5 minutes
  useEffect(() => {
    const timeout = setTimeout(() => closeToast, 30000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (surgeShiftData?.acceptSurgeShift.hasError) {
    return (
      <LeadShiftAcceptedWithErrorsNotification
        error={surgeShiftData.acceptSurgeShift.errorMessage}
        closeToast={closeToast}
      />
    )
  } else if (surgeShiftData?.acceptSurgeShift) {
    return <LeadShiftAcceptedNotification expertId={expertId} closeToast={closeToast} />
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.orange}>
          <TrendUp />
        </div>
        <div className={styles.content}>
          {qcPayStructure.amountPerSurgeShiftLead?.amount ? (
            <div>{`Leads are surging! Do you want to claim this surge shift and earn $${qcPayStructure.amountPerSurgeShiftLead?.amount} per QC?`}</div>
          ) : (
            <div>{`Leads are surging! Do you want to claim this surge shift?`}</div>
          )}
          <div className={styles.actionContainer}>
            <Button
              variant="neutral-dark-ghost"
              size="xsmall"
              className={styles.buttonYes}
              onClick={() => acceptSurgeShift()}
            >
              Yes
            </Button>
            <Button
              variant="neutral-dark-ghost"
              size="xsmall"
              className={styles.buttonNo}
              onClick={closeToast}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const LeadShiftAcceptedNotification: React.FC<React.PropsWithChildren<AcceptProps>> = ({ expertId, closeToast }) => {
  const [startExpertShift] = useStartExpertShiftMutation({
    variables: {
      id: expertId
    }
  })

  useEffect(() => {
    startExpertShift()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.orange}>
        <TrendUp />
      </div>
      <div className={styles.content}>
        <div>Thanks for helping! You will be able to accept leads for the next hour.</div>
        <div className={styles.actionContainer}>
          <Button
            variant="neutral-dark-ghost"
            size="xsmall"
            className={styles.buttonYes}
            onClick={closeToast}
          >
            Got it
          </Button>
        </div>
      </div>
    </div>
  )
}

const LeadShiftAcceptedWithErrorsNotification: React.FC<React.PropsWithChildren<AcceptWithErrorProps>> = ({
  error,
  closeToast
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.orange}>
        <TrendUp />
      </div>
      <div className={styles.content}>
        {error && <div>{error}</div>}
        {!error && <div>There was an error</div>}
        <div className={styles.actionContainer}>
          <Button
            variant="neutral-dark-ghost"
            size="xsmall"
            className={styles.buttonYes}
            onClick={closeToast}
          >
            Okay
          </Button>
        </div>
      </div>
    </div>
  )
}

const LeadSurgeStartedToast = (props: Props) => {
  return formattedToast(<LeadSurgeStartedNotification {...props} />)
}

export default LeadSurgeStartedToast
