import useBusinessUser from '#src/app/hooks/useBusinessUser'
import React from 'react'
import { useNewAiReEngagementMessageSubscription } from './NewAiReEngagementMessage.generated'

const NewAiReEngagementMessageSubscription: React.FC<React.PropsWithChildren<unknown>> = () => {
  const businessUser = useBusinessUser()
  useNewAiReEngagementMessageSubscription({
    variables: { expertId: businessUser.id },
    onData: ({ data: { data } }) => {
      if (window) {
        window.Appcues?.track('Lead re-engagement scheduled', {
          leadId: data?.leadReEngagementScheduled.id
        })
      }
    }
  })
  return null
}

export default NewAiReEngagementMessageSubscription
