import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCurationSubmittedEventProperties {
  lead_id: string
  activation_id?: string
  activity_id?: string
}

export class ExpertCurationSubmittedEvent extends Event<ExpertCurationSubmittedEventProperties> {
  static displayName = 'Expert Curation Submitted'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
