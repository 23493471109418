import React, { useContext, useState } from 'react'

type ConfettiContextType = {
  isActive: boolean
  setIsActive: (isActive: boolean) => void
}

// Context
const ConfettiContext = React.createContext<ConfettiContextType>({
  isActive: false,
  setIsActive: () => {}
})

// Consumer
const ConfettiConsumer = ConfettiContext.Consumer

// Provider
const ConfettiProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <ConfettiContext.Provider
      value={{
        isActive,
        setIsActive: (active: boolean) => setIsActive(active)
      }}
    >
      <>{children}</>
    </ConfettiContext.Provider>
  )
}

const useConfettiContext = () => {
  const context = useContext(ConfettiContext)

  if (!context) {
    throw new Error('Invoked ConfettiContext outside of provider')
  }

  return context
}

export { ConfettiConsumer, ConfettiProvider, ConfettiContext, useConfettiContext }
