import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCurationFlowExitedEventProperties {
  lead_id: string
  activity_id?: string
}

export class ExpertCurationFlowExitedEvent extends Event<ExpertCurationFlowExitedEventProperties> {
  static displayName = 'Expert Curation Flow Exited'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
