import React, { useState } from 'react'
import { Modal, Text, useModalTrigger } from '@deal/bluxome'
import { toastSuccess } from '#src/app/utilities/toast'
import SavedNameTextInput from '#src/app/components/Navigation/SavedNameTextInput'
import { ExpertTagFragment } from '#src/app/components/Navigation/ExpertTags/ExpertTags.generated'
import ExpertSavedActions from '#src/app/components/Navigation/ExpertSavedActions'
import { useDeleteExpertTagMutation, useUpdateExpertTagMutation } from './ManageTagsModal.generated'
import styles from './styles.css'

interface ManageTagsModalProps {
  savedTags?: (ExpertTagFragment | null)[]
  onClose: () => void
}

const ManageTagsModal: React.FC<ManageTagsModalProps> = ({ savedTags = [], onClose }) => {
  const [newTagName, setNewTagName] = useState<string>('')
  const [currentEditTagId, setCurrentEditTagId] = useState<string | null>(null)

  const { state, overlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        onClose()
      }
    },
    isOpen: true
  })

  const [updateExpertTag, { loading: updateLoading }] = useUpdateExpertTagMutation({
    onCompleted: () => {
      setNewTagName('')
      setCurrentEditTagId(null)
      toastSuccess('Tag updated')
    }
  })

  const [deleteExpertTag, { loading: deleteLoading }] = useDeleteExpertTagMutation({
    onCompleted: () => {
      toastSuccess('Tag deleted')
    }
  })

  const loading = updateLoading || deleteLoading

  return (
    <Modal
      state={state}
      overlayProps={overlayProps}
      title="Manage Tags"
      width="medium"
      actions={{
        primaryAction: {
          label: 'Confrim',
          onPress: onClose
        },
        secondaryAction: { label: 'Cancel', onPress: onClose },
        color: 'neutral-dark'
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Tag</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {savedTags.map(tag => {
            if (tag) {
              const isBeingEdited = tag.id === currentEditTagId
              return (
                <tr key={tag.id}>
                  <td>
                    {isBeingEdited ? (
                      <SavedNameTextInput
                        size="small"
                        value={newTagName}
                        onChange={value => setNewTagName(value)}
                        onEnterPressed={() => {
                          if (tag.name !== newTagName) {
                            updateExpertTag({
                              variables: {
                                input: {
                                  id: tag.id,
                                  name: newTagName
                                }
                              }
                            })
                          }
                        }}
                        isDisabled={loading}
                      />
                    ) : (
                      <Text as="div">{tag?.name}</Text>
                    )}
                  </td>
                  <td>
                    <ExpertSavedActions
                      onViewLeads={() => {
                        onClose()
                      }}
                      onEdit={() => {
                        setCurrentEditTagId(tag.id)
                        setNewTagName(tag.name)
                      }}
                      onDelete={() => {
                        deleteExpertTag({
                          variables: {
                            input: {
                              id: tag.id
                            }
                          }
                        })
                      }}
                      onSave={() => {
                        if (tag.name !== newTagName) {
                          updateExpertTag({
                            variables: {
                              input: {
                                id: tag.id,
                                name: newTagName
                              }
                            }
                          })
                        } else {
                          setNewTagName('')
                          setCurrentEditTagId(null)
                        }
                      }}
                      isDisabled={loading}
                      isBeingEdited={isBeingEdited}
                    />
                  </td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </Modal>
  )
}

export default ManageTagsModal
