// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription = { __typename: 'Subscription', expertLeadCampaignActionRequiredCountChanged: any };


export const ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertLeadCampaignActionRequiredCountChangedForNavigationFooter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadCampaignActionRequiredCountChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}]}]}}]} as unknown as DocumentNode;
export type ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription, ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionVariables>, 'subscription'>;

    export const ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterComponent = (props: ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription, ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionVariables> subscription={ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterDocument} {...props} />
    );
    

/**
 * __useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription__
 *
 * To run a query within a React component, call `useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription, ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription, ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionVariables>(ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterDocument, options);
      }
export type ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionHookResult = ReturnType<typeof useExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription>;
export type ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscriptionResult = Apollo.SubscriptionResult<ExpertLeadCampaignActionRequiredCountChangedForNavigationFooterSubscription>;