import { UserAgent } from '#src/app/context/UserAgent'

export function isSupportedBrowser(userAgent?: UserAgent) {
  if (!userAgent) return false
  /**
   * Allow any version of Webkit for iOS apps
   * Allow safari on iOS since Chrome uses safari engine on iOS
   */
  if (userAgent.raw.includes('DealWebView')) {
    return true
  } else if (userAgent.browser.name === 'WebKit') {
    return true
  } else if (isMobileiOS(userAgent) && userAgent.browser.name === 'Mobile Safari') {
    return true
  } else if (
    userAgent.browser.name === 'Chrome' &&
    !!userAgent.browser.version &&
    parseInt(userAgent.browser.version, 10) > 70
  ) {
    return true
  } else {
    return false
  }
}

/**
 * Helper method for determining if were inside a mobile browser, but not in a native wrapper
 */
export function isMobileBrowser(userAgent: UserAgent) {
  if (!userAgent) return false

  return userAgent.device.type === 'mobile' || userAgent.device.type === 'tablet'
}

/**
 * Helper method for determining if we're in mobile and using iOS
 */
export function isMobileiOS(userAgent: UserAgent) {
  if (!userAgent) return false

  if (!isMobileBrowser(userAgent)) {
    return false
  }

  return isiOS(userAgent)
}

export function isiOS(userAgent: UserAgent) {
  if (!userAgent) return false

  return userAgent.os.name === 'iOS'
}

/**
 * Helper method for determining if we're in mobile and in mobile iOS
 */
export function isChromeBrowser(userAgent: UserAgent) {
  if (!userAgent) return false

  return userAgent.browser.name === 'Chrome'
}
