import React, { createContext, useContext, useState } from 'react'

type MediaSearchControlContext = {
  isPopoverVisible: boolean
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const MediaSearchControlContext = createContext<MediaSearchControlContext>({
  isPopoverVisible: false,
  setIsPopoverVisible: () => {}
})

const MediaSearchContextControl = MediaSearchControlContext.Consumer

const MediaSearchControlContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  return (
    <MediaSearchControlContext.Provider
      value={{
        isPopoverVisible,
        setIsPopoverVisible
      }}
    >
      {children}
    </MediaSearchControlContext.Provider>
  )
}

const useMediaSearchControlContext = () => {
  const mediaSearchControlContext = useContext(MediaSearchControlContext)

  if (!mediaSearchControlContext) {
    throw new Error('Invoked "MediaSearchControlContext" outside of provider')
  }

  return mediaSearchControlContext
}

export {
  MediaSearchControlContext,
  MediaSearchContextControl,
  MediaSearchControlContextProvider,
  useMediaSearchControlContext
}
