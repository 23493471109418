import { History } from 'history'
import { insertSearchParamsById } from '#src/app/utilities/searchParams'

export default (history: History) => {
  return insertSearchParamsById({
    id: 'inbox',
    search: history.location.search,
    params: {
      dateRangeFilters: {
        SNOOZE_WAKEUP_DATE: {
          field: 'SNOOZE_WAKEUP_DATE',
          minDateTime: new Date(),
          maxDateTime: new Date(new Date().setHours(23, 59, 59, 999)),
          negativeFilter: false
        }
      },
      selections: {
        hasFollowupMessage: { name: 'hasFollowupMessage', bucketValues: ['YES'] }
      }
    }
  })
}
