import React, { useState } from 'react'
import { ToastId, toast } from 'react-toastify'
import { useHistory } from '@deal/router'
import { CallStatus, CallType } from '#src/generated/types'
import {
  CallActivityForNewExpertPhoneCallToastFragment,
  CallParticipantForNewExpertPhoneCallToastFragment
} from '#src/app/toasts/newExpertPhoneCallToast/CallActivityForNewExpertPhoneCallToast.generated'
import {
  newExpertPhoneCallToast,
  updateExpertPhoneCallToast
} from '#src/app/toasts/newExpertPhoneCallToast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import CloseIcon from '#src/app/assets/glyphs/close.svg'
import { useExpertCallActivityChangedForExpertPhoneCallsSubscription } from './ExpertCallActivityChangedForExpertPhoneCalls.generated'
import styles from './styles.css'

const getCallParticipantName = (
  callParticipant?: CallParticipantForNewExpertPhoneCallToastFragment | null
) => {
  if (!callParticipant) {
    return 'Unknown'
  }
  switch (callParticipant.__typename) {
    case 'BusinessUser':
      return callParticipant.businessUserFirstName

    case 'User':
      return callParticipant.userFirstName
  }
}

interface Props {}

type CallActivityIdToastIdMap = {
  [key: string]: { toastId?: ToastId; data: CallActivityForNewExpertPhoneCallToastFragment }
}

const ExpertPhoneCalls: React.FC<React.PropsWithChildren<Props>> = () => {
  const businessUser = useBusinessUser()
  const [activePhoneCalls, setActivePhoneCalls] = useState<CallActivityIdToastIdMap>({})

  const inProgressStates = [CallStatus.INITIATED, CallStatus.IN_PROGRESS, CallStatus.RINGING]
  const history = useHistory()
  const isLargerThanSmall = useBreakpoint('sm')

  useExpertCallActivityChangedForExpertPhoneCallsSubscription({
    variables: { expertId: businessUser.id },
    onData: ({ data: { data } }) => {
      if (data?.expertCallActivityChanged) {
        const { expertCallActivityChanged } = data
        const { id, status } = expertCallActivityChanged

        const isInProgress = inProgressStates.find(callStatus => callStatus === status)

        // If we currently have a toast or banner for the call activity
        if (activePhoneCalls[id]) {
          // If the phone call is in progress, update
          const toastId = activePhoneCalls[id].toastId
          if (isInProgress) {
            toastId && updateExpertPhoneCallToast(toastId, expertCallActivityChanged)
          }

          // Otherwise, close the toast and remove data from state
          toast.dismiss(toastId)
          const newActivePhoneCalls = { ...activePhoneCalls }
          delete newActivePhoneCalls[id]
          setActivePhoneCalls(newActivePhoneCalls)
        } else {
          if (isInProgress) {
            // On desktop we fire a toast
            if (isLargerThanSmall) {
              const toastId = newExpertPhoneCallToast(expertCallActivityChanged)
              setActivePhoneCalls({
                ...activePhoneCalls,
                ...{ [id]: { toastId, data: expertCallActivityChanged } }
              })
            } else {
              // On mobile, we just add and render the banner in the DOM
              setActivePhoneCalls({
                ...activePhoneCalls,
                ...{ [id]: { toastId: undefined, data: expertCallActivityChanged } }
              })
            }
          }
        }
      }
    }
  })

  if (!isLargerThanSmall) {
    return (
      <>
        {Object.values(activePhoneCalls).map(phoneCall => {
          const {
            data: { id, caller, callee, lead }
          } = phoneCall
          return (
            <div
              className={styles.mobileCall}
              onClick={() => lead && history.push(`/inbox/${lead.id}`)}
            >
              <div></div>
              <div className={styles.nameContainer}>
                Call with
                <div className={styles.name}>
                  {phoneCall.data.type === CallType.CONSUMER_TO_EXPERT
                    ? getCallParticipantName(caller)
                    : getCallParticipantName(callee)}
                </div>
              </div>
              <CloseIcon
                width={20}
                height={20}
                onClick={() => {
                  const newActivePhoneCalls = { ...activePhoneCalls }
                  delete newActivePhoneCalls[id]
                  setActivePhoneCalls(newActivePhoneCalls)
                }}
              />
            </div>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

export default ExpertPhoneCalls
