import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '@deal/components'
import RemoveIcon from './remove.svg'
import styles from './styles.css'

interface TagDisplayProps {
  content: string | React.ReactNode
  isActive?: boolean
  deprecated?: boolean
  onSelect?: () => void
  onRemove?: () => void
}

const TagDisplay: React.FC<React.PropsWithChildren<TagDisplayProps>> = ({
  content,
  isActive,
  deprecated,
  onSelect,
  onRemove
}) => {
  return (
    <Tooltip
      placement="auto"
      visible={deprecated ? undefined : false}
      message="This tag is no longer supported"
      theme="dark"
    >
      <div className={styles.suggestedMessageTag}>
        <div
          className={classnames(styles.content, {
            [styles.activeSuggestedMessageTag]: isActive,
            [styles.deprecated]: deprecated,
            [styles.contentWithRemove]: !!onRemove,
            [styles.clickable]: !!onSelect
          })}
          onClick={() => {
            onSelect?.()
          }}
        >
          {content}
        </div>
        {!!onRemove && (
          <div
            className={classnames(styles.remove, { [styles.deprecated]: deprecated })}
            onClick={onRemove}
          >
            <RemoveIcon />
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export default TagDisplay
