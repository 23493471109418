// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type QuickReplyVariableInfoPopoverQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QuickReplyVariableInfoPopoverQuery = { __typename: 'Query', quickReplyTemplateVariable?: { __typename: 'QuickReplyTemplateVariablePayload', variables?: Array<{ __typename: 'QuickReplyTemplateVariable', name: string, description: string, deprecated: boolean }> | null } | null };


export const QuickReplyVariableInfoPopoverDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuickReplyVariableInfoPopover"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quickReplyTemplateVariable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"}}]}}]}}]}}]} as unknown as DocumentNode;
export type QuickReplyVariableInfoPopoverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>, 'query'>;

    export const QuickReplyVariableInfoPopoverComponent = (props: QuickReplyVariableInfoPopoverComponentProps) => (
      <ApolloReactComponents.Query<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables> query={QuickReplyVariableInfoPopoverDocument} {...props} />
    );
    

/**
 * __useQuickReplyVariableInfoPopoverQuery__
 *
 * To run a query within a React component, call `useQuickReplyVariableInfoPopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickReplyVariableInfoPopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickReplyVariableInfoPopoverQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickReplyVariableInfoPopoverQuery(baseOptions?: Apollo.QueryHookOptions<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>(QuickReplyVariableInfoPopoverDocument, options);
      }
export function useQuickReplyVariableInfoPopoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>(QuickReplyVariableInfoPopoverDocument, options);
        }
export function useQuickReplyVariableInfoPopoverSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>(QuickReplyVariableInfoPopoverDocument, options);
        }
export type QuickReplyVariableInfoPopoverQueryHookResult = ReturnType<typeof useQuickReplyVariableInfoPopoverQuery>;
export type QuickReplyVariableInfoPopoverLazyQueryHookResult = ReturnType<typeof useQuickReplyVariableInfoPopoverLazyQuery>;
export type QuickReplyVariableInfoPopoverSuspenseQueryHookResult = ReturnType<typeof useQuickReplyVariableInfoPopoverSuspenseQuery>;
export type QuickReplyVariableInfoPopoverQueryResult = Apollo.QueryResult<QuickReplyVariableInfoPopoverQuery, QuickReplyVariableInfoPopoverQueryVariables>;