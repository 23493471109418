// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PredictNextMessageForLeadForSuggestedMessageControlSubscriptionVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type PredictNextMessageForLeadForSuggestedMessageControlSubscription = { __typename: 'Subscription', predictNextMessageForLead: { __typename: 'PredictNextMessageForLeadPayload', predictedMessage?: string | null, predictionForMessageId?: string | null, trackingId?: any | null } };

export type BusinessUserForSuggestedMessageControlFragment = { __typename: 'BusinessUser', id: any, optedIntoLLMMessageSuggestions: boolean };

export type UpdateBusinessUserForSuggestedMessageControlMutationVariables = Types.Exact<{
  input: Types.UpdateBusinessUserInput;
}>;


export type UpdateBusinessUserForSuggestedMessageControlMutation = { __typename: 'Mutation', updateBusinessUser: { __typename: 'UpdateBusinessUserPayload', businessUser?: { __typename: 'BusinessUser', id: any, optedIntoLLMMessageSuggestions: boolean } | null } };

export const BusinessUserForSuggestedMessageControlFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForSuggestedMessageControl"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"optedIntoLLMMessageSuggestions"}}]}}]} as unknown as DocumentNode;
export const PredictNextMessageForLeadForSuggestedMessageControlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"PredictNextMessageForLeadForSuggestedMessageControl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"predictNextMessageForLead"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"predictedMessage"}},{"kind":"Field","name":{"kind":"Name","value":"predictionForMessageId"}},{"kind":"Field","name":{"kind":"Name","value":"trackingId"}}]}}]}}]} as unknown as DocumentNode;
export type PredictNextMessageForLeadForSuggestedMessageControlComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<PredictNextMessageForLeadForSuggestedMessageControlSubscription, PredictNextMessageForLeadForSuggestedMessageControlSubscriptionVariables>, 'subscription'>;

    export const PredictNextMessageForLeadForSuggestedMessageControlComponent = (props: PredictNextMessageForLeadForSuggestedMessageControlComponentProps) => (
      <ApolloReactComponents.Subscription<PredictNextMessageForLeadForSuggestedMessageControlSubscription, PredictNextMessageForLeadForSuggestedMessageControlSubscriptionVariables> subscription={PredictNextMessageForLeadForSuggestedMessageControlDocument} {...props} />
    );
    

/**
 * __usePredictNextMessageForLeadForSuggestedMessageControlSubscription__
 *
 * To run a query within a React component, call `usePredictNextMessageForLeadForSuggestedMessageControlSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePredictNextMessageForLeadForSuggestedMessageControlSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredictNextMessageForLeadForSuggestedMessageControlSubscription({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function usePredictNextMessageForLeadForSuggestedMessageControlSubscription(baseOptions: Apollo.SubscriptionHookOptions<PredictNextMessageForLeadForSuggestedMessageControlSubscription, PredictNextMessageForLeadForSuggestedMessageControlSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PredictNextMessageForLeadForSuggestedMessageControlSubscription, PredictNextMessageForLeadForSuggestedMessageControlSubscriptionVariables>(PredictNextMessageForLeadForSuggestedMessageControlDocument, options);
      }
export type PredictNextMessageForLeadForSuggestedMessageControlSubscriptionHookResult = ReturnType<typeof usePredictNextMessageForLeadForSuggestedMessageControlSubscription>;
export type PredictNextMessageForLeadForSuggestedMessageControlSubscriptionResult = Apollo.SubscriptionResult<PredictNextMessageForLeadForSuggestedMessageControlSubscription>;
export const UpdateBusinessUserForSuggestedMessageControlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBusinessUserForSuggestedMessageControl"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBusinessUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBusinessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForSuggestedMessageControl"}}]}}]}}]}},...BusinessUserForSuggestedMessageControlFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateBusinessUserForSuggestedMessageControlMutationFn = Apollo.MutationFunction<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables>;
export type UpdateBusinessUserForSuggestedMessageControlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables>, 'mutation'>;

    export const UpdateBusinessUserForSuggestedMessageControlComponent = (props: UpdateBusinessUserForSuggestedMessageControlComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables> mutation={UpdateBusinessUserForSuggestedMessageControlDocument} {...props} />
    );
    

/**
 * __useUpdateBusinessUserForSuggestedMessageControlMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserForSuggestedMessageControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserForSuggestedMessageControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserForSuggestedMessageControlMutation, { data, loading, error }] = useUpdateBusinessUserForSuggestedMessageControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserForSuggestedMessageControlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables>(UpdateBusinessUserForSuggestedMessageControlDocument, options);
      }
export type UpdateBusinessUserForSuggestedMessageControlMutationHookResult = ReturnType<typeof useUpdateBusinessUserForSuggestedMessageControlMutation>;
export type UpdateBusinessUserForSuggestedMessageControlMutationResult = Apollo.MutationResult<UpdateBusinessUserForSuggestedMessageControlMutation>;
export type UpdateBusinessUserForSuggestedMessageControlMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserForSuggestedMessageControlMutation, UpdateBusinessUserForSuggestedMessageControlMutationVariables>;