import React from 'react'
import Check from './check-small.svg'
import styles from './styles.css'

const CheckColumn: React.FC<React.PropsWithChildren<{ checked: boolean }>> = ({ checked }) => {
  return checked ? (
    <div className={styles.checkContainer}>
      <Check className={styles.check} width={9} />
    </div>
  ) : (
    <div className={styles.checkContainer}>&nbsp;</div>
  )
}

export default CheckColumn
