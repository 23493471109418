import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertCurationReviewPageViewedEventProperties {
  lead_id: string
  activity_id?: string
}

export class ExpertCurationReviewPageViewedEvent extends Event<
  ExpertCurationReviewPageViewedEventProperties
> {
  static displayName = 'Expert Curation Review Page Viewed'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
