import React from 'react'
import { AttributeType } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import SurveyPathModal from '#src/app/components/SurveyPathModal'
import { useUpdateBusinessUserForExpertSurveyModalMutation } from './UpdateBusinessUserForExpertSurveyModal.generated'

interface Props {
  onCloseModal: () => void
  isOpen: boolean
  shiftId?: string
}

const EndOfShiftSurveyPathModal: React.FC<React.PropsWithChildren<Props>> = ({ onCloseModal, isOpen, shiftId }) => {
  const businessUser = useBusinessUser()

  const [updateBusinessUser] = useUpdateBusinessUserForExpertSurveyModalMutation()

  return (
    <SurveyPathModal
      onCloseRequested={onCloseModal}
      isOpen={isOpen}
      sourceKey="end-of-shift-survey"
      ctaName="end-of-shift"
      pathSchemaSlug="s-expert-nps"
      initialAttributes={
        shiftId
          ? [
              {
                name: 'expertShiftId',
                type: AttributeType.ID,
                idValue: shiftId
              }
            ]
          : undefined
      }
      onSurveyResponseFinished={() => {
        updateBusinessUser({
          variables: {
            input: {
              id: businessUser.id,
              expertAttributes: { endOfShiftSurveyTakenAt: new Date().toISOString() }
            }
          }
        })
      }}
    />
  )
}

export default EndOfShiftSurveyPathModal
