import React from 'react'
import classnames from 'classnames'
import { Button } from '@deal/components'
import { ExpertKnowledgeTestResult } from '../../StandalonePath'
import styles from './styles.css'

interface Props {
  onBackRequested?: () => void
  onRetakeRequested?: () => void
  onCloseRequested: () => void
  result: ExpertKnowledgeTestResult
}

const ExpertKnowledgeTestResultDisplay: React.FC<React.PropsWithChildren<Props>> = ({
  onBackRequested,
  onRetakeRequested,
  onCloseRequested,
  result
}) => {
  const { passed, passingPercentage, scorePercentage } = result

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.result, {
          [styles.pass]: passed,
          [styles.fail]: !passed
        })}
      >
        <div className={styles.score}>{scorePercentage}%</div>
        <div className={styles.outcome}>Quiz {passed ? 'passed' : 'failed'}</div>
      </div>

      <div className={styles.title}>You need {passingPercentage}% to pass</div>
      {!passed && <div className={styles.subTitle}>Try again by clicking the button below</div>}

      {passed ? (
        <Button size="small" variant="secondary" onClick={onCloseRequested}>
          Continue
        </Button>
      ) : (
        <div className={styles.footer}>
          {onBackRequested && (
            <Button size="small" variant="secondary-ghost" onClick={onBackRequested}>
              Back to video
            </Button>
          )}
          {onRetakeRequested && (
            <Button size="small" variant="secondary" onClick={onRetakeRequested}>
              Re-take quiz
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default ExpertKnowledgeTestResultDisplay
