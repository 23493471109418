import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Popover, PopoverProps } from '@deal/components'
import {
  Attachment,
  ComposerControlProps,
  Control,
  ImageUploadPreview,
  withMediaUploadControlBase,
  withMediaUpload
} from '@deal/chat-firebase'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { ExpertMediaSearchPanelViewedEvent } from '#src/app/events/ExpertMediaSearchPanelViewedEvent'
import { GlobalView, useGlobalViewsContext } from '#src/app/context/GlobalViews'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import { useMediaSearchControlContext } from './context'
import VideoPanelModule from './VideoPanel'
import RecentsPanel from './RecentsPanel'
import GoogleSearchPanel from './GooglePanel'
import GifUploadPanel from './GifUploadPanel'
import CatalogPanel from './CatalogPanel'
import UploadIcon from './upload.svg'
import styles from './styles.css'

type FileAttachment = Attachment<{ file: File | MediaFile }>

type MediaFile = {
  originalFileName?: string | null
  mimeType: string
  url: string
  id: string
}

type GifAttachment = Attachment<{ url: string }>

const panels = [RecentsPanel, GifUploadPanel, VideoPanelModule, GoogleSearchPanel, CatalogPanel]

export interface MediaSearchControlProps {
  leadId?: string
  appendTo?: PopoverProps['appendTo']
  wrapperClassname?: PopoverProps['wrapperClassName']
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const MediaSearchControl: React.FC<MediaSearchControlProps> = props => {
  const { isViewActive } = useGlobalViewsContext()
  const { isPopoverVisible, setIsPopoverVisible } = useMediaSearchControlContext()
  const imageModalIsOpen = isViewActive(GlobalView.ImageModal)

  return (
    <Popover
      contentLabel="Media Panel"
      visible={isPopoverVisible}
      contentClassName={styles.popoverContent}
      // If image modal is open we want to prevent this popover from closing otherwise the image modal will also close
      onRequestClose={() => !imageModalIsOpen && setIsPopoverVisible(false)}
      content={isPopoverVisible ? <PanelDisplayContainer {...props} /> : null}
      wrapperClassName={props.wrapperClassname}
      appendTo={props.appendTo || undefined}
      elevation="high"
    >
      <Control title="Add Media" onClick={() => setIsPopoverVisible(!isPopoverVisible)}>
        <UploadIcon />
      </Control>
    </Popover>
  )
}

export interface PanelDisplayProps {
  leadId?: string
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

export type Panel = {
  title: string
  Display: React.ReactNode
}

const PanelDisplayContainer: React.FC<PanelDisplayProps> = props => {
  const { leadId } = props
  const { id: expertId } = useBusinessUser()
  const analytics = useAnalyticsContext()
  const [activeDisplayIndex, setActiveDisplayIndex] = useState(0)

  // Only show video panel if leadId is present - use useMemo to prevent re-rendering
  const memoizedPanels = useMemo(() => {
    const panelsToShow = [...panels]
    if (!leadId) {
      panelsToShow.splice(2, 1)
    }
    return panelsToShow
  }, [leadId])

  const panelTitles = memoizedPanels.map(panel => panel.title)
  const ActivePanel = memoizedPanels[activeDisplayIndex].Display

  useEffect(() => {
    analytics?.track(
      new ExpertMediaSearchPanelViewedEvent({
        expert_id: expertId,
        panel_viewed: panelTitles[activeDisplayIndex]
      })
    )
  }, [activeDisplayIndex])

  return (
    <div className={styles.panelDisplayContainer}>
      <div className={styles.panelDisplayTitle}>Add rich media</div>
      <div className={styles.panelTitles}>
        {panelTitles.map((title, idx) => {
          return (
            <div
              key={title + idx}
              className={classnames(styles.panelTitle, {
                [styles.activePanelTitle]: idx === activeDisplayIndex
              })}
              onClick={() => setActiveDisplayIndex(idx)}
            >
              {title}
            </div>
          )
        })}
      </div>
      <div>
        <ActivePanel {...props} />
      </div>
    </div>
  )
}

const WrappedMediaSearchControl = withMediaUpload(withMediaUploadControlBase(MediaSearchControl))

interface WrapperProps extends ComposerControlProps<FileAttachment | GifAttachment> {
  leadId?: string
  appendTo?: PopoverProps['appendTo']
  wrapperClassname?: PopoverProps['wrapperClassName']
}

const MediaSearchComposerControl: React.FC<WrapperProps> = props => {
  return (
    <WrappedMediaSearchControl
      previews={[ImageUploadPreview]}
      wrapperClassname={styles.wrapper}
      {...props}
    />
  )
}

export default MediaSearchControl
export { MediaSearchComposerControl }
