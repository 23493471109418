// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SavedSearchFragment = { __typename: 'SavedSearch', id: any, title: string, count: number, entityName: string };

export type SavedSearchesQueryVariables = Types.Exact<{
  groupName: Types.Scalars['String']['input'];
  entityName: Types.Scalars['String']['input'];
}>;


export type SavedSearchesQuery = { __typename: 'Query', savedSearches: Array<{ __typename: 'SavedSearch', id: any, title: string, count: number, entityName: string }> };

export const SavedSearchFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SavedSearch"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SavedSearch"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"entityName"}}]}}]} as unknown as DocumentNode;
export const SavedSearchesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SavedSearches"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"groupName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"entityName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"savedSearches"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"groupName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"groupName"}}},{"kind":"Argument","name":{"kind":"Name","value":"entityName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"entityName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SavedSearch"}}]}}]}},...SavedSearchFragmentDoc.definitions]} as unknown as DocumentNode;
export type SavedSearchesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SavedSearchesQuery, SavedSearchesQueryVariables>, 'query'> & ({ variables: SavedSearchesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SavedSearchesComponent = (props: SavedSearchesComponentProps) => (
      <ApolloReactComponents.Query<SavedSearchesQuery, SavedSearchesQueryVariables> query={SavedSearchesDocument} {...props} />
    );
    

/**
 * __useSavedSearchesQuery__
 *
 * To run a query within a React component, call `useSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSearchesQuery({
 *   variables: {
 *      groupName: // value for 'groupName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useSavedSearchesQuery(baseOptions: Apollo.QueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SavedSearchesQuery, SavedSearchesQueryVariables>(SavedSearchesDocument, options);
      }
export function useSavedSearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SavedSearchesQuery, SavedSearchesQueryVariables>(SavedSearchesDocument, options);
        }
export function useSavedSearchesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SavedSearchesQuery, SavedSearchesQueryVariables>(SavedSearchesDocument, options);
        }
export type SavedSearchesQueryHookResult = ReturnType<typeof useSavedSearchesQuery>;
export type SavedSearchesLazyQueryHookResult = ReturnType<typeof useSavedSearchesLazyQuery>;
export type SavedSearchesSuspenseQueryHookResult = ReturnType<typeof useSavedSearchesSuspenseQuery>;
export type SavedSearchesQueryResult = Apollo.QueryResult<SavedSearchesQuery, SavedSearchesQueryVariables>;