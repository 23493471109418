// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { JiraTicketForTicketSummaryFragmentDoc } from '../../routes/help-center/SupportPage/TicketSummary/TicketSummary.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type KnowledgeCardForHelpCenterNewReleasesFragment = { __typename: 'KnowledgeCard', id: string, createdAt: any };

export type BusinessUserForHelpCenterNewReleasesFragment = { __typename: 'BusinessUser', id: any, jiraTicketsUnread: number };

export type SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables = Types.Exact<{
  departmentId: Types.Scalars['DepartmentId']['input'];
  createdAfter?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SearchKnowledgeCardsForHelpCenterNewReleasesQuery = { __typename: 'Query', searchKnowledgeCards: Array<{ __typename: 'KnowledgeCard', id: string, createdAt: any }> };

export type UpdateKnowledgeUserForHelpCenterMutationVariables = Types.Exact<{
  input: Types.UpdateKnowledgeUserInput;
}>;


export type UpdateKnowledgeUserForHelpCenterMutation = { __typename: 'Mutation', updateKnowledgeUser?: { __typename: 'UpdateKnowledgeUserPayload', error?: string | null, knowledgeManagementUser?: { __typename: 'KnowledgeUser', id: any, lastReleaseCardViewedAt?: any | null } | null } | null };

export type JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription = { __typename: 'Subscription', jiraTicketChangedForExpert: { __typename: 'JiraTicket', id: any, key: string, summary: string, status: Types.JiraTicketStatus, createdAt: any, unreadCount: number, updatedAt: any, expert: { __typename: 'BusinessUser', id: any, jiraTicketsUnread: number } } };

export const KnowledgeCardForHelpCenterNewReleasesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"KnowledgeCardForHelpCenterNewReleases"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"KnowledgeCard"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForHelpCenterNewReleasesFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForHelpCenterNewReleases"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"jiraTicketsUnread"}}]}}]} as unknown as DocumentNode;
export const SearchKnowledgeCardsForHelpCenterNewReleasesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchKnowledgeCardsForHelpCenterNewReleases"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"departmentId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DepartmentId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"createdAfter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Date"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"defaultValue":{"kind":"IntValue","value":"10"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchKnowledgeCards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"departmentId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"departmentId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"newReleasesOnly"},"value":{"kind":"BooleanValue","value":true}},{"kind":"ObjectField","name":{"kind":"Name","value":"createdAfter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"createdAfter"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"KnowledgeCardForHelpCenterNewReleases"}}]}}]}},...KnowledgeCardForHelpCenterNewReleasesFragmentDoc.definitions]} as unknown as DocumentNode;
export type SearchKnowledgeCardsForHelpCenterNewReleasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>, 'query'> & ({ variables: SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchKnowledgeCardsForHelpCenterNewReleasesComponent = (props: SearchKnowledgeCardsForHelpCenterNewReleasesComponentProps) => (
      <ApolloReactComponents.Query<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables> query={SearchKnowledgeCardsForHelpCenterNewReleasesDocument} {...props} />
    );
    

/**
 * __useSearchKnowledgeCardsForHelpCenterNewReleasesQuery__
 *
 * To run a query within a React component, call `useSearchKnowledgeCardsForHelpCenterNewReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchKnowledgeCardsForHelpCenterNewReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchKnowledgeCardsForHelpCenterNewReleasesQuery({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      createdAfter: // value for 'createdAfter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchKnowledgeCardsForHelpCenterNewReleasesQuery(baseOptions: Apollo.QueryHookOptions<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>(SearchKnowledgeCardsForHelpCenterNewReleasesDocument, options);
      }
export function useSearchKnowledgeCardsForHelpCenterNewReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>(SearchKnowledgeCardsForHelpCenterNewReleasesDocument, options);
        }
export function useSearchKnowledgeCardsForHelpCenterNewReleasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>(SearchKnowledgeCardsForHelpCenterNewReleasesDocument, options);
        }
export type SearchKnowledgeCardsForHelpCenterNewReleasesQueryHookResult = ReturnType<typeof useSearchKnowledgeCardsForHelpCenterNewReleasesQuery>;
export type SearchKnowledgeCardsForHelpCenterNewReleasesLazyQueryHookResult = ReturnType<typeof useSearchKnowledgeCardsForHelpCenterNewReleasesLazyQuery>;
export type SearchKnowledgeCardsForHelpCenterNewReleasesSuspenseQueryHookResult = ReturnType<typeof useSearchKnowledgeCardsForHelpCenterNewReleasesSuspenseQuery>;
export type SearchKnowledgeCardsForHelpCenterNewReleasesQueryResult = Apollo.QueryResult<SearchKnowledgeCardsForHelpCenterNewReleasesQuery, SearchKnowledgeCardsForHelpCenterNewReleasesQueryVariables>;
export const UpdateKnowledgeUserForHelpCenterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateKnowledgeUserForHelpCenter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateKnowledgeUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateKnowledgeUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"knowledgeManagementUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastReleaseCardViewedAt"}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateKnowledgeUserForHelpCenterMutationFn = Apollo.MutationFunction<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables>;
export type UpdateKnowledgeUserForHelpCenterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables>, 'mutation'>;

    export const UpdateKnowledgeUserForHelpCenterComponent = (props: UpdateKnowledgeUserForHelpCenterComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables> mutation={UpdateKnowledgeUserForHelpCenterDocument} {...props} />
    );
    

/**
 * __useUpdateKnowledgeUserForHelpCenterMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeUserForHelpCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeUserForHelpCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeUserForHelpCenterMutation, { data, loading, error }] = useUpdateKnowledgeUserForHelpCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKnowledgeUserForHelpCenterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables>(UpdateKnowledgeUserForHelpCenterDocument, options);
      }
export type UpdateKnowledgeUserForHelpCenterMutationHookResult = ReturnType<typeof useUpdateKnowledgeUserForHelpCenterMutation>;
export type UpdateKnowledgeUserForHelpCenterMutationResult = Apollo.MutationResult<UpdateKnowledgeUserForHelpCenterMutation>;
export type UpdateKnowledgeUserForHelpCenterMutationOptions = Apollo.BaseMutationOptions<UpdateKnowledgeUserForHelpCenterMutation, UpdateKnowledgeUserForHelpCenterMutationVariables>;
export const JiraTicketChangedForExpertForHelpCenterNewReleasesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"JiraTicketChangedForExpertForHelpCenterNewReleases"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jiraTicketChangedForExpert"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expert"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForHelpCenterNewReleases"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"JiraTicketForTicketSummary"}}]}}]}},...BusinessUserForHelpCenterNewReleasesFragmentDoc.definitions,...JiraTicketForTicketSummaryFragmentDoc.definitions]} as unknown as DocumentNode;
export type JiraTicketChangedForExpertForHelpCenterNewReleasesComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription, JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionVariables>, 'subscription'>;

    export const JiraTicketChangedForExpertForHelpCenterNewReleasesComponent = (props: JiraTicketChangedForExpertForHelpCenterNewReleasesComponentProps) => (
      <ApolloReactComponents.Subscription<JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription, JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionVariables> subscription={JiraTicketChangedForExpertForHelpCenterNewReleasesDocument} {...props} />
    );
    

/**
 * __useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription__
 *
 * To run a query within a React component, call `useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription(baseOptions: Apollo.SubscriptionHookOptions<JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription, JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription, JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionVariables>(JiraTicketChangedForExpertForHelpCenterNewReleasesDocument, options);
      }
export type JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionHookResult = ReturnType<typeof useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription>;
export type JiraTicketChangedForExpertForHelpCenterNewReleasesSubscriptionResult = Apollo.SubscriptionResult<JiraTicketChangedForExpertForHelpCenterNewReleasesSubscription>;