import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@deal/router'
import { Button, Popover } from '@deal/components'
import { ExpertPresenceStatus } from '#src/generated/types'
import loggerClient from '#src/app/services/loggerClient'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { ExpertAttemptedToClaimLeadEscalationEvent } from '#src/app/events/ExpertAttemptedToClaimLeadEscalationEvent'
import { InboxSnapshotContext } from '#src/app/context/InboxSnapshot'
import { AnalyticsContext } from '#src/app/containers/Analytics'
import NavigationText from '../NavigationText'
import { useOpenLeadEscalationCountChangedSubscription } from '../openLeadEscalationCountChanged.generated'
import { useFindAndAcceptOpenLeadEscalationsMutation } from '../findAndAcceptOpenLeadEscalation.generated'
import { useExpertBroadcastLeadEligibilityChangedSubscription } from '../ExpertBroadcastLeadEligibilityChanged.generated'
import { useCountOpenLeadEscalationsQuery } from '../countOpenLeadEscalation.generated'
import styles from './styles.css'

const BroadcastLeadFolder: React.FC = () => {
  const [error, setError] = useState<null | string>(null)
  const [escalationCount, setEscalationCount] = useState(0)
  const analytics = useContext(AnalyticsContext)
  const location = useLocation<{ isPushNotificationSession: false }>()
  const businessUser = useBusinessUser()
  const { showClaimLeadFromPoolConfirmation, setShowClaimLeadFromPoolConfirmation } = useContext(
    InboxSnapshotContext
  )

  const { data: broadcastLeadsEligibility } = useExpertBroadcastLeadEligibilityChangedSubscription({
    variables: {
      expertId: businessUser.id
    }
  })

  const trackExpertAttemptedToClaimLeadEscalation = (claimedLead: boolean) => {
    analytics?.track(
      new ExpertAttemptedToClaimLeadEscalationEvent({
        on_shift: businessUser.expertPresence.status === ExpertPresenceStatus.ON_SHIFT,
        // This state has possibly not been initialized
        push_notification: location.state?.isPushNotificationSession || false,
        claimed_lead: claimedLead
      })
    )
  }

  const { refetch: refetchCountOpenLeadEscalations } = useCountOpenLeadEscalationsQuery({
    variables: { expertId: businessUser.id },
    onCompleted: ({ countOpenLeadEscalations }) => {
      setEscalationCount(countOpenLeadEscalations)
    }
  })

  const [
    findAndAcceptOpenLeadEscalations,
    { loading }
  ] = useFindAndAcceptOpenLeadEscalationsMutation({
    variables: {
      input: { maxCount: 1, expertId: businessUser.id }
    },
    onCompleted: ({ findAndAcceptOpenLeadEscalations: { errorMessage, escalations } }) => {
      setShowClaimLeadFromPoolConfirmation(false)
      if (errorMessage) {
        setError(errorMessage)
        loggerClient.captureError(new Error(errorMessage))
        trackExpertAttemptedToClaimLeadEscalation(false)
      } else if (escalations.length > 0) {
        trackExpertAttemptedToClaimLeadEscalation(true)
        const escalation = escalations[0]
        toast.success(
          escalation.lead.neverReplied
            ? 'Lead added to No reply folder. Sent an auto-message'
            : 'Lead added to Active folder'
        )
      } else {
        // If there are no escalations to claim, force a recount
        refetchCountOpenLeadEscalations()
        trackExpertAttemptedToClaimLeadEscalation(false)
      }
    },
    onError: ({ name, message }) => {
      setError('Network error: could not complete request. Please try again.')
      loggerClient.captureError(new Error(`${name}: ${message}`))

      setShowClaimLeadFromPoolConfirmation(false)
      trackExpertAttemptedToClaimLeadEscalation(false)
    }
  })

  useOpenLeadEscalationCountChangedSubscription({
    variables: { expertId: businessUser.id },
    onData: ({ data: { data } }) => {
      if (data?.openLeadEscalationCountChanged) {
        setEscalationCount(data?.openLeadEscalationCountChanged.count)
      }
    }
  })

  // if the expert is not eligible to claim leads, don't show the folder to avoid confusion
  if (
    !broadcastLeadsEligibility ||
    !broadcastLeadsEligibility.expertBroadcastLeadEligibilityChanged.eligibility.eligible
  ) {
    return null
  }

  const hoverCardVisible = showClaimLeadFromPoolConfirmation || !!error

  return (
    <>
      <div className={styles.container}>
        {escalationCount > 0 ? (
          <>
            <div className={styles.topLine}>
              <NavigationText>Leads in pool</NavigationText>
              <div className={styles.count}>{escalationCount}</div>
            </div>
            <div className={styles.lowerContainer}>
              <Popover
                contentLabel="Claim Lead"
                placement="right"
                variant="dark"
                visible={hoverCardVisible}
                interactive={true}
                onRequestClose={() => {
                  setShowClaimLeadFromPoolConfirmation(false)
                  setError(null)
                }}
                content={
                  <div className={styles.content}>
                    {showClaimLeadFromPoolConfirmation ? (
                      <>
                        <div className={styles.hoverCardTitle}>
                          {escalationCount} broadcast leads
                        </div>
                        <div className={styles.hoverCardContent}>
                          Confirm you want to claim a lead
                        </div>
                        <Button
                          size="xsmall"
                          variant="secondary-ghost"
                          disabled={loading}
                          onClick={() => findAndAcceptOpenLeadEscalations()}
                          className={styles.contentButton}
                        >
                          Yes, I want a new lead!
                        </Button>
                      </>
                    ) : (
                      <>
                        <div className={styles.hoverCardTitle}>Blocked: cannot claim lead</div>
                        <div className={styles.hoverCardContent}>{error}</div>
                      </>
                    )}
                  </div>
                }
              >
                <Button
                  size="xsmall"
                  variant="secondary"
                  disabled={hoverCardVisible || !businessUser.canAcceptBroadcastLeads}
                  onClick={() => setShowClaimLeadFromPoolConfirmation(true)}
                  className={styles.claimButton}
                >
                  Claim 1 lead
                </Button>
              </Popover>
            </div>
          </>
        ) : (
          <NavigationText>No broadcast leads available</NavigationText>
        )}
      </div>
    </>
  )
}

export default BroadcastLeadFolder
