import React from 'react'
import { Link } from '@deal/router'
import { Button } from '@deal/components'
import { ExpertProfileState } from '#src/generated/types'
import { isImpersonating } from '#src/app/utilities/identity'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { useIdentityContext } from '#src/app/containers/Identity'
import NavigationHeader from '../NavigationHeader'
import AccountSelector from '../AccountSelector'
import StopImpersonationLink from '../../StopImpersonationLink'
import styles from './styles.css'

interface Props {}

const ExpertApplicantNavigation: React.FC<React.PropsWithChildren<Props>> = () => {
  const businessUser = useBusinessUser()
  const isLargerThanMd = useBreakpoint('md')
  const { myself } = useIdentityContext()

  const profileIsDraft = businessUser.expertProfile?.state === ExpertProfileState.DRAFT

  return (
    <>
      {!isLargerThanMd && <AccountSelector />}
      <NavigationHeader />
      <div className={styles.container}>
        <Link to={profileIsDraft ? '/profile/setup/introduction' : '/profile/setup/edit'}>
          <Button size="small">{profileIsDraft ? 'Create profile' : 'Edit Profile'}</Button>
        </Link>
      </div>
      {isImpersonating(myself) && <StopImpersonationLink />}
    </>
  )
}

export default ExpertApplicantNavigation
