// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadSearchConnectionForAwayAutoReplyFragment = { __typename: 'LeadSearchConnection', count?: number | null };

export type AwayAutoReplyFragment = { __typename: 'AwayAutoReply', id: any, status: Types.AwayAutoReplyStatus, effectiveFrom: any, effectiveUntil: any, message: string, isExtendedAway?: boolean | null };

export type ActiveOrPendingAwayAutoReplyQueryVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ActiveOrPendingAwayAutoReplyQuery = { __typename: 'Query', businessUser: { __typename: 'BusinessUser', id: any, activeOrPendingAwayAutoReply?: { __typename: 'AwayAutoReply', id: any, status: Types.AwayAutoReplyStatus, effectiveFrom: any, effectiveUntil: any, message: string, isExtendedAway?: boolean | null } | null } };

export type LeadsForAwayAutoReplyQueryVariables = Types.Exact<{
  range?: Types.InputMaybe<Array<Types.LeadDateRangeFilterInput> | Types.LeadDateRangeFilterInput>;
  expertId?: Types.InputMaybe<Types.Scalars['BusinessUserId']['input']>;
}>;


export type LeadsForAwayAutoReplyQuery = { __typename: 'Query', leads: { __typename: 'LeadSearchConnection', count?: number | null } };

export type SetExpertAwayAutoReplyMutationVariables = Types.Exact<{
  input: Types.SetExpertAwayAutoReplyInput;
}>;


export type SetExpertAwayAutoReplyMutation = { __typename: 'Mutation', setExpertAwayAutoReply: { __typename: 'SetExpertAwayAutoReplyPayload', error?: string | null, businessUser?: { __typename: 'BusinessUser', id: any, activeOrPendingAwayAutoReply?: { __typename: 'AwayAutoReply', id: any, status: Types.AwayAutoReplyStatus, effectiveFrom: any, effectiveUntil: any, message: string, isExtendedAway?: boolean | null } | null } | null } };

export type RemoveExpertAwayAutoReplyMutationVariables = Types.Exact<{
  input: Types.RemoveExpertAwayAutoReplyInput;
}>;


export type RemoveExpertAwayAutoReplyMutation = { __typename: 'Mutation', removeExpertAwayAutoReply: { __typename: 'RemoveExpertAwayAutoReplyPayload', error?: string | null, businessUser?: { __typename: 'BusinessUser', id: any, activeOrPendingAwayAutoReply?: { __typename: 'AwayAutoReply', id: any, status: Types.AwayAutoReplyStatus, effectiveFrom: any, effectiveUntil: any, message: string, isExtendedAway?: boolean | null } | null } | null } };

export const LeadSearchConnectionForAwayAutoReplyFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadSearchConnectionForAwayAutoReply"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LeadSearchConnection"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]} as unknown as DocumentNode;
export const AwayAutoReplyFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AwayAutoReply"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AwayAutoReply"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"effectiveFrom"}},{"kind":"Field","name":{"kind":"Name","value":"effectiveUntil"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"isExtendedAway"}}]}}]} as unknown as DocumentNode;
export const ActiveOrPendingAwayAutoReplyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ActiveOrPendingAwayAutoReply"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"activeOrPendingAwayAutoReply"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AwayAutoReply"}}]}}]}}]}},...AwayAutoReplyFragmentDoc.definitions]} as unknown as DocumentNode;
export type ActiveOrPendingAwayAutoReplyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>, 'query'> & ({ variables: ActiveOrPendingAwayAutoReplyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ActiveOrPendingAwayAutoReplyComponent = (props: ActiveOrPendingAwayAutoReplyComponentProps) => (
      <ApolloReactComponents.Query<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables> query={ActiveOrPendingAwayAutoReplyDocument} {...props} />
    );
    

/**
 * __useActiveOrPendingAwayAutoReplyQuery__
 *
 * To run a query within a React component, call `useActiveOrPendingAwayAutoReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveOrPendingAwayAutoReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveOrPendingAwayAutoReplyQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useActiveOrPendingAwayAutoReplyQuery(baseOptions: Apollo.QueryHookOptions<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>(ActiveOrPendingAwayAutoReplyDocument, options);
      }
export function useActiveOrPendingAwayAutoReplyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>(ActiveOrPendingAwayAutoReplyDocument, options);
        }
export function useActiveOrPendingAwayAutoReplySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>(ActiveOrPendingAwayAutoReplyDocument, options);
        }
export type ActiveOrPendingAwayAutoReplyQueryHookResult = ReturnType<typeof useActiveOrPendingAwayAutoReplyQuery>;
export type ActiveOrPendingAwayAutoReplyLazyQueryHookResult = ReturnType<typeof useActiveOrPendingAwayAutoReplyLazyQuery>;
export type ActiveOrPendingAwayAutoReplySuspenseQueryHookResult = ReturnType<typeof useActiveOrPendingAwayAutoReplySuspenseQuery>;
export type ActiveOrPendingAwayAutoReplyQueryResult = Apollo.QueryResult<ActiveOrPendingAwayAutoReplyQuery, ActiveOrPendingAwayAutoReplyQueryVariables>;
export const LeadsForAwayAutoReplyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LeadsForAwayAutoReply"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"range"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadDateRangeFilterInput"}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"facetedSearch"},"value":{"kind":"BooleanValue","value":false}},{"kind":"ObjectField","name":{"kind":"Name","value":"dateRangeFilters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"range"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadSearchConnectionForAwayAutoReply"}}]}}]}},...LeadSearchConnectionForAwayAutoReplyFragmentDoc.definitions]} as unknown as DocumentNode;
export type LeadsForAwayAutoReplyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>, 'query'>;

    export const LeadsForAwayAutoReplyComponent = (props: LeadsForAwayAutoReplyComponentProps) => (
      <ApolloReactComponents.Query<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables> query={LeadsForAwayAutoReplyDocument} {...props} />
    );
    

/**
 * __useLeadsForAwayAutoReplyQuery__
 *
 * To run a query within a React component, call `useLeadsForAwayAutoReplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsForAwayAutoReplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsForAwayAutoReplyQuery({
 *   variables: {
 *      range: // value for 'range'
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useLeadsForAwayAutoReplyQuery(baseOptions?: Apollo.QueryHookOptions<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>(LeadsForAwayAutoReplyDocument, options);
      }
export function useLeadsForAwayAutoReplyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>(LeadsForAwayAutoReplyDocument, options);
        }
export function useLeadsForAwayAutoReplySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>(LeadsForAwayAutoReplyDocument, options);
        }
export type LeadsForAwayAutoReplyQueryHookResult = ReturnType<typeof useLeadsForAwayAutoReplyQuery>;
export type LeadsForAwayAutoReplyLazyQueryHookResult = ReturnType<typeof useLeadsForAwayAutoReplyLazyQuery>;
export type LeadsForAwayAutoReplySuspenseQueryHookResult = ReturnType<typeof useLeadsForAwayAutoReplySuspenseQuery>;
export type LeadsForAwayAutoReplyQueryResult = Apollo.QueryResult<LeadsForAwayAutoReplyQuery, LeadsForAwayAutoReplyQueryVariables>;
export const SetExpertAwayAutoReplyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetExpertAwayAutoReply"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetExpertAwayAutoReplyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setExpertAwayAutoReply"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"activeOrPendingAwayAutoReply"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AwayAutoReply"}}]}}]}}]}}]}},...AwayAutoReplyFragmentDoc.definitions]} as unknown as DocumentNode;
export type SetExpertAwayAutoReplyMutationFn = Apollo.MutationFunction<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables>;
export type SetExpertAwayAutoReplyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables>, 'mutation'>;

    export const SetExpertAwayAutoReplyComponent = (props: SetExpertAwayAutoReplyComponentProps) => (
      <ApolloReactComponents.Mutation<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables> mutation={SetExpertAwayAutoReplyDocument} {...props} />
    );
    

/**
 * __useSetExpertAwayAutoReplyMutation__
 *
 * To run a mutation, you first call `useSetExpertAwayAutoReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExpertAwayAutoReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExpertAwayAutoReplyMutation, { data, loading, error }] = useSetExpertAwayAutoReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExpertAwayAutoReplyMutation(baseOptions?: Apollo.MutationHookOptions<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables>(SetExpertAwayAutoReplyDocument, options);
      }
export type SetExpertAwayAutoReplyMutationHookResult = ReturnType<typeof useSetExpertAwayAutoReplyMutation>;
export type SetExpertAwayAutoReplyMutationResult = Apollo.MutationResult<SetExpertAwayAutoReplyMutation>;
export type SetExpertAwayAutoReplyMutationOptions = Apollo.BaseMutationOptions<SetExpertAwayAutoReplyMutation, SetExpertAwayAutoReplyMutationVariables>;
export const RemoveExpertAwayAutoReplyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveExpertAwayAutoReply"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RemoveExpertAwayAutoReplyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeExpertAwayAutoReply"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"error"}},{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"activeOrPendingAwayAutoReply"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AwayAutoReply"}}]}}]}}]}}]}},...AwayAutoReplyFragmentDoc.definitions]} as unknown as DocumentNode;
export type RemoveExpertAwayAutoReplyMutationFn = Apollo.MutationFunction<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables>;
export type RemoveExpertAwayAutoReplyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables>, 'mutation'>;

    export const RemoveExpertAwayAutoReplyComponent = (props: RemoveExpertAwayAutoReplyComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables> mutation={RemoveExpertAwayAutoReplyDocument} {...props} />
    );
    

/**
 * __useRemoveExpertAwayAutoReplyMutation__
 *
 * To run a mutation, you first call `useRemoveExpertAwayAutoReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExpertAwayAutoReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExpertAwayAutoReplyMutation, { data, loading, error }] = useRemoveExpertAwayAutoReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveExpertAwayAutoReplyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables>(RemoveExpertAwayAutoReplyDocument, options);
      }
export type RemoveExpertAwayAutoReplyMutationHookResult = ReturnType<typeof useRemoveExpertAwayAutoReplyMutation>;
export type RemoveExpertAwayAutoReplyMutationResult = Apollo.MutationResult<RemoveExpertAwayAutoReplyMutation>;
export type RemoveExpertAwayAutoReplyMutationOptions = Apollo.BaseMutationOptions<RemoveExpertAwayAutoReplyMutation, RemoveExpertAwayAutoReplyMutationVariables>;