import { useContext, useEffect, useState } from 'react'
import { ChatContext } from '@deal/chat-firebase'

// Subscribe to the global number of unread Firebase messages.
//
// The <Notifier> component is not suitable for this since it doesn't
//  handle the initial state well. This should probably live as a hook
//  in @deal/chat-firebase...
//
// TODO: Support subscribing to the unread message count for a specific
//  conversation, potentially as a separate hook.
export default function() {
  const chatContext = useContext(ChatContext)
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  useEffect(() => {
    if (chatContext.client) {
      setUnreadMessageCount(chatContext.client!.unreadMessageCount)
    }
  }, [chatContext.client?.unreadMessageCount])

  return unreadMessageCount
}
