import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { LeadExpertRole } from '#src/generated/types'
import shiftEndedToast from '#src/app/toasts/shiftEndedToast'
import nextShiftStartedToast from '#src/app/toasts/nextShiftStartedToast'
import leadSurgeStartedToast from '#src/app/toasts/leadSurgeStarted'
import leadEscalationStarted from '#src/app/toasts/leadEscalationStarted'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { UserAgentContext } from '#src/app/context/UserAgent'
import { ExpertNotificationComponent } from './ExpertNotificationSubscription.generated'
import { isMobileNativeOrBrowser } from '../../services/mobile'

const ExpertNotificationSubscription = () => {
  const businessUser = useBusinessUser()
  const ua = useContext(UserAgentContext)
  const [leadEscalationIds, setLeadEscalationIds] = useState<number[]>([])

  return (
    <ExpertNotificationComponent
      variables={{ expertId: businessUser.id }}
      onData={({ data: { data } }) => {
        if (data) {
          switch (data.expertNotification.__typename) {
            case 'ExpertNotificationLeadEscalation':
              /**
               * If we are using the new nav broadcast claim button we should short circuit
               * escalations toasts for primary experts. The claim button is
               * only for claiming primary expert escalations
               */
              if (
                data.expertNotification.leadEscalation?.acceptAsLeadExpertRole ===
                LeadExpertRole.PRIMARY
              ) {
                return null
              }
              // Only show leadEscalationStarated toast if I am not the escalation owner (i.e. I am primary expert)
              const businessUserId = businessUser.id
              const now = Date.now()
              if (data.expertNotification.leadEscalation) {
                const { expert, delegateExpert } = data.expertNotification.leadEscalation.lead
                // if (!businessUserId) return null
                // Only show toast if current business user is not already primary expert or delegate expert
                if (
                  businessUserId !== expert.id &&
                  (delegateExpert ? delegateExpert.id !== businessUserId : true)
                ) {
                  // Show the notification if happened within a minute.
                  if (
                    differenceInMinutes(now, new Date(data.expertNotification.occurredAt)) <= 1 ||
                    !isMobileNativeOrBrowser(ua)
                  ) {
                    const leadEscalationId = leadEscalationStarted({
                      leadEscalationId: data.expertNotification.leadEscalation.id
                    })

                    if (!leadEscalationIds.includes(leadEscalationId)) {
                      const newLeadEscalations = [leadEscalationId, ...leadEscalationIds]
                      // Keep 3 most recent notifications in state
                      setLeadEscalationIds(newLeadEscalations.slice(0, 3))
                      // dismiss any notifications that fall outside 3 most recent
                      newLeadEscalations.slice(3).map(id => toast.dismiss(id))
                    }
                  }
                }
              }
              break
            case 'ExpertNotificationPresenceStatusChange':
              // it will just update the cache automatically that causes the UI to be updated.
              break
            case 'ExpertNotificationShiftEnded':
              const currentStatus = data.expertNotification.expert.expertPresence.status
              const prevStatus = data.expertNotification.statusBeforeShiftEnd
              shiftEndedToast({ previousStatus: prevStatus, currentStatus: currentStatus })
              break
            case 'ExpertNotificationNextShiftStarted':
              nextShiftStartedToast({})
              break
            case 'ExpertNotificationLeadSurge':
              leadSurgeStartedToast({
                leadSurgeId: data.expertNotification.leadSurge.id,
                expertId: businessUser.id
              })
              break
            default:
              null
          }
        }
      }}
    >
      {() => null}
    </ExpertNotificationComponent>
  )
}

export default ExpertNotificationSubscription
