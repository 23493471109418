// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type QuickReplyVariablePopoverQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QuickReplyVariablePopoverQuery = { __typename: 'Query', quickReplyTemplateVariable?: { __typename: 'QuickReplyTemplateVariablePayload', variables?: Array<{ __typename: 'QuickReplyTemplateVariable', name: string, description: string, deprecated: boolean }> | null } | null };


export const QuickReplyVariablePopoverDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuickReplyVariablePopover"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quickReplyTemplateVariable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"}}]}}]}}]}}]} as unknown as DocumentNode;
export type QuickReplyVariablePopoverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>, 'query'>;

    export const QuickReplyVariablePopoverComponent = (props: QuickReplyVariablePopoverComponentProps) => (
      <ApolloReactComponents.Query<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables> query={QuickReplyVariablePopoverDocument} {...props} />
    );
    

/**
 * __useQuickReplyVariablePopoverQuery__
 *
 * To run a query within a React component, call `useQuickReplyVariablePopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickReplyVariablePopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickReplyVariablePopoverQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickReplyVariablePopoverQuery(baseOptions?: Apollo.QueryHookOptions<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>(QuickReplyVariablePopoverDocument, options);
      }
export function useQuickReplyVariablePopoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>(QuickReplyVariablePopoverDocument, options);
        }
export function useQuickReplyVariablePopoverSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>(QuickReplyVariablePopoverDocument, options);
        }
export type QuickReplyVariablePopoverQueryHookResult = ReturnType<typeof useQuickReplyVariablePopoverQuery>;
export type QuickReplyVariablePopoverLazyQueryHookResult = ReturnType<typeof useQuickReplyVariablePopoverLazyQuery>;
export type QuickReplyVariablePopoverSuspenseQueryHookResult = ReturnType<typeof useQuickReplyVariablePopoverSuspenseQuery>;
export type QuickReplyVariablePopoverQueryResult = Apollo.QueryResult<QuickReplyVariablePopoverQuery, QuickReplyVariablePopoverQueryVariables>;