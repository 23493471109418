import uuidv4 from 'uuid/v4'
import cookies from 'js-cookie'

/**
 * Generate a referreral link given any base link
 */
export function generateReferralLink(link: string, referrerId: string, expertId?: string) {
  let urlBase = ''
  if (link.includes('?')) {
    urlBase = '&'
  } else {
    urlBase = '?'
  }

  return `${link}${urlBase}referrerId=${referrerId}${expertId ? `&expertId=${expertId}` : ''}`
}

/**
 * Determine if we should reset the user's session ID or not
 *
 * There is one case in which we regenerate the session id
 * 1. An existing session id isn't present in the cookie (first time)
 */
export function shouldClientSessionReset() {
  return !getClientSessionId()
}

export function getClientSessionId() {
  return cookies.get('curated_business_client_session_id')
}

export function setClientSessionId() {
  const generatedSessionId = uuidv4()
  cookies.set('curated_client_session_id', generatedSessionId)
}
