import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { getFullName } from '#src/app/services/fullName'
import Toast from '#src/app/components/Toast'
import { useNewMessageConversationQuery } from './NewMessageConversation.generated'
import formattedToast from '../formattedToast'
import QuickChat from '../components/QuickChat'
import CommentIcon from './comment.svg'
import styles from './styles.css'

interface Props {
  closeToast?: () => void
  conversationId: string
  onToastClosed: (conversationId: string) => void
}

const NewMessageToast: React.FC<React.PropsWithChildren<Props>> = ({
  closeToast,
  conversationId,
  onToastClosed
}) => {
  const { data } = useNewMessageConversationQuery({ variables: { id: conversationId } })
  const [showChat, setShowChat] = useState(false)
  const setTimeoutRef = useRef<NodeJS.Timeout>()

  /**
   * Plan to close toast after 5 seconds unless the ref is cleared by either
   *   - The component un-mounting
   *   - User selecting "Reply"
   */
  useEffect(() => {
    setTimeoutRef.current = setTimeout(() => {
      closeToast?.()
      onToastClosed(conversationId)
    }, 5000)
    return () => setTimeoutRef.current && clearTimeout(setTimeoutRef.current)
  }, [])

  if (data?.conversation.lead) {
    return (
      <Toast className={classnames({ [styles.mobileFullScreen]: showChat })}>
        <div className={styles.wrapper}>
          <div className={styles.body}>
            <div className={styles.iconBlock}>
              <CommentIcon />
            </div>
            <div className={styles.message}>
              <div className={styles.newMessage}>New message</div>
              <div className={styles.name}> {getFullName(data.conversation.lead.consumer)}</div>
            </div>
            <div className={styles.actions}>
              <button
                className={styles.reply}
                onClick={() => {
                  setTimeoutRef.current && clearTimeout(setTimeoutRef.current)
                  setShowChat(true)
                }}
              >
                Reply
              </button>
              <button
                className={styles.close}
                onClick={() => {
                  onToastClosed(conversationId)
                  closeToast?.()
                }}
              >
                Close
              </button>
            </div>
          </div>
          {showChat && (
            <QuickChat leadId={data.conversation.lead.id} conversationId={data.conversation.id} />
          )}
        </div>
      </Toast>
    )
  } else {
    return <FullBlockLoader loader={CircleLoader} />
  }
}

const newMessageToast = (props: Props) => {
  return formattedToast(<NewMessageToast {...props} />)
}

export default newMessageToast
