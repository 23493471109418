import React, { useContext } from 'react'
import { ExperimentClient, ExperimentClientProvider } from '@deal/experiment-js'
import { IdentityContext } from '#src/app/containers/Identity'

export interface UserExperimentClientProviderProps {
  experimentClient: ExperimentClient
  overrideId?: string
  forceExperimentDefaults?: boolean
}

const UserExperimentClientProvider: React.FC<React.PropsWithChildren<
  UserExperimentClientProviderProps
>> = ({ overrideId, experimentClient, children }) => {
  const { myself, session, businessUserExperimentAttributes } = useContext(IdentityContext)
  const businessUserId = myself && myself.businessUser && myself.businessUser.id
  const isImpersonating = myself && myself.user.id !== myself.realUser.id

  return (
    <ExperimentClientProvider
      experimentClient={experimentClient}
      id={overrideId || businessUserId || session.id}
      attributes={overrideId ? {} : businessUserExperimentAttributes}
      options={{
        trackImpression: !isImpersonating
      }}
    >
      {children}
    </ExperimentClientProvider>
  )
}

export default UserExperimentClientProvider
