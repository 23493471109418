// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ExpertPresenceFragmentDoc } from '../../fragments/expertPresence.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SetExpertOffShiftMutationVariables = Types.Exact<{
  id: Types.Scalars['BusinessUserId']['input'];
}>;


export type SetExpertOffShiftMutation = { __typename: 'Mutation', setExpertOffShift: { __typename: 'SetExpertOffShiftPayload', shouldShowEndOfShiftSurvey: boolean, error?: string | null, businessUser?: { __typename: 'BusinessUser', id: any, canAcceptBroadcastLeads: boolean, expertPresence: { __typename: 'ExpertPresenceAway', startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceExtendedAway', startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOffShift', status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnCall', startedAt: any, endsAt: any, shiftId: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnShift', acceptingNewLeads: boolean, startedAt: any, endsAt: any, shiftId: any, status: Types.ExpertPresenceStatus } | { __typename: 'ExpertPresenceOnStandby', acceptingNewLeads: boolean, startedAt: any, endsAt: any, status: Types.ExpertPresenceStatus } } | null } };


export const SetExpertOffShiftDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetExpertOffShift"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setExpertOffShift"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"canAcceptBroadcastLeads"}},{"kind":"Field","name":{"kind":"Name","value":"expertPresence"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertPresence"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"shouldShowEndOfShiftSurvey"}},{"kind":"Field","name":{"kind":"Name","value":"error"}}]}}]}},...ExpertPresenceFragmentDoc.definitions]} as unknown as DocumentNode;
export type SetExpertOffShiftMutationFn = Apollo.MutationFunction<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables>;
export type SetExpertOffShiftComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables>, 'mutation'>;

    export const SetExpertOffShiftComponent = (props: SetExpertOffShiftComponentProps) => (
      <ApolloReactComponents.Mutation<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables> mutation={SetExpertOffShiftDocument} {...props} />
    );
    

/**
 * __useSetExpertOffShiftMutation__
 *
 * To run a mutation, you first call `useSetExpertOffShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExpertOffShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExpertOffShiftMutation, { data, loading, error }] = useSetExpertOffShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetExpertOffShiftMutation(baseOptions?: Apollo.MutationHookOptions<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables>(SetExpertOffShiftDocument, options);
      }
export type SetExpertOffShiftMutationHookResult = ReturnType<typeof useSetExpertOffShiftMutation>;
export type SetExpertOffShiftMutationResult = Apollo.MutationResult<SetExpertOffShiftMutation>;
export type SetExpertOffShiftMutationOptions = Apollo.BaseMutationOptions<SetExpertOffShiftMutation, SetExpertOffShiftMutationVariables>;