import React, { useContext } from 'react'
import EventEmitter from 'eventemitter3'
import { NativeMessage, WebMessage, ifBridgeDefined } from '../../services/dealNativeBridge'

const MAX_BUFFER_SIZE = 16

export type Disposable = {
  dispose(): void
}

const EMPTY_DISPOSABLE: Disposable = {
  dispose() {}
}

Object.freeze(EMPTY_DISPOSABLE)

type WithMessageId = {
  messageId: string
}

export function disposable(onDispose?: () => void): Disposable {
  if (!onDispose) {
    return EMPTY_DISPOSABLE
  }
  let isDisposed = false
  return {
    dispose: () => {
      if (!isDisposed) {
        onDispose()
      }
      isDisposed = true
    }
  }
}

export class WebMessageEventEmitter {
  private eventEmitter: EventEmitter
  private dispatchTypes: Set<WebMessage['type']> = new Set()

  constructor() {
    this.eventEmitter = new EventEmitter()
    this.on = this.on.bind(this)
    this.emit = this.emit.bind(this)
  }

  public interceptBridgeSendToWeb() {
    ifBridgeDefined(bridge => {
      bridge.sendToWeb = this.emit
      if (bridge._sendToWebInitBuffer === undefined) {
        bridge._sendToWebInitBuffer = []
      }
    })
  }

  public on<T extends WebMessage>(messageType: T['type'], fn: (msg: T) => void): Disposable {
    // We explicitly register the event listener, and then if this is the first event
    // listener of its type, we dispatch all of the buffered messages of this messageType
    this.eventEmitter.on(messageType, fn)
    this.startDispatch(messageType)
    return disposable(() => this.eventEmitter.removeListener(messageType, fn))
  }

  public emit<T extends WebMessage>(webMessage: T): void {
    ifBridgeDefined(bridge => {
      if (this.dispatchTypes.has(webMessage.type)) {
        this.eventEmitterEmit(webMessage)
      } else {
        const itemCount = bridge._sendToWebInitBuffer.length
        if (bridge._sendToWebInitBuffer.length >= MAX_BUFFER_SIZE) {
          // This only happens if there's some kind of error; events are rarely emitted
          // before the app is mounted, and accumulating a backlog means a listener
          // isn't registered for the particular event type. At this point, we're
          // considering that an error.
          console.error(
            `Message buffer for event emitter is full (${itemCount} items); dropping message: `,
            webMessage
          )
        } else {
          bridge._sendToWebInitBuffer.push(webMessage)
        }
      }
    })
  }

  public requestResponse<RESPONSE_T extends WebMessage & WithMessageId>(
    requestMessage: NativeMessage & WithMessageId,
    responseMessageType: RESPONSE_T['type']
  ): Promise<RESPONSE_T> {
    return new Promise((resolve, reject) => {
      const hasBridge = ifBridgeDefined(bridge => {
        const disposable = this.on(responseMessageType, message => {
          if (
            message.type === responseMessageType &&
            message.messageId === requestMessage.messageId
          ) {
            disposable.dispose()
            resolve(message)
          }
        })
        bridge.sendToNative(requestMessage)
        return true
      })
      if (!hasBridge) {
        reject(new Error('dealNativeBridge not present!'))
      }
    })
  }

  private startDispatch(messageType: WebMessage['type']) {
    ifBridgeDefined(bridge => {
      // Already started dispatching these events
      if (this.dispatchTypes.has(messageType)) {
        return
      }
      this.dispatchTypes.add(messageType)

      for (let i = bridge._sendToWebInitBuffer.length - 1; i >= 0; --i) {
        const msg = bridge._sendToWebInitBuffer[i]
        if (this.dispatchTypes.has(msg.type)) {
          this.eventEmitterEmit(msg)
          bridge._sendToWebInitBuffer.splice(i, 1)
        }
      }
    })
  }

  private eventEmitterEmit(webMessage: WebMessage) {
    this.eventEmitter.emit(webMessage.type, webMessage)
  }
}

const WebMessageEventEmitterContext = React.createContext<WebMessageEventEmitter | undefined>(
  undefined
)

const WebMessageEventEmitterProvider = WebMessageEventEmitterContext.Provider
const WebMessageEventEmitterConsumer = WebMessageEventEmitterContext.Consumer

export interface WithWebMessageEventEmitterProps {
  eventEmitter?: WebMessageEventEmitter
}

function withWebMessageEventEmitter<P>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<P & WithWebMessageEventEmitterProps>>
) {
  const WithWebMessageEventEmitter: React.FC<React.PropsWithChildren<P>> = props => {
    const eventEmitter = useContext(WebMessageEventEmitterContext)

    return <WrappedComponent {...props} eventEmitter={eventEmitter} />
  }
  return WithWebMessageEventEmitter
}

export {
  WebMessageEventEmitterProvider,
  WebMessageEventEmitterConsumer,
  withWebMessageEventEmitter,
  WebMessageEventEmitterContext
}
