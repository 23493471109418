import React, { useContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { ComposerMessageMetaData } from '@deal/chat-firebase'
import { SuggestedActionRevisionState, SuggestedActionUseCase } from '#src/generated/types'
import { toastError } from '#src/app/utilities/toast'
import useFuseSearch from '#src/app/hooks/useFuseSearch'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { MediaFileFragment } from '#src/app/fragments/mediaFile.generated'
import { ExpertClickedSuggestedMessageEvent } from '#src/app/events/ExpertClickedSuggestedMessageEvent'
import { useTrainingContext } from '#src/app/context/Training'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import SuggestedMessageDisplay from '#src/app/components/SuggestedMessageDisplay'
import { SuggestedActionForSuggestedMessageListFragment } from '../QuickReplyPopoverContent/QuickReplyPopoverContent.generated'
import {
  SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument,
  useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation,
  useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery
} from './SuggestedMessageList.generated'
import styles from './styles.css'

export interface SuggestedMessageListProps {
  leadId: string
  searchTerm: string
  suggestedMessageTag: string
  isManageQuickRepliesModalOpen: boolean
  showSuggestedMessageRevisionTemplate?: boolean
  suggestedAction?: SuggestedActionForSuggestedMessageListFragment
  onSuggestedMessageClicked: (suggestedMessageData: {
    title: string
    message: string
    suggestedMessageRevisionId: string
    metadata?: ComposerMessageMetaData
    mediaFiles?: MediaFileFragment[] | null
  }) => void
}

const SuggestedMessageList: React.FC<SuggestedMessageListProps> = ({
  leadId,
  searchTerm,
  suggestedMessageTag,
  isManageQuickRepliesModalOpen,
  suggestedAction,
  onSuggestedMessageClicked
}) => {
  const analytics = useAnalyticsContext()
  const { cache } = useApolloClient()
  const { id: bizUserId, expertAttributes } = useBusinessUser()
  const [loading, setLoading] = useState(false)
  const [generateMessage] = useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation({
    onError: () => toastError('Server error: Failed to paste quick reply')
  })

  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const isMultiSelectEnabled = leadSearchFolderContext.isMultiSelectEnabled
  const { exerciseTrackingId } = useTrainingContext()

  const queryVariables = {
    filter: {
      useCase: SuggestedActionUseCase.LEAD,
      state: SuggestedActionRevisionState.PUBLISHED,
      tags: [suggestedMessageTag],
      businessUserId: bizUserId,
      categoryIds: [expertAttributes.category.id]
    },
    // Fetch max allowed to keep parity V1 implementation
    // We should eventually leverage the pagination and text search API here and not fetch all 100 on mount
    limit: 100
  }

  const {
    data: newData,
    previousData: previousData,
    refetch,
    fetchMore
  } = useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    // Recursively fetch all quick replies - for most experts this won't fetch any additional messages, but there are some experts with 100+ quick replies.
    // We should remove this when we have a more user-friendly text search implemented for this query.
    onCompleted: data => {
      const newDataRevisions = data.suggestedMessageRevisionsIncludeSystemTypePagination.revisions
      //  Do not fetch more if the query returns less than 100 (the maximum allowed)
      if (newDataRevisions.length === 100) {
        fetchMore({
          variables: {
            ...queryVariables,
            offset: newDataRevisions.length
          }
        }).then(res => {
          if (res.data.suggestedMessageRevisionsIncludeSystemTypePagination.revisions.length > 0) {
            cache.writeQuery({
              query: SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument,
              variables: queryVariables,
              data: {
                suggestedMessageRevisionsIncludeSystemTypePagination: {
                  revisions: [
                    ...data.suggestedMessageRevisionsIncludeSystemTypePagination.revisions,
                    ...res.data.suggestedMessageRevisionsIncludeSystemTypePagination.revisions
                  ]
                }
              }
            })
          }
        })
      }
    }
  })

  const data = newData || previousData

  useEffect(() => {
    // If the modal is closed we should refetch the messages (when this list first mounts)
    // We should refetch the messages when the modal closes since changes may have been made to the revision template
    if (!isManageQuickRepliesModalOpen) {
      refetch(queryVariables)
    }
  }, [isManageQuickRepliesModalOpen])

  const suggestedMessages =
    data?.suggestedMessageRevisionsIncludeSystemTypePagination.revisions || []

  const suggestedMessagesToShow = useFuseSearch({
    items: suggestedMessages,
    keys: ['title', 'message'],
    searchTerm,
    skip: searchTerm === ''
  })

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <FullBlockLoader loader={CircleLoader} />
        </div>
      )}
      {suggestedMessagesToShow.map(suggestedMessage => (
        <SuggestedMessageDisplay
          key={suggestedMessage.id}
          suggestedMessage={suggestedMessage}
          onClick={() => {
            setLoading(true)
            generateMessage({
              variables: {
                input: {
                  leadId: leadId,
                  suggestedMessageRevisionId: suggestedMessage.id
                }
              }
            })
              .then(result => {
                if (result.data?.generateSingleLeadSuggestedMessage) {
                  const messageMetadata: ComposerMessageMetaData = {
                    suggested_message_revision_id: suggestedMessage.id
                  }
                  if (exerciseTrackingId) {
                    messageMetadata.exercise_tracking_id = exerciseTrackingId
                  }
                  if (suggestedAction?.suggestedActionRevision?.id) {
                    messageMetadata.suggested_action_revision_id =
                      suggestedAction.suggestedActionRevision.id
                  }
                  if (suggestedAction?.id) {
                    messageMetadata.suggested_action_id = suggestedAction.id
                  }
                  onSuggestedMessageClicked({
                    title: suggestedMessage.title,
                    message: isMultiSelectEnabled
                      ? suggestedMessage.message
                      : result.data.generateSingleLeadSuggestedMessage.message,
                    suggestedMessageRevisionId: suggestedMessage.id,
                    metadata: messageMetadata,
                    mediaFiles: suggestedMessage.mediaFiles
                  })
                  analytics?.track(
                    new ExpertClickedSuggestedMessageEvent({
                      lead_id: leadId,
                      expert_id: bizUserId,
                      suggested_action_id: suggestedAction?.id,
                      suggested_action_revision_id: suggestedAction?.suggestedActionRevision?.id,
                      suggested_action_headline: suggestedAction?.suggestedActionRevision?.headline,
                      suggested_message_revision_id: suggestedMessage.id
                    })
                  )
                } else {
                  toastError('Server error: Failed to paste quick reply')
                }
              })
              .catch(() => toastError('Server error: Failed to paste quick reply'))
              .finally(() => setLoading(false))
          }}
        />
      ))}
      {suggestedMessagesToShow.length === 0 && <div>No quick replies found</div>}
    </>
  )
}

export default SuggestedMessageList
