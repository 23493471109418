import React from 'react'
import { useHistory } from '@deal/router'
import { Button } from '@deal/components'
import { KNOWLEDGE_CARD_INTELLIGENT_FOLLOWUP_ID } from '#src/app/services/helpCenter'
import styles from './styles.css'

interface FollowUpLeadsActionFooterProps {
  handleViewLeads: () => void
}

const FollowUpLeadsActionFooter: React.FC<React.PropsWithChildren<
  FollowUpLeadsActionFooterProps
>> = ({ handleViewLeads }) => {
  const history = useHistory()

  const handleLearnMore = () => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('knowledgeCardId', KNOWLEDGE_CARD_INTELLIGENT_FOLLOWUP_ID)
    history.replace({
      ...history.location,
      search: searchParams.toString()
    })
  }

  return (
    <div className={styles.buttonContainer}>
      <Button size="xsmall" variant="secondary" onClick={handleViewLeads} className={styles.button}>
        View leads
      </Button>
      <div className={styles.learnMore} onClick={handleLearnMore}>
        Learn more
      </div>
    </div>
  )
}

export default FollowUpLeadsActionFooter
