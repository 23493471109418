import { LeadSnoozeForIsResnoozableFragment } from './isResnoozable.generated'

/**
 * Expert can resnooze the lead if a message has been sent, but all steps are currently inactive
 * This commonly happens when a lead gets back to an expert before all the snooze steps have been sent.
 * The snooze steps still exists, but they are no longer active
 */
export default (snooze?: LeadSnoozeForIsResnoozableFragment | null) => {
  if (!snooze?.snoozeSteps) return false
  const someStepHasMessageSent = snooze.snoozeSteps.some(step => !!step.messageSentAt)
  const allStepsAreInactive = !snooze.snoozeSteps.some(step => step.active !== false)
  return someStepHasMessageSent && allStepsAreInactive
}
