import React, { useEffect, useState } from 'react'
import { ToastId } from 'react-toastify'
import gql from 'graphql-tag'
import { useHistory } from '@deal/router'
import { CallStatus, CallType, LeadNoteContextType } from '#src/generated/types'
import Toast from '#src/app/components/Toast'
import FieldInteractionWrapper from '#src/app/components/FieldInteractionWrapper'
import { useCreateLeadNoteForNewExpertPhoneCallToastMutation } from './CreateLeadNoteForNewExpertPhoneCallToast.generated'
import {
  CallActivityForNewExpertPhoneCallToastFragment,
  CallParticipantForNewExpertPhoneCallToastFragment
} from './CallActivityForNewExpertPhoneCallToast.generated'
import formattedToast, { updateFormattedToast } from '../formattedToast'
import styles from './styles.css'
import { uniqueId } from 'lodash'

interface Props {
  callActivity: CallActivityForNewExpertPhoneCallToastFragment
  closeToast?: () => void
}

const getCallParticipantName = (
  callParticipant?: CallParticipantForNewExpertPhoneCallToastFragment | null
) => {
  if (!callParticipant) {
    return 'Unknown'
  }
  switch (callParticipant.__typename) {
    case 'BusinessUser':
      return callParticipant.businessUserFirstName

    case 'User':
      return callParticipant.userFirstName
  }
}

const NewExpertPhoneCallToast: React.FC<React.PropsWithChildren<Props>> = ({
  closeToast,
  callActivity
}) => {
  const { status, type, callee, caller, lead } = callActivity
  const history = useHistory()
  const [note, setNote] = useState('')
  const [addedNotes, setAddedNotes] = useState<string[]>([])
  const [showTextField, setShowTextField] = useState(false)

  const [createLeadNote, { loading }] = useCreateLeadNoteForNewExpertPhoneCallToastMutation({
    variables: { input: { leadId: lead?.id, text: note, context: LeadNoteContextType.PHONE_CALL } },
    onCompleted: data => {
      setNote('')
      setAddedNotes([data.createLeadNote.note.text, ...addedNotes])
    },
    update: (cache, { data }) => {
      if (data?.createLeadNote && lead) {
        cache.modify({
          id: cache.identify(lead),
          fields: {
            notes: (existingNoteRefs = []) => {
              const newNoteRef = cache.writeFragment({
                data: data.createLeadNote.note,
                fragment: gql`
                  fragment SavedLeadNote on SavedLeadNote {
                    id
                    text
                    messageId
                  }
                `
              })
              return [newNoteRef, ...existingNoteRefs]
            }
          }
        })
      }
    }
  })

  // Create lead note onUnmount if there's unsaved note
  const onUnmount = React.useRef(() => {})
  onUnmount.current = () => {
    if (note) {
      createLeadNote()
    }
  }

  useEffect(
    () => () => {
      onUnmount.current()
    },
    []
  )

  return (
    <Toast
      onCloseToastRequested={() => closeToast && closeToast()}
      className={styles.container}
      onClick={() => lead && history.push(`/inbox/${lead.id}`)}
    >
      {status === CallStatus.RINGING ? (
        <>
          {type === CallType.EXPERT_TO_CONSUMER ? (
            <div className={styles.calling}>
              Calling {getCallParticipantName(callee)}
              <div className={styles.snippet}>
                <div className={styles.stage}>
                  <div className={styles.dotFlashing} />
                </div>
              </div>
            </div>
          ) : (
            <div>Call from {getCallParticipantName(caller)}</div>
          )}
        </>
      ) : (
        <div className={styles.content}>
          <div className={styles.message}>
            Call with
            <div className={styles.name}>
              {type === CallType.EXPERT_TO_CONSUMER
                ? getCallParticipantName(callee)
                : getCallParticipantName(caller)}
            </div>
          </div>
          <form
            onSubmit={e => {
              e.preventDefault()
              setShowTextField(false)
              if (note) {
                createLeadNote()
              }
            }}
            className={styles.form}
          >
            <div className={styles.row} onClick={e => e.stopPropagation()}>
              <FieldInteractionWrapper
                onClick={() => setShowTextField(true)}
                isActive={showTextField}
                onBlur={() => setShowTextField(false)}
                className={styles.inputWrapper}
              >
                <input
                  className={styles.input}
                  placeholder="Add notes from your call..."
                  value={note}
                  onChange={e => setNote(e.target.value)}
                />
                {note && (
                  <button disabled={loading} className={styles.button}>
                    +
                  </button>
                )}
              </FieldInteractionWrapper>
            </div>
          </form>
          {addedNotes.length > 0 && (
            <div className={styles.notes}>
              {addedNotes.map(note => {
                return (
                  <div className={styles.note} key={uniqueId()}>
                    {note}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </Toast>
  )
}

const newExpertPhoneCallToast = (props: CallActivityForNewExpertPhoneCallToastFragment) =>
  formattedToast(<NewExpertPhoneCallToast callActivity={props} />)

const updateExpertPhoneCallToast = (
  toastId: ToastId,
  props: CallActivityForNewExpertPhoneCallToastFragment
) => updateFormattedToast(toastId, <NewExpertPhoneCallToast callActivity={props} />)

export { newExpertPhoneCallToast, updateExpertPhoneCallToast }
