import React from 'react'
import { IEmojiPickerProps } from 'emoji-picker-react'

// The Picker component in package `emoji-picker-react` has side-effects
// on import that cause errors in our SSR environment.
//
// In order to deal with that, we check for window being defined,
// which means that we are in the client browser environment.

// We can then import the Picker component safely.
const isClient = typeof window !== 'undefined'

let Picker: React.FC<React.PropsWithChildren<IEmojiPickerProps>> | undefined

if (isClient) {
  import('emoji-picker-react').then(module => {
    Picker = module.default
  })
}

const EmojiPicker: React.FC<React.PropsWithChildren<IEmojiPickerProps>> = props => {
  return Picker ? <Picker {...props} /> : null
}

export default EmojiPicker
