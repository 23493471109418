import React from 'react'
import { Modal } from '@deal/components'
import PayPerLeadDailySummaryDisplay from '../PayPerLeadDailySummaryDisplay'

interface PayPerLeadDailySummaryModalProps {
  onRequestClose: () => void
}

const PayPerLeadDailySummaryModal: React.FC<PayPerLeadDailySummaryModalProps> = ({
  onRequestClose
}) => {
  return (
    <Modal
      isOpen
      contentLabel="Pay Per Lead Daily Summary"
      onRequestClose={onRequestClose}
      contentSpacing={false}
    >
      <PayPerLeadDailySummaryDisplay />
    </Modal>
  )
}

export default PayPerLeadDailySummaryModal
