// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AttentionNeededLeadCountChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type AttentionNeededLeadCountChangedSubscription = { __typename: 'Subscription', attentionNeededLeadCountChanged: { __typename: 'AttentionNeededLeadCountChanged', count: number } };


export const AttentionNeededLeadCountChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"AttentionNeededLeadCountChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"attentionNeededLeadCountChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]} as unknown as DocumentNode;
export type AttentionNeededLeadCountChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<AttentionNeededLeadCountChangedSubscription, AttentionNeededLeadCountChangedSubscriptionVariables>, 'subscription'>;

    export const AttentionNeededLeadCountChangedComponent = (props: AttentionNeededLeadCountChangedComponentProps) => (
      <ApolloReactComponents.Subscription<AttentionNeededLeadCountChangedSubscription, AttentionNeededLeadCountChangedSubscriptionVariables> subscription={AttentionNeededLeadCountChangedDocument} {...props} />
    );
    

/**
 * __useAttentionNeededLeadCountChangedSubscription__
 *
 * To run a query within a React component, call `useAttentionNeededLeadCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttentionNeededLeadCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttentionNeededLeadCountChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useAttentionNeededLeadCountChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<AttentionNeededLeadCountChangedSubscription, AttentionNeededLeadCountChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AttentionNeededLeadCountChangedSubscription, AttentionNeededLeadCountChangedSubscriptionVariables>(AttentionNeededLeadCountChangedDocument, options);
      }
export type AttentionNeededLeadCountChangedSubscriptionHookResult = ReturnType<typeof useAttentionNeededLeadCountChangedSubscription>;
export type AttentionNeededLeadCountChangedSubscriptionResult = Apollo.SubscriptionResult<AttentionNeededLeadCountChangedSubscription>;