// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../../../fragments/mediaFile.generated';
import { SnoozeStepForIsInvalidSnoozeStepFragmentDoc } from '../../../../../utilities/snooze/isInvalidSnoozeStep.generated';
export type SnoozeStepForSnoozeStepFragment = { __typename: 'SnoozeStep', wakeupTime: any, message?: string | null, title?: string | null, messageSentAt?: any | null, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export type LeadForSnoozeStepFragment = { __typename: 'Lead', id: any, consumer: { __typename: 'User', id: any, experimentUserId: any }, snooze?: { __typename: 'LeadSnooze', id?: any | null, isAiGenerated: boolean } | null };

export const SnoozeStepForSnoozeStepFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SnoozeStepForSnoozeStep"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SnoozeStep"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"wakeupTime"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"messageSentAt"}},{"kind":"Field","name":{"kind":"Name","value":"mediaFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SnoozeStepForIsInvalidSnoozeStep"}}]}}]} as unknown as DocumentNode;
export const LeadForSnoozeStepFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForSnoozeStep"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"consumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"experimentUserId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"snooze"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isAiGenerated"}}]}}]}}]} as unknown as DocumentNode;