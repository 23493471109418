// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertNewLeadEligibilityChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertNewLeadEligibilityChangedSubscription = { __typename: 'Subscription', expertNewLeadEligibilityChanged: { __typename: 'ExpertLeadEligibilityChange', id: any, eligibility: { __typename: 'ExpertLeadEligibility', eligible: boolean, detailReason?: string | null } } };


export const ExpertNewLeadEligibilityChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertNewLeadEligibilityChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertNewLeadEligibilityChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"eligibility"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eligible"}},{"kind":"Field","name":{"kind":"Name","value":"detailReason"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ExpertNewLeadEligibilityChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertNewLeadEligibilityChangedSubscription, ExpertNewLeadEligibilityChangedSubscriptionVariables>, 'subscription'>;

    export const ExpertNewLeadEligibilityChangedComponent = (props: ExpertNewLeadEligibilityChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertNewLeadEligibilityChangedSubscription, ExpertNewLeadEligibilityChangedSubscriptionVariables> subscription={ExpertNewLeadEligibilityChangedDocument} {...props} />
    );
    

/**
 * __useExpertNewLeadEligibilityChangedSubscription__
 *
 * To run a query within a React component, call `useExpertNewLeadEligibilityChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertNewLeadEligibilityChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertNewLeadEligibilityChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertNewLeadEligibilityChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertNewLeadEligibilityChangedSubscription, ExpertNewLeadEligibilityChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertNewLeadEligibilityChangedSubscription, ExpertNewLeadEligibilityChangedSubscriptionVariables>(ExpertNewLeadEligibilityChangedDocument, options);
      }
export type ExpertNewLeadEligibilityChangedSubscriptionHookResult = ReturnType<typeof useExpertNewLeadEligibilityChangedSubscription>;
export type ExpertNewLeadEligibilityChangedSubscriptionResult = Apollo.SubscriptionResult<ExpertNewLeadEligibilityChangedSubscription>;