import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import addMilliseconds from 'date-fns/addMilliseconds'
import addDays from 'date-fns/addDays'
import { SuggestedActionRevisionState, SuggestedActionUseCase } from '#src/generated/types'
import { isSnoozeWakeupTimeInvalid } from '#src/app/utilities/snooze'
import { gernateAttachmentsFromMediaFiles } from '#src/app/services/mediaAttachment'
import {
  getMustacheTemplateTextErrors,
  MustacheTemplateError
} from '#src/app/services/mustacheTemplate'
import { BusinessUserFragment } from '#src/app/fragments/businessUser.generated'
import { SnoozeStepForFollowUpFragment } from './FollowUp/FollowUp.generated'

export { MustacheTemplateError }

type GeneratedMessageType = {
  message: any
  title: any
  messageSourceId: any
  isMessageSourceSuggestedMessage: boolean
}

const CURATED_DOMAIN_NAME = 'curated.com'

export const defaultSuggestedMessageArray = [
  'FOLLOWUP_MESSAGE_ONE',
  'FOLLOWUP_MESSAGE_TWO',
  'FOLLOWUP_MESSAGE_THREE'
]

const sessionStorageKey = 'snooze-messages'

export const saveMessagesToSessionStorage = (
  leadId: string,
  snoozeSteps: SnoozeStepForFollowUpFragment[]
) => {
  window.sessionStorage.setItem(`${sessionStorageKey}-${leadId}`, JSON.stringify(snoozeSteps))
}

export const getMessagesFromSessionStorage = (
  leadId: string
): SnoozeStepForFollowUpFragment[] | undefined => {
  const snoozeMessagesFromSessionStorage = window.sessionStorage.getItem(
    `${sessionStorageKey}-${leadId}`
  )

  if (snoozeMessagesFromSessionStorage) {
    const snoozeSteps: SnoozeStepForFollowUpFragment[] = JSON.parse(
      snoozeMessagesFromSessionStorage
    )
    return convertWakeupTimeToDate(snoozeSteps)
  } else {
    return undefined
  }
}

export const convertWakeupTimeToDate = (steps: SnoozeStepForFollowUpFragment[]) => {
  return steps.map(step => ({
    ...step,
    wakeupTime: new Date(step.wakeupTime)
  }))
}

export const clearMessagesFromSessionStorage = (leadId: string) => {
  window.sessionStorage.removeItem(`${sessionStorageKey}-${leadId}`)
}

const getDefaultDateForSteps = (initialWakeupTime: Date, index: number) => {
  switch (index) {
    case 0:
      return new Date(initialWakeupTime.setHours(12, 0, 0))
    case 1:
      return new Date(addDays(initialWakeupTime, 2).setHours(12, 0, 0))
    case 2:
      return new Date(addDays(initialWakeupTime, 7).setHours(12, 0, 0))
    default:
      break
  }
}

const createSnoozeStep = (
  wakeupTime: Date,
  isMessageSourceSuggestedMessage: boolean,
  idx: number = 0
): SnoozeStepForFollowUpFragment => ({
  wakeupTime: getDefaultDateForSteps(wakeupTime, idx),
  title: 'Custom message',
  message: null,
  active: false,
  isMessageSourceSuggestedMessage,
  __typename: 'SnoozeStep'
})

export const generateDefaultSnoozeSteps = (
  initialWakeupTime: Date,
  isMessageSourceSuggestedMessage: boolean
): SnoozeStepForFollowUpFragment[] => {
  return [0, 1, 2].map(idx =>
    createSnoozeStep(initialWakeupTime, isMessageSourceSuggestedMessage, idx)
  )
}

export const adjustWakeupTimeForSnoozeSteps = (
  newStep: Pick<SnoozeStepForFollowUpFragment, 'wakeupTime'>,
  snoozeSteps: SnoozeStepForFollowUpFragment[]
): SnoozeStepForFollowUpFragment[] => {
  const millisecondsToAdd = differenceInMilliseconds(newStep.wakeupTime, snoozeSteps[0].wakeupTime)

  let previousWakeupTime = newStep.wakeupTime

  return snoozeSteps.map((step, idx) => {
    if (idx === 0) {
      return { ...step, ...newStep }
    }

    // If the current step's wakeup time is less than or equal to the previous step's time,
    // adjust the wakeup time for this step.
    if (step.wakeupTime <= previousWakeupTime) {
      step.wakeupTime = addMilliseconds(step.wakeupTime, millisecondsToAdd)
    }

    previousWakeupTime = step.wakeupTime
    return { ...step }
  })
}

export const getUpdatedSnoozeSteps = (
  newSnoozeStep: SnoozeStepForFollowUpFragment,
  snoozeSteps: SnoozeStepForFollowUpFragment[],
  index: number
) => {
  const stepsPriorToUpdatedStep = snoozeSteps.slice(0, index)
  const currentAndAllFutureSteps = snoozeSteps.slice(index)

  const adjustedSteps = adjustWakeupTimeForSnoozeSteps(newSnoozeStep, currentAndAllFutureSteps)
  return { updatedSnoozeSteps: [...stepsPriorToUpdatedStep, ...adjustedSteps] }
}

export const addMessagesToDefaultSteps = (generatedMessages: GeneratedMessageType[]) => {
  // Get base default steps, and then add the custom generated message copy
  const defaultSteps = generateDefaultSnoozeSteps(addDays(new Date(), 1), true)

  generatedMessages.forEach(({ message, title, messageSourceId }, idx) => {
    defaultSteps[idx].message = message
    defaultSteps[idx].title = title
    defaultSteps[idx].messageSourceId = messageSourceId
  })

  return defaultSteps
}

export const getSuggestedMessageRevisionVariables = (
  businessUser: BusinessUserFragment,
  idx: number
) => {
  idx
  return {
    variables: {
      filter: {
        useCase: SuggestedActionUseCase.LEAD,
        state: SuggestedActionRevisionState.PUBLISHED,
        businessUserId: businessUser.id,
        categoryIds: [businessUser.expertAttributes.category.id],
        tags: [defaultSuggestedMessageArray[idx]]
      },
      limit: 5
    }
  }
}

export const CUSTOM_TITLE = 'Custom message'

export const MESSAGE_VARIABLE_TYPES = {
  CUSTOMER_FIRST_NAME: {
    description: 'Customer First Name',
    name: '{{customerFirstName}}'
  },
  CONSUMER_FIRST_NAME: {
    description: 'Consumer First Name',
    name: '{{consumerFirstName}}'
  },
  EXPERT_FIRST_NAME: {
    description: 'Expert First Name',
    name: '{{expertFirstName}}'
  }
}

export const MESSAGE_VARIABLES = [
  MESSAGE_VARIABLE_TYPES.CUSTOMER_FIRST_NAME.name,
  MESSAGE_VARIABLE_TYPES.CONSUMER_FIRST_NAME.name,
  MESSAGE_VARIABLE_TYPES.EXPERT_FIRST_NAME.name
]

export enum SnoozeError {
  INVALID_URL_REFERENCE = 'INVALID_URL_REFERENCE',
  WAKEUP_TIME_IN_PAST = 'WAKEUP_TIME_IN_PAST',
  WAKEUP_TIME_BEFORE_PREVIOUS = 'WAKEUP_TIME_BEFORE_PREVIOUS',
  MISSING_VARIABLE = 'MISSING_VARIABLE',
  MISSING_MESSAGE = 'MISSING_MESSAGE'
}

export type SnoozeOrMustacheTemplateError = SnoozeError | MustacheTemplateError
export interface SnoozeStepError {
  type: SnoozeOrMustacheTemplateError
  message: string
}

// Returns an array or arrays where every index is the associated snooze step that holds a list of errors for that step
// null means no error
export const getSnoozeStepErrors = (
  snoozeSteps: SnoozeStepForFollowUpFragment[],
  ignoredErrorTypes: SnoozeError[] = []
): SnoozeStepError[][] => {
  return snoozeSteps.map((step, idx) =>
    getSnoozeStepErrorMessage(step, snoozeSteps.slice(0, idx), ignoredErrorTypes)
  )
}

export const hasValidUrls = (input: string) => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  const regex = new RegExp(expression)
  const urls = input.match(regex) || []
  return urls.every(url => url.includes(CURATED_DOMAIN_NAME))
}

function getMustacheTemplateTextErrorMessages(errorType: MustacheTemplateError) {
  switch (errorType) {
    case MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_BRACES:
      return 'One or more snooze steps has a variable with incorrect braces'

    case MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_VARIABLE:
      return 'One or more snooze steps is using an incorrect variable name'
  }
}

export const getSnoozeStepErrorMessage = (
  snoozeStep: SnoozeStepForFollowUpFragment,
  previousSteps: SnoozeStepForFollowUpFragment[],
  ignoredErrorTypes: SnoozeError[]
): SnoozeStepError[] => {
  const isWakeupTimeInvalid = isSnoozeWakeupTimeInvalid(snoozeStep)
  const isMissingVariable = !MESSAGE_VARIABLES.some(msgVar => snoozeStep?.message?.includes(msgVar))
  const msgHasValidUrls = hasValidUrls(snoozeStep?.message || '')
  const mustacheTemplateErrors = getMustacheTemplateTextErrors(
    snoozeStep?.message || '',
    Object.values(MESSAGE_VARIABLE_TYPES)
  )

  const errors: SnoozeStepError[] = mustacheTemplateErrors.map(errorType => {
    return {
      type: errorType,
      message: getMustacheTemplateTextErrorMessages(errorType)
    }
  })

  // Check to make sure that the current snoozeStep does not have a wakeup time before any previous snooze steps
  const isWakeupTimeBeforeAnyPreviousStep = previousSteps.some(
    previousStep => previousStep.wakeupTime > snoozeStep.wakeupTime
  )

  if (isWakeupTimeInvalid && !ignoredErrorTypes.includes(SnoozeError.WAKEUP_TIME_IN_PAST)) {
    errors.push({
      type: SnoozeError.WAKEUP_TIME_IN_PAST,
      message: 'All wakeup times must be in the future'
    })
  }
  if (isMissingVariable && !ignoredErrorTypes.includes(SnoozeError.MISSING_VARIABLE)) {
    errors.push({
      type: SnoozeError.MISSING_VARIABLE,
      message: 'Add at least 1 variable to your snooze messages'
    })
  }
  if (
    isWakeupTimeBeforeAnyPreviousStep &&
    !ignoredErrorTypes.includes(SnoozeError.WAKEUP_TIME_BEFORE_PREVIOUS)
  ) {
    errors.push({
      type: SnoozeError.WAKEUP_TIME_BEFORE_PREVIOUS,
      message: 'Wakeup times must be after the wakeup times of all previous steps'
    })
  }
  if (!snoozeStep.message && !ignoredErrorTypes.includes(SnoozeError.MISSING_MESSAGE)) {
    errors.push({
      type: SnoozeError.MISSING_MESSAGE,
      message: 'All snooze steps must have a message'
    })
  }
  if (!msgHasValidUrls && !ignoredErrorTypes.includes(SnoozeError.INVALID_URL_REFERENCE)) {
    errors.push({
      type: SnoozeError.INVALID_URL_REFERENCE,
      message:
        'Bulk Snooze messages can only contain references to curated.com URLs to make it less likely mobile carriers will block your SMS number'
    })
  }

  return errors
}

export const handleMessageChanged = (
  message: string,
  snoozeStep: SnoozeStepForFollowUpFragment
) => {
  /**
   * If we delete the entire message, or replace and start typing a new message (i.e. length = 1) remove any reference to a messageSourceId since the expert is writing from scratch
   * The user could delete all the text, and then undo the action, but we assume those events are unlikely.
   */
  if (message.length <= 1) {
    return {
      ...snoozeStep,
      message,
      title: CUSTOM_TITLE,
      messageSourceId: null
    }
  } else {
    return {
      ...snoozeStep,
      message,
      title:
        snoozeStep.messageSourceId && !snoozeStep.title?.includes('(edited)')
          ? `${snoozeStep.title} (edited)`
          : snoozeStep.title
    }
  }
}

// Snooze header manages the time of the first snooze step
export const doesSnoozeHeaderHaveError = (snoozeStepErrors: SnoozeStepError[][]) => {
  return snoozeStepErrors[0]?.some(error => error.type === SnoozeError.WAKEUP_TIME_IN_PAST)
}

export const generateAttachmentsFromSnoozeSteps = (
  snoozeSteps: SnoozeStepForFollowUpFragment[]
) => {
  return snoozeSteps.map(snoozeStep => generateSnoozeStepWithAttachments(snoozeStep))
}

export const generateSnoozeStepWithAttachments = (snoozeStep: SnoozeStepForFollowUpFragment) => {
  return gernateAttachmentsFromMediaFiles(snoozeStep.mediaFiles || [])
}
