// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type BusinessUserForToggleLlmMessageSuggestionsFragment = { __typename: 'BusinessUser', id: any, expertDeviceLLMPreferences: Array<{ __typename: 'ExpertDeviceLLMPreference', type: Types.ExpertDeviceType, isOptedIntoLLMMessageSuggestions: boolean }> };

export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables = Types.Exact<{
  input: Types.UpdateBusinessUserInput;
}>;


export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutation = { __typename: 'Mutation', updateBusinessUser: { __typename: 'UpdateBusinessUserPayload', businessUser?: { __typename: 'BusinessUser', id: any, expertDeviceLLMPreferences: Array<{ __typename: 'ExpertDeviceLLMPreference', type: Types.ExpertDeviceType, isOptedIntoLLMMessageSuggestions: boolean }> } | null } };

export const BusinessUserForToggleLlmMessageSuggestionsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForToggleLLMMessageSuggestions"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertDeviceLLMPreferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"isOptedIntoLLMMessageSuggestions"}}]}}]}}]} as unknown as DocumentNode;
export const UpdateBusinessUserForToggleLlmMessageSuggestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBusinessUserForToggleLLMMessageSuggestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBusinessUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBusinessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForToggleLLMMessageSuggestions"}}]}}]}}]}},...BusinessUserForToggleLlmMessageSuggestionsFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutationFn = Apollo.MutationFunction<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables>;
export type UpdateBusinessUserForToggleLlmMessageSuggestionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables>, 'mutation'>;

    export const UpdateBusinessUserForToggleLlmMessageSuggestionsComponent = (props: UpdateBusinessUserForToggleLlmMessageSuggestionsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables> mutation={UpdateBusinessUserForToggleLlmMessageSuggestionsDocument} {...props} />
    );
    

/**
 * __useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserForToggleLlmMessageSuggestionsMutation, { data, loading, error }] = useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables>(UpdateBusinessUserForToggleLlmMessageSuggestionsDocument, options);
      }
export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutationHookResult = ReturnType<typeof useUpdateBusinessUserForToggleLlmMessageSuggestionsMutation>;
export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutationResult = Apollo.MutationResult<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation>;
export type UpdateBusinessUserForToggleLlmMessageSuggestionsMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserForToggleLlmMessageSuggestionsMutation, UpdateBusinessUserForToggleLlmMessageSuggestionsMutationVariables>;