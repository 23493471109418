import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { addDays } from 'date-fns'
import { Checkbox, Modal } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useUpdateExpertSelectionSettingMutation } from './ExpertSelectionSetting.generated'
import styles from './styles.css'

interface ExpertSelectionSettingProps {
  onRequestClose: () => void
}

const ExpertSelectionSettingModal: React.FC<React.PropsWithChildren<ExpertSelectionSettingProps>> = ({ onRequestClose }) => {
  const businessUser = useBusinessUser()
  const [expertSelectionSettingEnabled, setExpertSelectionSettingEnabled] = useState(
    businessUser.expertAttributes.expertSelectionSetting.isExpertSelectionEnabled
  )
  const [disabledUntil, setDisabledUntil] = useState<Date>()
  const [updateExpertSelectiongSetting, { loading }] = useUpdateExpertSelectionSettingMutation({
    variables: {
      input: {
        expertId: businessUser.id,
        isExpertSelectionEnabled: expertSelectionSettingEnabled,
        disabledUntil: disabledUntil ? disabledUntil : null
      }
    },
    onCompleted: () => toast.success('Expert selection setting updated.'),
    onError: () => toast.error('Error updating expert selection setting.')
  })
  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      contentLabel="ExpertSelectionSetting"
      mobilePosition="bottom"
      title="Expert Selection Status"
      primaryAction={{
        label: 'Save Status',
        disabled: loading,
        onClick: () => updateExpertSelectiongSetting()
      }}
    >
      <div className={styles.subTitle}>
        Toggle the “enabled” checkbox to turn Expert Selection leads on or off. If you just want to
        take a break, select “snoozed” and we’ll turn Expert Selection off but automatically turn it
        back on for you tomorrow at 5pm.
      </div>
      <div className={styles.settingLine}>
        <Checkbox
          checked={expertSelectionSettingEnabled}
          onChange={evt => {
            setExpertSelectionSettingEnabled(evt.target.checked)
            setDisabledUntil(undefined)
          }}
        >
          Enabled -- Accepting Expert Selection leads
        </Checkbox>
      </div>
      <div className={styles.settingLine}>
        <Checkbox
          checked={!!disabledUntil}
          onChange={evt => {
            if (evt.target.checked) {
              setDisabledUntil(new Date(addDays(new Date(), 1).setHours(17, 0, 0)))
              setExpertSelectionSettingEnabled(false)
            } else {
              setDisabledUntil(undefined)
            }
          }}
        >
          Snoozed -- Pause Expert Selection leads until 5pm (your time) tomorrow
        </Checkbox>
      </div>
    </Modal>
  )
}

export default ExpertSelectionSettingModal
