import React, { useContext, useEffect, useRef } from 'react'
import { useLocation } from '@deal/router'
import { ChatClientEvent, ChatContext, summarizeMessage } from '@deal/chat-firebase'
import { MessagingUserType } from '#src/generated/types'
import newMessageToast from '#src/app/toasts/newMessageToast'
import { playNotificationSound, showNotification } from '#src/app/services/desktopNotification'
import { LeadDetailsContext } from '#src/app/context/LeadDetails'
import { GlobalViewsContext } from '#src/app/context/GlobalViews'
import { VisibilityContext } from '#src/app/containers/Visibility'

const NewMessageNotification: React.FC<React.PropsWithChildren<unknown>> = () => {
  const visible = useContext(VisibilityContext)
  const chatContext = useContext(ChatContext)
  const location = useLocation()
  const { activeViews } = useContext(GlobalViewsContext)
  const { lead } = useContext(LeadDetailsContext)

  /* expert doesn't have access to inbox when   *
   * 1. they are not currently in the inbox or
   * 2.  they are in the box, but there is a flyout other than quick curation since there is small chat window there
   */
  const isOutsideInbox = !location.pathname.includes('inbox') || activeViews.length > 0

  /**
   * We subscribe and unsubscribe the handler only once unless the client change.
   * To make that possible, all the variables used by the handler must be refs
   * and the refs must be kept up to date.
   */
  const refContext = useRef({})
  const visibilityRef = useRef(visible)
  const activeConversationsRef = useRef<string[]>([])
  const isOutsideInboxRef = useRef(isOutsideInbox)
  const leadRef = useRef(lead)

  useEffect(() => {
    visibilityRef.current = visible
    isOutsideInboxRef.current = isOutsideInbox
    leadRef.current = lead
  }, [visible, isOutsideInbox, lead])

  const handleNewMessage = (event: ChatClientEvent) => {
    const conversationId = event.message?.conversation.conversationId
    const playNewMessageSound = window.localStorage.getItem('playSoundForNewMessage') === 'on'

    if (event.message?.sentBy.type === MessagingUserType.USER) {
      if (!visibilityRef.current) {
        const message = event.message
        const title = `New message from ${message.sentBy.displayName}`

        showNotification({
          title,
          body: summarizeMessage(message),
          sound: playNewMessageSound
        })
      } else if (playNewMessageSound) {
        playNotificationSound()
      }
    }

    if (
      conversationId &&
      event.message?.sentBy.type === MessagingUserType.USER &&
      (isOutsideInboxRef.current || leadRef.current?.conversation.id !== conversationId) &&
      !activeConversationsRef.current.includes(conversationId)
    ) {
      activeConversationsRef.current = [...activeConversationsRef.current, conversationId]
      newMessageToast({
        conversationId,
        onToastClosed: conversationId => {
          activeConversationsRef.current = activeConversationsRef.current.filter(
            id => id !== conversationId
          )
        }
      })
    }
  }

  useEffect(() => {
    chatContext.client?.on('newMessage', handleNewMessage, refContext.current)
    return () => {
      chatContext.client?.off('newMessage', undefined, refContext.current)
    }
  }, [chatContext.client])

  return null
}

export default NewMessageNotification
