import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertRatedShiftDifficultyEventProperties {
  difficulty: number
}

export class ExpertRatedShiftDifficultyEvent extends Event<
  ExpertRatedShiftDifficultyEventProperties
> {
  static displayName = 'Expert Rated Shift Difficulty'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
