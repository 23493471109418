import React, { useState } from 'react'
import { Text } from '@deal/bluxome'
import NavSectionWithCollapsibleContent from '#src/app/components/NavSectionWithCollapsibleContent'
import { useLeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useFireSavedLeadSearch } from '#src/app/utilities/savedSearches'
import NavFolderLink from '../../NavFolderLink'
import { useFindExpertSavedLeadSearchesQuery } from './ExpertSavedSearches.generated'
import ManageSavedSearchesModal from './ManageSavedSearchesModal'
import styles from './styles.css'

function getSavedSearchDescription(search: string) {
  switch (search) {
    case 'MPQL Qualified':
      return 'Leads acquired with 120 days that affect your MPQL'
    case 'Needs Curation':
      return 'Leads that count for MPQL but have not received a Curation'
    default:
      return undefined
  }
}

interface ExpertSavedSearchesProps {}

const ExpertSavedSearches: React.FC<ExpertSavedSearchesProps> = () => {
  const { id } = useBusinessUser()
  const { savedSearch } = useLeadSearchFolderContext()
  const [isManageSavedSearchesModalOpen, setIsManageSavedSearchesModalOpen] = useState(false)
  const [isNavSectionOptionsVisible, setIsNavSectionOptionsVisible] = useState(false)
  const fireSavedLeadSearch = useFireSavedLeadSearch()

  const { data } = useFindExpertSavedLeadSearchesQuery({
    variables: {
      input: {
        expertId: id,
        includeSystemSearches: true
      }
    }
  })

  return (
    <>
      <NavSectionWithCollapsibleContent
        sectionTitle="Searches"
        contentVisible={!!savedSearch}
        optionsVisible={isNavSectionOptionsVisible}
        onOptionsIconClick={() => setIsNavSectionOptionsVisible(prev => !prev)}
        onOptionsClose={() => setIsNavSectionOptionsVisible(false)}
        optionsContent={
          <div
            className={styles.optionsContent}
            onClick={e => {
              e.stopPropagation()
              setIsNavSectionOptionsVisible(false)
              setIsManageSavedSearchesModalOpen(prev => !prev)
            }}
          >
            <Text>Manage Saved Searches</Text>
          </div>
        }
      >
        {data?.findExpertSavedLeadSearches.length
          ? data.findExpertSavedLeadSearches.map(search => {
              const description = search.isSystem && getSavedSearchDescription(search.name)
              return (
                <NavFolderLink
                  key={search.id}
                  displayName={search.name || 'Unnamed Search'}
                  onClick={() => {
                    fireSavedLeadSearch(search)
                  }}
                  isActive={search.id === savedSearch}
                  displayNameMessage={description || undefined}
                />
              )
            })
          : 'No saved searches'}
      </NavSectionWithCollapsibleContent>
      {isManageSavedSearchesModalOpen && (
        <ManageSavedSearchesModal
          savedSearches={data?.findExpertSavedLeadSearches}
          onRequestClose={() => setIsManageSavedSearchesModalOpen(false)}
        />
      )}
    </>
  )
}

export default ExpertSavedSearches
