import { Event, ViewerContext } from '@deal/web-tracking'

export interface IntelligentFollowUpCompleteModalMoreLeadsButtonClickedEventProperties {
  expert_id: string
}

export class IntelligentFollowUpCompleteModalMoreLeadsButtonClickedEvent extends Event<
  IntelligentFollowUpCompleteModalMoreLeadsButtonClickedEventProperties
> {
  static displayName = 'Modal Complete Celebration Get More Leads Button Clicked'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
