import React from 'react'
import { WithMediaUploadProps, FileUploadPreview, MediaUrlPreview } from '@deal/chat-firebase'
import {
  MediaFile,
  FileAttachment,
  GifAttachment,
  MediaUploadPreview
} from '#src/app/typings/media'

interface WithAttachmentUploadProps {
  attachFile: (file: File | MediaFile) => void
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

function withAttachmentUpload<P>(WrappedComponent: React.FC<P>) {
  const WrapperComponent: React.FC<Omit<
    P &
      WithMediaUploadProps & {
        onAttach: (attachment: FileAttachment | GifAttachment) => void
        previews: MediaUploadPreview[]
      },
    keyof WithAttachmentUploadProps
  >> = ({
    previews,
    onAttach,
    handleUploadMedia,
    handleCreateMedia,
    handleCreateMediaAsset,
    ...passThroughProps
  }) => {
    function onFileAttached(file: File | MediaFile) {
      onAttach({
        data: { file },
        previewer:
          previews.find(preview =>
            preview.accept((file as File).type || (file as MediaFile).mimeType)
          ) || FileUploadPreview,
        toMediaId: data => {
          if (data.file) {
            return handleUploadMedia(data.file as File).then(mediaFile => {
              return mediaFile.id
            })
          } else {
            throw new Error('No file provided')
          }
        }
      })
    }

    function onMediaUrlAttached(url: string, titleForMediaAsset?: string, sharedByUserId?: string) {
      onAttach({
        data: { url },
        previewer: MediaUrlPreview,
        toMediaId: data => {
          if (data.url) {
            return handleCreateMedia(data.url).then(mediaFile => {
              if (sharedByUserId) {
                handleCreateMediaAsset(mediaFile.id, sharedByUserId, titleForMediaAsset)
              }
              return mediaFile.id
            })
          } else {
            throw new Error('No url provided')
          }
        }
      })
    }

    return (
      <WrappedComponent
        {...(passThroughProps as P)}
        attachFile={onFileAttached}
        attachMediaUrl={onMediaUrlAttached}
      />
    )
  }

  return WrapperComponent
}

export default withAttachmentUpload
