// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ExpertTagFragmentDoc } from '../ExpertTags/ExpertTags.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateExpertTagMutationVariables = Types.Exact<{
  input: Types.UpdateExpertTagInput;
}>;


export type UpdateExpertTagMutation = { __typename: 'Mutation', updateExpertTag: { __typename: 'UpdateExpertTagPayload' } };

export type DeleteExpertTagMutationVariables = Types.Exact<{
  input: Types.DeleteExpertTagInput;
}>;


export type DeleteExpertTagMutation = { __typename: 'Mutation', deleteExpertTag: DeleteExpertTagPayload };


export const UpdateExpertTagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateExpertTag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateExpertTagInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateExpertTag"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertTag"}}]}}]}},...ExpertTagFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateExpertTagMutationFn = Apollo.MutationFunction<UpdateExpertTagMutation, UpdateExpertTagMutationVariables>;
export type UpdateExpertTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExpertTagMutation, UpdateExpertTagMutationVariables>, 'mutation'>;

    export const UpdateExpertTagComponent = (props: UpdateExpertTagComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExpertTagMutation, UpdateExpertTagMutationVariables> mutation={UpdateExpertTagDocument} {...props} />
    );
    

/**
 * __useUpdateExpertTagMutation__
 *
 * To run a mutation, you first call `useUpdateExpertTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpertTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpertTagMutation, { data, loading, error }] = useUpdateExpertTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpertTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpertTagMutation, UpdateExpertTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpertTagMutation, UpdateExpertTagMutationVariables>(UpdateExpertTagDocument, options);
      }
export type UpdateExpertTagMutationHookResult = ReturnType<typeof useUpdateExpertTagMutation>;
export type UpdateExpertTagMutationResult = Apollo.MutationResult<UpdateExpertTagMutation>;
export type UpdateExpertTagMutationOptions = Apollo.BaseMutationOptions<UpdateExpertTagMutation, UpdateExpertTagMutationVariables>;
export const DeleteExpertTagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteExpertTag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DeleteExpertTagInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteExpertTag"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type DeleteExpertTagMutationFn = Apollo.MutationFunction<DeleteExpertTagMutation, DeleteExpertTagMutationVariables>;
export type DeleteExpertTagComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteExpertTagMutation, DeleteExpertTagMutationVariables>, 'mutation'>;

    export const DeleteExpertTagComponent = (props: DeleteExpertTagComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteExpertTagMutation, DeleteExpertTagMutationVariables> mutation={DeleteExpertTagDocument} {...props} />
    );
    

/**
 * __useDeleteExpertTagMutation__
 *
 * To run a mutation, you first call `useDeleteExpertTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpertTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpertTagMutation, { data, loading, error }] = useDeleteExpertTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExpertTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpertTagMutation, DeleteExpertTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpertTagMutation, DeleteExpertTagMutationVariables>(DeleteExpertTagDocument, options);
      }
export type DeleteExpertTagMutationHookResult = ReturnType<typeof useDeleteExpertTagMutation>;
export type DeleteExpertTagMutationResult = Apollo.MutationResult<DeleteExpertTagMutation>;
export type DeleteExpertTagMutationOptions = Apollo.BaseMutationOptions<DeleteExpertTagMutation, DeleteExpertTagMutationVariables>;