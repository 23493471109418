import React from 'react'
import classnames from 'classnames'
import { Tooltip } from '@deal/components'
import {
  Attachment,
  ComposerControlProps,
  Control,
  ImageUploadPreview,
  withMediaUpload,
  withMediaUploadControlBase,
  WithMediaUploadControlBaseProps
} from '@deal/chat-firebase'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import QuickReplyPopover from '#src/app/components/QuickReplyPopover'
import QuickReplyHighlightIcon from './quick-reply-highlight.svg'
import QuickReplyFlatIcon from './quick-reply-flat.svg'
import styles from './styles.css'
import { MediaURLData, FileData } from '#src/app/typings/media'

type TemplateAttachment = Attachment<FileData> | Attachment<MediaURLData>

export interface QuickReplyControlProps {
  leadId: string
  isVisible: boolean
  hideManageButton?: boolean
  onCloseRequested: () => void
  onControlClicked: () => void
}

export enum Modal {
  Search,
  Edit,
  Create
}

const QuickReplyControlWithMediaUploadControlBase: React.FC<ComposerControlProps<
  TemplateAttachment
> &
  QuickReplyControlProps &
  WithMediaUploadControlBaseProps> = ({
  appendText,
  attachMediaUrl,
  leadId,
  isVisible,
  hideManageButton,
  onCloseRequested,
  onControlClicked
}) => {
  const { highlightQuickReplyIcon, showQuickReplyTooltip } = useQuickReplyContext()

  return (
    <QuickReplyPopover
      visible={isVisible}
      leadId={leadId}
      onSuggestedMessageClicked={({ message, metadata, mediaFiles }) => {
        appendText(message, metadata)
        if (mediaFiles && mediaFiles.length > 0) {
          mediaFiles.forEach(mediaFile => {
            attachMediaUrl(mediaFile.url)
          })
        }
        onCloseRequested()
      }}
      onRequestClose={() => onCloseRequested()}
      hideManageButton={hideManageButton}
    >
      {highlightQuickReplyIcon ? (
        <Tooltip
          visible={showQuickReplyTooltip}
          message="You can always access your suggested messages here"
          placement="top"
          theme="dark"
          wrapperClassName={styles.wrapper}
          hideTooltipOnMobile
        >
          <Control
            title="Add a quick reply"
            className={classnames(styles.icon, styles.flat)}
            onClick={onControlClicked}
          >
            <QuickReplyHighlightIcon className={styles.button} />
          </Control>
        </Tooltip>
      ) : (
        <Control
          title="Add a quick reply"
          className={classnames(styles.icon, styles.flat)}
          onClick={onControlClicked}
        >
          <QuickReplyFlatIcon className={styles.button} />
        </Control>
      )}
    </QuickReplyPopover>
  )
}

const WrappedQuickReplyControlWithMediaUploadControlBase = withMediaUpload(
  withMediaUploadControlBase(QuickReplyControlWithMediaUploadControlBase)
)

const QuickReplyControl: React.FC<ComposerControlProps<TemplateAttachment> &
  QuickReplyControlProps> = props => {
  return (
    <WrappedQuickReplyControlWithMediaUploadControlBase
      {...props}
      previews={[ImageUploadPreview]}
    />
  )
}

export default QuickReplyControl
