// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ExpertLeadSubFolderFragmentDoc } from '../ExpertLeadFolderGroups/ExpertLeadFolderGroups.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FindExpertTagsQueryVariables = Types.Exact<{
  id: Types.Scalars['BusinessUserId']['input'];
}>;


export type FindExpertTagsQuery = { __typename: 'Query', findExpertTags: Array<{ __typename: 'ExpertTag', id: any, name: string, isFeatured: boolean } | null> };

export type ExpertLeadTagChangedSubscriptionVariables = Types.Exact<{
  expertId?: Types.InputMaybe<Types.Scalars['BusinessUserId']['input']>;
}>;


export type ExpertLeadTagChangedSubscription = { __typename: 'Subscription', expertLeadTagChanged: { __typename: 'ExpertLeadFolder', id: any, description?: string | null, totalCount?: number | null, totalCountOnCurrentShift?: number | null, slug: string, displayName: string } };

export type ExpertTagFragment = { __typename: 'ExpertTag', id: any, name: string, isFeatured: boolean };

export const ExpertTagFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertTag"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertTag"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"isFeatured"}}]}}]} as unknown as DocumentNode;
export const FindExpertTagsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FindExpertTags"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"findExpertTags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertTag"}}]}}]}},...ExpertTagFragmentDoc.definitions]} as unknown as DocumentNode;
export type FindExpertTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindExpertTagsQuery, FindExpertTagsQueryVariables>, 'query'> & ({ variables: FindExpertTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindExpertTagsComponent = (props: FindExpertTagsComponentProps) => (
      <ApolloReactComponents.Query<FindExpertTagsQuery, FindExpertTagsQueryVariables> query={FindExpertTagsDocument} {...props} />
    );
    

/**
 * __useFindExpertTagsQuery__
 *
 * To run a query within a React component, call `useFindExpertTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindExpertTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindExpertTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindExpertTagsQuery(baseOptions: Apollo.QueryHookOptions<FindExpertTagsQuery, FindExpertTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindExpertTagsQuery, FindExpertTagsQueryVariables>(FindExpertTagsDocument, options);
      }
export function useFindExpertTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindExpertTagsQuery, FindExpertTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindExpertTagsQuery, FindExpertTagsQueryVariables>(FindExpertTagsDocument, options);
        }
export function useFindExpertTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindExpertTagsQuery, FindExpertTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindExpertTagsQuery, FindExpertTagsQueryVariables>(FindExpertTagsDocument, options);
        }
export type FindExpertTagsQueryHookResult = ReturnType<typeof useFindExpertTagsQuery>;
export type FindExpertTagsLazyQueryHookResult = ReturnType<typeof useFindExpertTagsLazyQuery>;
export type FindExpertTagsSuspenseQueryHookResult = ReturnType<typeof useFindExpertTagsSuspenseQuery>;
export type FindExpertTagsQueryResult = Apollo.QueryResult<FindExpertTagsQuery, FindExpertTagsQueryVariables>;
export const ExpertLeadTagChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertLeadTagChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertLeadTagChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadSubFolder"}}]}}]}},...ExpertLeadSubFolderFragmentDoc.definitions]} as unknown as DocumentNode;
export type ExpertLeadTagChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertLeadTagChangedSubscription, ExpertLeadTagChangedSubscriptionVariables>, 'subscription'>;

    export const ExpertLeadTagChangedComponent = (props: ExpertLeadTagChangedComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertLeadTagChangedSubscription, ExpertLeadTagChangedSubscriptionVariables> subscription={ExpertLeadTagChangedDocument} {...props} />
    );
    

/**
 * __useExpertLeadTagChangedSubscription__
 *
 * To run a query within a React component, call `useExpertLeadTagChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertLeadTagChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertLeadTagChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertLeadTagChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ExpertLeadTagChangedSubscription, ExpertLeadTagChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertLeadTagChangedSubscription, ExpertLeadTagChangedSubscriptionVariables>(ExpertLeadTagChangedDocument, options);
      }
export type ExpertLeadTagChangedSubscriptionHookResult = ReturnType<typeof useExpertLeadTagChangedSubscription>;
export type ExpertLeadTagChangedSubscriptionResult = Apollo.SubscriptionResult<ExpertLeadTagChangedSubscription>;