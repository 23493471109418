import React, { useContext, useMemo, useState } from 'react'
import { Chat } from '@deal/chat-firebase'
import { isBusinessMobileNative, isMobileBrowser } from '#src/app/services/mobile'
import { UserAgentContext } from '#src/app/context/UserAgent'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import QuickReplyControl from '#src/app/components/QuickReplyControl'
import EmojiPickerControl from '#src/app/components/EmojiPickerControl'
import styles from './styles.css'

interface Props {
  conversationId: string
  leadId: string
}

const QuickChat: React.FC<React.PropsWithChildren<Props>> = ({ conversationId, leadId }) => {
  const userAgent = useContext(UserAgentContext)
  const [showQuickReplyPopover, setShowQuickReplyPopover] = useState(false)
  const { setShowQuickReplyTooltip } = useQuickReplyContext()

  const onControlClicked = () => {
    setShowQuickReplyPopover(!showQuickReplyPopover)
    setShowQuickReplyTooltip(false)
  }

  const MemoizedQuickReplyControl = useMemo(
    () => (props: any) => (
      <QuickReplyControl
        {...props}
        onCloseRequested={() => setShowQuickReplyPopover(false)}
        onControlClicked={onControlClicked}
        isVisible={showQuickReplyPopover}
        leadId={leadId}
        hideManageButton
      />
    ),
    [leadId, showQuickReplyPopover]
  )

  return (
    <div className={styles.dropdown}>
      <Chat
        conversationId={conversationId}
        controls={[
          // disable the emoji picker on mobile browser or native mobile app
          // (not needed on mobile since they have built-in support)
          ...(isMobileBrowser(userAgent) || isBusinessMobileNative() ? [] : [EmojiPickerControl]),
          MemoizedQuickReplyControl
        ]}
      />
    </div>
  )
}

export default QuickChat
