// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForConversationSummaryButtonFragment = { __typename: 'Lead', id: any, conversationSummaryEligible: boolean, consumer: { __typename: 'User', id: any, experimentUserId: any } };

export type ConversationSummaryButtonQueryVariables = Types.Exact<{
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type ConversationSummaryButtonQuery = { __typename: 'Query', conversationSummary?: { __typename: 'ConversationSummary', summary: string, trackingId?: any | null } | null };

export const LeadForConversationSummaryButtonFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForConversationSummaryButton"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"conversationSummaryEligible"}},{"kind":"Field","name":{"kind":"Name","value":"consumer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"experimentUserId"}}]}}]}}]} as unknown as DocumentNode;
export const ConversationSummaryButtonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConversationSummaryButton"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conversationSummary"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"trackingId"}}]}}]}}]} as unknown as DocumentNode;
export type ConversationSummaryButtonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>, 'query'> & ({ variables: ConversationSummaryButtonQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConversationSummaryButtonComponent = (props: ConversationSummaryButtonComponentProps) => (
      <ApolloReactComponents.Query<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables> query={ConversationSummaryButtonDocument} {...props} />
    );
    

/**
 * __useConversationSummaryButtonQuery__
 *
 * To run a query within a React component, call `useConversationSummaryButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationSummaryButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationSummaryButtonQuery({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useConversationSummaryButtonQuery(baseOptions: Apollo.QueryHookOptions<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>(ConversationSummaryButtonDocument, options);
      }
export function useConversationSummaryButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>(ConversationSummaryButtonDocument, options);
        }
export function useConversationSummaryButtonSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>(ConversationSummaryButtonDocument, options);
        }
export type ConversationSummaryButtonQueryHookResult = ReturnType<typeof useConversationSummaryButtonQuery>;
export type ConversationSummaryButtonLazyQueryHookResult = ReturnType<typeof useConversationSummaryButtonLazyQuery>;
export type ConversationSummaryButtonSuspenseQueryHookResult = ReturnType<typeof useConversationSummaryButtonSuspenseQuery>;
export type ConversationSummaryButtonQueryResult = Apollo.QueryResult<ConversationSummaryButtonQuery, ConversationSummaryButtonQueryVariables>;