import React, { useContext, useState } from 'react'
import uniq from 'lodash/uniq'

export enum GlobalView {
  EmailReferralModal,
  EndOfShiftModal,
  ImageModal,
  TimeZoneModal,
  ShiftDifficultyModal,
  SmsReferralModal
}

export interface State {
  activeView: GlobalView | null
}

type GlobalViewsContextType = {
  activeViews: GlobalView[]
  closeAllViews: () => void
  closeView: (view: GlobalView) => void
  openView: (view: GlobalView) => void
  isViewActive: (view: GlobalView) => boolean
}

// Context
const GlobalViewsContext = React.createContext<GlobalViewsContextType>({
  activeViews: [],
  closeAllViews: () => {},
  openView: () => {},
  closeView: () => {},
  isViewActive: () => false
})

// Consumer
const GlobalViewsConsumer = GlobalViewsContext.Consumer

// Provider
const GlobalViewsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [activeViews, setActiveViews] = useState<GlobalView[]>([])

  return (
    <GlobalViewsContext.Provider
      value={{
        isViewActive: view => activeViews.some(activeView => activeView === view),
        activeViews,
        closeAllViews: () => setActiveViews([]),
        closeView: (view: GlobalView) => {
          setActiveViews(previousActiveViews =>
            previousActiveViews.filter(activeView => activeView !== view)
          )
        },
        openView: view =>
          setActiveViews(previousActiveViews => uniq([...previousActiveViews, view]))
      }}
    >
      <>{children}</>
    </GlobalViewsContext.Provider>
  )
}

const useGlobalViewsContext = () => {
  const globalViewsContext = useContext(GlobalViewsContext)

  if (!globalViewsContext) {
    throw new Error('Cannot access GlobalViewsContext outside the provider')
  }

  return globalViewsContext
}

export { GlobalViewsConsumer, GlobalViewsProvider, GlobalViewsContext, useGlobalViewsContext }
