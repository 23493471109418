import React, { createContext, useState } from 'react'

// Context
type NavigationContextType = {
  toggleNavigation: () => void
  setIsNavigationOpen: (isOpen: boolean) => void
  isNavigationOpen: boolean
  toggleUserMenuOpen: () => void
  setIsUserMenuOpen: (isOpen: boolean) => void
  isUserMenuOpen: boolean

  navigationDrawer: NavigationDrawer
  setNavigationDrawer: React.Dispatch<React.SetStateAction<NavigationDrawer>>
}

export const NavigationDrawerView = { 'help-center': 'help-center', tickets: 'tickets' } as const
export type NavigationDrawerView = keyof typeof NavigationDrawerView
export type NavigationDrawer =
  | { view: Extract<NavigationDrawerView, 'help-center'> | null; ticketId?: never }
  | { view: Extract<NavigationDrawerView, 'tickets'>; ticketId: string }

const NavigationContext = createContext<NavigationContextType>({
  isNavigationOpen: false,
  isUserMenuOpen: false,
  toggleNavigation: () => {},
  setIsNavigationOpen: () => {},
  toggleUserMenuOpen: () => {},
  setIsUserMenuOpen: () => {},
  navigationDrawer: { view: null },
  setNavigationDrawer: () => {}
})

const NavigationConsumer = NavigationContext.Consumer

/**
 * Provider
 * This provider must live outside of the identity provider if we would
 * like to maintain navigation state after an identity change
 * This is important for accepting an escalation for a different
 * business user
 */
const NavigationProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [navigationDrawer, setNavigationDrawer] = useState<NavigationDrawer>({ view: null })

  return (
    <NavigationContext.Provider
      value={{
        toggleNavigation: () => setIsNavigationOpen(!isNavigationOpen),
        setIsNavigationOpen: isNavigationOpen => setIsNavigationOpen(isNavigationOpen),
        isNavigationOpen: isNavigationOpen,
        toggleUserMenuOpen: () => setIsUserMenuOpen(!isUserMenuOpen),
        setIsUserMenuOpen: isUserMenuOpen => setIsUserMenuOpen(isUserMenuOpen),
        isUserMenuOpen: isUserMenuOpen,
        navigationDrawer,
        setNavigationDrawer
      }}
    >
      <>{children}</>
    </NavigationContext.Provider>
  )
}

export { NavigationConsumer, NavigationProvider, NavigationContext }
