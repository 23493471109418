// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { ExpertLeadGateForLeadGateProgressModalFragmentDoc, BusinessUserForLeadGateProgressModalFragmentDoc } from './LeadGateProgressModal/LeadGateProgressModal.generated';
import { MonetaryAmountFragmentDoc } from '../../../../fragments/monetaryAmount.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertLeadGateForLeadGateProgressFragment = { __typename: 'ExpertLeadGate', state: Types.ExpertLeadGateState, leadGateRule?: { __typename: 'ExpertLeadGateRuleAndProgress', leads?: number | null, expertLeadGateSyntheticScoreRule?: { __typename: 'ExpertLeadGateSyntheticScoreRuleAndProgress', passingScore: number, score?: number | null, leadCount: number, pointsForQualityConversation?: number | null, pointsForCurationVideo?: number | null, pointsForAddToCart?: number | null, pointsForPurchase?: number | null, qualityConversationCount?: number | null, curationVideoCount?: number | null, addToCartCount?: number | null, purchaseCount?: number | null, isPassing: boolean } | null } | null };

export type BusinessUserForLeadGateProgressFragment = { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', leadGates: Array<{ __typename: 'ExpertLeadGate', state: Types.ExpertLeadGateState, leadGateRule?: { __typename: 'ExpertLeadGateRuleAndProgress', leads?: number | null, expertLeadGateSyntheticScoreRule?: { __typename: 'ExpertLeadGateSyntheticScoreRuleAndProgress', passingScore: number, score?: number | null, leadCount: number, pointsForQualityConversation?: number | null, pointsForCurationVideo?: number | null, pointsForAddToCart?: number | null, pointsForPurchase?: number | null, qualityConversationCount?: number | null, curationVideoCount?: number | null, addToCartCount?: number | null, purchaseCount?: number | null, isPassing: boolean } | null } | null }> }, expertStats?: { __typename: 'ExpertStats', effectiveMPQL: { __typename: 'ExpertMPQLStats', qualifiedLeads: number, marginPerQualifiedLead: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string } }, last60DaysRPAL: { __typename: 'ExpertRPALStats', activeLeads: number } } | null, performance?: { __typename: 'ExpertPerformance', rating: Types.ExpertPerformanceRating } | null };

export type LeadGateChangedSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type LeadGateChangedSubscription = { __typename: 'Subscription', leadGateChanged: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', leadGates: Array<{ __typename: 'ExpertLeadGate', state: Types.ExpertLeadGateState, leadGateRule?: { __typename: 'ExpertLeadGateRuleAndProgress', leads?: number | null, expertLeadGateSyntheticScoreRule?: { __typename: 'ExpertLeadGateSyntheticScoreRuleAndProgress', passingScore: number, score?: number | null, leadCount: number, pointsForQualityConversation?: number | null, pointsForCurationVideo?: number | null, pointsForAddToCart?: number | null, pointsForPurchase?: number | null, qualityConversationCount?: number | null, curationVideoCount?: number | null, addToCartCount?: number | null, purchaseCount?: number | null, isPassing: boolean } | null } | null }> }, expertStats?: { __typename: 'ExpertStats', effectiveMPQL: { __typename: 'ExpertMPQLStats', qualifiedLeads: number, marginPerQualifiedLead: { __typename: 'MonetaryAmount', amount: string, currency: any, amountInSmallestDenomination: string } }, last60DaysRPAL: { __typename: 'ExpertRPALStats', activeLeads: number } } | null, performance?: { __typename: 'ExpertPerformance', rating: Types.ExpertPerformanceRating } | null } };

export const ExpertLeadGateForLeadGateProgressFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertLeadGateForLeadGateProgress"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertLeadGate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadGateRule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leads"}},{"kind":"Field","name":{"kind":"Name","value":"expertLeadGateSyntheticScoreRule"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"passingScore"}},{"kind":"Field","name":{"kind":"Name","value":"score"}},{"kind":"Field","name":{"kind":"Name","value":"leadCount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadGateForLeadGateProgressModal"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForLeadGateProgressFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForLeadGateProgress"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadGates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertLeadGateForLeadGateProgress"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"effectiveMPQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"marginPerQualifiedLead"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MonetaryAmount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"qualifiedLeads"}}]}},{"kind":"Field","name":{"kind":"Name","value":"last60DaysRPAL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeLeads"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"performance"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rating"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForLeadGateProgressModal"}}]}}]} as unknown as DocumentNode;
export const LeadGateChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"LeadGateChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadGateChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForLeadGateProgress"}}]}}]}},...BusinessUserForLeadGateProgressFragmentDoc.definitions,...ExpertLeadGateForLeadGateProgressFragmentDoc.definitions,...ExpertLeadGateForLeadGateProgressModalFragmentDoc.definitions,...MonetaryAmountFragmentDoc.definitions,...BusinessUserForLeadGateProgressModalFragmentDoc.definitions]} as unknown as DocumentNode;
export type LeadGateChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<LeadGateChangedSubscription, LeadGateChangedSubscriptionVariables>, 'subscription'>;

    export const LeadGateChangedComponent = (props: LeadGateChangedComponentProps) => (
      <ApolloReactComponents.Subscription<LeadGateChangedSubscription, LeadGateChangedSubscriptionVariables> subscription={LeadGateChangedDocument} {...props} />
    );
    

/**
 * __useLeadGateChangedSubscription__
 *
 * To run a query within a React component, call `useLeadGateChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLeadGateChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadGateChangedSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useLeadGateChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<LeadGateChangedSubscription, LeadGateChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LeadGateChangedSubscription, LeadGateChangedSubscriptionVariables>(LeadGateChangedDocument, options);
      }
export type LeadGateChangedSubscriptionHookResult = ReturnType<typeof useLeadGateChangedSubscription>;
export type LeadGateChangedSubscriptionResult = Apollo.SubscriptionResult<LeadGateChangedSubscription>;