import React, { useEffect } from 'react'
import { Redirect } from '@deal/router'
import { Notification } from '@deal/components'
import styles from './styles.css'

interface Props {
  to: string
  retainThen?: boolean
}
const ExternalUrlAwareRedirect: React.FC<React.PropsWithChildren<Props>> = ({ to }) => {
  useEffect(() => {
    if (/^(http|https|ftp)/.test(to)) {
      window.location.href = to
    }
  }, [])

  if (/^(http|https|ftp)/.test(to)) {
    return (
      <Notification type="info" className={styles.notification}>
        Redirecting to {to}
      </Notification>
    )
  } else {
    return <Redirect to={to} />
  }
}

export default ExternalUrlAwareRedirect
