// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadEscalationFragmentDoc } from '../../fragments/leadEscalation.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadEscalationChangedSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['LeadEscalationId']['input'];
}>;


export type LeadEscalationChangedSubscription = { __typename: 'Subscription', leadEscalationChanged: { __typename: 'LeadEscalation', id: any, createdAt: any, type: Types.LeadEscalationType, level: Types.LeadEscalationLevel, ownerId?: any | null, expertsNotifiedAt?: any | null, teamNotifiedAt?: any | null, supportNotifiedAt?: any | null, closedAt?: any | null, status: Types.LeadEscalationStatus, acceptAsLeadExpertRole: Types.LeadExpertRole, owner?: { __typename: 'BusinessUser', id: any, displayName: string, shortName: string } | null, lead: { __typename: 'Lead', id: any, delegateExpert?: { __typename: 'BusinessUser', id: any } | null, expert: { __typename: 'BusinessUser', id: any }, consumer: { __typename: 'User', id: any, firstName?: string | null, lastName?: string | null, vip: boolean }, conversation: { __typename: 'Conversation', id: any, externalId: string } } } };


export const LeadEscalationChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"LeadEscalationChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadEscalationId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"leadEscalationChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadEscalation"}}]}}]}},...LeadEscalationFragmentDoc.definitions]} as unknown as DocumentNode;
export type LeadEscalationChangedComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<LeadEscalationChangedSubscription, LeadEscalationChangedSubscriptionVariables>, 'subscription'>;

    export const LeadEscalationChangedComponent = (props: LeadEscalationChangedComponentProps) => (
      <ApolloReactComponents.Subscription<LeadEscalationChangedSubscription, LeadEscalationChangedSubscriptionVariables> subscription={LeadEscalationChangedDocument} {...props} />
    );
    

/**
 * __useLeadEscalationChangedSubscription__
 *
 * To run a query within a React component, call `useLeadEscalationChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLeadEscalationChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadEscalationChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeadEscalationChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<LeadEscalationChangedSubscription, LeadEscalationChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LeadEscalationChangedSubscription, LeadEscalationChangedSubscriptionVariables>(LeadEscalationChangedDocument, options);
      }
export type LeadEscalationChangedSubscriptionHookResult = ReturnType<typeof useLeadEscalationChangedSubscription>;
export type LeadEscalationChangedSubscriptionResult = Apollo.SubscriptionResult<LeadEscalationChangedSubscription>;