import React from 'react'
import { Path, PathNavigationDetails, PathPlacement } from '@deal/path-flow'
import { isMobileBrowser } from '#src/app/services/mobile'
import loggerClient from '#src/app/services/loggerClient'
import { useUserAgentContext } from '#src/app/context/UserAgent'
import { useIdentityContext } from '#src/app/containers/Identity'

export interface ExpertKnowledgeTestResult {
  passed: boolean
  passingPercentage: number
  scorePercentage: number
}
interface StandalonePathProps {
  pathPlacement?: PathPlacement
  flowId: string
  directedActiveNodeIdOrSlug: string
  onActiveNodeUpdated: (pathNavigationDetails: PathNavigationDetails) => void
  onReturnToPreviousContext?: () => void
  onSurveyResponseFinished?: () => void
  onExpertKnowledgeTestFinished?: (result: ExpertKnowledgeTestResult) => void
}

const StandalonePath: React.FC<React.PropsWithChildren<StandalonePathProps>> = ({
  flowId,
  pathPlacement,
  onActiveNodeUpdated,
  directedActiveNodeIdOrSlug,
  onReturnToPreviousContext,
  onSurveyResponseFinished,
  onExpertKnowledgeTestFinished
}) => {
  const { refetch: refetchIdentity } = useIdentityContext()
  const userAgent = useUserAgentContext()

  return (
    <Path
      videoCaptureMethod={isMobileBrowser(userAgent) ? 'video-upload' : 'media-recorder'}
      pathPlacement={pathPlacement}
      defaultPathGraphFlowId={flowId}
      directedActiveNodeSlugOrId={directedActiveNodeIdOrSlug}
      isRebranded
      // Should never happen with current implementation. Can add at later point if use-case ever arises
      onRequestLogin={() =>
        loggerClient.captureError(
          new Error('Path requested login, but not supported in business app')
        )
      }
      onRefetchIdentity={() => {
        if (refetchIdentity) {
          return refetchIdentity().then(response => {
            if (response.error) {
              return Promise.reject(response.error)
            }

            return Promise.resolve()
          })
        } else {
          return Promise.reject('Error refetching user identity!')
        }
      }}
      onActiveNodeUpdated={onActiveNodeUpdated}
      onPathCompleted={pathCompleteActions => {
        if (pathCompleteActions && pathCompleteActions.redirectResult) {
          const redirectAction = () => {
            switch (pathCompleteActions.redirectResult?.__typename) {
              case 'ReturnToPreviousContextRedirectPathNodeFlowViewModelSuccess':
                if (onReturnToPreviousContext) {
                  onReturnToPreviousContext()
                } else {
                  throw new Error(
                    'Path redirect action attempted to return to previous context without handler'
                  )
                }
                break
              case 'SurveyResponseFinishRedirectPathNodeFlowViewModelSuccess':
                if (onSurveyResponseFinished) {
                  onSurveyResponseFinished()
                } else {
                  throw new Error(
                    'Path redirect action attempted to call survey finished callback but no handler was found'
                  )
                }
                break
              case 'ExpertKnowledgeTestFinishRedirectPathNodeFlowViewModelSuccess':
                if (onExpertKnowledgeTestFinished) {
                  onExpertKnowledgeTestFinished({
                    passed: pathCompleteActions.redirectResult.expertKnowledgeTest.isPassed,
                    passingPercentage:
                      pathCompleteActions.redirectResult.expertKnowledgeTest.passingPercentage,
                    scorePercentage:
                      pathCompleteActions.redirectResult.expertKnowledgeTest.scorePercentage
                  })
                } else {
                  throw new Error(
                    'Path redirect action attempted to call expert knowledge test finished callback but no handler was found'
                  )
                }
                break
            }
          }

          redirectAction()
        } else {
          console.warn('Path completed with no eligible action for expert')
        }
      }}
    />
  )
}

export default StandalonePath
