import React, { useContext, useState } from 'react'
import { UserProfileForLeadViewFilter } from '#src/generated/types'

export type QueryFilters = {
  filter: UserProfileForLeadViewFilter
}

type UserNeedsFilterContextType = {
  queryFilters: QueryFilters
  setQueryFilters: (queryFilter: QueryFilters) => void
}

const UserNeedsFilterContext = React.createContext<UserNeedsFilterContextType>({
  queryFilters: { filter: { showArchived: false } },
  setQueryFilters: () => {}
})

// Consumer
const UserNeedsFilterConsumer = UserNeedsFilterContext.Consumer

// Provider
const UserNeedsFilterProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [queryFilters, setQueryFilters] = useState<QueryFilters>({
    filter: { showArchived: false }
  })

  return (
    <UserNeedsFilterContext.Provider
      value={{
        queryFilters,
        setQueryFilters: (queryFilter: QueryFilters) => setQueryFilters(queryFilter)
      }}
    >
      <>{children}</>
    </UserNeedsFilterContext.Provider>
  )
}

const useUserNeedsFilterContext = () => {
  const context = useContext(UserNeedsFilterContext)

  if (!context) {
    throw new Error('Invoked UserNeedsFilterContext outside of provider')
  }

  return context
}

export {
  UserNeedsFilterConsumer,
  UserNeedsFilterProvider,
  UserNeedsFilterContext,
  useUserNeedsFilterContext
}
