import React, { useContext, useRef } from 'react'
import isAfter from 'date-fns/isAfter'
import subDays from 'date-fns/subDays'
import { toast } from 'react-toastify'
import { useExperiment } from '@deal/experiment-js'
import { playNotificationSound } from '#src/app/services/desktopNotification'
import { useHasPermission } from '#src/app/utilities/permission'
import { Permission } from '#src/generated/types'
import {
  SearchJiraTicketsForSupportPageDocument,
  SearchJiraTicketsForSupportPageQuery
} from '#src/app/routes/help-center/SupportPage/SupportPage.generated'
import {
  useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription,
  useSearchKnowledgeCardsForHelpCenterNewReleasesQuery,
  useUpdateKnowledgeUserForHelpCenterMutation
} from './HelpCenterNewReleases.generated'
import useBusinessUser from '#src/app/hooks/useBusinessUser'

type HelpCenterNewReleasesContextType = {
  unviewedNewReleaseCardCount: number
  unviewedJiraTicketCount: number
  updateKnowledgeUserViewedNewReleases: () => void
}

const HelpCenterNewReleasesContext = React.createContext<HelpCenterNewReleasesContextType>({
  unviewedNewReleaseCardCount: 0,
  unviewedJiraTicketCount: 0,
  updateKnowledgeUserViewedNewReleases: () => {}
})

const HelpCenterNewReleasesConsumer = HelpCenterNewReleasesContext.Consumer

const HelpCenterNewReleasesProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const createdAfterRef = useRef(subDays(new Date().setHours(0, 0, 0, 0), 7).toISOString())
  const businessUser = useBusinessUser()
  const [canReadJiraTickets] = useHasPermission(Permission.TEMPLATE_MANAGE)

  const [updateKnowledgeUser] = useUpdateKnowledgeUserForHelpCenterMutation()

  const { result: helpCenterExperimentResult } = useExperiment({
    experiment: 'biz-app-help-center-tab',
    defaultTreatment: 'control'
  })

  const { data } = useSearchKnowledgeCardsForHelpCenterNewReleasesQuery({
    variables: {
      departmentId: businessUser.department.id,
      createdAfter: createdAfterRef.current
    },
    skip: helpCenterExperimentResult !== 'v2'
  })

  const unviewedNewReleaseCardCount =
    data?.searchKnowledgeCards.filter(card =>
      isAfter(
        new Date(card.createdAt),
        new Date(businessUser.knowledgeManagementUser?.lastReleaseCardViewedAt)
      )
    ).length || 0

  useJiraTicketChangedForExpertForHelpCenterNewReleasesSubscription({
    variables: { expertId: businessUser.id },
    skip: !canReadJiraTickets,
    onData: ({ client, data: { data } }) => {
      if (
        (data?.jiraTicketChangedForExpert.expert.jiraTicketsUnread || 0) >
        (businessUser.jiraTicketsUnread || 0)
      ) {
        const playSound = window.localStorage.getItem('playSoundForSupportTicketUpdate') === 'on'

        if (playSound) {
          playNotificationSound()
        }

        toast.info('There has been a change to one of your support tickets', {
          autoClose: 5000,
          position: 'bottom-left'
        })
      }

      const prev = client.readQuery<SearchJiraTicketsForSupportPageQuery>({
        query: SearchJiraTicketsForSupportPageDocument,
        variables: {
          query: {
            expertId: businessUser.id
          }
        }
      })

      if (!data || !prev?.searchJiraTickets.response) {
        return
      }

      const updatedTicket = data.jiraTicketChangedForExpert
      const updatedTicketId = updatedTicket.id

      const updatedIssues = [...prev.searchJiraTickets.response.issues].filter(
        issue => issue.id !== updatedTicketId
      )
      updatedIssues.unshift(updatedTicket)

      client.writeQuery({
        query: SearchJiraTicketsForSupportPageDocument,
        variables: {
          query: {
            expertId: businessUser.id
          }
        },
        data: {
          searchJiraTickets: {
            ...prev.searchJiraTickets,
            response: {
              ...prev.searchJiraTickets.response,
              issues: updatedIssues
            }
          }
        }
      })
    }
  })

  const value = {
    unviewedNewReleaseCardCount,
    unviewedJiraTicketCount: businessUser.jiraTicketsUnread || 0,
    updateKnowledgeUserViewedNewReleases: () => {
      updateKnowledgeUser({
        variables: {
          input: {
            id: businessUser.id,
            lastReleaseCardViewedAt: new Date().toISOString()
          }
        }
      })
    }
  }

  return (
    <HelpCenterNewReleasesContext.Provider value={value}>
      {children}
    </HelpCenterNewReleasesContext.Provider>
  )
}

const useHelpCenterNewReleasesContext = () => {
  const context = useContext(HelpCenterNewReleasesContext)

  if (!context) {
    throw new Error('Invoked HelpCenterNewReleasesContext outside of provider')
  }

  return context
}

export {
  HelpCenterNewReleasesContext,
  HelpCenterNewReleasesConsumer,
  HelpCenterNewReleasesProvider,
  useHelpCenterNewReleasesContext
}
