import React, { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import {
  MediaAssetShareStatsPaginationForEveryoneRecentsPanelDocument,
  useMediaAssetShareStatsPaginationForEveryoneRecentsPanelQuery
} from './EveryoneRecentsPanel.generated'
import GeneralRecentsPanel from '../GeneralRecentsPanel'
import { MediaAssetShareStatsSortOrder } from '../../../../../generated/types'

const RESULTS_PER_FETCH = 6

interface EveryoneRecentsPanelProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const EveryoneRecentsPanel: React.FC<EveryoneRecentsPanelProps> = props => {
  const apolloClient = useApolloClient()
  const [moreResultsMightExist, setMoreResultsMightExist] = useState(true)

  const { expertAttributes } = useBusinessUser()
  const myCategoryId = expertAttributes.category.id

  const queryVariables = {
    input: {
      sharedByCategoryId: myCategoryId
    },
    sortBy: MediaAssetShareStatsSortOrder.USE_COUNT_BOOSTED,
    limit: RESULTS_PER_FETCH
  }

  const {
    data,
    loading,
    fetchMore
  } = useMediaAssetShareStatsPaginationForEveryoneRecentsPanelQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data.mediaAssetShareStatsPagination.mediaAssetShareStats.length === 0) {
        setMoreResultsMightExist(false)
      }
    }
  })

  const mediaListings = data?.mediaAssetShareStatsPagination.mediaAssetShareStats || []

  const maybeFetchMoreResults = useCallback(
    (onMoreResultsConfirmed?: () => void) => {
      if (!moreResultsMightExist) {
        return
      }

      fetchMore({
        variables: { offset: mediaListings.length }
      }).then(res => {
        if (!res.data.mediaAssetShareStatsPagination.mediaAssetShareStats.length) {
          setMoreResultsMightExist(false)
        } else {
          apolloClient.cache.writeQuery({
            query: MediaAssetShareStatsPaginationForEveryoneRecentsPanelDocument,
            variables: queryVariables,
            data: {
              mediaAssetShareStatsPagination: {
                mediaAssetShareStats: [
                  ...mediaListings,
                  ...res.data.mediaAssetShareStatsPagination.mediaAssetShareStats
                ]
              }
            }
          })
          onMoreResultsConfirmed?.()
        }
      })
    },
    [mediaListings, moreResultsMightExist, setMoreResultsMightExist, fetchMore]
  )

  const listingPagination = {
    loading,
    moreResultsMightExist,
    mediaListings,
    maybeFetchMoreResults
  }
  return <GeneralRecentsPanel listingPagination={listingPagination} {...props} />
}

export default EveryoneRecentsPanel
