import React, { useContext, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { useHistory } from '@deal/router'
import { FOLDER_SLUGS } from '#src/app/utilities/displayNames'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { useLeadSuggestedActionGracePeriodChangedSubscription } from './LeadSuggestedActionGracePeriodChanged.generated'
import CountDown from './CountDown'
import styles from './styles.css'
import useBusinessUser from '#src/app/hooks/useBusinessUser'

export type PastSlaBannerElementType = {
  suggestedActionGracePeriodEndsAt: Date
  leadId: string
}

const LeadSLABanner: React.FC<React.PropsWithChildren<unknown>> = () => {
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const { id: expertId } = useBusinessUser()
  const history = useHistory()
  const [pastSLA, setPastSLA] = useState<PastSlaBannerElementType[]>([])

  useLeadSuggestedActionGracePeriodChangedSubscription({
    variables: {
      expertId
    },
    skip: !expertId,
    onData: ({ data: { data } }) => {
      const lead = data?.leadSuggestedActionGracePeriodChanged.lead
      const type = data?.leadSuggestedActionGracePeriodChanged.type
      const curAry = [...pastSLA]
      const curTime = new Date()
      const slaTime = new Date(lead?.suggestedActionGracePeriodEndsAt)
      const curPastSla = {
        suggestedActionGracePeriodEndsAt: slaTime,
        leadId: lead?.id
      }

      //3 CASE: ADD, REMOVE, UPDATE
      if (type === 'ADD') {
        const isExisted = curAry.find(element => element.leadId === lead?.id)
        // do not add the new time if it is already out of date and it is already saved locally
        if (slaTime > curTime && !isExisted) {
          curAry.push(curPastSla)
        }
        const sortedArray = curAry.sort(
          (a, b) =>
            a.suggestedActionGracePeriodEndsAt.valueOf() -
            b.suggestedActionGracePeriodEndsAt.valueOf()
        )

        setPastSLA(cloneDeep(sortedArray))
      } else if (type === 'REMOVE') {
        const filteredAry = curAry.filter(element => element.leadId !== lead?.id)

        setPastSLA(cloneDeep(filteredAry))
      } else {
        const updatedAry = []
        for (const element of curAry) {
          if (slaTime > curTime) {
            if (element.leadId === lead?.id) {
              updatedAry.push(curPastSla)
            } else {
              updatedAry.push(element)
            }
          }
        }

        setPastSLA(cloneDeep(updatedAry))
      }
    }
  })

  if (pastSLA.length === 0) {
    return null
  }

  const pastSLACount = pastSLA.length

  const SLADealineInSec = differenceInSeconds(
    pastSLA[0].suggestedActionGracePeriodEndsAt,
    new Date()
  )
  return (
    <div className={styles.container}>
      <CountDown
        startTime={SLADealineInSec}
        onSLAReassignTimeReached={setPastSLA}
        pastSLA={pastSLA}
      />
      {pastSLACount === 1 ? (
        <>
          <span className={styles.content}>
            SLA overdue: Your lead will be reassigned unless you take action
          </span>
          <button
            className={styles.button}
            onClick={() => {
              //simulating click !!button
              leadSearchFolderContext.setFolder(
                FOLDER_SLUGS.LABEL_ACTIVE,
                FOLDER_SLUGS.LABEL_NEEDS_ACTION
              )
              history.push(`/inbox/${pastSLA[0].leadId}`)
            }}
          >
            View lead
          </button>
        </>
      ) : (
        <>
          <span className={styles.content}>
            {pastSLA.length} SLA overdue: Your leads will be reassigned unless you take action
          </span>
          <button
            className={styles.button}
            onClick={() => {
              //simulating click !!button
              leadSearchFolderContext.setFolder(
                FOLDER_SLUGS.LABEL_ACTIVE,
                FOLDER_SLUGS.LABEL_NEEDS_ACTION
              )
              history.push('/inbox')
            }}
          >
            View leads
          </button>
        </>
      )}
    </div>
  )
}

export default LeadSLABanner
