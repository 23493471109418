import React, { useContext } from 'react'
import { BusinessUserState } from '#src/generated/types'
import { playNotificationSound } from '#src/app/services/desktopNotification'
import { AccountsNeedAttentionContext } from '#src/app/context/AccountsNeedAttention'
import { IdentityContext } from '#src/app/containers/Identity'
import { AttentionNeededLeadCountChangedComponent } from './AttentionNeededLeadChangedCount.generated'

const AccountsNeedAttentionSubscription: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { myself } = useContext(IdentityContext)
  const { updateLeadCountByExpertId, leadCountByExpertId } = useContext(
    AccountsNeedAttentionContext
  )

  if (!myself || !myself.businessUser) {
    return null
  }
  const bizUsers = myself.businessUser.user.assignedBusinessUsers.filter(
    expert =>
      expert.id !== myself.businessUser?.id &&
      expert.isExpert &&
      expert.state === BusinessUserState.ACTIVE
  )
  return (
    <>
      {bizUsers.map(expert => {
        return (
          <AttentionNeededLeadCountChangedComponent
            key={expert.id}
            variables={{
              expertId: expert.id
            }}
            onData={({ data: { data } }) => {
              if (data) {
                const updatedCount = data.attentionNeededLeadCountChanged.count
                const prevCount = leadCountByExpertId(expert.id)
                updateLeadCountByExpertId(expert.id, updatedCount)
                const playSound =
                  window.localStorage.getItem('playSoundForNeedsActionInOtherAccounts') === 'on'
                if (playSound && updatedCount > prevCount) {
                  playNotificationSound()
                }
              }
            }}
          >
            {() => null}
          </AttentionNeededLeadCountChangedComponent>
        )
      })}
    </>
  )
}

export default AccountsNeedAttentionSubscription
