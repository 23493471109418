import React from 'react'
import upperFirst from 'lodash/upperFirst'
import { formatDate } from '@deal/date-utils'
import { Avatar, Modal } from '@deal/components'
import { ConsumerReviewAddedActivityForConsumerReviewModalFragment } from './ConsumerReviewAddedActivityForConsumerReviewModal.generated'
import StarIcon from './star.svg'
import styles from './styles.css'

interface Props {
  onRequestClose: () => void
  review: ConsumerReviewAddedActivityForConsumerReviewModalFragment
}

const ConsumerReviewModal: React.FC<React.PropsWithChildren<Props>> = ({
  review,
  onRequestClose
}) => {
  const { lead, rating, comments, goodQualities, headline, occurredAt } = review
  const { consumer, expert } = lead
  const expertName = expert.displayName
  const consumerName = `${consumer.firstName}${
    consumer.lastName ? ` ${consumer.lastName[0].toUpperCase()}.` : ''
  }`

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      title={`New ${rating}-star review`}
      primaryAction={{
        label: 'Done',
        onClick: onRequestClose
      }}
    >
      <div className={styles.content}>
        <div className={styles.expert}>
          <Avatar
            size={40}
            name={expertName}
            src={expert.expertProfile?.profilePhoto?.url || undefined}
          />
          <div className={styles.message}>
            <strong>{expertName}</strong> received a new review from <strong>{consumerName}</strong>
          </div>
        </div>
        <div className={styles.reviewDetails}>
          <Stars stars={review.rating} />
          <h2 className={styles.headline}>{headline || 'Review'}</h2>
          {goodQualities.length > 0 && (
            <div>
              <div className={styles.qualityHeader}>{expert.shortName} is: </div>
              <div className={styles.qualitiesContainer}>
                {goodQualities.map(quality => (
                  <div className={styles.quality} key={quality}>
                    {upperFirst(quality.toLowerCase().replace('_', ' '))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <p className={styles.comment}>{comments}</p>
        <div className={styles.consumerDetails}>
          <strong>{consumerName}</strong> - Submitted {formatDate(occurredAt, { format: 'long' })}
        </div>
      </div>
    </Modal>
  )
}

const Stars: React.FC<React.PropsWithChildren<{ stars: number }>> = ({ stars }) => {
  const starArray = [...Array(stars)]
  return (
    <div className={styles.starContainer}>
      {starArray.map(num => (
        <StarIcon key={num} className={styles.star} />
      ))}
    </div>
  )
}

export default ConsumerReviewModal
