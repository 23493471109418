import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Link } from '@deal/router'
import { useInterval } from '@deal/dom-hooks'
import {
  Attachment,
  Chat,
  ComposerControlProps,
  FirebaseConversationEvent,
  useConversationChangedSubscription
} from '@deal/chat-firebase'
import { FileData } from '#src/app/typings/media'
import { isBusinessMobileNative, isMobileBrowser } from '#src/app/services/mobile'
import { playNotificationSound } from '#src/app/services/desktopNotification'
import { useUserAgentContext } from '#src/app/context/UserAgent'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import Toast from '#src/app/components/Toast'
import QuickReplyControl from '#src/app/components/QuickReplyControl'
import EmojiPickerControl from '#src/app/components/EmojiPickerControl'
import {
  ConsumerFirstMessageSentActivityFragment,
  LeadCreatedActivityFragment
} from '../../components/LeadActivitySubscription/LeadActivityOccurred.generated'
import formattedToast from '../formattedToast'
import VIPIcon from './vip.svg'
import styles from './styles.css'

interface Props {
  activity: ConsumerFirstMessageSentActivityFragment | LeadCreatedActivityFragment
  closeToast?: () => void
}

const formatTime = (secondsLeft: number) => {
  const minutes = Math.floor(secondsLeft / 60)
  const seconds = secondsLeft - minutes * 60
  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
}

const NewLeadToast: React.FC<Props> = ({ closeToast, activity }) => {
  const userAgent = useUserAgentContext()
  const [showQuickReplyPopover, setShowQuickReplyPopover] = useState(false)
  const { setShowQuickReplyTooltip } = useQuickReplyContext()

  const onControlClicked = () => {
    setShowQuickReplyPopover(!showQuickReplyPopover)
    setShowQuickReplyTooltip(false)
  }

  const { id: leadId, conversation, consumer } = activity.lead

  const [secondsToReply, setSecondsToReply] = useState(90)
  const [showChat, setShowChat] = useState(false)
  // Setting interval delay to null will clear interval
  useInterval(
    () => secondsToReply > 0 && setSecondsToReply(secondsToReply - 1),
    secondsToReply === -1 ? null : 1000
  )

  const handleConversationEvent = (event: FirebaseConversationEvent) => {
    if (event.conversation?.lastMessage?.sentBy.isMyself) {
      closeToast?.()
    }
  }

  useConversationChangedSubscription({
    conversationId: conversation.externalId,
    onConversationChanged: handleConversationEvent
  })

  useEffect(() => {
    const playSound = window.localStorage.getItem('playSoundForNewLead') === 'on'
    if (playSound) {
      playNotificationSound()
    }
  }, [leadId])

  // Every render causes this inline function to be re-defined and re-mounted as a result
  // useMemo allows us to prevent that from happening unless the lead or visibility changes
  const MemoizedQuickReplyControl = useMemo(
    () => (props: ComposerControlProps<Attachment<FileData>>) => (
      <QuickReplyControl
        {...props}
        onCloseRequested={() => setShowQuickReplyPopover(false)}
        onControlClicked={onControlClicked}
        isVisible={showQuickReplyPopover}
        leadId={leadId}
        hideManageButton
      />
    ),
    [leadId, showQuickReplyPopover]
  )

  return (
    <Toast
      className={classnames({ [styles.mobileFullScreen]: showChat })}
      onCloseToastRequested={() => closeToast?.()}
    >
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.time}>{formatTime(secondsToReply)}</div>
          <div className={styles.textContainer}>
            <div className={styles.progressBar} />
            <Link to={`/inbox/${leadId}`} onClick={closeToast} className={styles.link}>
              New lead: {consumer.firstName} {consumer.vip && <VIPIcon />}
            </Link>
          </div>
        </div>

        {!showChat && (
          <button className={styles.reply} onClick={() => setShowChat(!showChat)}>
            Reply
          </button>
        )}

        {showChat && (
          <div className={styles.dropdown}>
            <Chat
              conversationId={conversation.externalId}
              controls={[
                // disable the emoji picker on mobile browser or native mobile app
                // (not needed on mobile since they have built-in support)
                ...(isMobileBrowser(userAgent) || isBusinessMobileNative()
                  ? []
                  : [EmojiPickerControl]),
                MemoizedQuickReplyControl
              ]}
              onSendMessage={() => closeToast && closeToast()}
            />
          </div>
        )}
      </div>
    </Toast>
  )
}

const newLeadToast = (
  props: ConsumerFirstMessageSentActivityFragment | LeadCreatedActivityFragment
) => formattedToast(<NewLeadToast activity={props} />)

export default newLeadToast
