import React, { useEffect, useState } from 'react'
import { Button, Select, TextField } from '@deal/components'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import {
  SuggestedActionForQuickReplyPopoverContentFragment,
  useQuickReplyPopoverContentQuery
} from './QuickReplyPopoverContent.generated'
import SuggestedMessageList, { SuggestedMessageListProps } from '../SuggestedMessageList'
import styles from './styles.css'
import { suggestedMessageTagNameAllowList } from '#src/app/utilities/suggestedActionUtils'

export interface QuickReplyPopoverContentProps
  extends Omit<
    SuggestedMessageListProps,
    'searchTerm' | 'suggestedMessageTag' | 'isManageQuickRepliesModalOpen' | 'suggestedAction'
  > {
  leadId: string
  suggestedAction?: SuggestedActionForQuickReplyPopoverContentFragment
  showSuggestedMessageRevisionTemplate?: boolean
  hideManageButton?: boolean
  onBackRequested?: () => void
}
const QuickReplyPopoverContent: React.FC<React.PropsWithChildren<
  QuickReplyPopoverContentProps
>> = ({ suggestedAction, hideManageButton, onBackRequested, ...passThroughProps }) => {
  const {
    defaultTags,
    showManageQuickRepliesModal,

    setShowManageQuickRepliesModal,
    setDefaultTags,
    resetDefaultTag
  } = useQuickReplyContext()
  const [searchTerm, setSearchTerm] = useState('')
  const [suggestedMessageTag, setSuggestedMessageTag] = useState(
    suggestedAction?.suggestedActionRevision?.tags[0] || defaultTags[0] || ''
  )
  const { data, refetch: refetchQuickReplies } = useQuickReplyPopoverContentQuery({
    variables: {}
  })

  const filteredTags = data?.suggestedMessageTags.enumEntries.filter(
    enumEntry => !enumEntry.deprecated && suggestedMessageTagNameAllowList.has(enumEntry.name)
  )

  // When we close the popover, reset the default state
  useEffect(() => {
    return () => resetDefaultTag()
  }, [])

  // When the manage quick replies modal is closed, we want to refetch the quick replies in case things have changed
  useEffect(() => {
    if (!showManageQuickRepliesModal) {
      refetchQuickReplies()
    }
  }, [showManageQuickRepliesModal])

  const tagOptions = filteredTags?.map(enumEntry => ({
    label: enumEntry.displayName,
    value: enumEntry.name,
    disabled: false
  }))

  return (
    <div className={styles.popoverContent}>
      {!!onBackRequested && (
        <Button
          className={styles.backButton}
          variant="secondary-link"
          size="xsmall"
          onClick={onBackRequested}
        >
          Back
        </Button>
      )}
      <div className={styles.topLine}>
        <div className={styles.header}>Quick replies</div>

        {!hideManageButton && (
          <div
            className={styles.manage}
            onClick={() => {
              setShowManageQuickRepliesModal(true)
            }}
          >
            Manage quick replies
          </div>
        )}
      </div>
      {tagOptions && (
        <div className={styles.dropdown}>
          <Select
            label="Tag"
            labelHidden
            value={suggestedMessageTag}
            options={tagOptions}
            onChange={e => {
              setSuggestedMessageTag(e.target.value)
              setDefaultTags([e.target.value])
            }}
          />
          <TextField
            label="Search..."
            labelHidden
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value)
            }}
            placeholder="Find a quick reply..."
          />
        </div>
      )}

      <div className={styles.messagesContainer}>
        <SuggestedMessageList
          {...passThroughProps}
          suggestedAction={suggestedAction}
          searchTerm={searchTerm}
          suggestedMessageTag={suggestedMessageTag}
          isManageQuickRepliesModalOpen={showManageQuickRepliesModal}
        />
      </div>
    </div>
  )
}

export default QuickReplyPopoverContent
