// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertSelectionSettingFragment = { __typename: 'ExpertSelectionSetting', isExpertSelectionEnabled: boolean, disabledUntil?: any | null };

export type BusinessUserForExpertSelectionSettingFragment = { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', expertSelectionSetting: { __typename: 'ExpertSelectionSetting', isExpertSelectionEnabled: boolean, disabledUntil?: any | null } } };

export type UpdateExpertSelectionSettingMutationVariables = Types.Exact<{
  input: Types.UpdateExpertSelectionSettingInput;
}>;


export type UpdateExpertSelectionSettingMutation = { __typename: 'Mutation', updateExpertSelectionSetting: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', expertSelectionSetting: { __typename: 'ExpertSelectionSetting', isExpertSelectionEnabled: boolean, disabledUntil?: any | null } } } };

export const ExpertSelectionSettingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExpertSelectionSetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertSelectionSetting"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isExpertSelectionEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"disabledUntil"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForExpertSelectionSettingFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertSelectionSetting"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertSelectionSetting"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExpertSelectionSetting"}}]}}]}}]}}]} as unknown as DocumentNode;
export const UpdateExpertSelectionSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateExpertSelectionSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateExpertSelectionSettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateExpertSelectionSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"BusinessUserForExpertSelectionSetting"}}]}}]}},...BusinessUserForExpertSelectionSettingFragmentDoc.definitions,...ExpertSelectionSettingFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateExpertSelectionSettingMutationFn = Apollo.MutationFunction<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables>;
export type UpdateExpertSelectionSettingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables>, 'mutation'>;

    export const UpdateExpertSelectionSettingComponent = (props: UpdateExpertSelectionSettingComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables> mutation={UpdateExpertSelectionSettingDocument} {...props} />
    );
    

/**
 * __useUpdateExpertSelectionSettingMutation__
 *
 * To run a mutation, you first call `useUpdateExpertSelectionSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpertSelectionSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpertSelectionSettingMutation, { data, loading, error }] = useUpdateExpertSelectionSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpertSelectionSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables>(UpdateExpertSelectionSettingDocument, options);
      }
export type UpdateExpertSelectionSettingMutationHookResult = ReturnType<typeof useUpdateExpertSelectionSettingMutation>;
export type UpdateExpertSelectionSettingMutationResult = Apollo.MutationResult<UpdateExpertSelectionSettingMutation>;
export type UpdateExpertSelectionSettingMutationOptions = Apollo.BaseMutationOptions<UpdateExpertSelectionSettingMutation, UpdateExpertSelectionSettingMutationVariables>;