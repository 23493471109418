import React from 'react'
import loadable from '@loadable/component'
import { CircleLoader, FullBlockLoader } from '@deal/components'

export const Inbox = loadable(() => import('#src/app/routes/inbox'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Reports = loadable(() => import('#src/app/routes/reports'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Performance = loadable(() => import('#src/app/routes/performance'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Notifications = loadable(() => import('#src/app/routes/notifications'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Emails = loadable(() => import('#src/app/routes/emails'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Content = loadable(() => import('#src/app/routes/gigs'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Acquisition = loadable(() => import('#src/app/routes/acquisition'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const InfluencerProgram = loadable(() => import('#src/app/routes/influencer-program'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Curate = loadable(() => import('#src/app/routes/curate'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Auth = loadable(() => import('#src/app/routes/auth'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Profile = loadable(() => import('#src/app/routes/profile'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Onboarding = loadable(() => import('#src/app/routes/onboarding'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Scheduling = loadable(() => import('#src/app/routes/scheduling'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Training = loadable(() => import('#src/app/routes/training'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const HelpCenter = loadable(() => import('#src/app/routes/help-center'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Community = loadable(() => import('#src/app/routes/community'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const Tickets = loadable(() => import('#src/app/routes/tickets'), {
  resolveComponent: component => component.TicketsRoute,
  fallback: <FullBlockLoader loader={CircleLoader} />
})

export const PDPReview = loadable(() => import('#src/app/routes/pdp-review'), {
  fallback: <FullBlockLoader loader={CircleLoader} />
})
