import React, { useEffect, useState } from 'react'
import { useInterval } from '@deal/dom-hooks'
import { PastSlaBannerElementType } from '../index'
import styles from './styles.css'

interface Props {
  startTime: number
  pastSLA: PastSlaBannerElementType[]
  onSLAReassignTimeReached: (PastSlaBannerElementType: PastSlaBannerElementType[]) => void
}

const CountDown: React.FC<React.PropsWithChildren<Props>> = ({ startTime, pastSLA, onSLAReassignTimeReached }) => {
  const [secondsToReassigned, setSecondsToReassigned] = useState(startTime)
  const formatTime = (secondsLeft: number) => {
    const hours = Math.floor(secondsLeft / 3600)
    secondsLeft %= 3600
    const minutes = Math.floor(secondsLeft / 60)
    const seconds = secondsLeft % 60

    // If you want strings with leading zeroes:
    const minutesStr = String(minutes).padStart(2, '0')
    const hoursStr = String(hours).padStart(2, '0')
    const secondStr = String(seconds).padStart(2, '0')

    return `${hoursStr}:${minutesStr}:${secondStr}`
  }

  useEffect(() => {
    // remove the first past sla element when it is reach 0
    if (secondsToReassigned === 0) {
      const newPastSLAAry = [...pastSLA]
      newPastSLAAry.shift()
      onSLAReassignTimeReached(newPastSLAAry)
    }
  }, [secondsToReassigned])

  useEffect(() => {
    setSecondsToReassigned(startTime)
  }, [startTime])

  // Setting interval delay to null will clear interval
  useInterval(
    () => secondsToReassigned > 0 && setSecondsToReassigned(secondsToReassigned - 1),
    secondsToReassigned === -1 ? null : 1000
  )
  return <div className={styles.container}>{formatTime(secondsToReassigned)}</div>
}

export default CountDown
