import React, { useRef } from 'react'
import { ExpertPresenceStatus } from '#src/generated/types'
import { playNotificationSound } from '#src/app/services/desktopNotification'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import {
  useExpertLeadFolderChangedOnCurrentShiftSubscription,
  useExpertLeadFolderChangedSubscription,
  useExpertLeadFolderGroupsQuery
} from './ExpertLeadFolderGroups.generated'
import InboxFolderGroups from '../../InboxFolderGroups'

interface Props {
  onClosed: () => void
}

const ExpertLeadFolderGroups: React.FC<Props> = ({ onClosed }) => {
  const previousNoReplyCount = useRef(0)
  const businessUser = useBusinessUser()
  const { id: expertId } = businessUser

  const { data } = useExpertLeadFolderGroupsQuery({
    variables: {
      expertId
    }
  })

  useExpertLeadFolderChangedSubscription({ variables: { expertId } })

  useExpertLeadFolderChangedOnCurrentShiftSubscription({
    variables: { expertId },
    skip: businessUser.expertPresence.status !== ExpertPresenceStatus.ON_SHIFT,
    onData: ({ data: { data } }) => {
      const noReplyCount = data?.expertLeadFolderChangedOnCurrentShift.totalCountOnCurrentShift || 0
      if (data && noReplyCount > previousNoReplyCount.current) {
        window.localStorage.getItem('playLeadMatchSound') === 'on' && playNotificationSound()

        previousNoReplyCount.current = noReplyCount
      }
    }
  })

  if (data?.expertLeadFolderGroups) {
    return (
      <InboxFolderGroups expertLeadFolderGroups={data.expertLeadFolderGroups} onClosed={onClosed} />
    )
  } else {
    return null
  }
}

export default ExpertLeadFolderGroups
