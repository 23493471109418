import React, { useState } from 'react'
import { Modal, Text, useModalTrigger } from '@deal/bluxome'
import { useHistory } from '@deal/router'
import { toastError, toastSuccess } from '#src/app/utilities/toast'
import { useFireSavedLeadSearch } from '#src/app/utilities/savedSearches'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import SavedNameTextInput from '#src/app/components/Navigation/SavedNameTextInput'
import {
  FindExpertSavedLeadSearchesDocument,
  FindExpertSavedLeadSearchesQuery
} from '#src/app/components/Navigation/ExpertSavedSearches/ExpertSavedSearches.generated'
import ExpertSavedActions from '#src/app/components/Navigation/ExpertSavedActions'
import { useLeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import {
  ExpertSavedLeadSearchesForManageSavedSearchesModalFragment,
  useDeleteExpertSavedLeadSearchMutation,
  useUpdateExpertSavedLeadSearchMutation
} from './ManageSavedSearchesModal.generated'
import styles from './styles.css'

interface ManageSavedSearchesModalProps {
  savedSearches?: ExpertSavedLeadSearchesForManageSavedSearchesModalFragment[]
  onRequestClose: () => void
}

const ManageSavedSearchesModal: React.FC<ManageSavedSearchesModalProps> = ({
  savedSearches = [],
  onRequestClose
}) => {
  const history = useHistory()
  const businessUser = useBusinessUser()
  const { setIsCustomFacetMenuOpen } = useLeadSearchFolderContext()
  const [editingSearchId, setEditingSearchId] = useState<string | null>(null)
  const [editingSearchName, setEditingSearchName] = useState<string>('')
  const fireSavedLeadSearch = useFireSavedLeadSearch()

  const { state, overlayProps } = useModalTrigger({
    onOpenChange: isOpen => {
      if (!isOpen) {
        onRequestClose()
      }
    },
    isOpen: true
  })

  const [
    updateExpertSavedLeadSearch,
    { loading: updateMutationLoading }
  ] = useUpdateExpertSavedLeadSearchMutation({
    onCompleted: res => {
      if (res.updateExpertSavedLeadSearch.error) {
        toastError(res.updateExpertSavedLeadSearch.error.errorMessage)
      } else {
        handleEditingComplete()
        toastSuccess('Saved search updated')
      }
    }
  })

  const [
    deleteExpertSavedLeadSearch,
    { loading: deleteMutationLoading }
  ] = useDeleteExpertSavedLeadSearchMutation({
    onCompleted: res => {
      if (res.deleteExpertSavedLeadSearch.deletedId) {
        toastSuccess('Saved search deleted')
      } else {
        toastError('Failed to delete saved search')
      }
    },
    update: (cache, res) => {
      const variables = { input: { expertId: businessUser.id, includeSystemSearches: true } }

      const currentData = cache.readQuery<FindExpertSavedLeadSearchesQuery>({
        query: FindExpertSavedLeadSearchesDocument,
        variables
      })

      if (!res.data?.deleteExpertSavedLeadSearch.deletedId) {
        return
      }

      const currentSavedSearches = currentData?.findExpertSavedLeadSearches || []

      cache.writeQuery({
        query: FindExpertSavedLeadSearchesDocument,
        variables,
        data: {
          findExpertSavedLeadSearches: currentSavedSearches.filter(
            search => search.id !== res.data?.deleteExpertSavedLeadSearch.deletedId
          )
        }
      })
    }
  })

  const handleUpdateSavedSearch = (
    search: ExpertSavedLeadSearchesForManageSavedSearchesModalFragment
  ) => {
    updateExpertSavedLeadSearch({
      variables: {
        input: {
          id: search.id,
          name: editingSearchName
        }
      }
    })
  }

  const handleEditingComplete = () => {
    setEditingSearchId(null)
    setEditingSearchName('')
  }

  const loading = updateMutationLoading || deleteMutationLoading

  return (
    <Modal
      state={state}
      overlayProps={overlayProps}
      title="Edit Saved Searches"
      width="medium"
      actions={{
        primaryAction: {
          label: 'Done',
          onPress: onRequestClose
        },
        secondaryAction: {
          label: 'Cancel',
          onPress: onRequestClose
        },
        tertiaryAction: {
          label: 'Create new search',
          onPress: () => {
            setIsCustomFacetMenuOpen(true)
            history.push('/inbox')
            onRequestClose()
          }
        },
        color: 'neutral-dark'
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Saved Search</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {savedSearches.map(search => {
            const isBeingEdited = search.id === editingSearchId

            return (
              <tr key={search?.id}>
                <td>
                  {isBeingEdited ? (
                    <SavedNameTextInput
                      size="small"
                      value={editingSearchName}
                      onChange={value => setEditingSearchName(value)}
                      onEnterPressed={() => {
                        if (search.name !== editingSearchName) {
                          handleUpdateSavedSearch(search)
                        }
                      }}
                      isDisabled={loading}
                    />
                  ) : (
                    <>
                      <Text as="div">{search?.name}</Text>
                      <Text as="div" style="extra-small" color="neutrals-600">
                        {search.isSystem ? 'System Recommended' : 'Custom'}
                      </Text>
                    </>
                  )}
                </td>
                <td>
                  <ExpertSavedActions
                    onViewLeads={() => {
                      fireSavedLeadSearch(search)
                      onRequestClose()
                    }}
                    onEdit={() => {
                      setEditingSearchId(search.id)
                      setEditingSearchName(search.name)
                    }}
                    onDelete={() => {
                      deleteExpertSavedLeadSearch({
                        variables: {
                          input: {
                            id: search.id
                          }
                        }
                      })
                    }}
                    onSave={() => {
                      if (search.name !== editingSearchName) {
                        handleUpdateSavedSearch(search)
                      } else {
                        handleEditingComplete()
                      }
                    }}
                    isDisabled={loading}
                    isBeingEdited={isBeingEdited}
                    isSystem={search.isSystem}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Modal>
  )
}

export default ManageSavedSearchesModal
