import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useApolloClient } from '@apollo/client'
import { useHistory } from '@deal/router'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import { useMyselfLazyQuery } from '#src/app/containers/Identity/Myself.generated'
import { ApolloReconnectContext } from '#src/app/containers/ApolloReconnect'
import { useAssumeBusinessUserIdentityMutation } from '../../mutations/AssumeBusinessUserIdentity.generated'
import styles from './styles.css'

const ChangeAccounts: React.FC<{ newExpertId: string }> = ({ newExpertId }) => {
  const apolloClient = useApolloClient()
  const history = useHistory()
  const reconnect = useContext(ApolloReconnectContext)
  const [fetchMyself] = useMyselfLazyQuery()

  /**
   * When we assume a new business user identity we must do the following steps in order
   *   1. Clear the Apollo cache
   *   2. Refetch the expert's current identity to populate the Apollo cache
   *   3. Reconnect Apollo client
   *   4. Redirect the expert back to the biz app
   *
   * If we don't refetch the expert's identity to populate the Apollo cache before rendering rest of the app, the requests will fail
   * because the identity in the JWT token will not match what is in the Apollo cache (since it is still undefined). We need to make sure we update
   * the Apollo cache before running any other queries or subscriptions.
   */
  const [assumeBusinessUserIdentity] = useAssumeBusinessUserIdentityMutation({
    onError: () => toast.error('Failed to switch accounts'),
    onCompleted: () => {
      apolloClient
        .clearStore()
        .then(() => fetchMyself())
        .then(() => reconnect?.())
        .then(() => history.push({ pathname: '/inbox' }))
    }
  })

  useEffect(() => {
    assumeBusinessUserIdentity({ variables: { input: { businessUserId: newExpertId } } })
  }, [])

  return (
    <div className={styles.container}>
      Signing in. Please wait...
      <FullBlockLoader loader={CircleLoader} />
    </div>
  )
}

export default ChangeAccounts
