import React, { useContext, useEffect, useRef } from 'react'
import { useHistory } from '@deal/router'
import { NavigateToMessage } from '#src/app/services/dealNativeBridge'
import {
  Disposable,
  WebMessageEventEmitter,
  WebMessageEventEmitterContext
} from '../ReceiveMessageFromNative'

interface Props {
  eventEmitter: WebMessageEventEmitter
}

const NavigateOnEvent: React.FC<React.PropsWithChildren<Props>> = ({ children, eventEmitter }) => {
  const history = useHistory()
  const disposable = useRef<null | Disposable>(null)

  useEffect(() => {
    disposable.current = eventEmitter.on<NavigateToMessage>('navigateTo', msg => {
      if (msg.page.name === 'leadConversation') {
        history.push(`/inbox/${msg.page.leadId}`)
      } else {
        console.error('Request to navigate to unknown page: ', msg)
      }
    })
    return () => {
      disposable.current && disposable.current.dispose()
    }
  }, [])

  return <>{children}</>
}

const WrappedNavigateWithConsumer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const eventEmitter = useContext(WebMessageEventEmitterContext)

  if (eventEmitter) {
    return <NavigateOnEvent eventEmitter={eventEmitter} />
  } else {
    return null
  }
}

export default WrappedNavigateWithConsumer
