import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import differenceInDays from 'date-fns/differenceInDays'
import addDays from 'date-fns/addDays'
import {
  Button,
  CircleLoader,
  FullBlockLoader,
  Modal,
  Notification,
  SingleDatePickerWithTime,
  TextArea
} from '@deal/components'
import { AwayAutoReplyStatus } from '#src/generated/types'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import {
  AwayAutoReplyFragment,
  useActiveOrPendingAwayAutoReplyQuery,
  useLeadsForAwayAutoReplyQuery,
  useRemoveExpertAwayAutoReplyMutation,
  useSetExpertAwayAutoReplyMutation
} from './ManageAwayAutoReply.generated'
import styles from './styles.css'

function getTitle(isForExtendedAway: boolean, awayAutoReply?: AwayAutoReplyFragment | null) {
  const activeAwayOfSameTypeExists =
    awayAutoReply?.status === AwayAutoReplyStatus.ACTIVE &&
    ((awayAutoReply?.isExtendedAway && isForExtendedAway) ||
      (!awayAutoReply?.isExtendedAway && !isForExtendedAway))
  const status = activeAwayOfSameTypeExists ? ' - active' : awayAutoReply ? ' - pending' : ''
  const title = isForExtendedAway ? 'Extended away message' : 'Away message'
  return title + status
}

function getErrorMessage(
  message: string,
  effectiveFrom: Date,
  effectiveUntil: Date,
  isForExtendedAway: boolean
) {
  switch (true) {
    case !message:
      return 'Please provide an away message'
    case effectiveFrom >= effectiveUntil:
      return 'Start date must be before the end date'
    case !isForExtendedAway && differenceInDays(effectiveUntil, effectiveFrom) > 30:
      return 'Away message can be set for a maximum of 30 days'
    case isForExtendedAway && differenceInDays(effectiveUntil, effectiveFrom) <= 30:
      return 'Extended away message must be set for a minimum of 30 days'
    case isForExtendedAway && differenceInDays(effectiveUntil, effectiveFrom) > 180:
      return 'Extended away message can be set for a maximum of 180 days'
  }
}

interface Props {
  onRequestClose: () => void
  expertId: string
  isForExtendedAway?: boolean
}
const ManageAwayAutoReply: React.FC<Props> = ({
  expertId,
  isForExtendedAway = false,
  onRequestClose
}) => {
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const [message, setMessage] = useState('')
  const [effectiveFrom, setEffectiveFrom] = useState<Date>(new Date())
  const [effectiveUntil, setEffectiveUntil] = useState<Date>(addDays(new Date(), 7))

  const { data } = useActiveOrPendingAwayAutoReplyQuery({
    variables: {
      expertId: expertId
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data && data.businessUser && data.businessUser.activeOrPendingAwayAutoReply) {
        setMessage(data.businessUser.activeOrPendingAwayAutoReply.message)
        setEffectiveFrom(new Date(data.businessUser.activeOrPendingAwayAutoReply.effectiveFrom))
        setEffectiveUntil(new Date(data.businessUser.activeOrPendingAwayAutoReply.effectiveUntil))
      }
    }
  })

  const {
    data: currentLeadsForAwayAutoReplyData,
    previousData: previousLeadsForAwayAutoReplyData,
    loading
  } = useLeadsForAwayAutoReplyQuery({
    variables: {
      expertId: expertId,
      range: [
        {
          field: 'SNOOZE_WAKEUP_DATE',
          minDateTime: effectiveFrom,
          maxDateTime: effectiveUntil,
          negativeFilter: false
        }
      ]
    },
    fetchPolicy: 'network-only'
  })

  const leadsForAwayAutoReplyData =
    currentLeadsForAwayAutoReplyData || previousLeadsForAwayAutoReplyData

  const [setExpertAwayMessage] = useSetExpertAwayAutoReplyMutation({
    onCompleted: data => {
      if (data.setExpertAwayAutoReply.error) {
        toast.error(data.setExpertAwayAutoReply.error)
      } else if (
        data.setExpertAwayAutoReply.businessUser &&
        data.setExpertAwayAutoReply.businessUser.activeOrPendingAwayAutoReply
      ) {
        onRequestClose()
      }
    }
  })

  const [removeExpertAwayMessage] = useRemoveExpertAwayAutoReplyMutation({
    onCompleted: data => {
      if (data.removeExpertAwayAutoReply.error) {
        toast.error(data.removeExpertAwayAutoReply.error)
      } else {
        onRequestClose()
      }
    }
  })

  const leadsWakingUpDuringTimeRange = leadsForAwayAutoReplyData?.leads.count

  if (data && data.businessUser) {
    const activeOrPendingAwayAutoReply = data.businessUser.activeOrPendingAwayAutoReply
    const activeAwayExists = activeOrPendingAwayAutoReply?.status === AwayAutoReplyStatus.ACTIVE
    const errorMessage = getErrorMessage(message, effectiveFrom, effectiveUntil, isForExtendedAway)

    return (
      <Modal
        isOpen={true}
        onRequestClose={onRequestClose}
        mobilePosition="bottom"
        title={`${getTitle(isForExtendedAway, activeOrPendingAwayAutoReply)}`}
        primaryAction={{
          label: activeAwayExists ? 'Update' : 'Save',
          onClick: () => {
            if (effectiveFrom.getTime() > effectiveUntil.getTime()) {
              toast.error('End date must be after start date')
            } else {
              setExpertAwayMessage({
                variables: {
                  input: {
                    expertId: expertId,
                    message: message,
                    effectiveFrom: effectiveFrom,
                    effectiveUntil: effectiveUntil,
                    isExtendedAway: !!isForExtendedAway
                  }
                }
              })
            }
          },
          disabled: loading || !!errorMessage
        }}
        secondaryActions={[
          {
            label: 'Remove',
            onClick: () =>
              removeExpertAwayMessage({
                variables: {
                  input: {
                    expertId
                  }
                }
              }),
            disabled: loading || !activeOrPendingAwayAutoReply
          }
        ]}
        actionVariant="neutral-dark"
      >
        <div className={styles.field}>
          <TextArea
            onChange={evt => setMessage(evt.target.value)}
            value={message || ''}
            rows={5}
            label="Edit your away message"
          />
        </div>
        <div className={styles.field}>
          <SingleDatePickerWithTime
            id="away-effective-from"
            label="Start date"
            disabled={
              data.businessUser.activeOrPendingAwayAutoReply?.status === AwayAutoReplyStatus.ACTIVE
            }
            startingDate={effectiveFrom}
            datePickerProps={{
              appendToBody: true,
              openDirection: 'up'
            }}
            onDateTimeChange={date => date && setEffectiveFrom(date)}
          />
          <div className={styles.field}>
            <SingleDatePickerWithTime
              id="away-effective-until"
              label="End date"
              startingDate={effectiveUntil}
              datePickerProps={{
                appendToBody: true,
                openDirection: 'up'
              }}
              onDateTimeChange={date => date && setEffectiveUntil(date)}
            />
          </div>
          {!!errorMessage && (
            <div>
              <Notification type="error" className={styles.notification}>
                {errorMessage}
              </Notification>
            </div>
          )}
          {!!leadsWakingUpDuringTimeRange && (
            <div>
              <Notification type="info" className={styles.notification}>
                {leadsWakingUpDuringTimeRange} leads scheduled for followup during this period.
                Reschedule followups before setting your away message.
              </Notification>
            </div>
          )}
          <div>
            {!!leadsWakingUpDuringTimeRange && (
              <Button
                onClick={() => {
                  onRequestClose()
                  leadSearchFolderContext.setIsBulkReSnoozeFlyoutVisible(true)
                }}
                className={styles.notification}
                size="small"
                variant="secondary"
              >
                Reschedule Snoozes
              </Button>
            )}
          </div>
        </div>
      </Modal>
    )
  } else if (loading) {
    return <FullBlockLoader loader={CircleLoader} />
  } else {
    return null
  }
}

export default ManageAwayAutoReply
