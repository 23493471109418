import { Event, ViewerContext } from '@deal/web-tracking'

export interface IntelligentFollowUpNavigationViewLeadsButtonClickedEventProperties {
  expert_id: string
}

export class IntelligentFollowUpNavigationViewLeadsButtonClickedEvent extends Event<
IntelligentFollowUpNavigationViewLeadsButtonClickedEventProperties
> {
  static displayName = 'Navigation Dropdown View Leads Button Clicked'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
