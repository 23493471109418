import React from 'react'
import styles from './styles.css'

interface NavSectionWithCollapsibleContentProps {
  sectionTitle: string
}

const NavSectionWithCollapsibleContent: React.FC<React.PropsWithChildren<NavSectionWithCollapsibleContentProps>> = ({
  sectionTitle,
  children
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{sectionTitle}</div>
      <div className={styles.links}>{children}</div>
    </div>
  )
}

export default NavSectionWithCollapsibleContent
