// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateBusinessUserForExpertSurveyModalMutationVariables = Types.Exact<{
  input: Types.UpdateBusinessUserInput;
}>;


export type UpdateBusinessUserForExpertSurveyModalMutation = { __typename: 'Mutation', updateBusinessUser: { __typename: 'UpdateBusinessUserPayload', errors?: Array<string> | null, businessUser?: { __typename: 'BusinessUser', id: any, expertAttributes: { __typename: 'BusinessUserExpertAttributes', endOfShiftSurveyTakenAt?: any | null } } | null } };


export const UpdateBusinessUserForExpertSurveyModalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateBusinessUserForExpertSurveyModal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateBusinessUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateBusinessUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errors"}},{"kind":"Field","name":{"kind":"Name","value":"businessUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"expertAttributes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"endOfShiftSurveyTakenAt"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type UpdateBusinessUserForExpertSurveyModalMutationFn = Apollo.MutationFunction<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables>;
export type UpdateBusinessUserForExpertSurveyModalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables>, 'mutation'>;

    export const UpdateBusinessUserForExpertSurveyModalComponent = (props: UpdateBusinessUserForExpertSurveyModalComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables> mutation={UpdateBusinessUserForExpertSurveyModalDocument} {...props} />
    );
    

/**
 * __useUpdateBusinessUserForExpertSurveyModalMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUserForExpertSurveyModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUserForExpertSurveyModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUserForExpertSurveyModalMutation, { data, loading, error }] = useUpdateBusinessUserForExpertSurveyModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessUserForExpertSurveyModalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables>(UpdateBusinessUserForExpertSurveyModalDocument, options);
      }
export type UpdateBusinessUserForExpertSurveyModalMutationHookResult = ReturnType<typeof useUpdateBusinessUserForExpertSurveyModalMutation>;
export type UpdateBusinessUserForExpertSurveyModalMutationResult = Apollo.MutationResult<UpdateBusinessUserForExpertSurveyModalMutation>;
export type UpdateBusinessUserForExpertSurveyModalMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessUserForExpertSurveyModalMutation, UpdateBusinessUserForExpertSurveyModalMutationVariables>;