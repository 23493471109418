import { Event, ViewerContext } from '@deal/web-tracking'

export interface ExpertClickedSuggestedMessageEventProperties {
  lead_id: string
  expert_id: string
  suggested_action_id?: string
  suggested_action_revision_id?: string
  suggested_action_headline?: string
  suggested_message_revision_id: string
}

export class ExpertClickedSuggestedMessageEvent extends Event<
  ExpertClickedSuggestedMessageEventProperties
> {
  static displayName = 'Expert Clicked Suggested Message'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
