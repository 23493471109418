// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type ExpertInboxSnapshotViewQueryVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type ExpertInboxSnapshotViewQuery = { __typename: 'Query', expertInboxSnapshotView: Array<{ __typename: 'ExpertInboxSnapshotView', title: string, count: any, detail: { __typename: 'ExpertInboxSnapshotBroadcastPoolDetail', inboxSnapshotType: string } | { __typename: 'ExpertInboxSnapshotFolderDetail', folderSlug: string, inboxSnapshotType: string } }> };


export const ExpertInboxSnapshotViewDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExpertInboxSnapshotView"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertInboxSnapshotView"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"detail"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inboxSnapshotType"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ExpertInboxSnapshotFolderDetail"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"folderSlug"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type ExpertInboxSnapshotViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>, 'query'> & ({ variables: ExpertInboxSnapshotViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpertInboxSnapshotViewComponent = (props: ExpertInboxSnapshotViewComponentProps) => (
      <ApolloReactComponents.Query<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables> query={ExpertInboxSnapshotViewDocument} {...props} />
    );
    

/**
 * __useExpertInboxSnapshotViewQuery__
 *
 * To run a query within a React component, call `useExpertInboxSnapshotViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertInboxSnapshotViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertInboxSnapshotViewQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useExpertInboxSnapshotViewQuery(baseOptions: Apollo.QueryHookOptions<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>(ExpertInboxSnapshotViewDocument, options);
      }
export function useExpertInboxSnapshotViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>(ExpertInboxSnapshotViewDocument, options);
        }
export function useExpertInboxSnapshotViewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>(ExpertInboxSnapshotViewDocument, options);
        }
export type ExpertInboxSnapshotViewQueryHookResult = ReturnType<typeof useExpertInboxSnapshotViewQuery>;
export type ExpertInboxSnapshotViewLazyQueryHookResult = ReturnType<typeof useExpertInboxSnapshotViewLazyQuery>;
export type ExpertInboxSnapshotViewSuspenseQueryHookResult = ReturnType<typeof useExpertInboxSnapshotViewSuspenseQuery>;
export type ExpertInboxSnapshotViewQueryResult = Apollo.QueryResult<ExpertInboxSnapshotViewQuery, ExpertInboxSnapshotViewQueryVariables>;