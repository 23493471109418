import React from 'react'
import { Button, Image } from '@deal/components'
import ThumbImage from './thumb.png'
import styles from './styles.css'

interface Props {
  onRedirectClicked: () => void
}

const SurveyComplete: React.FC<React.PropsWithChildren<Props>> = ({ onRedirectClicked }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topBar} />
      <div className={styles.content}>
        <Image sizes="64" src={ThumbImage} className={styles.thumb} />
        <div className={styles.headline}>Thank you for your feedback!</div>
        <div className={styles.body}>
          Your ideas help us make the Curated expert community stronger!
        </div>
        <Button onClick={onRedirectClicked}>Go to inbox</Button>
      </div>
    </div>
  )
}

export default SurveyComplete
