import { ExpertPresenceStatus } from '#src/generated/types'
import { BusinessUserFragment } from '../fragments/businessUser.generated'

const isAcceptingNewLeads = (businessUser: BusinessUserFragment) => {
  return (
    (businessUser.expertPresence.__typename === 'ExpertPresenceOnShift' ||
      businessUser.expertPresence.__typename === 'ExpertPresenceOnStandby') &&
    businessUser.expertPresence.acceptingNewLeads
  )
}

const isOnShift = (businessUser: BusinessUserFragment) => {
  const status = businessUser.expertPresence.status
  return (
    status === ExpertPresenceStatus.ON_SHIFT ||
    status == ExpertPresenceStatus.ON_CALL ||
    status == ExpertPresenceStatus.ON_STANDBY
  )
}

const isOnLeadShift = (businessUser: BusinessUserFragment) => {
  const status = businessUser.expertPresence.status
  return (
    status === ExpertPresenceStatus.ON_SHIFT ||
    status == ExpertPresenceStatus.ON_CALL ||
    status == ExpertPresenceStatus.ON_STANDBY
  )
}

export { isAcceptingNewLeads, isOnShift, isOnLeadShift }
