// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type QuickReplyPopoverContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QuickReplyPopoverContentQuery = { __typename: 'Query', suggestedMessageTags: { __typename: 'AttributeSchemaRevision', id: any, enumEntries: Array<{ __typename: 'AttributeEnumEntry', name: string, displayName: string, deprecated?: { __typename: 'AttributeEnumEntryDeprecated', at: any } | null }> } };

export type SuggestedActionForSuggestedMessageListFragment = { __typename: 'SuggestedAction', id?: any | null, suggestedActionRevision?: { __typename: 'SuggestedActionRevision', id: any, headline: string, tags: Array<string> } | null };

export type SuggestedActionForQuickReplyPopoverContentFragment = { __typename: 'SuggestedAction', id?: any | null, suggestedActionRevision?: { __typename: 'SuggestedActionRevision', id: any, headline: string, tags: Array<string> } | null };

export const SuggestedActionForSuggestedMessageListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedActionForSuggestedMessageList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedAction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedActionRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}}]}}]}}]} as unknown as DocumentNode;
export const SuggestedActionForQuickReplyPopoverContentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedActionForQuickReplyPopoverContent"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedAction"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedActionForSuggestedMessageList"}}]}}]} as unknown as DocumentNode;
export const QuickReplyPopoverContentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuickReplyPopoverContent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageTags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"useCase"},"value":{"kind":"EnumValue","value":"LEAD"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enumEntries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"at"}}]}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]}}]} as unknown as DocumentNode;
export type QuickReplyPopoverContentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>, 'query'>;

    export const QuickReplyPopoverContentComponent = (props: QuickReplyPopoverContentComponentProps) => (
      <ApolloReactComponents.Query<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables> query={QuickReplyPopoverContentDocument} {...props} />
    );
    

/**
 * __useQuickReplyPopoverContentQuery__
 *
 * To run a query within a React component, call `useQuickReplyPopoverContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickReplyPopoverContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickReplyPopoverContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickReplyPopoverContentQuery(baseOptions?: Apollo.QueryHookOptions<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>(QuickReplyPopoverContentDocument, options);
      }
export function useQuickReplyPopoverContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>(QuickReplyPopoverContentDocument, options);
        }
export function useQuickReplyPopoverContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>(QuickReplyPopoverContentDocument, options);
        }
export type QuickReplyPopoverContentQueryHookResult = ReturnType<typeof useQuickReplyPopoverContentQuery>;
export type QuickReplyPopoverContentLazyQueryHookResult = ReturnType<typeof useQuickReplyPopoverContentLazyQuery>;
export type QuickReplyPopoverContentSuspenseQueryHookResult = ReturnType<typeof useQuickReplyPopoverContentSuspenseQuery>;
export type QuickReplyPopoverContentQueryResult = Apollo.QueryResult<QuickReplyPopoverContentQuery, QuickReplyPopoverContentQueryVariables>;