import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@deal/components'
import { getLeadsFollowUpSummary } from '#src/app/utilities/grammerUtils'
import { FOLDER_SLUGS } from '#src/app/utilities/displayNames'
import FollowUpCompleteModal from '#src/app/routes/inbox/FollowUpCompleteModal'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import useBreakpoint from '#src/app/hooks/useBreakpoint'
import { IntelligentFollowUpNavigationViewLeadsButtonClickedEvent } from '#src/app/events/IntelligentFollowUpNavigationViewLeadsButtonClicked'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { InboxSnapshotContext } from '#src/app/context/InboxSnapshot'
import { useAnalyticsContext } from '#src/app/containers/Analytics'
import NavigationText from '../NavigationText'
import FollowUpNudgePopover, {
  FOLLOW_UP_NUDGE_LOCAL_KEY,
  FOLLOW_UP_NUDGE_LOCAL_KEY_COMPLETE
} from './FollowUpNudgePopover'
import styles from './styles.css'
import { useExperiment } from '@deal/experiment-js'

interface FollowUpLeadFolderProps {
  needsActionCount: number
}

const FollowUpLeadFolder: React.FC<React.PropsWithChildren<FollowUpLeadFolderProps>> = ({
  needsActionCount
}) => {
  const analytics = useAnalyticsContext()
  const { id: expertId } = useBusinessUser()
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const [hasClickedFollowUpNudge, setHasClickedFollowUpNudge] = useState(false)
  const isLargerThanMd = useBreakpoint('md')
  const isSmallerThanSm = !useBreakpoint('sm')
  const { showFollowUpNudge, currentFollowUpLeadCount, showFollowUpSuccessModal } = useContext(
    InboxSnapshotContext
  )

  const { result: expertResult } = useExperiment({
    experiment: 'intelligent-followup-re-engagement',
    defaultTreatment: 'control',
    // We're not actually rolling for the experiment here, so we don't need to track the impression.
    options: { trackImpression: false }
  })
  const expertHasAiReEngagementSupport = expertResult !== 'control'

  useEffect(() => {
    setHasClickedFollowUpNudge(
      window &&
        window.localStorage.getItem(FOLLOW_UP_NUDGE_LOCAL_KEY) ===
          FOLLOW_UP_NUDGE_LOCAL_KEY_COMPLETE
    )
  }, [])

  const hasFollowUps = currentFollowUpLeadCount > 0

  const handleViewLeads = () => {
    leadSearchFolderContext.setFolder(FOLDER_SLUGS.LABEL_INTELLIGENT_FOLLOW_UP, null)
  }

  const handlNavigationViewLeads = () => {
    analytics?.track(
      new IntelligentFollowUpNavigationViewLeadsButtonClickedEvent({
        expert_id: expertId
      })
    )
    handleViewLeads()
  }

  const isNudgeVisible =
    !expertHasAiReEngagementSupport &&
    !hasClickedFollowUpNudge &&
    needsActionCount === 0 &&
    showFollowUpNudge &&
    (isLargerThanMd || isSmallerThanSm)

  return (
    <div className={styles.container}>
      {showFollowUpSuccessModal && <FollowUpCompleteModal />}
      {hasFollowUps ? (
        <>
          <div className={styles.topLine}>
            <NavigationText>Leads to Re-engage</NavigationText>
          </div>
          <div className={styles.lowerContainer}>
            <FollowUpNudgePopover
              isVisible={isNudgeVisible}
              followUpCount={currentFollowUpLeadCount}
              handleViewLeads={handleViewLeads}
            >
              <Button
                size="xsmall"
                variant="secondary"
                onClick={handlNavigationViewLeads}
                className={styles.viewLeadsButton}
                id="follow-up-lead-folder-view-leads-button"
              >
                {getLeadsFollowUpSummary(currentFollowUpLeadCount)}
              </Button>
            </FollowUpNudgePopover>
          </div>
        </>
      ) : (
        <NavigationText>No leads to follow up at the moment.</NavigationText>
      )}
    </div>
  )
}

export default FollowUpLeadFolder
