import { DealNativeBridge } from '#src/app/services/dealNativeBridge'
import { UserAgent } from '#src/app/context/UserAgent'

/**
 * Helper method for determining if we're inside of a native mobile wrapper
 */
export function isBusinessMobileNative() {
  try {
    const dealNativeBridge = (window as any).dealNativeBridge as DealNativeBridge | undefined
    return !!dealNativeBridge
  } catch (e) {
    return false
  }
}

/**
 * Helper method for determing if we're inside a native or mobile browser
 */
export function isMobileNativeOrBrowser(userAgent: UserAgent) {
  return isMobileBrowser(userAgent) || isMobileNative(userAgent)
}

/**
 * Helper method to determine if we're inside of any deal native mobile wrapper app.
 */
export function isMobileNative(userAgent?: UserAgent) {
  return isBusinessMobileNative() || (!!userAgent && userAgent.raw.includes('DealWebView'))
}

/**
 * Helper method for determining if were inside a mobile browser, but not in a native wrapper
 */
export function isMobileBrowser(userAgent: UserAgent) {
  if (!userAgent) return false

  return (
    !isBusinessMobileNative() &&
    (userAgent.device.type === 'mobile' || userAgent.device.type === 'tablet')
  )
}

export function isAppleDevice(userAgent: UserAgent) {
  return userAgent && (userAgent.os.name === 'iOS' || userAgent.os.name === 'Mac OS')
}

export function isAndroidDevice(userAgent: UserAgent) {
  return userAgent && userAgent.os.name === 'Android'
}
