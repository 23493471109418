import React, { useState } from 'react'
import { CircleLoader, FullBlockLoader, Image } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import ResultsContainer, {
  decrementMaybeNumber,
  incrementMaybeNumber
} from '../../ResultsContainer'
import Magnifier from '../../Magnifier'
import { useMediaSearchControlContext } from '../../context'
import styles from './styles.css'

export type ListingPagination = {
  loading: boolean
  moreResultsMightExist: boolean
  mediaListings: MediaListing[]
  maybeFetchMoreResults(onMoreResultsConfirmed?: () => void): void
}

interface MediaListing {
  id: any
  media: Media
}

interface Media {
  id: any
  url: string
}

interface GeneralRecentsPanelProps {
  listingPagination: ListingPagination
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
}

const GeneralRecentsPanel: React.FC<GeneralRecentsPanelProps> = ({
  attachMediaUrl,
  listingPagination
}) => {
  const { setIsPopoverVisible } = useMediaSearchControlContext()
  const [zoomImageSourceIndex, setZoomImageSourceIndex] = useState<number>()

  const { id: sharedByUserId } = useBusinessUser()

  const { loading, mediaListings, moreResultsMightExist, maybeFetchMoreResults } = listingPagination

  const zoomedImageIsTheLastQueriedImage = mediaListings.length - 1 === zoomImageSourceIndex
  const noNextZoomExists = zoomedImageIsTheLastQueriedImage && !moreResultsMightExist

  const zoomedImageUrl =
    zoomImageSourceIndex === undefined || !mediaListings
      ? undefined
      : mediaListings[zoomImageSourceIndex].media.url

  return (
    <div className={styles.container}>
      <ResultsContainer
        zoomImageSource={zoomedImageUrl}
        loading={loading}
        onZoomedImageSelected={() => {
          attachMediaUrl(zoomedImageUrl!, undefined, sharedByUserId)
          setZoomImageSourceIndex(undefined)
        }}
        onNextZoomedImageRequested={
          noNextZoomExists
            ? undefined
            : () => {
                if (zoomedImageIsTheLastQueriedImage) {
                  maybeFetchMoreResults(() => setZoomImageSourceIndex(incrementMaybeNumber))
                } else {
                  setZoomImageSourceIndex(incrementMaybeNumber)
                }
              }
        }
        onPreviousZoomedImageRequested={
          zoomImageSourceIndex !== 0
            ? () => setZoomImageSourceIndex(decrementMaybeNumber)
            : undefined
        }
        onModalClose={() => {
          setZoomImageSourceIndex(undefined)
        }}
        onScrollToEndOfResults={
          moreResultsMightExist ? () => maybeFetchMoreResults(undefined) : undefined
        }
      >
        {mediaListings ? (
          <>
            {mediaListings.map((mediaAsset, idx) => {
              const { media } = mediaAsset
              return (
                <div
                  key={mediaAsset.id}
                  onClick={() => {
                    attachMediaUrl(media.url, undefined, sharedByUserId)
                    setIsPopoverVisible(false)
                  }}
                  className={styles.imageContainer}
                >
                  <Image src={media.url} size="240px" />
                  <div className={styles.magnifierContainer}>
                    <Magnifier
                      onClick={() => {
                        setZoomImageSourceIndex(idx)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </>
        ) : loading ? (
          <FullBlockLoader loader={CircleLoader} />
        ) : null}
      </ResultsContainer>
    </div>
  )
}

export default GeneralRecentsPanel
