import React from 'react'
import styles from './styles.css'

interface NavigationTextProps {}

const NavigationText: React.FC<React.PropsWithChildren<NavigationTextProps>> = ({ children }) => {
  return <div className={styles.text}>{children}</div>
}

export default NavigationText
