const KNOWLEDGE_CARD_ENGAGEMENT_PAY_ID = '869d4503-cb36-427a-a1d9-feab926262c7'
const KNOWLEDGE_CARD_CURATION_VIDEO_ID = '36e5eb46-f513-4cee-9aef-b5cfe4efa037'
const KNOWLEDGE_CARD_CAAS_PAYROLL_ID = 'c8960fd1-3038-49ea-b6ae-3e9d502ab4fb'
const KNOWLEDGE_CARD_SEND_MEDIA_ID = '3b083d5b-22c3-4976-893c-35d66f4938ce'
const KNOWLEDGE_CARD_INTELLIGENT_FOLLOWUP_ID = '813370f5-68dc-40b6-a44c-d2c934ca478d'
const KNOWLEDGE_CARD_TEMRMS_OF_SERVICE_ID = 'b65b01d9-bcdc-4105-9600-acca3069d1e9'
const KNOWLEDGE_CARD_CO_OWNERS_ID = 'e72e1639-de5c-47a5-a87d-2c27a67c68a6'
const KNOWLEDGE_CARD_MARGIN_ID = 'c366f796-537a-43dd-82c9-62c8b3364ac9'
const KNOWLEDGE_CARD_MPQL_ID = '277ab214-0711-4227-bc64-5a5ee2819034'
const KNOWLEDGE_CARD_LEAD_GATES = 'ac0796a4-556c-4740-bff1-5ef2345688f0'
const KNOWLEDGE_CARD_GRADUATION = '16dd57b0-6888-41ff-8b9b-0de92e5f8ccb'
const KNOWLEDGE_CARD_MANAGE_MENU = 'baf57a37-d69a-466c-aee3-e7f0e9134595'
const KNOWLEDGE_CARD_IMPROVE_MPQL_ID = 'b6146965-a92a-4ea5-8d63-b97597dd3ee4'

export {
  KNOWLEDGE_CARD_ENGAGEMENT_PAY_ID,
  KNOWLEDGE_CARD_CURATION_VIDEO_ID,
  KNOWLEDGE_CARD_CAAS_PAYROLL_ID,
  KNOWLEDGE_CARD_SEND_MEDIA_ID,
  KNOWLEDGE_CARD_INTELLIGENT_FOLLOWUP_ID,
  KNOWLEDGE_CARD_TEMRMS_OF_SERVICE_ID,
  KNOWLEDGE_CARD_CO_OWNERS_ID,
  KNOWLEDGE_CARD_MARGIN_ID,
  KNOWLEDGE_CARD_MPQL_ID,
  KNOWLEDGE_CARD_LEAD_GATES,
  KNOWLEDGE_CARD_GRADUATION,
  KNOWLEDGE_CARD_MANAGE_MENU,
  KNOWLEDGE_CARD_IMPROVE_MPQL_ID
}
