import React from 'react'
import classnames from 'classnames'
import {
  Button,
  ButtonProps,
  Popover,
  PopoverProps,
  TextField,
  TextFieldProps
, TouchScrollable } from '@deal/components'
import styles from './styles.css'

interface PopoverWithTextFieldAndButtonProps {
  popoverProps: PopoverProps
  textfieldProps: Omit<TextFieldProps, 'className'>
  buttonProps?: ButtonProps
  contentClassName?: string
}

const PopoverWithTextFieldAndButton: React.FC<React.PropsWithChildren<PopoverWithTextFieldAndButtonProps>> = ({
  popoverProps,
  buttonProps,
  textfieldProps,
  contentClassName,
  children
}) => {
  const { content } = popoverProps
  return (
    <Popover
      {...popoverProps}
      content={
        <div className={classnames(contentClassName, styles.container)}>
          <TextField {...textfieldProps} className={styles.textField} />
          <TouchScrollable>
            <div className={styles.contentContainer}>{content}</div>
          </TouchScrollable>
          <Button {...buttonProps}>Submit</Button>
        </div>
      }
    >
      {children}
    </Popover>
  )
}

export default PopoverWithTextFieldAndButton
