// @ts-nocheck
import type * as Types from '../../generated/types';

import type { DocumentNode } from 'graphql';
import { AttributeValuesFragmentDoc, AttributeValueDecimalFragmentDoc, AttributeValueDecimalRangeFragmentDoc, AttributeValueEnumerationFragmentDoc, AttributeValueIdFragmentDoc, AttributeValueIntegerFragmentDoc, AttributeValueIntegerRangeFragmentDoc, AttributeValueTextFragmentDoc, AttributeValueDateFragmentDoc, AttributeValueDateRangeFragmentDoc, AttributeValueDateTimeFragmentDoc, AttributeValueDateTimeRangeFragmentDoc, AttributeValueTimeFragmentDoc, AttributeValueTimeRangeFragmentDoc, AttributeValueZoneIdFragmentDoc } from './attributeValues.generated';
export type FilterFieldValue_FilterFieldAttributeValue_Fragment = { __typename: 'FilterFieldAttributeValue', custom: boolean, attributeValue: { __typename: 'Attribute', schema: { __typename: 'AttributeSchemaRevision', id: any, name: string, displayName: string, type: Types.AttributeType, historyId: any }, values: Array<{ __typename: 'AttributeValueDate', date: any } | { __typename: 'AttributeValueDateRange', maxDate?: any | null, minDate?: any | null } | { __typename: 'AttributeValueDateTime', dateTime: any } | { __typename: 'AttributeValueDateTimeRange', maxDateTime?: any | null, minDateTime?: any | null } | { __typename: 'AttributeValueDecimal', decimal: string } | { __typename: 'AttributeValueDecimalRange', maxDecimal?: string | null, minDecimal?: string | null } | { __typename: 'AttributeValueEnumeration', enumerationValue: string, enumerationValueDescription: string } | { __typename: 'AttributeValueId', id?: any | null } | { __typename: 'AttributeValueInteger', integer: any } | { __typename: 'AttributeValueIntegerRange', maxInteger?: any | null, minInteger?: any | null } | { __typename: 'AttributeValueText', text: string } | { __typename: 'AttributeValueTime', time: any } | { __typename: 'AttributeValueTimeRange', maxTime?: any | null, minTime?: any | null } | { __typename: 'AttributeValueZoneId', zoneId?: string | null }>, formatted?: { __typename: 'FormattedAttribute', name: string, displayHtmlValues: Array<string> } | null } };

export type FilterFieldValue_FilterFieldBooleanValue_Fragment = { __typename: 'FilterFieldBooleanValue', custom: boolean, booleanValue: boolean };

export type FilterFieldValue_FilterFieldDateRangeValue_Fragment = { __typename: 'FilterFieldDateRangeValue', custom: boolean, dateRangeValue: { __typename: 'DateRange', from?: any | null, to?: any | null } };

export type FilterFieldValue_FilterFieldGeoSearchValue_Fragment = { __typename: 'FilterFieldGeoSearchValue', custom: boolean, geoSearchValue: { __typename: 'GeoZipCodeRadius', postalCode: string, countryCode: string, distanceInMiles: number } };

export type FilterFieldValue_FilterFieldIdValue_Fragment = { __typename: 'FilterFieldIdValue', displayValue: string, custom: boolean, idValue: any };

export type FilterFieldValue_FilterFieldNumberRangeValue_Fragment = { __typename: 'FilterFieldNumberRangeValue', custom: boolean, numberRangeValue: { __typename: 'BigDecimalRange', from?: string | null, to?: string | null } };

export type FilterFieldValue_FilterFieldRelativeDateRangeValue_Fragment = { __typename: 'FilterFieldRelativeDateRangeValue', custom: boolean, relativeDateRangeValue: { __typename: 'RelativeDateRange', dateRange: { __typename: 'DateRange', from?: any | null, to?: any | null }, from?: { __typename: 'RelativeDate', amount: any, date: any, truncateTo?: Types.RelativeDateUnit | null, unit: Types.RelativeDateUnit } | null, to?: { __typename: 'RelativeDate', amount: any, date: any, truncateTo?: Types.RelativeDateUnit | null, unit: Types.RelativeDateUnit } | null } };

export type FilterFieldValue_FilterFieldStringValue_Fragment = { __typename: 'FilterFieldStringValue', custom: boolean, stringValue: string };

export type FilterFieldValue_LeadTouchFilterFieldValue_Fragment = { __typename: 'LeadTouchFilterFieldValue', custom: boolean, source: string, campaignIds?: Array<string> | null, adGroupIds?: Array<string> | null, adIds?: Array<string> | null };

export type FilterFieldValueFragment = FilterFieldValue_FilterFieldAttributeValue_Fragment | FilterFieldValue_FilterFieldBooleanValue_Fragment | FilterFieldValue_FilterFieldDateRangeValue_Fragment | FilterFieldValue_FilterFieldGeoSearchValue_Fragment | FilterFieldValue_FilterFieldIdValue_Fragment | FilterFieldValue_FilterFieldNumberRangeValue_Fragment | FilterFieldValue_FilterFieldRelativeDateRangeValue_Fragment | FilterFieldValue_FilterFieldStringValue_Fragment | FilterFieldValue_LeadTouchFilterFieldValue_Fragment;

export type LeadTouchFilterFieldValueFragment = { __typename: 'LeadTouchFilterFieldValue', custom: boolean, source: string, campaignIds?: Array<string> | null, adGroupIds?: Array<string> | null, adIds?: Array<string> | null };

export type FilterFieldStringValueFragment = { __typename: 'FilterFieldStringValue', custom: boolean, stringValue: string };

export type FilterFieldRelativeDateRangeValueFragment = { __typename: 'FilterFieldRelativeDateRangeValue', custom: boolean, relativeDateRangeValue: { __typename: 'RelativeDateRange', dateRange: { __typename: 'DateRange', from?: any | null, to?: any | null }, from?: { __typename: 'RelativeDate', amount: any, date: any, truncateTo?: Types.RelativeDateUnit | null, unit: Types.RelativeDateUnit } | null, to?: { __typename: 'RelativeDate', amount: any, date: any, truncateTo?: Types.RelativeDateUnit | null, unit: Types.RelativeDateUnit } | null } };

export type FilterFieldNumberRangeValueFragment = { __typename: 'FilterFieldNumberRangeValue', custom: boolean, numberRangeValue: { __typename: 'BigDecimalRange', from?: string | null, to?: string | null } };

export type FilterFieldIdValueFragment = { __typename: 'FilterFieldIdValue', displayValue: string, custom: boolean, idValue: any };

export type FilterFieldDateRangeValueFragment = { __typename: 'FilterFieldDateRangeValue', custom: boolean, dateRangeValue: { __typename: 'DateRange', from?: any | null, to?: any | null } };

export type FilterFieldBooleanValueFragment = { __typename: 'FilterFieldBooleanValue', custom: boolean, booleanValue: boolean };

export type FilterFieldAttributeValueFragment = { __typename: 'FilterFieldAttributeValue', custom: boolean, attributeValue: { __typename: 'Attribute', schema: { __typename: 'AttributeSchemaRevision', id: any, name: string, displayName: string, type: Types.AttributeType, historyId: any }, values: Array<{ __typename: 'AttributeValueDate', date: any } | { __typename: 'AttributeValueDateRange', maxDate?: any | null, minDate?: any | null } | { __typename: 'AttributeValueDateTime', dateTime: any } | { __typename: 'AttributeValueDateTimeRange', maxDateTime?: any | null, minDateTime?: any | null } | { __typename: 'AttributeValueDecimal', decimal: string } | { __typename: 'AttributeValueDecimalRange', maxDecimal?: string | null, minDecimal?: string | null } | { __typename: 'AttributeValueEnumeration', enumerationValue: string, enumerationValueDescription: string } | { __typename: 'AttributeValueId', id?: any | null } | { __typename: 'AttributeValueInteger', integer: any } | { __typename: 'AttributeValueIntegerRange', maxInteger?: any | null, minInteger?: any | null } | { __typename: 'AttributeValueText', text: string } | { __typename: 'AttributeValueTime', time: any } | { __typename: 'AttributeValueTimeRange', maxTime?: any | null, minTime?: any | null } | { __typename: 'AttributeValueZoneId', zoneId?: string | null }>, formatted?: { __typename: 'FormattedAttribute', name: string, displayHtmlValues: Array<string> } | null } };

export type FilterFieldGeoSearchValueFragment = { __typename: 'FilterFieldGeoSearchValue', custom: boolean, geoSearchValue: { __typename: 'GeoZipCodeRadius', postalCode: string, countryCode: string, distanceInMiles: number } };

export const LeadTouchFilterFieldValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadTouchFilterFieldValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LeadTouchFilterFieldValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"custom"}},{"kind":"Field","name":{"kind":"Name","value":"source"}},{"kind":"Field","name":{"kind":"Name","value":"campaignIds"}},{"kind":"Field","name":{"kind":"Name","value":"adGroupIds"}},{"kind":"Field","name":{"kind":"Name","value":"adIds"}}]}}]} as unknown as DocumentNode;
export const FilterFieldStringValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldStringValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldStringValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"stringValue"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldRelativeDateRangeValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldRelativeDateRangeValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldRelativeDateRangeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"relativeDateRangeValue"},"name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dateRange"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"to"}}]}},{"kind":"Field","name":{"kind":"Name","value":"from"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"truncateTo"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}}]}},{"kind":"Field","name":{"kind":"Name","value":"to"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"truncateTo"}},{"kind":"Field","name":{"kind":"Name","value":"unit"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldNumberRangeValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldNumberRangeValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldNumberRangeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"numberRangeValue"},"name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"to"}}]}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldIdValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldIdValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldIdValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"idValue"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"displayValue"}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldDateRangeValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldDateRangeValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldDateRangeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"dateRangeValue"},"name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"from"}},{"kind":"Field","name":{"kind":"Name","value":"to"}}]}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldBooleanValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldBooleanValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldBooleanValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"booleanValue"},"name":{"kind":"Name","value":"value"}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldAttributeValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldAttributeValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldAttributeValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"attributeValue"},"name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"schema"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"historyId"}}]}},{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AttributeValues"}}]}},{"kind":"Field","name":{"kind":"Name","value":"formatted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayHtmlValues"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldGeoSearchValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldGeoSearchValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldGeoSearchValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"geoSearchValue"},"name":{"kind":"Name","value":"value"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GeoZipCodeRadius"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"postalCode"}},{"kind":"Field","name":{"kind":"Name","value":"countryCode"}},{"kind":"Field","name":{"kind":"Name","value":"distanceInMiles"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"custom"}}]}}]} as unknown as DocumentNode;
export const FilterFieldValueFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FilterFieldValue"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FilterFieldValue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadTouchFilterFieldValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldStringValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldRelativeDateRangeValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldNumberRangeValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldIdValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldDateRangeValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldBooleanValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldAttributeValue"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"FilterFieldGeoSearchValue"}}]}}]} as unknown as DocumentNode;