import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Button, Checkbox, Popover, Tooltip } from '@deal/components'
import { getAccountDisplayName, getExpertCategoryCounts } from '#src/app/utilities/identity'
import { IdentityContext } from '#src/app/containers/Identity'
import { useSubmitAccountReactivationRequestMutation } from './SubmitAccountReactivationRequest.generated'
import {
  ExpertAccountChangeRequestForReactivateBusinessUserAccountFragment,
  useBusinessUsersForReactivationQuery
} from './ReactivateBusinessUserAccount.generated'
import styles from './styles.css'

interface Props {
  existingRequests?: ExpertAccountChangeRequestForReactivateBusinessUserAccountFragment[]
  selectedBusinessUserId?: string
  onSubmitted?: () => void
}

const ReactivateBusinessAccount: React.FC<React.PropsWithChildren<Props>> = ({
  existingRequests,
  selectedBusinessUserId,
  onSubmitted
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [selectedAccountIds, setSelectedAccountIds] = useState(
    selectedBusinessUserId ? [selectedBusinessUserId] : []
  )
  const requestString = (requests: number) => {
    return requests === 1 ? '1 request' : `${requests} requests`
  }

  const [
    submitAccountReactivationRequest,
    { loading }
  ] = useSubmitAccountReactivationRequestMutation({
    onCompleted: data => {
      let requestsSubmitted = 0
      let existingRequests = 0
      let alreadyActivated = 0
      data.submitExpertAccountChangeRequests.forEach(result => {
        if (result.request) {
          requestsSubmitted++
        } else if (result.error?.__typename === 'SubmitExpertAccountChangeRequestExistsError') {
          existingRequests++
        } else if (result.error?.__typename === 'SubmitExpertAccountChangeAlreadyActivatedError') {
          alreadyActivated++
        }
      })
      let status = ''
      if (requestsSubmitted > 0) {
        status = status + `${requestString(requestsSubmitted)} submitted`
      }
      if (existingRequests > 0) {
        status = status + ` ${requestString(existingRequests)} already in review`
      }
      if (alreadyActivated > 0) {
        status =
          status +
          ` ${requestString(alreadyActivated)} skipped because account is already activated`
      }
      toast.success(status)
      if (onSubmitted) {
        onSubmitted()
      }
      setIsPopoverOpen(false)
    }
  })

  const { myself } = useContext(IdentityContext)
  if (!myself || !myself.businessUser) {
    return null
  }

  const deactivatedAccountsWithoutExistingRequests = myself.businessUser.user.assignedBusinessUsers.filter(
    businessUser =>
      businessUser.deactivated &&
      businessUser.isExpert &&
      !existingRequests?.some(request => request.expert.id === businessUser.id)
  )

  const allDeactivatedAccountIdsWithoutExistingRequests = deactivatedAccountsWithoutExistingRequests.map(
    expert => expert.id
  )

  const { data } = useBusinessUsersForReactivationQuery({
    variables: {
      ids: allDeactivatedAccountIdsWithoutExistingRequests
    }
  })

  const eligibleExpertIds = data?.businessUsers
    .filter(businessUser => businessUser?.isEligibleToReactivate)
    .map(businessUser => businessUser?.id)

  if (deactivatedAccountsWithoutExistingRequests.length === 0) {
    return null
  }

  const priorRequestExists = existingRequests && existingRequests.length > 0
  const disableButton = deactivatedAccountsWithoutExistingRequests.length === 0

  const categoryCount = getExpertCategoryCounts(deactivatedAccountsWithoutExistingRequests)

  if (deactivatedAccountsWithoutExistingRequests.length === 1) {
    const expertId = deactivatedAccountsWithoutExistingRequests[0].id
    const isEligible = eligibleExpertIds?.includes(expertId)
    return !isEligible ? (
      <Tooltip message={'Disabled as not eligible to reactivate'}>
        <Button disabled={true}>Reactivate my account</Button>
      </Tooltip>
    ) : (
      <Button
        disabled={loading || disableButton}
        onClick={() =>
          submitAccountReactivationRequest({
            variables: { expertIds: [expertId] }
          })
        }
      >
        Reactivate my account
      </Button>
    )
  } else {
    return (
      <Popover
        contentLabel="Reactivate account"
        visible={isPopoverOpen}
        onRequestClose={() => setIsPopoverOpen(false)}
        content={
          <div className={styles.page}>
            <Box column>
              <div className={styles.header}>
                Which accounts do you want to reactivate? (Only accounts eligible to reactivate will
                list below)
              </div>
              {deactivatedAccountsWithoutExistingRequests
                .filter(expert => eligibleExpertIds?.includes(expert.id))
                .map(businessUser => {
                  const displayName = getAccountDisplayName(businessUser, categoryCount)
                  return (
                    <div key={businessUser.id}>
                      <Checkbox
                        checked={selectedAccountIds.includes(businessUser.id)}
                        onChange={() => {
                          if (selectedAccountIds.includes(businessUser.id)) {
                            setSelectedAccountIds(
                              selectedAccountIds.filter(each => each !== businessUser.id)
                            )
                          } else {
                            setSelectedAccountIds(selectedAccountIds.concat(businessUser.id))
                          }
                        }}
                      >
                        {displayName}
                      </Checkbox>
                    </div>
                  )
                })}

              <Button
                disabled={loading || !selectedAccountIds.length}
                onClick={() =>
                  submitAccountReactivationRequest({
                    variables: { expertIds: selectedAccountIds }
                  })
                }
                variant="primary-gradient"
              >
                Reactivate my account{selectedAccountIds.length > 1 ? 's' : ''}
              </Button>
            </Box>
          </div>
        }
      >
        <Button
          onClick={() => setIsPopoverOpen(true)}
          variant="primary-gradient"
          disabled={disableButton}
        >
          {priorRequestExists ? 'Reactivate another account' : `Reactivate an account`}
        </Button>
      </Popover>
    )
  }
}

export default ReactivateBusinessAccount
