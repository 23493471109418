export enum MustacheTemplateError {
  MUSTACHE_TEMPLATE_INCORRECT_VARIABLE = 'MUSTACHE_TEMPLATE_INCORRECT_VARIABLE',
  MUSTACHE_TEMPLATE_INCORRECT_BRACES = 'MUSTACHE_TEMPLATE_INCORRECT_BRACES'
}

export function getGenericFriendlyMustacheTemplateTextErrorMessage(
  errorType: MustacheTemplateError
) {
  switch (errorType) {
    case MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_BRACES:
      return 'An opening or closing brace is missing'

    case MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_VARIABLE:
      return 'And incorrect variable name is being used'
  }
}

export function getMustacheTemplateTextErrors(message: string, variables: { name: string }[]) {
  const variablesNames = variables.map(variable => variable.name)
  const openBraces = (message.match(/\{\{/g) || []).length
  const closeBraces = (message.match(/\}\}/g) || []).length
  const correctBracesMatches = message.match(/\{\{(.*?)\}\}/g)
  const errors = []

  const correctBracesError =
    !!correctBracesMatches &&
    !correctBracesMatches.every(match => {
      return variablesNames.includes(match)
    })
  const braceCountError = openBraces !== closeBraces

  if (correctBracesError) {
    errors.push(MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_VARIABLE)
  }

  if (braceCountError) {
    errors.push(MustacheTemplateError.MUSTACHE_TEMPLATE_INCORRECT_BRACES)
  }

  return errors
}
