import React, { useEffect, useRef } from 'react'
import { TextField, TextFieldProps } from '@deal/bluxome'

const SavedNameTextInput: React.FC<TextFieldProps & { onEnterPressed: () => void }> = ({
  onEnterPressed,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <TextField
      {...props}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEnterPressed()
        } else {
          props.onKeyDown?.(e)
        }
      }}
      ref={inputRef}
    />
  )
}

export default SavedNameTextInput
