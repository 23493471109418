import {
  LeadFragment,
  SuggestedActionForConversationFragment
} from '../components/LeadConversation/LeadConversation.generated'
import { SuggestedActionForSuggestedActionsFragment } from '../components/SuggestedActions/SuggestedActions.generated'
import { SuggestedActionType } from '#src/generated/types'

type suggestedActionToRender = SuggestedActionForSuggestedActionsFragment &
  SuggestedActionForConversationFragment

export const getSuggestedActionToRender = (suggestedActions: suggestedActionToRender[]) => {
  return suggestedActions.reduce((suggestedActionToRender, suggestedAction) => {
    if (!suggestedAction.suggestedActionRevision?.priority) {
      return suggestedActionToRender
    } else if (!suggestedActionToRender.suggestedActionRevision?.priority) {
      return suggestedAction
    } else if (
      suggestedAction.suggestedActionRevision.priority >
      suggestedActionToRender.suggestedActionRevision.priority
    ) {
      return suggestedAction
    } else {
      return suggestedActionToRender
    }
  })
}

export const getSuggestedActionTypes = (
  suggestedActionToRender: suggestedActionToRender,
  lead: LeadFragment
): SuggestedActionType[] => {
  // We push the Snooze type to the suggetedActionToRender if the lead's snooze was deactivated because of the suggested action.
  // It is possible the expert will want them to remain snoozed so we offer the re-snooze
  if (
    !suggestedActionToRender.types.some(type => type === SuggestedActionType.SNOOZE) &&
    lead.snooze &&
    !lead.snooze.active
  ) {
    return suggestedActionToRender.types.concat(SuggestedActionType.SNOOZE)
  }
  return suggestedActionToRender.types
}

  export const suggestedMessageTagNameAllowList = new Set([
    'THIRTY_DAYS_AFTER_ORDER_DELIVERED',
    'QUICK_REPLIES',
    'ORDER_DELIVERED',
    'FOLLOWUP_MESSAGE_TWO',
    'FOLLOWUP_MESSAGE_THREE',
    'FOLLOWUP_MESSAGE_ONE',
    'DORMANT_PURCHASER_RETURNED',
    'CONSUMER_VIEWED_CURATION',
    'CONSUMER_COMPLETED_CHECKOUT',
    'ABOUT_CURATED'
  ])