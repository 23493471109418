import { useHistory } from '@deal/router'
import { useLeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import { QueryStringState, serializeInboxSearchParams } from '../searchParams'
import { LeadDateRangeFilterField } from '#src/generated/types'
import { ExpertSavedLeadSearchForOpenSavedSearchFragment } from './SavedSearches.generated'

export const useFireSavedLeadSearch = () => {
  const history = useHistory()
  const { setSavedSearch } = useLeadSearchFolderContext()

  return (search: ExpertSavedLeadSearchForOpenSavedSearchFragment) => {
    const query: QueryStringState = { selections: {}, dateRangeFilters: {} }

    search.facets?.forEach(facet => {
      query.selections[facet.name] = {
        name: facet.name,
        bucketValues: facet.bucketValues,
        lower: facet.lower ? parseFloat(facet.lower) : undefined,
        upper: facet.upper ? parseFloat(facet.upper) : undefined
      }
    })

    search.conditions.forEach(condition => {
      if (
        condition.values.length === 1 &&
        condition.values[0]?.__typename === 'FilterFieldDateRangeValue'
      ) {
        query.dateRangeFilters[condition.field.name] = {
          minDateTime: condition.values[0].dateRangeValue.from,
          maxDateTime: condition.values[0].dateRangeValue.to || new Date(),
          negativeFilter: condition.negative,
          field: condition.field.name as LeadDateRangeFilterField
        }
      } else if (
        condition.values.length === 1 &&
        condition.values[0]?.__typename === 'FilterFieldRelativeDateRangeValue'
      ) {
        const amount = condition.values[0].relativeDateRangeValue.from?.amount
        const unit = condition.values[0].relativeDateRangeValue.from?.unit || 'DAYS'

        query.dateRangeFilters[condition.field.name] = {
          negativeFilter: false,
          field: condition.field.name as LeadDateRangeFilterField,
          duration:
            amount > 0
              ? {
                  to: {
                    amount,
                    unit
                  }
                }
              : {
                  from: {
                    amount,
                    unit
                  }
                }
        }
      }
    })

    setSavedSearch(search.id)
    history.replace({
      search: serializeInboxSearchParams(query, history)
    })
  }
}
