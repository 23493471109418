import React from 'react'
import { Attachment } from '#src/app/typings/media'
import DetachIcon from './detach.svg'
import styles from './styles.css'

interface AttachmentProps {
  attachments: Attachment<any>[]
  onDetach?: (idx: number) => void
}

const AttachmentHandler: React.FC<AttachmentProps> = ({ attachments, onDetach }) => {
  return (
    <div className={styles.preview}>
      {attachments.map((attachment, index) => {
        const PreviewComponent = attachment.previewer

        return (
          <div key={index} className={styles.attachment}>
            {!!onDetach && (
              <div className={styles.detach} onClick={() => onDetach(index)}>
                <DetachIcon className={styles.detachIcon} />
              </div>
            )}
            <PreviewComponent data={attachment.data} />
          </div>
        )
      })}
    </div>
  )
}

export default AttachmentHandler
