import React, { useRef } from 'react'
import { Control } from '@deal/chat-firebase'
import { MediaFile } from '#src/app/typings/media'
import UploadIcon from './upload.svg'
import styles from './styles.css'

interface ImageUploadControlProps {
  attachFile: (file: File | MediaFile, titleForMediaAsset?: string) => void
}

const ImageUploadControl: React.FC<ImageUploadControlProps> = ({ attachFile }) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click()
    }
  }

  const handleFileChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const files = event.target.files

    if (!files || files.length === 0) {
      return
    }

    attachFile(files[0])
  }
  return (
    <Control title="Add file" onClick={handleClick} className={styles.control}>
      <UploadIcon />
      <input
        ref={hiddenInputRef}
        className={styles.hidden}
        type="file"
        onChange={handleFileChanged}
        accept={['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'].join(',')}
      />
    </Control>
  )
}

export default ImageUploadControl
