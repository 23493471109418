import React, { useRef } from 'react'
import { Modal } from '@deal/components'
import { useHistory } from '@deal/router'
import { Text } from '@deal/bluxome'
import { useWakeupLeadsSnoozeMutation } from '#src/app/mutations/WakeupLeadsSnooze.generated'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { toastError } from '#src/app/utilities/toast'
import { addSearchParamsForLeadsWithSnoozeMessageToday } from '#src/app/utilities/snooze'
import { useGetLeadsWithScheduledSnoozeMessageCountQuery } from '#src/app/queries/GetLeadsWithScheduledSnoozeMessageCount.generated'

interface WakeupSnooozedLeadsModalProps {
  onRequestClose: () => void
}

const WakeupSnooozedLeadsModal: React.FC<WakeupSnooozedLeadsModalProps> = ({ onRequestClose }) => {
  const businessUser = useBusinessUser()
  const history = useHistory()
  const now = useRef(new Date())
  const endOfDayTime = new Date(new Date().setHours(23, 59, 59, 999))

  const { data, loading: loadingCount } = useGetLeadsWithScheduledSnoozeMessageCountQuery({
    variables: {
      expertId: businessUser.id,
      minDateTime: now.current,
      maxDateTime: endOfDayTime
    }
  })

  const [wakeupLeads, { loading }] = useWakeupLeadsSnoozeMutation({
    onError: () => toastError('Something went wrong, please try again.'),
    onCompleted: onRequestClose
  })

  const numberOfLeadsWakingUpTodayWithMessage = data?.leads.count || 0

  if (!loadingCount && numberOfLeadsWakingUpTodayWithMessage === 0) {
    onRequestClose()
  }

  return (
    <Modal
      contentLabel="wakeup-snoozed-leads-modal"
      isOpen
      primaryAction={{
        disabled: loading,
        label: 'Send Snooze Messages Now',
        onClick: () => {
          wakeupLeads({
            variables: {
              input: {
                wakeupTimeRange: {
                  from: new Date(),
                  to: endOfDayTime
                }
              }
            }
          })
        }
      }}
      secondaryActions={[
        {
          label: 'See leads',
          disabled: loading,
          onClick: () => {
            history.push({
              search: addSearchParamsForLeadsWithSnoozeMessageToday(history)
            })
            onRequestClose()
          }
        }
      ]}
      tertiaryAction={{
        disabled: loading,
        label: 'Close',
        onClick: onRequestClose
      }}
      onRequestClose={onRequestClose}
    >
      <Text style="large-semibold">
        While you're on Flex Mode, do you want to send today's scheduled snooze messages?
      </Text>
      <br />
      <br />
      <Text>
        You have {numberOfLeadsWakingUpTodayWithMessage} message
        {numberOfLeadsWakingUpTodayWithMessage === 1 ? '' : 's'} scheduled for today
      </Text>
    </Modal>
  )
}

export default WakeupSnooozedLeadsModal
