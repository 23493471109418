import React from 'react'
import numeral from 'numeral'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { usePayPerLeadSummaryDisplayQuery } from './PayPerLeadDailySummaryDisplay.generated'
import styles from './styles.css'

interface PayPerLeadDailySummaryDisplayProps {}

const PayPerLeadDailySummaryDisplay: React.FC<PayPerLeadDailySummaryDisplayProps> = () => {
  const businessUser = useBusinessUser()

  const { data, loading } = usePayPerLeadSummaryDisplayQuery({
    variables: {
      expertId: businessUser.id
    },
    fetchPolicy: 'cache-and-network'
  })

  if (data) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>Your Daily Summary</div>
        <InformationRow
          blockText={data.payPerLeadTodaySummary.newLeadCount.toString()}
          headline="New leads today"
          subHeadline="Includes every assignment, active or not"
        />
        <InformationRow
          blockText={numeral(data.payPerLeadTodaySummary.totalExpertSpend.amount).format('$0,0')}
          headline="Your lead spend (DC)"
          subHeadline="If lead is not active in 24 hours, $1 is returned"
        />
        <InformationRow
          blockText={numeral(data.payPerLeadTodaySummary.totalCuratedSpend.amount).format('$0,0')}
          headline="Curated's lead spend"
          subHeadline="For your new leads"
        />
        <InformationRow
          blockText={numeral(
            data.me?.businessUser?.discountCashAccount.usableBalance.amount
          ).format('$0,0')}
          headline="Your remaining DC"
        />
      </div>
    )
  } else if (loading) {
    return <FullBlockLoader loader={CircleLoader} />
  }
  return null
}

interface InformationRowProps {
  blockText: string
  headline: string
  subHeadline?: string
}

const InformationRow: React.FC<InformationRowProps> = ({ blockText, headline, subHeadline }) => {
  return (
    <div className={styles.row}>
      <div className={styles.numberBlock}>{blockText}</div>
      <div className={styles.info}>
        <div className={styles.infoMain}>{headline}</div>
        {subHeadline && <div className={styles.infoSub}>{subHeadline}</div>}
      </div>
    </div>
  )
}

export default PayPerLeadDailySummaryDisplay
