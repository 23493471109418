import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Link, RouteComponentProps, useLocation, withRouter } from '@deal/router'
import { CircleLoader, FullBlockLoader, Grid, GridItem } from '@deal/components'
import { ExpertApplicationState } from '#src/generated/types'
import { isImpersonating } from '#src/app/utilities/identity'
import { IdentityContext } from '#src/app/containers/Identity'
import { HostAwareConfigContext } from '#src/app/containers/HostAwareConfig'
import ExternalUrlAwareRedirect from '#src/app/components/ExternalUrlAwareRedirect'
import { useAssumeBusinessUserIdentityMutation } from '../../mutations/AssumeBusinessUserIdentity.generated'
import {
  AssignedBusinessUserFragment,
  AuthenticatedBusinessUserFragment
} from '../../fragments/myself.generated'
import ReactivateBusinessAccount from '../../components/Navigation/ReactivateBusinessUserAccountSplash'
import styles from './styles.css'

interface Props {
  children: (businessUser: AuthenticatedBusinessUserFragment) => React.ReactNode
}

const Authenticate: React.FC<Props> = ({ children }) => {
  const { myself } = useContext(IdentityContext)
  const [assumeBusinessUserIdentity] = useAssumeBusinessUserIdentityMutation({
    onError: () => toast.error('Failed to log in as business user')
  })
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const authExpertId = params.get('authExpertId')
  const config = useContext(HostAwareConfigContext)

  if (myself) {
    const impersonating = isImpersonating(myself)
    const assignedBusinessUsers = myself.realUser.assignedBusinessUsers
    const activeBusinessUsers: AssignedBusinessUserFragment[] = []
    const deactivatedBusinessUsers: AssignedBusinessUserFragment[] = []

    assignedBusinessUsers.forEach(businessUser => {
      businessUser.deactivated
        ? deactivatedBusinessUsers.push(businessUser)
        : activeBusinessUsers.push(businessUser)
    })

    /**
     * If the user only has one assigned business user and that business user has a pending application (not approved) we should just redirect them to
     * the consumer app post-application page (/apply/create-profile).
     * First-time applicants who have not been approved should never realistically be directed to the business app. If there are, we should just send them back to the post-application page
     * they land on after the application path (/apply/create-profile)
     */
    if (
      assignedBusinessUsers.length <= 1 &&
      myself.businessUser?.expertApplication?.state === ExpertApplicationState.PENDING
    ) {
      const consumer = config.get('consumer')

      const postApplicationPathUrl = `${consumer.protocol}://${consumer.host}:${consumer.port}/apply/create-profile/${myself.businessUser.expertApplication.id}`
      return <ExternalUrlAwareRedirect to={postApplicationPathUrl} />
    }

    if (
      myself.businessUser &&
      (!myself.businessUser.deactivated || impersonating) &&
      (authExpertId === null || myself.businessUser?.id === authExpertId)
    ) {
      return <>{children(myself.businessUser)}</>
    } else if (activeBusinessUsers.length >= 1 && !impersonating && !authExpertId) {
      // Just set the first business user as the default
      // Experts can easily change once logged in from rail
      return (
        <SetDefaultBusinessUser
          setDefaultBusinessUser={() => {
            assumeBusinessUserIdentity({
              variables: {
                input: { businessUserId: activeBusinessUsers[0].id }
              }
            })
          }}
        />
      )
    } else if (
      authExpertId !== null &&
      activeBusinessUsers.find(expert => expert.id === authExpertId)
    ) {
      return (
        <SetDefaultBusinessUser
          setDefaultBusinessUser={() => {
            assumeBusinessUserIdentity({
              variables: {
                input: { businessUserId: authExpertId }
              }
            })
          }}
        />
      )
    } else if (myself.businessUser?.deactivated || deactivatedBusinessUsers.length > 0) {
      if (!myself.businessUser) {
        return (
          <SetDefaultBusinessUser
            setDefaultBusinessUser={() => {
              assumeBusinessUserIdentity({
                variables: {
                  input: { businessUserId: deactivatedBusinessUsers[0].id }
                }
              })
            }}
          />
        )
      } else {
        return <ReactivateBusinessAccount />
      }
    } else {
      return (
        <Grid>
          <GridItem col={2} lgCol={8} className={styles.container}>
            You are not logged in to an account associated with an Expert account.
            <br />
            <br />
            Please log out by clicking <Link to="/auth/logout">here</Link> then log back in using
            the email/phone number associated with your Expert account.
            <br />
            <br />
            If you need additional assisstance, please email{' '}
            <a href="mailto:expert-success@curated.com" target="_top">
              expert-success@curated.com
            </a>
          </GridItem>
        </Grid>
      )
    }
  } else {
    return <RedirectToLogin />
  }
}

const RedirectToLogin = withRouter((props: RouteComponentProps<any>) => {
  const config = useContext(HostAwareConfigContext)
  const consumer = config.get('consumer')
  const business = config.get('business')

  const returnUrl =
    process.env.target === 'web'
      ? window.location.href
      : `${business.protocol}://${business.host}:${business.port}${props.location.pathname}${props.location.search}`
  const loginUrl = `${consumer.protocol}://${consumer.host}:${
    consumer.port
  }/auth/login?then=${encodeURIComponent(returnUrl)}`

  return <ExternalUrlAwareRedirect to={loginUrl} />
})

interface SetDefaultBusinessUserProps {
  setDefaultBusinessUser: () => void
}

const SetDefaultBusinessUser: React.FC<SetDefaultBusinessUserProps> = ({
  setDefaultBusinessUser
}) => {
  useEffect(() => {
    setDefaultBusinessUser()
  }, [])

  return (
    <Grid>
      <GridItem col={2} className={styles.container}>
        Signing in. Please wait...
        <FullBlockLoader loader={CircleLoader} />
      </GridItem>
    </Grid>
  )
}

export default Authenticate
