import React, { useContext, useEffect, useState } from 'react'
import useUnreadMessageCount from '#src/app/hooks/useUnreadMessageCount'
import { VisibilityContext } from '#src/app/containers/Visibility'

const NewMessagePageTitleNotification: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const isPageVisible = useContext(VisibilityContext)
  const unreadMessageCount = useUnreadMessageCount()
  const [previousTitle, setPreviousTitle] = useState(
    process.env.TARGET === 'node' ? undefined : document.title
  )
  const [flashingTitleToggle, setFlashingTitleToggle] = useState(false)
  // Update the <title> when the page visibility, unread message count,
  //   or the "flashing" state (documented below) changes.
  //
  // Note: we can't use react-helmet-async for this because it does not
  //   work when the page is hidden: https://github.com/staylor/react-helmet-async/issues/82
  useEffect(() => {
    // Reset to the previous title when the tab regains focus
    if (isPageVisible) {
      if (previousTitle) {
        document.title = previousTitle
      }

      setPreviousTitle(undefined)
    }
  }, [isPageVisible, unreadMessageCount, flashingTitleToggle])

  // When showing the title notification, alternate between 2 messages in
  //   the <title>, every second:
  //
  //  - "(1) Curated Business"
  //  - "*New Message*"
  useEffect(() => {
    if (!isPageVisible) {
      const timeout = setInterval(() => {
        setFlashingTitleToggle(isFlashing => !isFlashing)
      }, 1500)

      return () => {
        clearInterval(timeout)
      }
    }
  }, [isPageVisible])

  return null
}

export default NewMessagePageTitleNotification
