import React from 'react'
import { Modal } from '@deal/components'
import styles from './styles.css'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { formatTime } from '@deal/date-utils'

const EndShiftConfirmation: React.FC<React.PropsWithChildren<{
  onRequestClose: () => void
  onEndShiftConfirmed: () => void
}>> = ({ onRequestClose, onEndShiftConfirmed }) => {
  const businessUser = useBusinessUser()
  const timeZone = businessUser.timeZone || 'America/Los_Angeles'
  const endsAt =
    businessUser.expertPresence.__typename === 'ExpertPresenceOnShift' &&
    new Date(businessUser.expertPresence.endsAt)

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      title="End CAT"
      actionVariant="neutral-dark"
      primaryAction={{
        label: 'End CAT',
        onClick: onEndShiftConfirmed
      }}
      secondaryActions={[
        {
          label: 'Cancel',
          onClick: onRequestClose
        }
      ]}
    >
      <div className={styles.warning}>
        <b>Please note!</b> You are about to end your CAT early. If you end your CAT more than 15
        minutes before its end time, a penalty will be imposed. If you are receiving too many leads,
        you can pause leads instead of ending your CAT.
      </div>

      {endsAt ? (
        <>
          <div>
            Your current CAT will automatically end at <b>{formatTime(endsAt, timeZone)}</b> and, if
            you have another immediately, it will start automatically.
          </div>
          <div className={styles.confirmation}>Are you sure you want to end your CAT early?</div>
        </>
      ) : (
        <div>Are you sure you want to end your CAT early?</div>
      )}
    </Modal>
  )
}

export default EndShiftConfirmation
