// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MediaAssetsPaginationForMyRecentsPanelQueryVariables = Types.Exact<{
  input: Types.MediaAssetQueryInput;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type MediaAssetsPaginationForMyRecentsPanelQuery = { __typename: 'Query', mediaAssetsPagination: { __typename: 'MediaAssetsPagination', mediaAssets: Array<{ __typename: 'MediaAsset', id: any, media: { __typename: 'MediaFile', id: any, url: string } }> } };


export const MediaAssetsPaginationForMyRecentsPanelDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MediaAssetsPaginationForMyRecentsPanel"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MediaAssetQueryInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"offset"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaAssetsPagination"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaAssets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]}}]} as unknown as DocumentNode;
export type MediaAssetsPaginationForMyRecentsPanelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>, 'query'> & ({ variables: MediaAssetsPaginationForMyRecentsPanelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MediaAssetsPaginationForMyRecentsPanelComponent = (props: MediaAssetsPaginationForMyRecentsPanelComponentProps) => (
      <ApolloReactComponents.Query<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables> query={MediaAssetsPaginationForMyRecentsPanelDocument} {...props} />
    );
    

/**
 * __useMediaAssetsPaginationForMyRecentsPanelQuery__
 *
 * To run a query within a React component, call `useMediaAssetsPaginationForMyRecentsPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaAssetsPaginationForMyRecentsPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaAssetsPaginationForMyRecentsPanelQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMediaAssetsPaginationForMyRecentsPanelQuery(baseOptions: Apollo.QueryHookOptions<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>(MediaAssetsPaginationForMyRecentsPanelDocument, options);
      }
export function useMediaAssetsPaginationForMyRecentsPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>(MediaAssetsPaginationForMyRecentsPanelDocument, options);
        }
export function useMediaAssetsPaginationForMyRecentsPanelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>(MediaAssetsPaginationForMyRecentsPanelDocument, options);
        }
export type MediaAssetsPaginationForMyRecentsPanelQueryHookResult = ReturnType<typeof useMediaAssetsPaginationForMyRecentsPanelQuery>;
export type MediaAssetsPaginationForMyRecentsPanelLazyQueryHookResult = ReturnType<typeof useMediaAssetsPaginationForMyRecentsPanelLazyQuery>;
export type MediaAssetsPaginationForMyRecentsPanelSuspenseQueryHookResult = ReturnType<typeof useMediaAssetsPaginationForMyRecentsPanelSuspenseQuery>;
export type MediaAssetsPaginationForMyRecentsPanelQueryResult = Apollo.QueryResult<MediaAssetsPaginationForMyRecentsPanelQuery, MediaAssetsPaginationForMyRecentsPanelQueryVariables>;