import React, { useContext } from 'react'
import { UAParser } from 'ua-parser-js'
import { DealRequest } from '@deal/web-express-server'

type UserAgent =
  | {
      browser: UAParser.IBrowser
      device: UAParser.IDevice
      os: UAParser.IOS
      engine: UAParser.IEngine
      cpu: UAParser.ICPU
      raw: string
      isBot?: boolean
    }
  | undefined

// Context
const UserAgentContext = React.createContext<UserAgent>(undefined)

// Consumer
const UserAgentConsumer = UserAgentContext.Consumer

interface UserAgentProps {
  req?: DealRequest
  isBot: boolean
}

const UserAgentProvider: React.FC<React.PropsWithChildren<UserAgentProps>> = ({ req, isBot, children }) => {
  const parser = new UAParser(req && req.headers['user-agent'])

  return (
    <UserAgentContext.Provider
      value={{
        browser: parser.getBrowser(),
        device: parser.getDevice(),
        os: parser.getOS(),
        engine: parser.getEngine(),
        cpu: parser.getCPU(),
        raw: parser.getUA(),
        isBot
      }}
    >
      {children}
    </UserAgentContext.Provider>
  )
}

const useUserAgentContext = () => {
  const userAgentContext = useContext(UserAgentContext)

  if (!userAgentContext) {
    throw new Error('Invoked UserAgentContext outside of provider')
  }

  return userAgentContext
}

export { UserAgentProvider, UserAgentConsumer, UserAgentContext, UserAgent, useUserAgentContext }
