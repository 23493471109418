import '@deal/boomerang'

export function initBoomerang(): void {
  const BOOMR = window.BOOMR
  if (!BOOMR) {
    return
  }

  // See the following docs:
  // https://akamai.github.io/boomerang/index.html
  // https://akamai.github.io/boomerang/BOOMR.plugins.SPA.html
  BOOMR.init({
    autorun: false,
    // Disable logging; it's extremely verbose by default
    log: null
  })
  BOOMR.addVar('app', 'business-app')
}
