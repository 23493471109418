import React, { useEffect, useState } from 'react'
import { useLocation } from '@deal/router'

const VisibilityContext = React.createContext<boolean>(true)

const VisibilityConsumer = VisibilityContext.Consumer

const VisibilityProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [visible, setVisible] = useState(true)
  const location = useLocation()
  useEffect(() => {
    const VisibilityJs = require('visibilityjs')
    setVisible(VisibilityJs.state() === 'visible')

    VisibilityJs.change((_event: any, state: string) => {
      if (state === 'visible') {
        setVisible(true)
      } else {
        /**
         * Anytime the app is hidden we can assume the push notification session is ending.
         * We don't need to check if we are in the native mobile app since this should
         * already be false if we aren't
         */
        location.state = { isPushNotificationSession: false }
        setVisible(false)
      }
    })
  }, [])

  return <VisibilityContext.Provider value={visible}>{children}</VisibilityContext.Provider>
}

export { VisibilityProvider, VisibilityConsumer, VisibilityContext }
