import React from 'react'
import { Waypoint } from 'react-waypoint'
import { CircleLoader, FullBlockLoader } from '@deal/components'
import ImageModal, {
  decrementMaybeNumber,
  incrementMaybeNumber
} from '#src/app/components/ImageModal'
import styles from './styles.css'

interface ResultsContainerProps {
  zoomImageSource?: string
  loading?: boolean
  onZoomedImageSelected?: () => void
  onPreviousZoomedImageRequested?: () => void
  onNextZoomedImageRequested?: () => void
  onModalClose?: () => void
  onScrollToEndOfResults?: () => void
}

const ResultsContainer: React.FC<React.PropsWithChildren<
  React.PropsWithChildren<ResultsContainerProps>
>> = ({
  children,
  zoomImageSource,
  loading,
  onZoomedImageSelected,
  onNextZoomedImageRequested,
  onPreviousZoomedImageRequested,
  onModalClose,
  onScrollToEndOfResults
}) => {
  return (
    <div className={styles.container}>
      {children}
      {!loading && onScrollToEndOfResults && <Waypoint onEnter={onScrollToEndOfResults} />}
      {loading && (
        <div className={styles.loaderContainer}>
          <FullBlockLoader loader={CircleLoader} />
        </div>
      )}
      {zoomImageSource && (
        <ImageModal
          src={zoomImageSource}
          loading={loading}
          onImageSelected={onZoomedImageSelected}
          onNext={onNextZoomedImageRequested}
          onPrevious={onPreviousZoomedImageRequested}
          onModalClose={() => onModalClose?.()}
        />
      )}
    </div>
  )
}

export default ResultsContainer
export { incrementMaybeNumber, decrementMaybeNumber }
