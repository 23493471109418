import React, { useContext } from 'react'
import { ImageUploadPreview, withMediaUpload } from '@deal/chat-firebase'
import { FileAttachment, GifAttachment } from '#src/app/typings/media'
import { isBusinessMobileNative, isMobileBrowser } from '#src/app/services/mobile'
import { UserAgentContext } from '#src/app/context/UserAgent'
import { MediaSearchControlContextProvider } from '#src/app/components/MediaSearchControl/context'
import MediaSearchControl, { MediaSearchControlProps } from '#src/app/components/MediaSearchControl'
import withAttachmentUpload from '#src/app/components/MediaAttachmentControls/hocs/withAttachmentUpload'
import EmojiPickerControl from '#src/app/components/EmojiPickerControl'
import VariableControl from '../VariableControl'
import ImageUploadControl from '../ImageUploadControl'
import styles from './styles.css'

export interface MediaAttachmentControlsProps {
  hasVariableError: boolean
  onAppendText: (text: string, isVariable: boolean) => void
  onAttachmentAdded: (attachment: FileAttachment | GifAttachment) => void
}

const WrappedImageUploadControl = withMediaUpload(withAttachmentUpload(ImageUploadControl))
const WrappedMediaSearchControl = withMediaUpload(
  withAttachmentUpload<MediaSearchControlProps>(MediaSearchControl)
)

const MediaAttachmentControls: React.FC<MediaAttachmentControlsProps> = ({
  hasVariableError,
  onAppendText,
  onAttachmentAdded
}) => {
  const userAgent = useContext(UserAgentContext)
  return (
    <MediaSearchControlContextProvider>
      <div className={styles.controls}>
        {/* hide the emoji picker if mobile */}
        {!isMobileBrowser(userAgent) && !isBusinessMobileNative() && (
          <EmojiPickerControl appendText={emoji => onAppendText(emoji, false)} appendTo="parent" />
        )}
        <WrappedMediaSearchControl
          onAttach={onAttachmentAdded}
          previews={[ImageUploadPreview]}
          appendTo="parent"
        />
        <WrappedImageUploadControl onAttach={onAttachmentAdded} previews={[ImageUploadPreview]} />
        <VariableControl
          onVariableSelected={variable => onAppendText(variable, true)}
          hasError={hasVariableError}
        />
      </div>
    </MediaSearchControlContextProvider>
  )
}

export default MediaAttachmentControls
