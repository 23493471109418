// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateLeadNoteForNewExpertPhoneCallToastMutationVariables = Types.Exact<{
  input: Types.CreateLeadNote;
}>;


export type CreateLeadNoteForNewExpertPhoneCallToastMutation = { __typename: 'Mutation', createLeadNote: { __typename: 'CreateLeadNotePayload', note: { __typename: 'SavedLeadNote', id: any, text: string, messageId?: string | null } } };


export const CreateLeadNoteForNewExpertPhoneCallToastDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateLeadNoteForNewExpertPhoneCallToast"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateLeadNote"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createLeadNote"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"note"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}},{"kind":"Field","name":{"kind":"Name","value":"messageId"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreateLeadNoteForNewExpertPhoneCallToastMutationFn = Apollo.MutationFunction<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables>;
export type CreateLeadNoteForNewExpertPhoneCallToastComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables>, 'mutation'>;

    export const CreateLeadNoteForNewExpertPhoneCallToastComponent = (props: CreateLeadNoteForNewExpertPhoneCallToastComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables> mutation={CreateLeadNoteForNewExpertPhoneCallToastDocument} {...props} />
    );
    

/**
 * __useCreateLeadNoteForNewExpertPhoneCallToastMutation__
 *
 * To run a mutation, you first call `useCreateLeadNoteForNewExpertPhoneCallToastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeadNoteForNewExpertPhoneCallToastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeadNoteForNewExpertPhoneCallToastMutation, { data, loading, error }] = useCreateLeadNoteForNewExpertPhoneCallToastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeadNoteForNewExpertPhoneCallToastMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables>(CreateLeadNoteForNewExpertPhoneCallToastDocument, options);
      }
export type CreateLeadNoteForNewExpertPhoneCallToastMutationHookResult = ReturnType<typeof useCreateLeadNoteForNewExpertPhoneCallToastMutation>;
export type CreateLeadNoteForNewExpertPhoneCallToastMutationResult = Apollo.MutationResult<CreateLeadNoteForNewExpertPhoneCallToastMutation>;
export type CreateLeadNoteForNewExpertPhoneCallToastMutationOptions = Apollo.BaseMutationOptions<CreateLeadNoteForNewExpertPhoneCallToastMutation, CreateLeadNoteForNewExpertPhoneCallToastMutationVariables>;