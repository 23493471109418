// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type AcceptLeadEscalationMutationVariables = Types.Exact<{
  input: Types.AcceptEscalationInput;
}>;


export type AcceptLeadEscalationMutation = { __typename: 'Mutation', acceptLeadEscalation: { __typename: 'AcceptEscalationResult', status: Types.AcceptEscalationResultStatus, escalation: { __typename: 'LeadEscalation', id: any } } };


export const AcceptLeadEscalationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AcceptLeadEscalation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AcceptEscalationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptLeadEscalation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"escalation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
export type AcceptLeadEscalationMutationFn = Apollo.MutationFunction<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables>;
export type AcceptLeadEscalationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables>, 'mutation'>;

    export const AcceptLeadEscalationComponent = (props: AcceptLeadEscalationComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables> mutation={AcceptLeadEscalationDocument} {...props} />
    );
    

/**
 * __useAcceptLeadEscalationMutation__
 *
 * To run a mutation, you first call `useAcceptLeadEscalationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptLeadEscalationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptLeadEscalationMutation, { data, loading, error }] = useAcceptLeadEscalationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptLeadEscalationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables>(AcceptLeadEscalationDocument, options);
      }
export type AcceptLeadEscalationMutationHookResult = ReturnType<typeof useAcceptLeadEscalationMutation>;
export type AcceptLeadEscalationMutationResult = Apollo.MutationResult<AcceptLeadEscalationMutation>;
export type AcceptLeadEscalationMutationOptions = Apollo.BaseMutationOptions<AcceptLeadEscalationMutation, AcceptLeadEscalationMutationVariables>;