import React from 'react'
import { ToastOptions, toast } from 'react-toastify'
import classnames from 'classnames'
import styles from './styles.css'

const getToastClassNameString = (className: ToastOptions['className']) => {
  return typeof className === 'function' ? className() : className
}

export const toastSuccess = (toastMessage: string | React.ReactNode, options?: ToastOptions) => {
  const { className, bodyClassName, ...rest }: ToastOptions = options || {}
  toast(() => <div>{toastMessage}</div>, {
    className: classnames(styles.successContainer, getToastClassNameString(className)),
    bodyClassName: classnames(styles.successBody, getToastClassNameString(bodyClassName)),
    ...rest
  })
}

export const toastError = (toastMessage: string | React.ReactNode, options?: ToastOptions) => {
  const { className, bodyClassName, autoClose = 10000, ...rest }: ToastOptions = options || {}
  toast(() => <div>{toastMessage}</div>, {
    className: classnames(styles.errorContainer, getToastClassNameString(className)),
    bodyClassName: classnames(styles.errorBody, getToastClassNameString(bodyClassName)),
    autoClose,
    ...rest
  })
}
