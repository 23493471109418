import React from 'react'
import numeral from 'numeral'
import { Link } from '@deal/router'
import { PaymentActivityType } from '#src/generated/types'
import Toast from '#src/app/components/Toast'
import { LeadPaymentFailedActivityFragment } from '../../components/LeadActivitySubscription/LeadActivityOccurred.generated'
import formattedToast from '../formattedToast'
import CartIcon from './cart.svg'
import styles from './styles.css'

interface Props {
  activity: LeadPaymentFailedActivityFragment
  closeToast?: () => void
}

const LeadPaymentFailedToast: React.FC<React.PropsWithChildren<Props>> = ({ closeToast, activity }) => {
  const { lead, paymentError } = activity
  return (
    <Toast onCloseToastRequested={() => closeToast && closeToast()}>
      <div className={styles.side}>
        <CartIcon />
      </div>
      <div className={styles.textContainer}>
        <Link to={`/inbox/${lead.id}`} onClick={closeToast} className={styles.title}>
          {toastHeadline(activity)}
        </Link>

        <div className={styles.subTitle}>{toastBody(activity)}</div>
        <div className={styles.error}>{paymentError}</div>
      </div>
    </Toast>
  )
}

const toastHeadline = function(activity: LeadPaymentFailedActivityFragment): string {
  switch (activity.paymentActivityType) {
    case PaymentActivityType.DEPOSIT:
      return `Deposit payment failed for ${activity.lead.consumer.firstName}`
    case PaymentActivityType.FULL_PAYMENT:
      return `Payment failed for ${activity.lead.consumer.firstName}.`
    case PaymentActivityType.REMAINING_BALANCE:
      return `Remaining balance payment failed for ${activity.lead.consumer.firstName}`
    default:
      return `Payment succeeded from ${activity.lead.consumer.firstName}`
  }
}

const toastBody = function(activity: LeadPaymentFailedActivityFragment): string {
  const { tip, total, amountPaid, transactionAmount } = activity

  switch (activity.paymentActivityType) {
    case PaymentActivityType.DEPOSIT:
      if (tip) {
        return `${numeral(tip.amount).format('$0,0.00')} tip and ${numeral(
          transactionAmount.amount
        ).format('$0,0.00')} deposit on a ${numeral(total.amount).format('$0,0.00')} purchase`
      } else {
        return `${numeral(transactionAmount.amount).format('$0,0.00')} deposit on a ${numeral(
          total.amount
        ).format('$0,0.00')} purchase`
      }
    case PaymentActivityType.FULL_PAYMENT:
      if (tip) {
        return `${numeral(tip.amount).format('$0,0.00')} tip on a ${numeral(total.amount).format(
          '$0,0.00'
        )} purchase`
      } else {
        return ` ${numeral(total.amount).format('$0,0.00')} purchase`
      }
    case PaymentActivityType.REMAINING_BALANCE:
      return `${numeral(amountPaid.amount).format('$0,0.00')} paid on a ${numeral(
        total.amount
      ).format('$0,0.00')} purchase`
    default:
      return `Failed payment from ${activity.lead.consumer.firstName}`
  }
}

const leadPaymentFailedToast = (props: LeadPaymentFailedActivityFragment) =>
  formattedToast(<LeadPaymentFailedToast activity={props} />)

export default leadPaymentFailedToast
