import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { Modal, Tooltip } from '@deal/components'
import { CuratedTermsOfUseType } from '#src/generated/types'
import { toastError, toastSuccess } from '#src/app/utilities/toast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useAcceptCuratedTermsOfUseMutation } from './TermsOfUseAgreementModal.generated'
import SuccessIcon from './success.svg'
import styles from './styles.css'

interface TermsOfUseAgreementModalProps {
  type: CuratedTermsOfUseType
  isNewExpert: boolean
  onRequestClose?: () => void
  isOpen: boolean
}

const TermsOfUseAgreementModal: React.FC<React.PropsWithChildren<
  TermsOfUseAgreementModalProps
>> = ({ type, isNewExpert, isOpen, onRequestClose }) => {
  const businessUser = useBusinessUser()
  const [hasUserScrolledToBottomOfAgreement, setHasUserScrolledToBottomOfAgreement] = useState(
    false
  )

  const [acceptTerms] = useAcceptCuratedTermsOfUseMutation({
    onCompleted: () => {
      onRequestClose?.()
      toastSuccess(
        <div className={styles.toastContent}>
          <SuccessIcon className={styles.toastIcon} />
          <div>
            <div className={styles.toastHeader}>Task completed</div>
            <div className={styles.toastBodyText}>Accepted terms of service and use</div>
          </div>
        </div>,
        { bodyClassName: styles.toastBody }
      )
    },
    onError: () => {
      onRequestClose?.()
      toastError('Failed to sign contract')
    }
  })

  return (
    <Modal
      onRequestClose={onRequestClose}
      elevation="highest"
      contentLabel={
        type === CuratedTermsOfUseType.NDA
          ? 'Business App NDA'
          : 'Business App Terms of Service and Use'
      }
      isOpen={isOpen}
      width="large"
      mobilePosition="bottom"
      contentScroll
      actionVariant="secondary"
      primaryAction={{
        label: (
          <Tooltip
            message="Read the whole doc before clicking agree & continue"
            visible={hasUserScrolledToBottomOfAgreement ? false : undefined}
            theme="dark"
          >
            <>Agree & continue</>
          </Tooltip>
        ),
        onClick: () => {
          acceptTerms({
            variables: {
              input: {
                id: businessUser.id,
                type
              }
            }
          })
        },
        disabled: !hasUserScrolledToBottomOfAgreement
      }}
    >
      {type === CuratedTermsOfUseType.NDA ? (
        <NDA onScrolledToBottom={() => setHasUserScrolledToBottomOfAgreement(true)} />
      ) : (
        <ExpertContract
          isNewExpert={isNewExpert}
          onScrolledToBottom={() => setHasUserScrolledToBottomOfAgreement(true)}
        />
      )}
    </Modal>
  )
}

const NDA: React.FC<React.PropsWithChildren<{ onScrolledToBottom: () => void }>> = ({
  onScrolledToBottom
}) => {
  return (
    <div className={styles.container}>
      <h1>Curated Expert NDA</h1>
      <h4>Updated on Feb 16, 2023</h4>
      <section>
        <p>
          Non-Disclosure Agreement that applies to prospective candidates for admission to the
          Expert community
        </p>
        <p>
          We encourage you to review carefully. Your continuation with your application to join the
          Expert community and use of our platform is contingent on your acknowledgement of and
          agreement to the non-disclosure agreement.
        </p>
        <p>Thank you!</p>
        <p>- The Curated Team</p>
      </section>
      <p>
        <strong>What this document concerns:</strong> You are applying to join the Curated Expert
        community! That’s great, and we’re happy about that. We have spent a lot of time and effort
        developing our application process and also our platform orientation process, and we
        consider all of the information that you’ll see in those processes to be confidential.
        Because you will be exposed to confidential information as part of these processes, it is
        important to us that you enter into a non-disclosure agreement before we go further.
      </p>
      <p>
        <strong>And so:</strong> By your acceptance of the terms and conditions of this
        non-disclosure agreement (the <strong>“Agreement”</strong>) between you and Curated, Inc., a
        Delaware corporation (the <strong>"Company"</strong>, or <strong>"we"</strong> or{' '}
        <strong>"us"</strong>), which you understand is necessary in order to proceed with the
        evaluation of any relationship between you and us, you hereby acknowledge and agree as
        follows:
      </p>
      <p>
        In consideration of any disclosure and such relationship or proposed relationship, you agree
        as follows:
      </p>
      <ol>
        <li>
          You will hold in confidence and will not possess or use (except in connection with the
          evaluation of any proposed relationship with us).) or disclose any business, technical or
          other information, materials and/or ideas of or disclosed to you by the Company (
          <strong>“Proprietary Information”</strong>, which term shall include, without limitation,
          anything you learn or discover as a result of exposure to or analysis of any Proprietary
          Information), except information you can document (a) is in the public domain through no
          fault of yours, (b) was properly known to you, without restriction, prior to disclosure by
          Company or (c) was properly disclosed to you by another person without restriction. You
          will not reverse engineer or attempt to derive the composition or underlying information,
          structure or ideas of any Proprietary Information. The foregoing does not grant you a
          license in or to any of the Proprietary Information.
        </li>
        <li>
          If you decide not to proceed with the proposed business relationship or if asked by
          Company, you will promptly return all Proprietary Information and all copies, extracts and
          other objects or items in which Proprietary Information may be contained or embodied.
        </li>
        <li>
          You will promptly notify Company of any unauthorized release, disclosure or use of
          Proprietary Information.
        </li>
        <li>
          You understand that this Agreement does not obligate Company to disclose any information
          or negotiate or enter into any agreement or relationship. You will strictly abide by any
          and all instructions and restrictions provided by Company from time to time with respect
          to Proprietary Information or Company systems. You will ensure the security of any
          facilities, machines, accounts, passwords and methods you use to store any Proprietary
          Information or to access Company systems and ensure that no other person has or obtains
          access thereto.
        </li>
        <li>
          The terms of this Agreement will remain in effect for one (1) year from the date of your
          acceptance of it.
        </li>
        <li>
          You acknowledge and agree that due to the unique nature of the Proprietary Information,
          any breach of this agreement would cause irreparable harm to the Company for which damages
          are not an adequate remedy, and that Company shall therefore be entitled to equitable
          relief in addition to all other remedies available at law.
        </li>
        <li>
          This Agreement is personal to you, is nonassignable by you, is governed by the internal
          laws of the State of California and may be modified or waived only in writing signed by
          both parties. If any provision of this Agreement is found to be unenforceable, such
          provision will be limited or deleted to the minimum extent necessary so that the remaining
          terms remain in full force and effect. The prevailing party in any dispute or legal action
          regarding the subject matter of this Agreement shall be entitled to recover attorneys’
          fees and costs.
        </li>
      </ol>

      <Waypoint bottomOffset={-10} onEnter={onScrolledToBottom} />
    </div>
  )
}

const ExpertContract: React.FC<React.PropsWithChildren<{
  isNewExpert: boolean
  onScrolledToBottom: () => void
}>> = ({ onScrolledToBottom, isNewExpert }) => {
  const intro = isNewExpert ? (
    <>
      <h1>Curated Terms of Service & Use</h1>
      <h4>Updated on January 1, 2024</h4>
      <section>
        <p>
          Terms of Service & Use that apply to Experts’ use of the Curated platform, including
          information on procedures for resolving disputes with us.
        </p>
        <p>
          We encourage you to review carefully all of the terms. Your use of our platform is
          contingent on your acknowledgement of and agreement to the Terms of Service & Use.
        </p>
        <p>
          Thank you for being a part of our community, and we wish you a healthy and prosperous
          year!
        </p>
        <p>- The Curated Team</p>
      </section>
    </>
  ) : (
    <>
      <h1>We've Updated Our Terms of Service & Use</h1>
      <h4>Updated on January 1, 2024</h4>
      <section>
        <p>
          Because we value your partnership, we want you to know that we've made updates to the
          Terms of Service & Use that apply to Experts' use of the Curated platform, including
          information on procedures for resolving disputes with us.
        </p>
        <p>
          We encourage you to review carefully all of the updated terms. Your continued use of our
          platform is contingent on your acknowledgement of and agreement to the updated Terms of
          Service & Use.
        </p>
        <p>
          Thank you for being a part of our community, and we wish you a healthy and prosperous
          year!
        </p>
        <p>- The Curated Team</p>
      </section>
    </>
  )

  return (
    <div className={styles.container}>
      {intro}
      <p>
        These Terms of Service constitute a legally binding agreement (together with any Supplement,
        the <strong>“Agreement”</strong>) between you, an independent contractor engaged in the
        business of performing services through the Curated platform, and Curated, Inc., its
        parents, subsidiaries, representatives, affiliates, officers and directors (collectively,
        the <strong>“Company”</strong>, <strong>“we”</strong>, <strong>“us”</strong> or{' '}
        <strong>“our”</strong>) governing your use of the Company’s platform, applications, websites
        and technology (the <strong>“Curated Platform”</strong>). In your capacity as an Expert (as
        defined below), you are required to acknowledge and agree to this Agreement and to abide by
        its terms at all times while using the Curated Platform and providing services through the
        Curated platform.
      </p>
      <p>
        BE ADVISED: THE AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN YOU AND THE
        COMPANY CAN BE BROUGHT (SEE SECTION 12 BELOW).{' '}
        <strong>
          THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO: (1) WAIVE YOUR RIGHT TO A
          JURY TRIAL, AND (2) SUBMIT CLAIMS THAT YOU HAVE AGAINST THE COMPANY TO BINDING AND FINAL
          ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR A CLASS MEMBER IN ANY CLASS,
          GROUP OR REPRESENTATIVE ACTION OR PROCEEDING.
        </strong>
      </p>
      <p>
        By entering into this Agreement, and/or by using or accessing the Curated Platform, you
        expressly acknowledge that you understand the Agreement (including the dispute resolution
        and arbitration provisions in Section 12), and accept all of its terms.{' '}
        <strong>
          IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT
          USE OR ACCESS THE COMPANY PLATFORM OR ANY OF THE SERVICES PROVIDED THROUGH THE PLATFORM.
        </strong>
      </p>
      <p>
        When using the Curated Platform, you also agree to conduct yourself in accordance with our
        Community Standards, which shall form part of this Agreement between you and the Company.
      </p>
      <ol>
        <li>
          <u>Curated Platform</u>. The Curated Platform provides a three-sided marketplace whereby,
          among other things, persons who seek advice on high consideration consumer product
          purchases (<strong> “Users”</strong>) can be connected to, or access content provided by,
          experts on those consumer products (<strong>“Experts”</strong>). The expert services
          provided by Experts to or for the benefit of Users is referred to herein as{' '}
          <strong>“Expert Services”</strong>, and includes any Content Expert Services.
        </li>
        <li>
          <p>
            <u>Expert Services; Payment of Expert Fees; Curated Platform Fees</u>. You are entitled
            to receive the compensation for the Expert Services you perform through the Curated
            platform, as applicable, as provided in this Agreement (the{' '}
            <strong>“Expert Fees”</strong>). The Expert Fees will be set forth from time to time in
            a rate card on the Curated Platform, which may have terms, conditions or rates
            specifically applicable to certain product categories or services to be provided, and
            which may be changed from time to time in the Company’s sole discretion. By continuing
            to use the Curated Platform, you are deemed to accept any such changes published on any
            rate card. The Company will use reasonable efforts to ensure that any Expert Fees owed
            to you will be paid to you on at least a semi-monthly basis. The Company reserves the
            right to adjust or withhold all or a portion of any Expert Fee or other payment owed to
            you (except tips) if we believe you have attempted to defraud or abuse Users or the
            Company or any part of the Company’s Platform or if you have violated any part of the
            Community Standards. You acknowledge and agree that all payments owed to you shall not
            include any interest and will be net of any amounts that we are required to withhold by
            law.
          </p>
          <p>
            In exchange for facilitating the Expert Services that you provide through the Curated
            platform, as applicable, you agree to pay to the Company (and permit the Company to
            retain) certain platform fees that may be defined from time to time. The amount of any
            such fees may vary by market or by product category or other factors, and shall be
            communicated to you in advance of the implementation of such a fee; and, if you don’t
            want to pay such fee, you will have the opportunity to terminate your Expert Services at
            that time.
          </p>
        </li>
        <li>
          <u>No Violation of Rights or Other Obligations</u>. You agree that in providing the Expert
          Services or using the Curated Platform, you will not violate any agreement with or rights
          of any third party or, except as expressly authorized by the Company in writing hereafter,
          use or disclose at any time your own or any third party’s confidential information or
          intellectual property in connection with the Expert Services or otherwise for or on behalf
          of the Company or bring any such information on to the Company’s premises or incorporate
          any such information into the Company’s systems or any deliverables resulting from the
          Expert Services.
        </li>
        <li>
          <u>Your Information</u>. Your Information is any information you provide, publish or post,
          and any information provided on your behalf, to or through the Curated Platform (including
          any profile information you provide) or send to other Experts or Users (including via
          in-application feedback, any email feature, or through any Company-related Facebook,
          Twitter or other social media posting) (your <strong>“Information”</strong>). You consent
          to us using your Information to create an Expert account that will allow you to use the
          Curated Platform and participate in the Expert Services. Our collection and use of
          personal information in connection with the Curated Platform and Expert Services is as
          provided in the Company’s Privacy Policy. You are solely responsible for your Information
          and your interactions with other members of the public, and we act only as a passive
          conduit for your online posting of your Information. You agree to provide and maintain
          accurate, current and complete Information and that we and other members of the public may
          rely on your Information as accurate, current and complete. To enable the Company to use
          your Information for the purposes described in the Privacy Policy and this Agreement, or
          to otherwise improve the Curated Platform or Expert Services you grant to us a fully-paid,
          non-exclusive, worldwide, perpetual, irrevocable, royalty-free, transferable,
          sub-licensable (through multiple tiers) right and license to exercise the copyright,
          publicity, and database rights you have in your Information, and to use, copy, perform,
          display and distribute such Information to prepare derivative works, or incorporate into
          other works, such Information, in any media now known or not currently known. Except as
          otherwise agreed between you and the Company, the Company does not assert any ownership
          over your Information; rather, as between you and the Company, subject to the rights
          granted to us in this Agreement or otherwise, you retain full ownership of all of your
          Information and any intellectual property rights or other proprietary rights associated
          with your Information.
        </li>
        <li>
          <p>
            <u>Intellectual Property</u>. All intellectual property rights in and to the Curated
            Platform shall be owned by the Company absolutely and in their entirety. These rights
            include database rights, inventions and patents, copyright, design rights (whether
            registered or unregistered), trademarks (whether registered or unregistered) and other
            similar rights wherever existing in the world, together with the right to apply for
            protection of the same. All other trademarks, logos, service marks, company or other
            product names set forth on the Curated Platform are the property of their respective
            owners. You acknowledge and agree that any questions, comments, suggestions, ideas,
            feedback or other information (<strong>“Submissions”</strong>) provided by you to us are
            non-confidential and shall become the sole property of the Company. The Company shall
            own exclusive rights, including all intellectual property rights, and shall be entitled
            to the unrestricted use and dissemination of these Submissions for any purpose,
            commercial or otherwise, without acknowledgment or compensation to you. Except for the
            explicit license grants hereunder, nothing in this Agreement shall be construed to
            transfer ownership of or grant a license under any intellectual property rights.
          </p>
          <p>
            CURATED and other Company logos, designs, graphics, icons, scripts and service names are
            registered trademarks, trademarks or trade dress of the Company in the United States
            and/or other countries (collectively, the <strong>“Company Marks”</strong>). The
            Company’s logo (or any Company Marks) may not be used in any manner that is likely to
            cause confusion, including but not limited to: use of a Company Mark in a domain name or
            a Company referral code, or use of a Company Mark as a social media handle or name,
            avatar, profile photo, icon, favicon, or banner. You may identify yourself as an Expert
            on the Curated Platform, but may not misidentify yourself as the Company, an employee of
            the Company, or a representative or agent of the Company (outside of your services as an
            Expert on the Curated Platform).
          </p>
          <p>
            You agree that you will not: (1) create any materials that use the Company Marks or any
            derivatives of the Company Marks as a trademark, service mark, trade name or trade
            dress, other than as expressly approved by the Company in writing; (2) use the Company
            Marks in any way that tends to impair their validity as proprietary trademarks, service
            marks, trade names or trade dress, or use the Company Marks other than in accordance
            with the terms, conditions and restrictions herein; (3) take any other action that would
            jeopardize or impair the Company’s rights as owner of the Company Marks or the legality
            and/or enforceability of the Company Marks, including, challenging or opposing the
            Company’s ownership in the Company Marks; (4) apply for trademark registration or
            renewal of trademark registration of any of the Company Marks, any derivative of the
            Company Marks, any combination of the Company Marks and any other name, or any
            trademark, service mark, trade name, symbol or word which is similar to the Company
            Marks; (5) use the Company Marks on or in connection with any product, service or
            activity that is in violation of any law, statute, government regulation or standard.
          </p>
          <p>
            You agree you will not manufacture, produce, print, sell, distribute, purchase, or
            display counterfeit/inauthentic Company Marks (including but not limited to signage,
            stickers, apparel, or decals) from any source other than directly from the Company.
          </p>
          <p>
            Violation of this provision may result in the Company’s sole discretion, a takedown
            request sent to the appropriate ISP, or social media platform, and/or a Uniform
            Domain-Name Dispute-Resolution Policy Proceeding (or equivalent proceeding). If you
            create any materials (physical or digital) bearing the Company Marks (in violation of
            this Agreement or otherwise), you agree that upon their creation the Company exclusively
            owns all right, title and interest in and to such materials, including any modifications
            to the Company Marks or derivative works based on the Company Marks or the Company’s
            copyrights. You further agree to assign any interest or right you may have in such
            materials to the Company, and to provide information and execute any documents as
            reasonably requested by the Company to enable the Company to formalize such assignment.
          </p>
        </li>
        <li>
          <u>Confidentiality</u>. You agree not to use any technical, financial, strategic and other
          proprietary and confidential information relating to the Company’s business, operations
          and properties, information about a User made available to you in connection with such
          User’s use of the Curated Platform, which may include the User’s name and contact
          information (<strong>“Confidential Information”</strong>) disclosed to you by the Company
          for your own use or for any purpose other than as contemplated herein. You shall not
          disclose or permit disclosure of any Confidential Information to third parties, and you
          agree not to store separate and outside of the Curated Platform any Confidential
          Information obtained from the Curated Platform. You agree to take all reasonable measures
          to protect the secrecy of and avoid disclosure or use of Confidential Information of the
          Company in order to prevent it from falling into the public domain. Notwithstanding the
          above, you shall not have liability to the Company with regard to any Confidential
          Information which you can prove: was in the public domain at the time it was disclosed by
          the Company or has entered the public domain through no fault of yours; was known to you,
          without restriction, at the time of disclosure, as demonstrated by files in existence at
          the time of disclosure; is disclosed with the prior written approval of the Company;
          becomes known to you, without restriction, from a source other than the Company without
          breach of this Agreement by you and otherwise not in violation of the Company’s rights; or
          is disclosed pursuant to the order or requirement of a court, administrative agency, or
          other governmental body; provided, however, that you shall provide prompt notice of such
          court order or requirement to the Company to enable the Company to seek a protective order
          or otherwise prevent or restrict such disclosure.
        </li>
        <li>
          <u> Warranties and Other Obligations</u>. You represent, warrant and covenant that: (i)
          the Expert Services will be performed in a professional and workmanlike manner and that
          none of such Expert Services nor any part of this Agreement is or will be inconsistent
          with any obligation that you may have to others; (ii) all work under this Agreement shall
          be your original work and none of the Expert Services nor any development, use,
          production, distribution or exploitation thereof will infringe, misappropriate or violate
          any intellectual property or other right of any person or entity (including, without
          limitation, yourself); (iii) you have the full right to provide the Company with the
          assignments and rights provided for herein; (iv) you shall comply with all applicable laws
          and the Company safety rules and Community Standards in the course of performing the
          Expert Services; (v) if your work requires a license, you have obtained that license and
          the license is in full force and effect; (vi) you agree that we may obtain information
          about you, including your criminal record if applicable, and you agree to provide any
          further necessary authorizations to facilitate our access to such records during the term
          of the Agreement; (vii) you will pay all applicable federal, state and local taxes based
          on your provision of the Expert Services and any Expert Fee received by you from time to
          time; and (viii) you will comply with the Company’s reasonable requests to provide
          information in connection with any User complaints, law enforcement requests or any other
          incident.
        </li>
        <li>
          <p>
            <u>Independent Contractor; No Employee Benefits; Non-Exclusive Relationship</u>. You
            hereby acknowledge and agree that, in providing any Expert Services and using the
            Curated Platform, you are an independent contractor (not an employee or other agent)
            solely responsible for the manner and hours in which Expert Services are performed,
            solely responsible for all taxes, withholdings, and other statutory, regulatory or
            contractual obligations of any sort (including, but not limited to, those relating to
            workers’ compensation, disability insurance, Social Security, unemployment compensation
            coverage, the Fair Labor Standards Act, income taxes, etc.), and are not entitled to
            participate in any employee benefit plans, fringe benefit programs, group insurance
            arrangements or similar programs. You hereby represent and warrant that you are an agent
            of your own business.
          </p>
          <p>
            <u>
              <strong>
                You further acknowledge and agree that you are not entitled to unemployment
                insurance benefits (unless unemployment compensation coverage is provided by you or
                some other entity), you are not entitled to workers' compensation benefits, and you
                are obligated to pay federal and state income tax on any moneys paid pursuant to
                this Agreement.
              </strong>
            </u>
          </p>
          <p>
            You represent that you have the qualifications and ability to perform the Expert
            Services in a professional manner, without the advice, control or supervision of the
            Company. You will obtain, at your sole expense, any necessary tools, materials,
            resources or outside assistance necessary for completion of the Expert Services. You
            will ensure that your employees, contractors and others that you engage to assist or
            support you in providing the Expert Services (but excluding, for clarity, any person who
            is directly engaged by Curated to provide Expert Services), if any, are bound in writing
            to the foregoing, and to all of your obligations under any provision of this Agreement,
            for the Company’s benefit, and you will be solely responsible for any noncompliance by
            them.
          </p>
          <p>
            The Company acknowledges that the Expert Services provided by you under this Agreement
            are not your exclusive activity. Consistent with your performance of the Expert Services
            hereunder, during the term of this Agreement, you may undertake such additional projects
            and perform services for such additional persons or companies as you see fit, so long as
            you are able to fulfill your obligations under this Agreement.
          </p>
        </li>
        <li>
          <u>Term; Termination</u>. This Agreement is effective upon an Expert’s use of the Curated
          Platform on or after January 22, 2024. This Agreement may be terminated: (a) by an Expert
          at any time; (b) by either Party immediately, without notice, upon the other Party’s
          material breach of this Agreement, including but not limited to any breach of Section 7 or
          Section 8 of this Agreement; or (c) by the Company for an Expert’s violation of the
          Community Standards and/or Company policies. In addition, the Company may deactivate your
          Expert account due to inactivity, subject to reactivation upon request, or due to a
          material change in the Company’s business or practices. Section 4 , Section 5, Section 6
          and Sections 9 through 14 of this Agreement and any remedies for breach of this Agreement
          shall survive any termination or expiration.
        </li>
        <li>
          <u>LIMITATION OF LIABILITY</u>. YOU MAY NOT SEEK, AND IN NO EVENT WILL THE COMPANY BE
          LIABLE TO YOU FOR, ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
          HOWEVER CHARACTERIZED, TO THE FULLEST EXTENT THE LAW PERMITS THIS DISCLAIMER AND EVEN IF
          THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES. IN NO EVENT WILL THE
          COMPANY’S CUMULATIVE AGGREGATE LIABILITY, UNDER ANY THEORY AND IRRESPECTIVE OF FAULT OR
          NEGLIGENCE, EXCEED THE AMOUNT OF, WHICHEVER IS LESS, (I) THE FEES PAID TO YOU IN THE FIRST
          THREE (3) MONTHS UNDER THIS AGREEMENT OR (II) THE TOTAL FEES PAID OR PAYABLE BY THE
          COMPANY PURSUANT TO THIS AGREEMENT.
        </li>
        <li>
          <u>Indemnification</u>. To the maximum extent permitted by law, you shall indemnify and
          hold the Company and its affiliates, and each of their respective representatives, agents,
          directors, managers, officers and employees harmless from and against any liabilities,
          losses, damages, claims, demands, actions and rights of action (including reasonable
          attorneys’ and paralegals’ fees and costs) related to (a) any and all actions by you
          outside the scope of this Agreement, (b) your gross negligence or willful misconduct, (c)
          any third-party claims alleging a breach of the third party’s intellectual property rights
          as a result of your provision of Expert Services, and (d) your breach of any
          representation, warranty or obligation stated in this Agreement. You shall fully cooperate
          as reasonably required in the defense of any claim.
        </li>
        <li>
          <p>
            <u>Dispute Resolution</u>.
          </p>
          <p>
            (a) In the event of any dispute, claim, question or disagreement arising from or
            relating to this Agreement or the breach thereof, or arising from any arrangement under
            this Agreement between you and the Company, the parties hereto shall use their best
            efforts to settle the dispute, claim, question or disagreement. To this effect, the
            parties shall consult and negotiate with one another in good faith, in an attempt to
            reach a just and equitable solution, satisfactory to all parties. If informal resolution
            of the dispute, claim, question or disagreement cannot be reached, disputes that are
            within the jurisdictional maximum for small claims will be settled in the California
            Small Claims Court. With regard to other disputes, the Company and you mutually agree to
            resolve any justiciable disputes between them exclusively through final and binding
            arbitration instead of a court or jury trial. This agreement to arbitrate is governed by
            the Federal Arbitration Act (9 U.S.C. §§ 1-16) and shall apply to any and all claims
            arising out of or relating to this Agreement, including without limitation your
            classification as an independent contractor, your provision of services through the
            Curated platform, the payments received by you for providing services through the
            Curated platform, the termination of this Agreement, and all other aspects of your
            relationship with the Company, past, present or future, whether arising under federal,
            state or local statutory and/or common law, including without limitation harassment,
            discrimination or retaliation claims and claims arising under or related to the Fair
            Credit Reporting Act, Defend Trade Secrets Act, Worker Adjustment and Retraining
            Notification Act, Civil Rights Act of 1964, Americans with Disabilities Act, Age
            Discrimination in Employment Act, Family and Medical Leave Act, Affordable Care Act,
            Uniformed Services Employment and Reemployment Rights Act, or Fair Labor Standards Act,
            state statutes or regulations addressing the same or similar subject matters, and all
            other federal or state legal claims, whether under a statute or the common law (for
            example, tort or contract claims) arising out of or relating to your relationship or the
            termination of that relationship with the Company (
            <strong>“Arbitrable Disputes”</strong>). However, this agreement to arbitrate does not
            apply to any claim that may not be arbitrated as provided by an Act of Congress or
            lawful, enforceable executive order.
          </p>
          <p>
            (b) Any Arbitrable Dispute arising out of this Agreement shall be exclusively settled by
            arbitration administered by the American Arbitration Association (<strong>“AAA”</strong>
            ) under its Commercial Arbitration Rules, except that disputes involving $75,000 or less
            shall use the AAA’s Expedited Rules. The parties shall mutually agree upon a single
            commercial arbitrator, and in the absence of agreement, the AAA shall select an
            arbitrator. The place of arbitration shall be San Francisco, California. Each party
            shall, upon written request of the other party, promptly provide the other with copies
            of all relevant documents. There shall be no other discovery allowed. The award of the
            arbitrators shall be accompanied by a reasoned opinion. Judgment on an arbitration award
            may be entered in accordance with the Federal Arbitration Act in any federal court with
            jurisdiction. The parties agree to participate in the AAA’s optional appellate process
            should one party so desire, at such party’s expense.{' '}
            <strong>
              YOU UNDERSTAND THAT THE FOREGOING AGREEMENT TO ARBITRATE SERVES AS A WAIVER OF YOUR
              RIGHT TO A JURY TRIAL.
            </strong>
          </p>
          <p>
            (c) If either party wishes to initiate arbitration, the initiating party must notify the
            other party in writing via certified mail, return receipt requested, within the
            applicable statute of limitations period. This demand for arbitration must include (1)
            the name and address of the party seeking arbitration, (2) a statement of the legal and
            factual basis of the claim, and (3) a description of the remedy sought. Any demand for
            arbitration shall be delivered to the address indicated in the notice terms of this
            Agreement, unless that address has been updated, in writing.
          </p>
          <p>
            (d) Class Action Waiver. You and the Company each hereby agree to bring any claim or
            dispute in arbitration on an individual basis only, and not as a class or collective
            action, and there will be no right or authority for any claim or dispute to be brought,
            heard or arbitrated as a class or collective action. Notwithstanding any other clause
            contained in this Agreement or the AAA rules, as set forth below, any claim that all of
            part of this Class Action Waiver is unenforceable, unconscionable, void or voidable may
            be determined only by a court of competent jurisdiction and not by an arbitrator. In any
            case in which (1) the dispute is filed as a class, collective, representative or private
            attorney general action, and (2) there is a final judicial determination that all or
            part of the Class Action Waiver is unenforceable, the class, collective, representative
            and/or private attorney general action to that extent must be litigated in a civil court
            of competent jurisdiction, ut the portion of the Class Action Waiver that is enforceable
            shall be enforced in arbitration. This Agreement does not prevent the filing of charges
            with a government agency like the Department of Labor, NLRB, or the EEOC or
            participation in any investigation or proceeding conducted by a government agency.
          </p>
        </li>
        <li>
          <u>Assignment</u>. Neither party may assign this Agreement, in whole or in part, without
          the prior written consent of the other party.
        </li>
        <li>
          <u>Notice</u>. Except as otherwise explicitly stated, any notices to the Company shall be
          given by certified mail, postage prepaid and return receipt requested to Curated, Inc.,
          638 4th Street, San Francisco, CA 94107, Attn: LEGAL. Any notices to you shall be provided
          to you through the Curated Platform or given to you via the email address or physical
          address you provide to the Company during the registration process.
        </li>
        <li>
          <u>Miscellaneous</u>. Any breach of Section 5 or Section 6 will cause irreparable harm to
          the Company for which damages would not be an adequate remedy, and therefore, the Company
          will be entitled to injunctive relief with respect thereto (without being required to post
          a bond or prove actual damages) in addition to any other remedies. The failure of either
          party to enforce its rights under this Agreement at any time for any period shall not be
          construed as a waiver of such rights. In the event that any provision of this Agreement
          shall be determined to be illegal or unenforceable, that provision will be limited or
          eliminated to the minimum extent necessary so that this Agreement shall otherwise remain
          in full force and effect and enforceable. This Agreement shall be governed by and
          construed in accordance with the laws of the State of California without regard to the
          conflicts of laws provisions thereof. In any action or proceeding to enforce rights under
          this Agreement, the prevailing party will be entitled to recover costs and reasonable
          attorneys’ fees. Headings herein are for convenience of reference only and shall in no way
          affect interpretation of this Agreement.
        </li>
        <li>
          <u>Modification</u>. The Company may modify this Agreement at any time. When the Company
          makes material changes to this Agreement, it will provide Experts with notice of any
          material changes before the revised Agreement becomes effective. If an Expert disagrees
          with the revised Agreement, the Expert may discontinue use of the Curated Platform. If an
          Expert continues to access the Curated Platform and/or provide services through the
          Curated Platform, Expert’s continued access to or use of the Curated Platform will
          constitute acceptance of the revised Agreement. Curated may modify information in any of
          its other cited policies, including its Community Standards, from time to time, and such
          modifications shall be effective upon posting. Continued use of the Curated Platform after
          any such changes shall constitute an Expert’s consent to such changes.
        </li>
      </ol>
      <Waypoint bottomOffset={-10} onEnter={onScrolledToBottom} />
    </div>
  )
}

export default TermsOfUseAgreementModal
