import { Event, ViewerContext } from '@deal/web-tracking'

export interface IntelligentFollowUpNudgePopoverViewLeadsButtonClickedEventProperties {
  expert_id: string
}

export class IntelligentFollowUpNudgePopoverViewLeadsButtonClickedEvent extends Event<
  IntelligentFollowUpNudgePopoverViewLeadsButtonClickedEventProperties
> {
  static displayName = 'Nudge Popover View Leads Button Clicked'

  static allowedViewerContexts = [ViewerContext.BusinessUser]
}
