// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CountOpenLeadEscalationsQueryVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
}>;


export type CountOpenLeadEscalationsQuery = { __typename: 'Query', countOpenLeadEscalations: number };


export const CountOpenLeadEscalationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"countOpenLeadEscalations"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countOpenLeadEscalations"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}}]}]}}]} as unknown as DocumentNode;
export type CountOpenLeadEscalationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>, 'query'> & ({ variables: CountOpenLeadEscalationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CountOpenLeadEscalationsComponent = (props: CountOpenLeadEscalationsComponentProps) => (
      <ApolloReactComponents.Query<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables> query={CountOpenLeadEscalationsDocument} {...props} />
    );
    

/**
 * __useCountOpenLeadEscalationsQuery__
 *
 * To run a query within a React component, call `useCountOpenLeadEscalationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountOpenLeadEscalationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountOpenLeadEscalationsQuery({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useCountOpenLeadEscalationsQuery(baseOptions: Apollo.QueryHookOptions<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>(CountOpenLeadEscalationsDocument, options);
      }
export function useCountOpenLeadEscalationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>(CountOpenLeadEscalationsDocument, options);
        }
export function useCountOpenLeadEscalationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>(CountOpenLeadEscalationsDocument, options);
        }
export type CountOpenLeadEscalationsQueryHookResult = ReturnType<typeof useCountOpenLeadEscalationsQuery>;
export type CountOpenLeadEscalationsLazyQueryHookResult = ReturnType<typeof useCountOpenLeadEscalationsLazyQuery>;
export type CountOpenLeadEscalationsSuspenseQueryHookResult = ReturnType<typeof useCountOpenLeadEscalationsSuspenseQuery>;
export type CountOpenLeadEscalationsQueryResult = Apollo.QueryResult<CountOpenLeadEscalationsQuery, CountOpenLeadEscalationsQueryVariables>;