import React, { useEffect, useState } from 'react'
import { Image } from '@deal/components'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useCatalogPanelLazyQuery } from './CatalogPanel.generated'
import SearchTextField from '../SearchTextField'
import ResultsPlaceholder from '../ResultsPlaceholder'
import ResultsContainer, { decrementMaybeNumber, incrementMaybeNumber } from '../ResultsContainer'
import Magnifier from '../Magnifier'
import { useMediaSearchControlContext } from '../context'
import styles from './styles.css'

const SELLABLES_PER_FETCH = 6

export interface CatalogProps {
  attachMediaUrl: (url: string, titleForMediaAsset?: string, sharedByUserId?: string) => void
  onModalClose?: () => void
}

const Catalog: React.FC<React.PropsWithChildren<CatalogProps>> = ({ attachMediaUrl }) => {
  const {
    department: {
      topLevelCategory: { id: categoryId }
    },
    id: currentBusinessUserId
  } = useBusinessUser()
  const { setIsPopoverVisible } = useMediaSearchControlContext()
  const [zoomImageSourceIndex, setZoomImageSourceIndex] = useState<number>()
  const [keywords, setKeywords] = useState('')
  const [searchedKeywords, setSearchedKeywords] = useState('')
  const [searchForItems, { data, fetchMore, loading, called }] = useCatalogPanelLazyQuery()

  useEffect(() => {
    searchedKeywords &&
      searchForItems({
        variables: {
          query: { categoryIds: [categoryId], keywords: searchedKeywords },
          first: SELLABLES_PER_FETCH
        }
      })
  }, [searchedKeywords])

  const handleSearch = () => {
    if (keywords) {
      setSearchedKeywords(keywords)
      searchForItems({
        variables: {
          query: { categoryIds: [categoryId], keywords: searchedKeywords },
          first: SELLABLES_PER_FETCH
        }
      })
    }
  }

  const handleImageSelected = (url: string) => {
    attachMediaUrl(url, searchedKeywords, currentBusinessUserId)
  }

  const sellables = data?.searchSellables.edges.map(edges => edges.node) || []
  const images = sellables?.reduce<{ url: string; title: string }[]>((images, sellable) => {
    if (sellable.images) {
      return [
        ...images,
        ...sellable.images.map(image => ({ url: image.url, title: sellable.title }))
      ]
    } else {
      return images
    }
  }, [])

  const pageInfo = data?.searchSellables.pageInfo
  const zoomedImageUrl =
    zoomImageSourceIndex === undefined ? zoomImageSourceIndex : images[zoomImageSourceIndex].url
  const zoomedImageIsTheLastQueriedImage = images.length - 1 === zoomImageSourceIndex
  const imagesExist = images.length > 0

  return (
    <div>
      <div className={styles.searchRow}>
        <SearchTextField
          textFieldProps={{
            label: 'Catalog search',
            labelHidden: true,
            onChange: e => setKeywords(e.target.value),
            inputProps: {
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }
            }
          }}
          buttonProps={{
            onClick: () => handleSearch(),
            variant: 'secondary',
            disabled: keywords === searchedKeywords
          }}
        />
      </div>
      <ResultsContainer
        zoomImageSource={zoomedImageUrl}
        loading={loading}
        onZoomedImageSelected={() => {
          handleImageSelected(zoomedImageUrl!)
          setZoomImageSourceIndex(undefined)
        }}
        onNextZoomedImageRequested={
          zoomedImageIsTheLastQueriedImage && !pageInfo?.hasNextPage
            ? undefined
            : () => {
                if (zoomedImageIsTheLastQueriedImage && pageInfo?.hasNextPage) {
                  fetchMore?.({
                    variables: { after: pageInfo.endCursor, first: SELLABLES_PER_FETCH }
                  }).then(() => {
                    setZoomImageSourceIndex(incrementMaybeNumber)
                  })
                } else {
                  setZoomImageSourceIndex(incrementMaybeNumber)
                }
              }
        }
        onPreviousZoomedImageRequested={
          zoomImageSourceIndex !== 0
            ? () => setZoomImageSourceIndex(decrementMaybeNumber)
            : undefined
        }
        onModalClose={() => {
          setZoomImageSourceIndex(undefined)
        }}
        onScrollToEndOfResults={
          pageInfo?.hasNextPage
            ? () => {
                fetchMore?.({
                  variables: { after: pageInfo.endCursor, first: SELLABLES_PER_FETCH }
                })
              }
            : undefined
        }
      >
        {called ? (
          <>
            {imagesExist ? (
              images.map((image, idx) => {
                return (
                  <div
                    className={styles.result}
                    onClick={() => {
                      handleImageSelected(image.url)
                      setIsPopoverVisible(false)
                    }}
                  >
                    <div key={`${image.url}-${idx}`} className={styles.imageContainer}>
                      <Image src={image.url} size="240px" />
                      <div className={styles.magnifierContainer}>
                        <Magnifier
                          onClick={() => {
                            setZoomImageSourceIndex(idx)
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.imageTitle}>{image.title}</div>
                  </div>
                )
              })
            ) : (
              <div className={styles.noResults}>No results found - try another search</div>
            )}
          </>
        ) : (
          <ResultsPlaceholder placeholderText="Search the catalog" />
        )}
      </ResultsContainer>
    </div>
  )
}

const CatalogPanelModule = {
  title: 'Catalog',
  Display: Catalog
}

export default CatalogPanelModule
